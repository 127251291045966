import styled from 'styled-components'
import { Paper } from '@mui/material'

export const StylePaper = styled(Paper)`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  height: 100%;
  padding: 24px;
`
export const StyleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
`

export const StyleListRight = styled.div`
  width: 80%;
  display: flex;
  gap: 20px;
`
export const StyleListLeft = styled.div`
  width: 20%;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 16px;
  height: 240px;
  padding-top: 46px;
`

export const StyleTitle = styled.h2`
  font-size: 25px;
  font-weight: bold;
  color: var(--color-primary);
  padding-bottom: 16px;
`

export const StyleAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`

export const StyleItemRight = styled.div``
export const StyleItemLeft = styled.div``
