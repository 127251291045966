export const dataAvailable = [
  {
    ca: 'shift1',
    order: 1,
    t2: false,
    t3: false,
    t4: false,
    t5: false,
    t6: false,
    t7: false,
    t8: false,
    caFree: '7',
    caLR: '0/0',
  },
  {
    ca: 'shift2',
    order: 2,
    t2: false,
    t3: false,
    t4: false,
    t5: false,
    t6: false,
    t7: false,
    t8: false,
    caFree: '7',
    caLR: '0/0',
  },
  {
    ca: 'shift3',
    order: 3,
    t2: false,
    t3: false,
    t4: false,
    t5: false,
    t6: false,
    t7: false,
    t8: false,
    caFree: '7',
    caLR: '0/0',
  },
]

export const typeFile = [
  {
    value: 'identityCard',
    label: 'CCCD/CMND',
  },
  {
    value: 'license',
    label: 'Bằng lái xe',
  },
]

export const statusStaff = [
  {
    value: 'Doing',
    label: 'Đang làm',
  },
  {
    value: 'TakeABreak',
    label: 'Tạm nghỉ',
  },
  {
    value: 'quitOneIsJob',
    label: 'Nghỉ việc',
  },
]

export const operatingStatusStaff = [
  {
    value: 'active',
    label: 'Đang hoạt động',
  },
  {
    value: 'accountLock',
    label: 'Khoá tài khoản',
  },
  {
    value: 'TemporarilyLockTheCalendar',
    label: 'Tạm khoá nhận lịch',
  },
]

export const theReasonStaff = [
  {
    value: 'seriousViolation',
    label: 'Vi phạm nghiệm trọng',
  },
  {
    value: 'violatedManyTimes',
    label: 'Vi phạm nhiều lần',
  },
  {
    value: 'sick',
    label: 'Bị ốm',
  },
]

export const skills = [
  {
    value: 'cook',
    title: 'Nấu ăn',
  },
  {
    value: 'CleanUp',
    title: 'Dọn dẹp',
  },
  {
    value: 'wiperGlass',
    title: 'Làm kính',
  },
]

export const bank = [
  {
    value: 'techcombank',
    label: 'Techcombank',
  },
  {
    value: 'vietcombank',
    label: 'Vietcombank',
  },
  {
    value: 'abc',
    label: 'ABC',
  },
]

export const pileStatus = [
  {
    value: 'noDeposit',
    label: 'Chưa cọc',
  },
  {
    value: 'depositing',
    label: 'Đang cọc',
  },
  {
    value: 'RefundOfDeposit',
    label: 'Hoàn cọc',
  },
  {
    value: 'feesCCDC',
    label: 'Phí CCDC',
  },
]

export const source = [
  {
    value: 'facebook',
    label: 'Facebook',
  },
  {
    value: 'google',
    label: 'Google',
  },
  {
    value: 'news',
    label: 'Bản tin',
  },
]

export const maritalStatus = [
  {
    value: 'single',
    label: 'Độc thân',
  },
  {
    value: 'marry',
    label: 'Kết hôn',
  },
  {
    value: 'divorce',
    label: 'Ly dị',
  },
  {
    value: 'widow',
    label: 'Goá phụ',
  },
  {
    value: 'remarried',
    label: 'Tái hôn',
  },
]

export const typePaper = [
  {
    value: 'citizenId',
    label: 'Căn cước công dân',
  },
  {
    value: 'identityCard',
    label: 'Chứng minh nhân dân',
  },
  {
    value: 'passPort',
    label: 'Hộ chiếu',
  },
]

export const gender = [
  {
    value: 'male',
    label: 'Nam',
  },
  {
    value: 'female',
    label: 'Nữ',
  },
]

export const dataRecruiter = [
  {
    value: '1',
    title: 'Nguyễn văn A',
  },
  {
    value: '2',
    title: 'Nguyễn văn B',
  },
  {
    value: '3',
    title: 'Nguyễn văn C',
  },
  {
    value: '4',
    title: 'Nguyễn văn D',
  },
  {
    value: '5',
    title: 'Nguyễn văn E',
  },
]

export const dataHR = [
  {
    value: '1',
    title: 'Nguyễn văn A',
  },
  {
    value: '2',
    title: 'Nguyễn văn B',
  },
  {
    value: '3',
    title: 'Nguyễn văn C',
  },
  {
    value: '4',
    title: 'Nguyễn văn D',
  },
  {
    value: '5',
    title: 'Nguyễn văn E',
  },
]

export const dataLeader = [
  {
    value: '1',
    title: 'Nguyễn văn A',
  },
  {
    value: '2',
    title: 'Nguyễn văn B',
  },
  {
    value: '3',
    title: 'Nguyễn văn C',
  },
  {
    value: '4',
    title: 'Nguyễn văn D',
  },
  {
    value: '5',
    title: 'Nguyễn văn E',
  },
]

export const labelColor = [
  {
    value: '#009900',
    label: 'Màu 1',
  },
  {
    value: '#336666',
    label: 'Màu 2',
  },
  {
    value: '#FF6699',
    label: 'Màu 3',
  },
  {
    value: '#666600',
    label: 'Màu 4',
  },
  {
    value: '#FFCC33',
    label: 'Màu 5',
  },
  {
    value: '#FF9966',
    label: 'Màu 6',
  },
  {
    value: '#FF6600',
    label: 'Màu 7',
  },
  {
    value: '#3366CC',
    label: 'Màu 8',
  },
  {
    value: '#9900FF',
    label: 'Màu 9',
  },
  {
    value: '#0033CC',
    label: 'Màu 10',
  },
  {
    value: '#FF0000',
    label: 'Màu 10',
  },
]

export const rankType = [
  {
    value: '1',
    label: 'Member',
  },
  {
    value: '2',
    label: 'Silver',
  },
  {
    value: '3',
    label: 'Gold',
  },
  {
    value: '4',
    label: 'Platinum',
  },
  {
    value: '5',
    label: 'Premium',
  },
]

export const aspectStatus = [
  {
    value: 'active',
    label: 'Đang hoạt động',
  },
  {
    value: 'shutDown',
    label: 'Ngưng hoạt động',
  },
  {
    value: 'suspendOperations',
    label: 'Tạm ngưng hoạt động',
  },
]

export const workStatus = [
  {
    value: 'contract',
    label: 'Ký thoả thuận',
  },
  {
    value: 'doing',
    label: 'Đang làm',
  },
  {
    value: 'pause',
    label: 'Tạm nghĩ',
  },
  {
    value: 'inactivity',
    label: 'Nghỉ việc',
  },
]

export const filterDateTime = [
  {
    value: 'contractSigningDate',
    label: 'Ngày Ký hợp đồng',
  },
  {
    value: 'firstDayOfWork',
    label: 'Ngày đầu tiên làm việc',
  },
  {
    value: 'birthDay',
    label: 'Ngày sinh nhật',
  },
  {
    value: 'monthlyWorkingHours',
    label: 'Giờ công tháng',
  },
  {
    value: 'workingHoursLastMonth',
    label: 'Giờ công tháng trước',
  },
  {
    value: 'accumulatedWorkingHoursOfTheYear',
    label: 'Giờ công luỹ kế năm',
  },
  {
    value: 'numberOfStars',
    label: 'Số sao',
  },
]

export enum FilterStaffType {
  ALL = 'all',
  BD = 'birthDay',
  FDOW = 'firstDayOfWork',
  CSD = 'contractSigningDate',
  MWH = 'monthlyWorkingHours',
  WHLM = 'workingHoursLastMonth',
  AWHOTY = 'accumulatedWorkingHoursOfTheYear',
  STAR = 'numberOfStars',
}

export const shiftName = {
  shift1: 'Sáng',
  shift2: 'Chiều',
  shift3: 'Tối',
}
