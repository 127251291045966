import CustomAlert from 'components/Alert'
import { AlertProvider } from 'contexts/AlertContext'
import { LoadingProvider } from 'contexts/LoadingContext'
import { SidebarProvider } from 'contexts/SideBarContext'
import { BrowserRouter } from 'react-router-dom'
import './App.css'
import CustomLoading from './components/Loading/index'
import Routers from './routes/index'

function App() {
  return (
    <SidebarProvider>
      <LoadingProvider>
        <CustomLoading />
        <AlertProvider>
          <CustomAlert />
          <BrowserRouter>
            <Routers />
          </BrowserRouter>
        </AlertProvider>
      </LoadingProvider>
    </SidebarProvider>
  )
}

export default App
