import axios from 'axios'
import axiosClient from '../utils/axios'
import { API_MAP_4D_URL } from 'constants/common'
import { KEY_MAP_4D } from 'constants/common'
import queryString from 'query-string'

const staffApi = {
  getLabel: () => {
    const url = `/staff/label?sortType=DESC`
    return axiosClient.get(url)
  },

  getLabelById: (id: string) => {
    const url = `/staff/label/${id}`
    return axiosClient.get(url)
  },

  createLabel: (data: any) => {
    const url = `/staff/label`
    return axiosClient.post(url, data)
  },

  updateLabel: (data: any, id: string) => {
    const url = `/staff/label/${id}`
    return axiosClient.put(url, data)
  },

  deleteLabel: (id: string) => {
    const url = `/staff/label/${id}`
    return axiosClient.delete(url)
  },

  getIdentifierLabel: () => {
    const url = `/staff/identifierLabel?sortType=DESC`
    return axiosClient.get(url)
  },

  getIdentifierLabelById: (id: string) => {
    const url = `/staff/identifierLabel/${id}`
    return axiosClient.get(url)
  },

  createIdentifierLabel: (data: any) => {
    const url = `/staff/identifierLabel`
    return axiosClient.post(url, data)
  },

  updateNameIdentifierLabel: (data: any, id: string) => {
    const url = `/staff/identifierLabel/${id}`
    return axiosClient.put(url, data)
  },

  updateStatusIdentifierLabel: (id: string) => {
    const url = `/staff/identifierLabelStatus/${id}`
    return axiosClient.patch(url)
  },

  deleteIdentifierLabel: (id: string) => {
    const url = `/staff/identifierLabel/${id}`
    return axiosClient.delete(url)
  },

  getLocation: (text: any) => {
    const url = `${API_MAP_4D_URL}?key=${KEY_MAP_4D}&text=${text}`
    return axios.get(url)
  },

  getStaff: (params: any) => {
    const url =
      `/staff?` +
      queryString.stringify(
        {
          page: params.page,
          limit: params.limit,
          sortType: 'DESC',
          search: params.search,
          rank: params.rank,
          service: params.service,
          district: params.district,
          status: params.status,
          activeStatus: params.activeStatus,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      ) +
      `&birth[startDate]=${params?.birthStartDate || ''}&birth[endDate]=${
        params?.birthEndDate || ''
      }&startDate[startDate]=${
        params.startStartDate || ''
      }&startDate[endDate]=${
        params.startEndDate || ''
      }&signingDate[startDate]=${
        params.signingStartDate || ''
      }&signingDate[endDate]=${
        params.signingEndDate || ''
      }&metrics[startDate]=${
        params.monthlyWorkingHoursStartOfMonth ||
        params.workingHoursLastMonthStartOfPreviousMonth ||
        params.accumulatedWorkingHoursOfTheYearStartOfYear ||
        ''
      }&metrics[endDate]=${
        params.monthlyWorkingHoursEndOfMonth ||
        params.workingHoursLastMonthEndOfPreviousMonth ||
        params.accumulatedWorkingHoursOfTheYearEndOfYear ||
        ''
      }&metrics[from]= ${
        params.monthlyWorkingHoursFrom ||
        params.workingHoursLastMonthFrom ||
        params.accumulatedWorkingHoursOfTheYearFrom ||
        ''
      }&metrics[to]= ${
        params.monthlyWorkingHoursTo ||
        params.workingHoursLastMonthTo ||
        params.accumulatedWorkingHoursOfTheYearTo ||
        ''
      }&star[from]= ${params.starFrom || ''}&star[to]= ${params.starTo || ''}`

    return axiosClient.get(url, params)
    // return axiosClient.get(url, {
    //   params: params,
    //   headers: { timezone: 'Asia/Ho_Chi_Minh' },
    // })
  },

  createStaff: (data: any) => {
    const url = `/staff`
    return axiosClient.post(url, data)
  },

  getStaffPriority: (params: any) => {
    const url =
      `/staff?` +
      queryString.stringify(
        {
          page: params.page,
          limit: params.limit,
          sortBy: 'priority',
          sortType: 'DESC',
        },
        {
          skipNull: true,
          skipEmptyString: true,
        }
      )
    return axiosClient.get(url, params)
  },

  getService: () => {
    const url = `/service`
    return axiosClient.get(url)
  },

  getStaffById: (id: string) => {
    const url = `/staff/${id}`
    return axiosClient.get(url)
  },

  updateStaff: (data: any, id: string) => {
    const url = `/staff/${id}`
    return axiosClient.put(url, data)
  },

  delete: (id: string) => {
    const url = `/staff/delete/${id}`
    return axiosClient.delete(url)
  },
}

export default staffApi

export const workCalendar = {
  create: (data: any, id: string) => {
    const url = `/staff/registerSchedule/${id}`
    return axiosClient.post(url, data)
  },
}

export const performance = {
  getMetricsById: (id: string) => {
    const url = `/staff/performance/${id}`
    return axiosClient.get(url)
  },
}
