import axios from 'axios'
import axiosClient from '../utils/axios'
import { API_MAP_4D_URL, KEY_MAP_4D } from 'constants/common'

const orderAPI = {
  getLocation: (text: any) => {
    const url = `${API_MAP_4D_URL}?key=${KEY_MAP_4D}&text=${text}`
    return axios.get(url)
  },
  getAddLocation: (userId: any) => {
    const url = `location/?userId=${userId}&nonPaging=true`
    return axiosClient.get(url)
  },
  getOrder: (params: any) => {
    const url = `/orders/?page=${params.page}&limit=${params.limit}&sortBy=createdAt&sortType=DESC&serviceId=${params.serviceId}&status=${params.status}&search=${params.search}&minPrice=${params.minPrice}&maxPrice=${params.maxPrice}&dateCreateOrder[startDate]=${params.dateCreateOrderStart}&dateCreateOrder[endDate]=${params.dateCreateOrderEnd}
    `
    return axiosClient.get(url, params)
  },
  getOrderId: (id: string) => {
    const url = `/orders/${id}`
    return axiosClient.get(url)
  },
  updateOrder: (data: any, id: string) => {
    const url = `/orders/${id}`
    return axiosClient.put(url, data)
  },
  getUser: (params: any) => {
    const url = `/users/?page=1&limit=10000000000&search=${params.search}`
    return axiosClient.get(url, params)
  },
  getServices: () => {
    const url = `/service?sortType=ASC&sortBy=kind`
    return axiosClient.get(url)
  },
  getServiceById: (id: string) => {
    const url = `/service/${id}`
    return axiosClient.get(url)
  },
  getStaff: (params: any) => {
    const url = `/staff/?page=1&limit=10000000000&search=${
      params.search ?? ''
    }&displayId=${params.displayId ?? ''}`
    return axiosClient.get(url)
  },

  createOrder: (data: any) => {
    const url = `/orders/`
    return axiosClient.post(url, data)
  },
  deleteOrder: (id: number) => {
    const url = `/orders/${id}`
    return axiosClient.delete(url)
  },
  createCalcOrder: (data: any) => {
    const url = `/orders/calc`
    return axiosClient.post(url, data)
  },
  addLocation: (data: any) => {
    const url = `/location`
    return axiosClient.post(url, data)
  },
  addUser: (data: any) => {
    const url = `/users/signup`
    return axiosClient.post(url, data)
  },
}
export default orderAPI
