import DoneAllIcon from '@mui/icons-material/DoneAll'
import RemoveDoneIcon from '@mui/icons-material/RemoveDone'
import SmsFailedOutlinedIcon from '@mui/icons-material/SmsFailedOutlined'

export interface IObjectPaymentStatus {
  paid: string
  unpaid: string
  failed: string
}

export const paymentStatusOrder: IObjectPaymentStatus = {
  paid: 'Đã thanh toán',
  unpaid: 'Chưa thanh toán',
  failed: 'Thất bại',
}

export const colorPaymentStatus: IObjectPaymentStatus = {
  paid: '#5cb85c',
  unpaid: '#e23900',
  failed: '#ff0000',
}

export const iconsPaymentStatus: Record<string, any> = {
  paid: <DoneAllIcon sx={{ fontSize: '20px' }} />,
  unpaid: <RemoveDoneIcon sx={{ fontSize: '20px' }} />,
  failed: <SmsFailedOutlinedIcon sx={{ fontSize: '20px' }} />,
}
