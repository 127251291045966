import styled from 'styled-components'

export const StyleError = styled.p`
  display: block;
  color: red;
  font-size: 14px;
  text-align: left;
  white-space: normal;
`
export const StyleInput = styled.div`
  width: 100%;
`
