import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import background from '../../assets/images/Background.jpg'
import { Button } from '@material-ui/core'

export const FormLogin = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: gray;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
`
export const StyleLogin = styled.div`
  padding: 40px 50px;
  margin: 20px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 50%);
  background-color: #fff;
`

export const TitleLogin = styled(Typography)`
  && {
    font-size: 2.3rem;
    color: #6c7cec;
    font-family: 'Great Vibes', cursive;
    font-weight: normal;
    margin-top: -10px;
    text-align: center;
    text-shadow: 0 1px 1px #fff;
  }
`
export const Heading = styled.div`
  padding-bottom: 24px;
`

export const LabelLogin = styled.div`
  display: block;
  font-size: 17px;
  color: #000000;
  font-weight: 500;
  text-align: left;
  padding-bottom: 10px;
`
export const ContentInput = styled.div`
  white-space: nowrap;
  position: relative;
`
export const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 2px solid #000000;
  outline: none;
  font-size: 14px;
  background-color: transparent;
  color: #000000;
  text-align: left;
`
export const SpanEye = styled.p`
  color: #858282;
  margin-top: 25px;
  position: absolute;
  z-index: 1;
  left: 93%;
  top: 0;
  cursor: pointer;
  svg {
    height: 17px;
  }
`
export const LoginButton = styled.button`
  margin: 40px auto;
  border: none;
  outline: none;
  width: 100%;
  height: 50px;
  text-align: center;
  padding: 5px 0;
  font-size: 20px;
  background: #ffffff;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;
  letter-spacing: 0.08em;
  transition: 0.3s ease-in-out 0s;
  box-shadow: 0 5px 30px 0 rgb(3 216 222 / 20%);
  position: relative;
  &::before {
    transition: all 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    content: '';
    border-radius: 25px 0px 0px 25px;
    width: 50%;
    height: 100%;
    background: #a64bf4;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:hover {
    &::before {
      border-radius: 25px;
      background: black;
      width: 100%;
    }
  }
`
export const ButtonText = styled.span`
  color: #fff;
  mix-blend-mode: difference;
`
export const StyleForgot = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
export const StyleForgotPwd = styled(Typography)`
  && {
    font-size: 16px;
    margin-top: -8px;
    color: #6e96ed;
    margin-bottom: 8px;
    width: fit-content;
  }
  &:hover {
    cursor: pointer;
  }
`
export const Forgot = styled.div`
  padding-bottom: 31px;
  padding-top: 8px;
`
export const ForgotLink = styled.a`
  padding-bottom: 31px;
  padding-top: 8px;
`
export const StyleError = styled.p`
  display: block;
  color: red;
  font-size: 14px;
  margin: 5px 0;
  text-align: left;
  white-space: normal;
  margin-top: 10px;
  margin-bottom: 0;
`
export const SendButton = styled(Button)`
  && {
    margin-top: 5px;
    background: linear-gradient(45deg, #8302f4 30%, #a64bf4 90%);
    border: 0;
    border-radius: 3;
    box-shadow: 0 5px 30px 0 rgb(3 216 222 / 20%);
    color: #fff;
    height: 54px;
    text-transform: none;
    font-size: 16px;
    transform: scale(1);
    transition: transform 0.3s ease-out;
    &:hover {
      transform: scale(1.05);
    }
  }
`
export const Description = styled(Typography)`
  && {
    opacity: 0.8;
  }
`
