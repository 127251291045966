import { Typography } from '@mui/material'
import styled from 'styled-components'

export const StyleError = styled.p`
  display: flex;
  color: red;
  font-size: 14px;
  white-space: normal;
`
export const StyleImage = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
  margin: auto !important;
`
export const ButtonImg = styled.button`
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: all 200ms;
  &:hover {
    opacity: 0.3;
    transform: scale(1.1);
  }
`
export const InputQR = styled.input`
  width: 200px;
  padding-top: 10px;
  margin: auto;
`
export const StyleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
`
export const StyleAddItem = styled.div`
  display: flex;
  margin-left: 12px;
`

export const StyleLinks = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  background: #fff;
  min-width: 50px;
  border-radius: 10px;
  border: none;
  padding: 10px 15px;
  margin-right: 10px;
  transition: 0.1s ease-in-out;
  text-transform: none;
  &:hover {
    transform: scale(1.02);
  }
`
export const StyleTitle = styled.h1`
  font-size: 35px;
  color: #33303c;
`
export const StyleLink = styled.span`
  margin-top: 2px;
  color: #000;
`
export const StyleInput = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`
export const StyleInputMaxWidth = styled.div`
  max-width: 205px;
`
export const StyleTabPanel = styled.div`
  margin-top: -24px;
  margin-bottom: -24px;
  width: 100%;
`

export const StyleLabel = styled(Typography)`
  && {
    font-weight: 500 !important;
    line-height: 1.2;
    text-align: left;
    font-size: 1rem;
    color: #33303cad;
    margin-bottom: 14px;
  }
`
export const StyleWidthInput = styled.div`
  width: 31.5%;
`
