import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { styleModal } from './style'

interface IModal {
  children: React.ReactNode
  open: boolean
  handleClose?: () => void
}

export default function BasicModal({ children, open, handleClose }: IModal) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>{children}</Box>
      </Modal>
    </div>
  )
}
