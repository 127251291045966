import styled from 'styled-components'
import { FormHelperText } from '@mui/material'

export const StyleForm = styled.form`
  display: flex;
  gap: 24px;
`
export const StyleContentRight = styled.div`
  flex-direction: column;
  width: 100%;
`

export const StyleHeaderItem = styled.div`
  color: #333333;
  display: flex;
  justify-content: space-between;
`

export const StyleTitle = styled.h2`
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 24px;
`

export const StyleImage = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`
export const ButtonImg = styled.button`
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: all 200ms;
  &:hover {
    opacity: 0.3;
    transform: scale(1.05);
  }
`

export const StyleAddImage = styled.label`
  position: relative;
  margin: auto;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cecece;
  cursor: pointer;
  img {
    width: 500px;
    height: 300px;
    border-radius: 8px;
    object-fit: contain;
  }
  input {
    display: none;
  }
`
export const InputQR = styled.input`
  width: 200px;
  padding-top: 10px;
  margin: auto;
`
export const StyleError = styled.p`
  margin-bottom: 12px;
  display: block;
  color: red;
  font-size: 14px;
  text-align: left;
  white-space: normal;
`

export const StyleAddItem = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 0;
`

export const StyleLinks = styled.div`
  cursor: pointer;
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--color-secondary);
  background: #fff;
  min-width: 50px;
  border-radius: 8px;
  border: none;
  padding: 3px 15px;
  margin-left: 15px;
  transition: 0.1s ease-in-out;
  text-transform: none;
  &:hover {
    transform: scale(1.02);
  }
`
export const StyleLink = styled.span`
  margin-top: 2px !important;
  color: #050505 !important;
  font-size: 12px !important;
  line-height: 1.3333 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  padding: 0 !important;
`

export const StyleErrorImage = styled(FormHelperText)`
  color: #ff0000 !important;
  width: 100%;
  height: 40px;
`
