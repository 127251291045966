import styled from 'styled-components'
import Typography from '@mui/material/Typography'

export const StyleTitle = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 25px;
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 14px;
  }
`
export const StyleLinks = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  width: 150px;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin: 0px 0px 14px;
  transition: 0.1s ease-in-out;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`

export const StyleLink = styled.span`
  font-weight: bold;
`
