import { API_JSON_URL } from 'constants/common'
import axiosClient from '../utils/axios'

const bannerApi = {
  createBanner: (data: IBanner) => {
    const url = `/banners/create`
    return axiosClient.post(url, data)
  },
  getBanner: (params: any) => {
    const url = `/banners?page=${params.page}&limit=${params.limit}&sortType='ASC'`
    return axiosClient.get(url, params)
  },
  getBannerPriority: (params: any) => {
    const url = `/banners?page=${params.page}&limit=${params.limit}&sortBy=priority&sortType=DESC`
    return axiosClient.get(url, params)
  },

  getPromoCode: (params?: any) => {
    const url = `/promotionCode?limit=${params?.limit}&sortType='DESC'`
    return axiosClient.get(url)
  },

  getService: () => {
    const url = `/service`
    return axiosClient.get(url)
  },
  getBannerById: (id: string) => {
    const url = `/banners/${id}`
    return axiosClient.get(url)
  },
  update: (data: IBanner, id: string) => {
    const url = `/banners/update/${id}`
    return axiosClient.patch(url, data)
  },
  delete: (id: string) => {
    const url = `/banners/delete/${id}`
    return axiosClient.delete(url)
  },
}

export default bannerApi
