import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import Item from 'components/Item'
import { StyleTableCell } from 'pages/order/style'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ConfigurePriceAPI } from 'services/servicesAPI'
import {
  StyleCell,
  StyleCellDistrict,
  StyleCountry,
  StyleElement,
  StyleTableCellBorder,
  StyleTitle,
} from './style'
import dayjs from 'dayjs'

const PriceConfigurations = () => {
  const [data, setData] = useState([])

  const navigate = useNavigate()
  const { id } = useParams()
  const fetchData = async () => {
    const { data } = await ConfigurePriceAPI.getPriceArea({
      serviceId: id,
    })
    const result: any = data.map((item: any) => {
      const provinces = [...item.provinces]
      return provinces.map((subItem: any) => {
        return {
          id: subItem?.id,
          areaId: subItem?.areaId,
          native: item?.native,
          province: subItem?.name,
          basePriceList: subItem?.basePrice?.list?.map((row: any) => ({
            service: `${row?.hourPeriods} giờ`,
            prices: row?.price,
            premium: row?.serviceExtraOptions[0]?.ServiceItemOption?.value,
          })),
          basePriceManual:
            subItem?.basePrice?.manualSelectPrice?.serviceExtraOptions[0]
              ?.ServiceOption?.value,

          districts: subItem?.district
            .filter((a: any) => a.priceValue != 0)
            .map((b: any) => ({
              name: b.location.name,
              price: b.priceValue,
            })),
          holidays: subItem?.prices
            .filter((a: any) => a?.rule?.name == 'holidays')
            .map((b: any) => ({
              name: b?.name,
              price: b?.priceValue,
              time: `${dayjs(b?.startDateTime).format('DD/MM/YYYY')} - ${dayjs(
                b?.endDateTime
              ).format('DD/MM/YYYY')}`,
            })),
          weekend: subItem.prices
            .filter((a: any) => a?.rule?.name == 'weekend')
            .map((b: any) => ({
              price: b?.priceValue,
            })),
          time: subItem.prices
            .filter((a: any) => a?.rule?.name == 'time')
            .map((b: any) => ({
              name: b?.name,
              price: b?.priceValue,
              time: `${dayjs(b.startDateTime).format('hh:mm A')} - ${dayjs(
                b.endDateTime
              ).format('hh:mm A')}`,
            })),
        }
      })
    })
    setData([].concat(...result))
  }
  useEffect(() => {
    fetchData()
  }, [id])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="padding-bottom">
        <Item sx={{ padding: '12px 24px' }} className="bd-radius">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              sx={{
                textTransform: 'capitalize',
                cursor: 'default',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
            >
              Quản lý dịch vụ
            </Link>
            <Link
              sx={{
                textTransform: 'capitalize',
                cursor: 'pointer',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
              onClick={() => navigate(`/service/${id}`)}
            >
              Dọn dẹp nhà gói lẻ
            </Link>
            <Link
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                cursor: 'default',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
            >
              Cấu hình giá
            </Link>
          </Breadcrumbs>
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <StyleTitle>Bảng cấu hình giá</StyleTitle>
          <TableContainer component={Paper} className="box-shadow">
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <StyleTableCell
                    align="center"
                    colSpan={3}
                    style={{ backgroundColor: '#f8f9fa' }}
                  >
                    Khu vực
                  </StyleTableCell>
                  <StyleTableCell
                    align="center"
                    colSpan={4}
                    style={{ backgroundColor: '#f8f9fa' }}
                  >
                    Giá cơ sở
                  </StyleTableCell>
                  <StyleTableCell
                    align="center"
                    colSpan={3}
                    style={{
                      backgroundColor: '#f8f9fa',
                      borderLeft: '1px solid #e0e0e0',
                      borderRight: '1px solid #e0e0e0',
                    }}
                  >
                    Giá lễ tết
                  </StyleTableCell>
                  <StyleTableCell
                    align="center"
                    colSpan={1}
                    style={{
                      backgroundColor: '#f8f9fa',
                      borderLeft: '1px solid #e0e0e0',
                      borderRight: '1px solid #e0e0e0',
                    }}
                  >
                    Giá thứ ngày
                  </StyleTableCell>
                  <StyleTableCell
                    align="center"
                    colSpan={3}
                    style={{
                      backgroundColor: '#f8f9fa',
                      borderLeft: '1px solid #e0e0e0',
                      borderRight: '1px solid #e0e0e0',
                    }}
                  >
                    Giá theo giờ
                  </StyleTableCell>
                </TableRow>

                <TableRow>
                  <StyleTableCell>Quốc gia</StyleTableCell>
                  <StyleTableCell align="left">Tỉnh/Thành phố</StyleTableCell>
                  <StyleTableCell
                    align="center"
                    style={{
                      borderLeft: '1px solid #e0e0e0',
                    }}
                  >
                    Quận/huyện
                  </StyleTableCell>
                  <StyleTableCell
                    align="center"
                    style={{
                      borderLeft: '1px solid #e0e0e0',
                    }}
                  >
                    Dịch vụ
                  </StyleTableCell>
                  <StyleTableCell align="left">
                    Giá dịch vụ(1 giờ)
                  </StyleTableCell>
                  <StyleTableCell align="left">
                    Premium(Giá cộng thêm 1 giờ)
                  </StyleTableCell>
                  <StyleTableCell align="left">
                    Tự chọn người làm
                  </StyleTableCell>

                  <StyleTableCell
                    align="center"
                    style={{
                      borderLeft: '1px solid #e0e0e0',
                    }}
                  >
                    Tên lễ
                  </StyleTableCell>
                  <StyleTableCell align="center">Thời gian</StyleTableCell>
                  <StyleTableCell align="center">Giá</StyleTableCell>
                  <StyleTableCell
                    align="center"
                    style={{
                      borderLeft: '1px solid #e0e0e0',
                    }}
                  >
                    Cuối tuần T7,CN
                  </StyleTableCell>
                  <StyleTableCell
                    style={{
                      borderLeft: '1px solid #e0e0e0',
                    }}
                    align="center"
                  >
                    Tên{' '}
                  </StyleTableCell>
                  <StyleTableCell align="center">Giờ</StyleTableCell>
                  <StyleTableCell align="center">Giá</StyleTableCell>
                  <StyleTableCell
                    align="center"
                    style={{
                      borderLeft: '1px solid #e0e0e0',
                      position: 'relative',
                      top: '-20px',
                    }}
                  >
                    Hành động
                  </StyleTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.map((row: any, index: number) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{
                        verticalAlign: 'unset',
                        marginTop: '5px',
                      }}
                    >
                      <StyleCountry>{row?.native}</StyleCountry>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{ verticalAlign: 'unset', marginTop: '5px' }}
                    >
                      {row?.province}
                    </TableCell>

                    <StyleTableCellBorder
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row?.districts.map((districs: any, index: number) => (
                        <StyleCellDistrict key={index}>
                          <StyleElement>{districs?.name}</StyleElement>
                          <StyleElement>{districs?.price}</StyleElement>
                        </StyleCellDistrict>
                      ))}
                    </StyleTableCellBorder>

                    <StyleTableCellBorder
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: '6px 0px',
                      }}
                    >
                      {row?.basePriceList.map(
                        (basePrice: any, index: number) => (
                          <StyleCell key={index}>
                            <StyleElement>{basePrice?.service}</StyleElement>
                          </StyleCell>
                        )
                      )}
                    </StyleTableCellBorder>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: '6px 0px',
                      }}
                    >
                      {row?.basePriceList.map(
                        (basePrice: any, index: number) => (
                          <StyleCell key={index}>
                            <StyleElement>{basePrice?.prices}</StyleElement>
                          </StyleCell>
                        )
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: '6px 0px',
                      }}
                    >
                      {row?.basePriceList.map(
                        (basePrice: any, index: number) => (
                          <StyleCell key={index}>
                            <StyleElement>{basePrice?.premium}</StyleElement>
                          </StyleCell>
                        )
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: '6px 0px',
                      }}
                    >
                      <StyleCell key={index}>
                        <StyleElement>{row?.basePriceManual}</StyleElement>
                      </StyleCell>
                    </TableCell>
                    <StyleTableCellBorder
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: '6px 0px',
                      }}
                    >
                      {row?.holidays.map((holiday: any, index: number) => (
                        <StyleCell key={index}>
                          <StyleElement>{holiday?.name}</StyleElement>
                        </StyleCell>
                      ))}
                    </StyleTableCellBorder>

                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: '6px 0px',
                      }}
                    >
                      {row?.holidays.map((holiday: any, index: number) => (
                        <StyleCell key={index}>
                          <StyleElement>{holiday?.time}</StyleElement>
                        </StyleCell>
                      ))}
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: '6px 0px',
                      }}
                    >
                      {row?.holidays.map((holiday: any, index: number) => (
                        <StyleCell key={index}>
                          <StyleElement>{holiday?.price}</StyleElement>
                        </StyleCell>
                      ))}
                    </TableCell>
                    <StyleTableCellBorder
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row?.weekend.map((item: any, index: number) => (
                        <StyleCell key={index}>
                          <StyleElement>{item?.price}</StyleElement>
                        </StyleCell>
                      ))}
                    </StyleTableCellBorder>
                    <StyleTableCellBorder
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: '6px 0px',
                      }}
                    >
                      {row?.time.map((hour: any, index: number) => (
                        <StyleCell key={index}>
                          <StyleElement>{hour?.name}</StyleElement>
                        </StyleCell>
                      ))}
                    </StyleTableCellBorder>

                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: '6px 0px',
                      }}
                    >
                      {row?.time.map((hour: any, index: number) => (
                        <StyleCell key={index}>
                          <StyleElement>{hour?.time}</StyleElement>
                        </StyleCell>
                      ))}
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      style={{
                        padding: '6px 0px',
                      }}
                    >
                      {row?.time.map((hour: any, index: number) => (
                        <StyleCell key={index}>
                          <StyleElement>{hour?.price}</StyleElement>
                        </StyleCell>
                      ))}
                    </TableCell>
                    <StyleTableCellBorder
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <BorderColorTwoToneIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigate(
                            `/service/${id}/price-configurations/${row?.id}/${row?.areaId}`
                          )
                        }}
                      />
                    </StyleTableCellBorder>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Item>
      </Grid>
    </Grid>
  )
}

export default PriceConfigurations
