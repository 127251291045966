import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { dataAvailable, shiftName } from 'constants/partTimeStaff'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import staffsApi from 'services/staffsAPT'
import { StyleTableCell } from './style'

type TshiftName = {
  shift1: string
  shift2: string
  shift3: string
}
function WorkCalendarDetail() {
  const { id } = useParams()
  const [data, setData] = useState<any>([])
  useEffect(() => {
    staffsApi.getSchedule(id).then((result) => {
      const customData = result.data.scheduleRegister.map((item: any) => {
        const listDay = item.dayStaffWork.map((subItem: any) => subItem.day)
        return {
          ca: item.shiftName,
          t2: listDay.includes(0),
          t3: listDay.includes(1),
          t4: listDay.includes(2),
          t5: listDay.includes(3),
          t6: listDay.includes(4),
          t7: listDay.includes(5),
          t8: listDay.includes(6),
          caFree: result.data.countFree[item.shiftName],
          caLR: `${result.data.countWork[item.shiftName]}/${
            result.data.countRegister[item.shiftName]
          }`,
        }
      })

      const missingObjects = dataAvailable.filter((data) => {
        return !customData.some((custom: any) => custom.ca === data.ca)
      })

      customData.push(...missingObjects)

      setData(
        // customData.length == 0
        // ? dataAvailable
        // :
        customData.sort((a: any, b: any) => a.order - b.order)
      )
    })
  }, [])

  const renderCheckBox = (check: boolean) => {
    if (check === true) {
      return <CheckBoxIcon sx={{ opacity: '0.5' }} />
    } else if (check === false) {
      return <CheckBoxOutlineBlankIcon sx={{ opacity: '0.5' }} />
    } else {
      return (
        <IndeterminateCheckBoxIcon sx={{ opacity: '0.5', color: '#ddd728' }} />
      )
    }
  }

  return (
    <>
      <TableContainer component={Paper} className="box-shadow">
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <StyleTableCell align="center">Ca</StyleTableCell>
              <StyleTableCell align="center">Thứ 2</StyleTableCell>
              <StyleTableCell align="center">Thứ 3</StyleTableCell>
              <StyleTableCell align="center">Thứ 4</StyleTableCell>
              <StyleTableCell align="center">Thứ 5</StyleTableCell>
              <StyleTableCell align="center">Thứ 6</StyleTableCell>
              <StyleTableCell align="center">Thứ 7</StyleTableCell>
              <StyleTableCell align="center">Chủ nhật</StyleTableCell>
              <StyleTableCell align="center">Số ca</StyleTableCell>
              <StyleTableCell align="center">Tổng ca</StyleTableCell>
            </TableRow>
          </TableHead>
          {!!data.length && (
            <TableBody>
              {data?.map((row: any) => (
                <TableRow
                  key={row.ca}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {shiftName[row.ca as keyof TshiftName]}
                  </TableCell>
                  <TableCell align="center">{renderCheckBox(row.t2)}</TableCell>
                  <TableCell align="center">{renderCheckBox(row.t3)}</TableCell>
                  <TableCell align="center">{renderCheckBox(row.t4)}</TableCell>
                  <TableCell align="center">{renderCheckBox(row.t5)}</TableCell>
                  <TableCell align="center">{renderCheckBox(row.t6)}</TableCell>
                  <TableCell align="center">{renderCheckBox(row.t7)}</TableCell>
                  <TableCell align="center">{renderCheckBox(row.t8)}</TableCell>
                  <TableCell align="center">{row.caFree}</TableCell>
                  <TableCell align="center">{row.caLR}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <div
        style={{
          display: 'flex',
          marginTop: '24px',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Typography variant="h6" gutterBottom>
          *Thời gian các ca
        </Typography>
        <Typography variant="body1" gutterBottom>
          &nbsp; &nbsp; Ca sáng: 06:00 AM - 12:00 PM
        </Typography>
        <Typography variant="body1" gutterBottom>
          &nbsp; &nbsp; Ca chiều: 01:00 PM - 05:00 PM
        </Typography>
        <Typography variant="body1" gutterBottom>
          &nbsp; &nbsp; Ca tối: 05:00 PM - 00:00 AM
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '24px',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Typography variant="h6" gutterBottom>
          *Ý nghĩa ký hiệu
        </Typography>
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          &nbsp;
          <CheckBoxOutlineBlankIcon sx={{ opacity: '0.5' }} />
          <Typography variant="body1" gutterBottom>
            Lịch rảnh (Ca NV không đăng ký làm việc)
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          &nbsp;
          <CheckBoxIcon sx={{ opacity: '0.5' }} />
          <Typography variant="body1" gutterBottom>
            Lịch trống (Ca làm NV đăng ký nhưng chưa có việc)
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          &nbsp;
          <IndeterminateCheckBoxIcon
            sx={{ opacity: '0.5', color: '#ddd728' }}
          />
          <Typography variant="body1" gutterBottom>
            Lịch ca đang làm (Ca làm NV đăng ký và đã có việc)
          </Typography>
        </div>
      </div>
    </>
  )
}

export default WorkCalendarDetail
