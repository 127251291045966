import {
  FormLogin,
  StyleLogin,
  TitleLogin,
  Heading,
  StyleError,
  SendButton,
  Description,
} from 'pages/auth/style'
import { TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { emailSchema_ } from 'validations/emailSchema'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Back, Container, BackButton } from './style'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import useToken from 'hooks/useToken'
import authAPI from 'services/authAPI'

const FormEmail = () => {
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertClose, setAlertError } = useAlert()
  const navigate = useNavigate()
  const { token } = useToken()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPassword>({
    resolver: yupResolver(emailSchema_),
  })
  const onSubmit = async (values: IForgotPassword) => {
    setLoadingTrue()
    setAlertClose()
    try {
      const data = await authAPI.sendOTP(values)
      if (data) {
        const expiredTime = new Date(data.data.resendTime).getTime()
        localStorage.setItem('countdownTime', expiredTime.toString())
        localStorage.setItem('remainRequest', 'true')
        setLoadingFalse()
        setTimeout(() => {
          navigate(`/auth/otp/${values.email}`)
        }, 300)
      }
      // eslint-disable-next-line
    } catch (error: any) {
      setAlertError(error.response.data.message)
      setLoadingFalse()
    }
  }
  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [])
  return (
    <FormLogin onSubmit={handleSubmit(onSubmit)}>
      <StyleLogin>
        <TitleLogin>Tìm kiếm tài khoản</TitleLogin>
        <Description style={{ marginBottom: '20px' }}>
          Vui lòng nhập địa chỉ email của bạn để tìm kiếm tài khoản của bạn.
        </Description>
        <Heading>
          <TextField
            sx={{ width: '100%' }}
            id="standard-textarea"
            label="Địa chỉ e-mail"
            variant="outlined"
            {...register('email')}
            autoFocus
          />
          {errors.email && (
            <StyleError>{errors.email?.message as string}</StyleError>
          )}
        </Heading>
        <SendButton type="submit">Gửi OTP</SendButton>
        <Container>
          <BackButton onClick={() => navigate('/auth/login')}>
            <ArrowBackIosIcon sx={{ fontSize: '15px' }} />
            <Typography>
              Quay lại <Back>Đăng nhập</Back>
            </Typography>
          </BackButton>
        </Container>
      </StyleLogin>
    </FormLogin>
  )
}

export default FormEmail
