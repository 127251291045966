import { Typography, TableCell } from '@mui/material'
import styled from 'styled-components'

export const StyleHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyleElement = styled.div`
  justify-content: flex-start;
  margin: 24px 0px;
`
export const StyleTitle = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 22px;
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 14px;
  }
`

export const StyleBold = styled(Typography)`
  && {
    display: inline;
    margin-right: 5px;
    font-weight: bold;
  }
`

export const StyleContent = styled.div`
  display: flex;
`

export const StylePremium = styled.div`
  background-color: #3a6998;
  border-radius: 20px;
  padding: 10px;
  color: #fff;
`

export const StyleTableCell = styled(TableCell)`
  && {
    font-weight: bold;
    color: var(--color-secondary);
  }
  min-width: 200px;
  border-left: 1px solid #e0e0e0;
`

export const StyleTableCellData = styled(TableCell)`
  border-left: 1px solid #e0e0e0;
`

export const StyleTableCellItem = styled.p`
  -webkit-line-clamp: 1;
  /* -webkit-box-orient: vertical; */
  display: -webkit-box;
  margin: 0;
  overflow: hidden;
  /* text-overflow: ellipsis; */
`
