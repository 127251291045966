import axiosClient from '../utils/axios'

const authAPI = {
  login: (data: ILoginAuth) => {
    const url = `/login`
    return axiosClient.post(url, data)
  },
  sendOTP: (data: IForgotPassword) => {
    const url = `/resendExcAuOTP`
    return axiosClient.post(url, data)
  },

  checkOTP: (data: IValidOTP) => {
    const url = `/isValidOTP`
    return axiosClient.post(url, data)
  },
 
  changePassword: (data: IChangePassword) => {
    const url = `/changePwd`
    return axiosClient.post(url, data)
  },
}

export default authAPI
