import * as yup from 'yup'

export const orderEditSchema_ = yup.object().shape({
  date: yup
    .string()
    .required('Thứ ngày không được để trống')
    .test(
      'date',
      'Ngày bắt đầu phải lớn hơn hoặc bằng ngày hiện tại và ít hơn 7 ngày',
      function (value: string | undefined) {
        const currentDate = new Date()
        const valueDate = new Date(value as any)
        const utcDate1 = Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        )
        const utcDate2 = Date.UTC(
          valueDate.getFullYear(),
          valueDate.getMonth(),
          valueDate.getDate()
        )
        if (!value) {
          return true
        }

        const diffInDays = Math.floor(
          (valueDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
        )

        return utcDate1 <= utcDate2 && diffInDays < 7
      }
    ),
  startTime: yup.string().required('Giờ bắt đầu không được để trống'),
  dailyWeek: yup.string(),
  // pet: yup.string().when('isPet', {
  //   is: true,
  //   then: yup.string().required('Mã khuyến mãi là bắt buộc'),
  //   otherwise: yup.string(),
  // }),

  note: yup.string().trim(),

  promotion: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'Mã khuyến mãi phải là chữ hoặc số'),
})
