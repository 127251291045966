import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { StyleLoading } from './style'
import { useLoading } from 'contexts/LoadingContext'
export default function CustomLoading() {
  const { isLoading } = useLoading()
  return (
    <>
      {isLoading && (
        <StyleLoading>
          <CircularProgress
            sx={{
              width: '75px !important',
              height: '75px !important',
              color: '#f0a970',
            }}
          />
        </StyleLoading>
      )}
    </>
  )
}
