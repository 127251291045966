import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import { StyleError } from 'pages/auth/style'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ConfigurePriceAPI } from 'services/servicesAPI'
import { usePrice } from '../../../context/PriceContext'
import {
  StyleCellHover,
  StyleTableCell,
  StyleTitle,
  StyleTitleHead,
} from '../TableWeek/style'

export default function TablePrice() {
  const editingRowRef = useRef<HTMLTableRowElement>(null)
  const { provinceName } = usePrice()
  const [editingRowId, setEditingRowId] = useState<any>(null)
  const [editValue, setEditValue] = useState<any>()
  const [data, setData] = useState<any>([])
  const { id, provinceId } = useParams()
  const [errorChooseStaff, setErrorChooseStaff] = useState('')
  const [errorPrice, setErrorPrice] = useState('')
  const [errorPremium, setErrorPremium] = useState('')
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertSuccess, setAlertError } = useAlert()

  const fetchData = async (id: string, provinceId: string) => {
    const { data } = await ConfigurePriceAPI.getPriceTable({
      serviceId: id,
      province: provinceId,
    })
    setData(data)
  }
  const handleStartEditing = (id: string, name: any) => {
    setEditingRowId(`${id} ${name}`)
  }

  const handleCancelEditing = () => {
    setEditingRowId(null)
    setErrorChooseStaff('')
    setErrorPrice('')
    setErrorPremium('')
    setEditValue(undefined)
  }

  const handleSave = async (idSave: string) => {
    if (errorChooseStaff || errorPremium || errorPrice) {
      return
    }
    setLoadingTrue()
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await ConfigurePriceAPI.updatePriceTable(editValue, idSave)
      setLoadingFalse()
      setAlertSuccess('Lưu thay đổi thành công')
      fetchData(id as any, provinceId as any)
      setEditingRowId(null)
      setEditValue(undefined)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoadingFalse()
      setAlertError(error.response?.data?.message)
    }
  }
  const handleChange = (id: string, field: any, value: any) => {
    const regex = /^[0-9]\d*$/
    if (field === 'manualSelectPrice') {
      if (value) {
        if (!regex.test(value)) {
          setErrorChooseStaff('Giá tiền sai định dạng')
        } else {
          setErrorChooseStaff('')
        }
      } else {
        setErrorChooseStaff('Giá tiền không được để trống')
      }
    }
    if (field === 'price') {
      if (value) {
        if (!regex.test(value)) {
          setErrorPrice('Giá tiền sai định dạng')
        } else {
          setErrorPrice('')
        }
      } else {
        setErrorPrice('Giá tiền không được để trống')
      }
    }
    if (field === 'premiumPrice') {
      if (value) {
        if (!regex.test(value)) {
          setErrorPremium('Giá tiền sai định dạng')
        } else {
          setErrorPremium('')
        }
      } else {
        setErrorPremium('Giá tiền không được để trống')
      }
    }
    const newObj = {
      [field]: value,
    } as any
    setEditValue(newObj)
  }

  useEffect(() => {
    const handleDocumentMouseDown = (event: MouseEvent) => {
      if (
        editingRowRef.current &&
        !editingRowRef.current.contains(event.target as Node)
      ) {
        setEditingRowId(null)
        setErrorChooseStaff('')
        setErrorPrice('')
        setErrorPremium('')
        setEditValue(undefined)
      }
    }
    document.addEventListener('mousedown', handleDocumentMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleDocumentMouseDown)
    }
  }, [editingRowRef, setEditingRowId])
  useEffect(() => {
    fetchData(id as any, provinceId as any)
  }, [])
  useEffect(() => {
    setErrorPrice('')
  }, [editingRowId])
  return (
    <>
      <StyleTitleHead style={{ marginBottom: '0px' }}>
        {provinceName}
      </StyleTitleHead>

      <StyleTitle style={{ marginTop: '16px' }}>Bảng giá dịch vụ</StyleTitle>
      {data && (
        <TableContainer
          component={Paper}
          className="box-shadow"
          ref={editingRowRef}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <StyleTableCell width={50} align="center">
                  STT
                </StyleTableCell>
                <StyleTableCell align="center">Loại</StyleTableCell>
                <StyleTableCell align="center">Mô tả</StyleTableCell>
                <StyleTableCell align="center">
                  Giá dịch vụ(1 giờ)
                </StyleTableCell>
                <StyleTableCell align="center">
                  Premium(giá cộng thêm 1 giờ)
                </StyleTableCell>
                <StyleTableCell align="center">
                  Tự chọn người làm{' '}
                </StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.list?.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    height: '60px',
                  }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell align="center">{`${row.hourPeriods} giờ`}</TableCell>
                  <TableCell align="center">
                    {row?.serviceItem?.shortDescription}
                  </TableCell>
                  <TableCell align="center">
                    {editingRowId === `${row.id} price` ? (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <TextField
                            size="small"
                            sx={{ width: '80%' }}
                            id={`price-${row.id}`}
                            defaultValue={row.price}
                            onChange={(e: any) =>
                              handleChange(row.id, 'price', e.target.value)
                            }
                            autoComplete="off"
                            type="number"
                            inputProps={{
                              step: 1000,
                              inputMode: 'numeric',
                            }}
                          />

                          <CheckIcon
                            onClick={() => handleSave(row.id)}
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                          />
                          <ClearIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleCancelEditing()}
                          />
                        </div>
                        {errorPrice && (
                          <StyleError
                            style={{
                              textAlign: 'left',
                              paddingTop: '5px',
                            }}
                          >
                            {errorPrice as string}
                          </StyleError>
                        )}
                      </div>
                    ) : (
                      <StyleCellHover
                        onClick={() => handleStartEditing(row.id, 'price')}
                      >
                        {row.price}
                      </StyleCellHover>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {editingRowId === `${row.id} premium` ? (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <TextField
                            sx={{ width: '80%' }}
                            size="small"
                            id={`price-${row.id}`}
                            defaultValue={
                              row?.serviceExtraOptions[0]?.ServiceItemOption
                                ?.value
                            }
                            autoComplete="off"
                            type="number"
                            inputProps={{
                              step: 1000,
                              inputMode: 'numeric',
                            }}
                            onChange={(e: any) =>
                              handleChange(
                                row.id,
                                'premiumPrice',
                                e.target.value
                              )
                            }
                          />

                          <CheckIcon
                            onClick={() => handleSave(row.id)}
                            style={{ marginLeft: '5px', cursor: 'pointer' }}
                          />
                          <ClearIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleCancelEditing()}
                          />
                        </div>
                        {errorPremium && (
                          <StyleError
                            style={{
                              textAlign: 'left',
                              paddingTop: '5px',
                            }}
                          >
                            {errorPremium as string}
                          </StyleError>
                        )}
                      </div>
                    ) : (
                      <StyleCellHover
                        onClick={() => handleStartEditing(row.id, 'premium')}
                      >
                        {row?.serviceExtraOptions[0]?.ServiceItemOption?.value}
                      </StyleCellHover>
                    )}
                  </TableCell>
                  {index === 0 && (
                    <TableCell align="center" rowSpan={4}>
                      {editingRowId === `${row.id} choose` ? (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <TextField
                              size="small"
                              defaultValue={
                                data.manualSelectPrice?.serviceExtraOptions[0]
                                  ?.ServiceOption?.value
                              }
                              sx={{ width: '80%' }}
                              autoComplete="off"
                              type="number"
                              inputProps={{
                                step: 1000,
                                inputMode: 'numeric',
                              }}
                              onChange={(e: any) =>
                                handleChange(
                                  row.id,
                                  'manualSelectPrice',
                                  e.target.value
                                )
                              }
                            />

                            <CheckIcon
                              onClick={() => handleSave(row.id)}
                              style={{ marginLeft: '5px', cursor: 'pointer' }}
                            />
                            <ClearIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleCancelEditing()}
                            />
                          </div>
                          {errorChooseStaff && (
                            <StyleError
                              style={{
                                textAlign: 'left',
                                paddingTop: '5px',
                              }}
                            >
                              {errorChooseStaff as string}
                            </StyleError>
                          )}
                        </div>
                      ) : (
                        <StyleCellHover
                          onClick={() => handleStartEditing(row.id, 'choose')}
                        >
                          {
                            data.manualSelectPrice?.serviceExtraOptions[0]
                              ?.ServiceOption?.value
                          }
                        </StyleCellHover>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
