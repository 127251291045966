import axiosClient from '../utils/axios'

const promoApi = {
  getServices: () => {
    const url = `/service`
    return axiosClient.get(url)
  },
  getServiceCondition: (ids: string) => {
    const url = `/service?ids=${ids}`
    return axiosClient.get(url)
  },
  getCountryById: (id: string) => {
    const url = `/countries/${id}`
    return axiosClient.get(url)
  },
  getCountries: () => {
    const url = `/countries`
    return axiosClient.get(url)
  },
  getProvincesById: (id: string) => {
    const url = `/provinces/${id}`
    return axiosClient.get(url)
  },
  getProvincesByIdCondition: (id: string, ids: string) => {
    const url = `/provinces/${id}?ids=${ids}`
    return axiosClient.get(url)
  },
  getUser: (value: any) => {
    const url = `/users/?page=1&limit=10&search=${value}`
    return axiosClient.get(url)
  },
  getUserCondition: (ids: string) => {
    const url = `/users/?ids=${ids}`
    return axiosClient.get(url)
  },

  createPromotion: (data: IPromotion) => {
    const url = `promotionCode/create`
    return axiosClient.post(url, data)
  },
  getPromotionById: (id: string) => {
    const url = `/promotionCode/${id}`
    return axiosClient.get(url)
  },
  updatePromotion: (data: IPromotion, id: string) => {
    const url = `/promotionCode/update/${id}`
    return axiosClient.patch(url, data)
  },
}

export default promoApi
