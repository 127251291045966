import React, { createContext, useState, useContext } from 'react'

interface IChildren {
  children: React.ReactNode
}

interface ITemporaryStaffContext {
  temporaryDataStaff: any
  addTemporaryDataStaff: (temporaryDataStaff: string) => void
  // removeTemporaryDataStaff: (temporaryDataStaff: string) => void
}

const TemporaryDataStaffContext = createContext<ITemporaryStaffContext>({
  temporaryDataStaff: [],
  addTemporaryDataStaff: () => undefined,
  // removeTemporaryDataStaff: () => undefined,
})

const TemporaryDataStaffProvider = ({ children }: IChildren) => {
  const [temporaryDataStaff, setTemporaryDataStaff] = useState<any>([])

  const addTemporaryDataStaff = (data: any) => {
    setTemporaryDataStaff(data)
  }

  // const removeTemporaryDataStaff = (data: any) => {
  //   setTemporaryDataStaff(
  //     temporaryDataStaff.filter((item: any) => item !== data)
  //   )
  // }

  return (
    <TemporaryDataStaffContext.Provider
      value={{
        temporaryDataStaff,
        addTemporaryDataStaff,
        // removeTemporaryDataStaff,
      }}
    >
      {children}
    </TemporaryDataStaffContext.Provider>
  )
}
const useTemporaryDataStaff = () => {
  return useContext(TemporaryDataStaffContext)
}

export { TemporaryDataStaffProvider, useTemporaryDataStaff }
