import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Checkbox from '@mui/material/Checkbox'

type Props = {
  items: any
  name: string
  placeholder?: string
  selectAllLabel: string
  noOptionsText?: string
  limitTags?: number
  defaultValue?: any
  readonly?: boolean
  onChange: (selectedOptions: any) => void
}

const MultiSelect = ({
  items,
  name,
  defaultValue,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  readonly,
  onChange,
}: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<any>([])
  const allSelected = items?.length === selectedOptions?.length

  const handleToggleOption = (selectedOptions: any) =>
    setSelectedOptions(selectedOptions)
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const handleClearOptions = () => setSelectedOptions([])

  const getOptionLabel = (option: any) => `${option.name}`

  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedOptions(items)
    } else {
      handleClearOptions()
    }
  }

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected)
  }

  const handleChange = (
    event: React.ChangeEvent<any>,
    value: any,
    reason: string
  ) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (value.find((option: any) => option.id === 'select-all')) {
        handleToggleSelectAll()
        let result = []
        result = items.filter((el: any) => el.id !== 'select-all')
        return onChange([])
      } else {
        handleToggleOption && handleToggleOption(value)
        return onChange(value)
      }
    } else if (reason === 'clear') {
      handleClearOptions && handleClearOptions()
    }
  }

  const optionRenderer = (props: any, option: any, { selected }: any) => {
    const selectAllProps =
      option.id === 'select-all' ? { checked: allSelected } : {}
    return (
      <>
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            {...selectAllProps}
          />
          {option.name}
        </li>
      </>
    )
  }

  const inputRenderer = (params: any) => (
    <TextField
      {...params}
      label={name}
      placeholder={placeholder}
      size="small"
    />
  )

  const filter = createFilterOptions<any>()
  useEffect(() => {
    if (defaultValue) {
      setSelectedOptions(defaultValue)
    }
  }, [])
  return (
    <Autocomplete
      multiple
      fullWidth
      limitTags={limitTags}
      options={items}
      value={selectedOptions}
      disableCloseOnSelect
      readOnly={readonly}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={getOptionLabel}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        return [{ name: selectAllLabel, id: 'select-all' }, ...filtered]
      }}
      onChange={handleChange}
      renderOption={(props, option, { selected }) =>
        optionRenderer(props, option, { selected })
      }
      renderInput={inputRenderer}
    />
  )
}

export default MultiSelect
