import React, { useEffect, useState } from 'react'
import InputField from 'components/FormControl/InputField'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { StyleImage, StyleInput, StyleLabel, StyleWidthInput } from './style'
import { Paper, Button, MenuItem } from '@mui/material'
import { Grid } from '@mui/material'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import bannerApi from 'services/bannerAPI'
import dayjs from 'dayjs'
import { BANNER_DETAIL } from 'constants/breadcrumbs'
import Breadcrumb from 'components/Breadcrumbs'
import Editor from 'components/Editor'
import { IObjectBannerType, bannerType } from 'utils/bannerType'

const BannerDetail = () => {
  const { id } = useParams()
  const [data, setData] = useState<IBanner>()
  const [listService, setListService] = useState([])
  const navigate = useNavigate()

  const getListService = async () => {
    const { data } = await bannerApi.getService()
    setListService(data)
  }

  useEffect(() => {
    getListService()
    const getBannerDetail = async () => {
      const { data } = await bannerApi.getBannerById(id as string)
      setData(data)
    }
    getBannerDetail().catch(console.error)
  }, [id])

  if (!data) return <></>

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={BANNER_DETAIL} />
      <Grid item xs={12}>
        <Paper
          sx={{
            boxShadow:
              '0px 12px 33px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
            textAlign: 'center',
            height: '100%',
            padding: '24px',
          }}
        >
          <Box
            sx={{
              '& > :not(style)': { m: 1, width: '100ch', margin: 'auto' },
            }}
          >
            <StyleImage>
              {data?.thumbnail ? (
                <img
                  src={data?.thumbnail}
                  alt="Uploaded Image"
                  width={890}
                  height={300}
                  style={{
                    borderRadius: '10px',
                    objectFit: 'contain',
                    background: '#cecece',
                  }}
                />
              ) : (
                <AddPhotoAlternateIcon
                  sx={{
                    borderRadius: '10px',
                    width: '890px',
                    height: '300px',
                    objectFit: 'contain',
                    background: '#cecece',
                  }}
                />
              )}
            </StyleImage>

            <InputField label="Tiêu đề" value={data?.title} />

            <div style={{ marginBottom: '10px' }}>
              <StyleLabel>Nội dung</StyleLabel>
              <Editor
                onChange={() => {
                  null
                }}
                value={data?.content}
                disable={true}
              />
            </div>

            <StyleInput>
              <InputField
                label="Kiểu banner"
                value={bannerType[data?.typeAction as keyof IObjectBannerType]}
              />

              <InputField
                label="Tình trạng"
                value={data?.status === true ? 'Hiển thị' : 'Ẩn'}
              />

              {data?.typeAction === 'service' ? (
                <InputField
                  id="serviceId"
                  label="Tên dịch vụ"
                  select
                  value={data?.serviceId}
                  sx={{
                    width: '100%',
                  }}
                  inputProps={{ readOnly: true }}
                >
                  {listService.map((option: any) => (
                    <MenuItem
                      key={option?.id}
                      value={option?.id}
                      sx={{ justifyContent: 'center' }}
                    >
                      {option?.name}
                    </MenuItem>
                  ))}
                </InputField>
              ) : (
                <InputField label="Độ ưu tiên" value={data?.priority} />
              )}
            </StyleInput>

            <StyleInput>
              <StyleWidthInput>
                <InputField label="Nội dung nút bấm" value={data?.buttonName} />
              </StyleWidthInput>

              {data?.typeAction === 'service' && (
                <>
                  <StyleWidthInput>
                    <InputField label="Mã khuyến mãi" value={data?.code} />
                  </StyleWidthInput>
                  <StyleWidthInput>
                    <InputField label="Độ ưu tiên" value={data?.priority} />
                  </StyleWidthInput>
                </>
              )}

              {data?.typeAction === 'link' && (
                <div style={{ width: '66%' }}>
                  <InputField label="Link" value={data?.link} />
                </div>
              )}
            </StyleInput>

            {data?.typeAction === 'service' && (
              <div style={{ display: 'flex', gap: '24px' }}>
                <div style={{ width: '31.5%' }}>
                  <InputField
                    label="Ngày bắt đầu"
                    value={dayjs(data?.startDate).format('DD/MM/YYYY')}
                  />
                </div>
                <div style={{ width: '31.5%' }}>
                  <InputField
                    label="Ngày kết thúc"
                    value={dayjs(data?.endDate).format('DD/MM/YYYY')}
                  />
                </div>
              </div>
            )}

            <div style={{ textAlign: 'left', marginTop: '14px' }}>
              <Button
                variant="outlined"
                onClick={() => navigate('/marketing/banners')}
              >
                Quay lại
              </Button>
            </div>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default BannerDetail
