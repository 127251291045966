import dayjs from 'dayjs'
import * as yup from 'yup'

export const bannerCreateSchema_ = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required('Tiêu đề không được để trống')
    .min(3, 'Tiêu đề có tối thiểu 3 ký tự, tối đa 50 ký tự')
    .max(50, 'Tiêu đề có tối thiểu 3 ký tự, tối đa 50 ký tự'),

  // content: yup.string().trim().required('Nội dung không được để trống'),

  typeAction: yup
    .string()
    .trim()
    .required('Kiểu khuyến mãi không được để trống'),

  serviceId: yup
    .string()
    .trim()
    .when('typeAction', {
      is: 'service',
      then: yup.string().required('Kiểu dịch vụ không được để trống'),
    }),

  link: yup.string().when('typeAction', {
    is: 'link',
    then: yup
      .string()
      .trim()
      .required('Link không được để trống')
      .url('Link không hợp lệ'),
  }),

  buttonName: yup
    .string()
    .trim()
    .typeError('Nội dung nút bấm không được để trống')
    .required('Nội dung nút bấm không được để trống'),

  code: yup.string().when('typeAction', {
    is: 'service',
    then: yup.string().trim().required('Mã khuyến mãi không được để trống'),
  }),

  priority: yup
    .number()
    .typeError('Nhập số, giới hạn 0-100')
    .nullable(true)
    .min(0, 'Nhập số, giới hạn 0-100')
    .max(100, 'Nhập số, giới hạn 0-100')
    .transform((_, val) => (val !== '' ? Number(val) : null)),

  startDate: yup.string().when('typeAction', {
    is: 'service',
    then: yup
      .string()
      .typeError('Ngày bắt đầu không được để trống')
      .required('Ngày bắt đầu không được để trống')
      .test(
        'startDate',
        'Định dạng ngày tháng năm không hợp lệ',
        function (value: any) {
          const checkDate = dayjs(value).format('DD/MM/YYYY')
          if (!checkDate) return true // Allow empty value, if it's not required
          const dateFormat =
            /^(0[1-9]|[1-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
          if (!dateFormat.test(checkDate)) return false
          const [day, month, year] = checkDate.split('/')
          const isValidDate = !isNaN(Date.parse(`${year}-${month}-${day}`))
          return isValidDate
        }
      )
      .test(
        'startDate',
        'Ngày bắt đầu phải nhỏ hơn hoặc bằng ngày kết thúc',
        function (value: string | undefined) {
          const { endDate } = this.parent
          if (!value) {
            return true
          }
          return !endDate || new Date(value) <= new Date(endDate)
        }
      ),
  }),

  endDate: yup.string().when('typeAction', {
    is: 'service',
    then: yup
      .string()
      .typeError('Ngày kết thúc không được để trống')
      .required('Ngày kết thúc không được để trống')
      .test(
        'endDate',
        'Định dạng ngày tháng năm không hợp lệ',
        function (value: any) {
          const checkDate = dayjs(value).format('DD/MM/YYYY')
          if (!checkDate) return true // Allow empty value, if it's not required
          const dateFormat =
            /^(0[1-9]|[1-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
          if (!dateFormat.test(checkDate)) return false
          const [day, month, year] = checkDate.split('/')
          const isValidDate = !isNaN(Date.parse(`${year}-${month}-${day}`))
          return isValidDate
        }
      )
      .test(
        'endDate',
        'Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu',
        function (value: string | undefined) {
          const { startDate } = this.parent
          if (!value) {
            return true
          }
          return !startDate || new Date(value) >= new Date(startDate)
        }
      ),
  }),
})

export const bannerEditSchema_ = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required('Tiêu đề không được để trống')
    .min(3, 'Tiêu đề có tối thiểu 3 ký tự, tối đa 50 ký tự')
    .max(50, 'Tiêu đề có tối thiểu 3 ký tự, tối đa 50 ký tự'),

  content: yup.string().trim().required('Nội dung không được để trống'),

  typeAction: yup
    .string()
    .trim()
    .required('Kiểu khuyến mãi không được để trống'),

  serviceId: yup.string().when('typeAction', {
    is: 'service',
    then: yup
      .string()
      .trim()
      .typeError('Kiểu dịch vụ ')
      .required('Kiểu dịch vụ không được để trống'),
  }),

  link: yup.string().when('typeAction', {
    is: 'link',
    then: yup
      .string()
      .trim()
      .required('Link không được để trống')
      .url('Link không hợp lệ'),
  }),

  priority: yup
    .number()
    .typeError('Nhập số, giới hạn 0-100')
    .nullable(true)
    .min(0, 'Nhập số, giới hạn 0-100')
    .max(100, 'Nhập số, giới hạn 0-100')
    .transform((_, val) => (val !== '' ? Number(val) : null)),

  code: yup.string().when('typeAction', {
    is: 'service',
    then: yup
      .string()
      .trim()
      .typeError('Mã khuyến mãi không được để trống')
      .required('Mã khuyến mãi không được để trống'),
  }),

  buttonName: yup
    .string()
    .trim()
    .typeError('Nội dung nút bấm không được để trống')
    .required('Nội dung nút bấm không được để trống'),

  startDate: yup.string().when('typeAction', {
    is: 'service',
    then: yup
      .string()
      .typeError('Ngày bắt đầu không được để trống')
      .required('Ngày bắt đầu không được để trống')
      .test(
        'startDate',
        'Định dạng ngày tháng năm không hợp lệ',
        function (value: any) {
          const checkDate = dayjs(value).format('DD/MM/YYYY')
          if (!checkDate) return true // Allow empty value, if it's not required
          const dateFormat =
            /^(0[1-9]|[1-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
          if (!dateFormat.test(checkDate)) return false
          const [day, month, year] = checkDate.split('/')
          const isValidDate = !isNaN(Date.parse(`${year}-${month}-${day}`))
          return isValidDate
        }
      )
      .test(
        'startDate',
        'Ngày bắt đầu phải nhỏ hơn hoặc bằng ngày kết thúc',
        function (value: string | undefined) {
          const { endDate } = this.parent
          if (!value) {
            return true
          }
          return !endDate || new Date(value) <= new Date(endDate)
        }
      ),
  }),

  endDate: yup.string().when('typeAction', {
    is: 'service',
    then: yup
      .string()
      .typeError('Ngày kết thúc không được để trống')
      .required('Ngày kết thúc không được để trống')
      .test(
        'endDate',
        'Định dạng ngày tháng năm không hợp lệ',
        function (value: any) {
          const checkDate = dayjs(value).format('DD/MM/YYYY')
          if (!checkDate) return true // Allow empty value, if it's not required
          const dateFormat =
            /^(0[1-9]|[1-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
          if (!dateFormat.test(checkDate)) return false
          const [day, month, year] = checkDate.split('/')
          const isValidDate = !isNaN(Date.parse(`${year}-${month}-${day}`))
          return isValidDate
        }
      )
      .test(
        'endDate',
        'Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu',
        function (value: string | undefined) {
          const { startDate } = this.parent
          if (!value) {
            return true
          }
          return !startDate || new Date(value) >= new Date(startDate)
        }
      ),
  }),
})
