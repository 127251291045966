import StarIcon from '@mui/icons-material/Star'
import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Rating from '@mui/material/Rating'
import { StyleLink, StyleLinks } from 'pages/PromotionCode/style'
import {
  StyleBold,
  StyleElement,
  StylePremium,
  StyleTableCell,
  StyleTableCellData,
  StyleTableCellItem,
  StyleTitle,
  StyleContent,
} from './style'
import { performance } from 'services/staffAPI'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { performanceName } from 'utils/performance'

const PerformanceDetail = () => {
  const { id } = useParams()
  const [dataPerformance, setDataPerformance] = useState<any>([])

  const fetchDataPerformance = async () => {
    const { data } = await performance.getMetricsById(id as string)
    console.log('🚀 ~ file: index.tsx:42 ~ fetchDataPerformance ~ data:', data)

    const dataFormat = Object.entries(data).map(([key, value]) => ({
      [key]: value,
    }))
    const sortedArray = dataFormat.sort((a, b) => {
      const keyA = Object.keys(a)[0] // Get the first key of object a
      const keyB = Object.keys(b)[0] // Get the first key of object b

      return keyA.localeCompare(keyB) // Compare the keys alphabetically
    })

    setDataPerformance(sortedArray)
  }

  useEffect(() => {
    fetchDataPerformance()
  }, [])

  const value = 4.3
  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '19px',
          justifyContent: 'space-between',
          marginBottom: '24px',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2" gutterBottom>
          Hiệu suất
        </Typography>
        <StyleContent style={{ flexDirection: 'column' }}>
          <StylePremium>Cao cấp (premium)</StylePremium>
        </StyleContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyleBold>Số sao:</StyleBold>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Rating
                name="text-feedback"
                value={value}
                readOnly
                precision={0.1}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              <Box sx={{ ml: 2 }}>{value}</Box>
            </Box>
          </div>
          <StyleContent>
            <StyleBold>Số lần đánh giá: </StyleBold>
            <Typography>{`100`}</Typography>
          </StyleContent>
        </div>

        <Typography>
          <Box component="span" fontWeight="bold">
            Tổng số lần đánh giá:&nbsp;
          </Box>
          100
        </Typography>

        <Typography>
          <Box component="span" fontWeight="bold">
            Tỷ lệ chuyên cần (trong tháng này):&nbsp;
          </Box>
          100
        </Typography>

        <Typography>
          <Box component="span" fontWeight="bold">
            Số giờ làm việc (trong tháng này):&nbsp;
          </Box>
          100
        </Typography>
      </div>
      <Divider />
      <StyleElement>
        <StyleTitle>Lịch sử hạng</StyleTitle>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyleTableCell component="th" scope="row">
                  Thời gian:
                </StyleTableCell>
                {[].map((row, index: number) => (
                  <TableCell component="th" scope="row" key={index}>
                    name
                  </TableCell>
                ))}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: 'bold' }}
                >
                  Thời điểm
                </TableCell>
                {[].map((row, index: number) => (
                  <TableCell component="th" scope="row" key={index}>
                    name
                  </TableCell>
                ))}
              </TableRow>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: 'bold' }}
                >
                  Hạng
                </TableCell>
                {[].map((row, index: number) => (
                  <TableCell component="th" scope="row" key={index}>
                    name
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </StyleElement>
      <StyleElement>
        <StyleTitle>Thông tin chi tiết</StyleTitle>
        <TableContainer component={Paper} className="box-shadow">
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow sx={{ background: 'rgb(241, 243, 244)' }}>
                <StyleTableCell align="left">Thời gian:</StyleTableCell>
                <StyleTableCell align="left">
                  Hiện tại(trong tháng)
                </StyleTableCell>
                <StyleTableCell align="left">Tháng trước</StyleTableCell>
                <StyleTableCell align="left">
                  Luỹ kế 3 tháng trước
                </StyleTableCell>
                <StyleTableCell align="left">
                  Luỹ kế 12 tháng trước
                </StyleTableCell>
                <StyleTableCell align="left">
                  Luỹ kế năm hiện tại
                </StyleTableCell>
                <StyleTableCell
                  align="left"
                  sx={{ borderRight: '1px solid #e0e0e0' }}
                >
                  Luỹ kế tất cả
                </StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataPerformance?.map((row: any, index: number) => (
                <TableRow key={row.id}>
                  <StyleTableCellData align="left" component="th" scope="row">
                    <StyleTableCellItem>
                      {performanceName[Object.keys(row) as keyof unknown]}
                    </StyleTableCellItem>
                  </StyleTableCellData>

                  <StyleTableCellData align="left">
                    <StyleTableCellItem>
                      {row[Object.keys(row) as any]?.monthNow?.value ?? 0}
                    </StyleTableCellItem>
                  </StyleTableCellData>

                  <StyleTableCellData align="center">
                    <StyleTableCellItem>
                      {row[Object.keys(row) as any]?.monthPrevious?.value ?? 0}
                    </StyleTableCellItem>
                  </StyleTableCellData>

                  <StyleTableCellData align="left">
                    <StyleTableCellItem>
                      {row[Object.keys(row) as any]?.threeMonthsAgo?.value ?? 0}
                    </StyleTableCellItem>
                  </StyleTableCellData>

                  <StyleTableCellData align="left">
                    <StyleTableCellItem>
                      {row[Object.keys(row) as any]?.twelveMonthsAgo?.value ??
                        0}
                    </StyleTableCellItem>
                  </StyleTableCellData>

                  <StyleTableCellData align="left">
                    <StyleTableCellItem>
                      {row[Object.keys(row) as any]?.currentYear?.value ?? 0}
                    </StyleTableCellItem>
                  </StyleTableCellData>

                  <StyleTableCellData
                    align="left"
                    sx={{ borderRight: '1px solid #e0e0e0' }}
                  >
                    <StyleTableCellItem>
                      {' '}
                      {row[Object.keys(row) as any]?.all?.value ?? 0}
                    </StyleTableCellItem>
                  </StyleTableCellData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyleElement>
      <StyleElement>
        <StyleTitle>Điều kiện cần</StyleTitle>
        <div style={{ width: '15%' }}>
          <StyleLinks>
            <StyleLink>Khoá đào tạo định kỳ</StyleLink>
          </StyleLinks>
        </div>
        <div style={{ display: 'flex' }}>
          {' '}
          <Checkbox
            sx={{ paddingLeft: '0', paddingTop: '0' }}
            checked={true}
            color="secondary"
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography>Đầu vào</Typography>
        </div>
        <div style={{ display: 'flex' }}>
          {' '}
          <Checkbox
            sx={{ paddingLeft: '0', paddingTop: '0' }}
            checked={true}
            color="secondary"
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography>Nâng cao</Typography>
        </div>
      </StyleElement>
      <StyleElement>
        <StyleTitle>Bộ dụng cụ</StyleTitle>
        <div style={{ display: 'flex' }}>
          {' '}
          <Checkbox
            sx={{ paddingLeft: '0', paddingTop: '0' }}
            checked={true}
            color="secondary"
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography>Cao cấp</Typography>
        </div>
        <div style={{ display: 'flex' }}>
          {' '}
          <Checkbox
            sx={{ paddingLeft: '0', paddingTop: '0' }}
            checked={true}
            color="secondary"
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography>Tiêu chuẩn</Typography>
        </div>
      </StyleElement>
    </>
  )
}

export default PerformanceDetail
