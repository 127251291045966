import { TableCell } from '@mui/material'
import styled from 'styled-components'

export const StyleLinks = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  min-width: 47%;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin-bottom: 24px;
  transition: 0.1s ease-in-out;
  height: 37px;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`
export const StyleError = styled.p`
  display: block;
  color: red;
  font-size: 14px;
  text-align: left;
  white-space: normal;
`

export const StyleLink = styled.span`
  margin-top: 2px;
  font-weight: bold;
`
export const StyleTableCell = styled(TableCell)`
  && {
    font-weight: bold;
    color: var(--color-secondary);
  }
`
export const StyleTableCellItem = styled.p`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const StyleHeaderItem = styled.div`
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const StyleTitle = styled.h2`
  font-size: 25px;
  color: var(--color-primary);
  font-weight: bold;
  text-align: left;
`
