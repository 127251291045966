import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined'
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'

export interface IObjectBannerType {
  service: string
  link: string
  inviteFriend: string
  voucher: string
}

export const bannerType: IObjectBannerType = {
  service: 'Dịch vụ',
  link: 'Link',
  inviteFriend: 'Mời bạn bè',
  voucher: 'Ưu đãi',
}

export const colorBannerType: IObjectBannerType = {
  service: '#ffa044',
  link: '#0dd1e9',
  inviteFriend: '#2b50f2',
  voucher: '#cc0de9',
}

export const iconsBannerType: Record<string, any> = {
  service: (
    <MedicalInformationOutlinedIcon
      sx={{
        background: '#ffa2443c',
        borderRadius: '50%',
        fontSize: '35px',
        padding: '8px',
      }}
    />
  ),
  link: (
    <InsertLinkOutlinedIcon
      sx={{
        background: '#0dcfe92a',
        borderRadius: '50%',
        fontSize: '35px',
        padding: '8px',
      }}
    />
  ),
  inviteFriend: (
    <GroupAddIcon
      sx={{
        background: '#5270f744',
        borderRadius: '50%',
        fontSize: '35px',
        padding: '8px',
      }}
    />
  ),
  voucher: (
    <ConfirmationNumberIcon
      sx={{
        background: '#a624ba37',
        borderRadius: '50%',
        fontSize: '35px',
        padding: '8px',
      }}
    />
  ),
}
