import styled from 'styled-components'

export const StyleError = styled.p`
  display: block;
  color: red;
  font-size: 14px;
  margin: auto !important;
  white-space: normal;
`
export const StyleInput = styled.div`
  opacity: 1;
  outline: 0;
  padding: 10px 0px;
  width: 100%;
`
