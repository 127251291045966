import axiosClient from '../utils/axios'

const staffsApi = {
  createStaff: (data: IStaff) => {
    const url = `/staff/create`
    return axiosClient.post(url, data)
  },
  getStaff: (params: any) => {
    const url = `/staff?page=${params.page}&limit=${params.limit}&search=${params.search}&rank=${params.rank}&service=${params.service}&district=${params.district}`
    return axiosClient.get(url, params)
  },
  getStaffPriority: (params: any) => {
    const url = `/staff?page=${params.page}&limit=${params.limit}&sortBy=priority&sortType=DESC`
    return axiosClient.get(url, params)
  },
  getService: () => {
    const url = `/service`
    return axiosClient.get(url)
  },
  getStaffById: (id: string) => {
    const url = `/staff/${id}`
    return axiosClient.get(url)
  },
  update: (data: IStaff, id: string) => {
    const url = `/staff/update/${id}`
    return axiosClient.patch(url, data)
  },
  delete: (id: string) => {
    const url = `/staff/delete/${id}`
    return axiosClient.delete(url)
  },
  getHistory: (params: any, id: string) => {
    const url = `/staff/${id}/historyOrder?page=${params.page}&limit=${params.limit}`
    return axiosClient.get(url)
  },
  getSchedule: (id: string | undefined) => {
    const url = `/staff/registerSchedule/${id}`
    return axiosClient.get(url)
  },
}

export default staffsApi
