import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Grid, Link } from '@mui/material'
import Item from 'components/Item'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { servicesAPI } from 'services/servicesAPI'
import { PriceProvider } from '../../context/PriceContext'
import TableDistricts from '../Table/TableDistrics'
import TableNation from '../Table/TableNation'
import TablePrice from '../Table/TablePrice'
import TableTime from '../Table/TableTime'
import TableWeek from '../Table/TableWeek'

const DetailProvince = () => {
  const [dataService, setDataService] = useState<any>([])
  const navigate = useNavigate()
  const { id } = useParams()
  const fetchServiceDetail = async () => {
    const { data } = await servicesAPI.getServiceById(id as string)
    setDataService(data)
  }
  useEffect(() => {
    fetchServiceDetail()
  }, [id])
  return (
    <PriceProvider>
      <Grid container spacing={2}>
        <Grid item xs={12} className="padding-bottom">
          <Item sx={{ padding: '12px 24px' }} className="bd-radius">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                sx={{
                  textTransform: 'capitalize',
                  cursor: 'default',
                  '&:hover': {},
                }}
                underline="none"
                color="inherit"
              >
                Quản lý dịch vụ
              </Link>
              <Link
                sx={{
                  textTransform: 'capitalize',
                  cursor: 'pointer',
                  '&:hover': {},
                }}
                underline="none"
                color="inherit"
                onClick={() => navigate(`/service/${id}`)}
              >
                {dataService?.name}
              </Link>
              <Link
                sx={{
                  textTransform: 'capitalize',
                  cursor: 'pointer',
                  '&:hover': {},
                }}
                underline="none"
                color="inherit"
                onClick={() => navigate(`/service/${id}/price-configurations`)}
              >
                Cấu hình giá
              </Link>
              <Link
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  cursor: 'default',
                  '&:hover': {},
                }}
                underline="none"
                color="inherit"
              >
                Chi tiết từng khu vực
              </Link>
            </Breadcrumbs>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{ padding: '24px' }}>
            <TablePrice />
            <TableNation />
            <TableWeek />
            <TableTime />
            <TableDistricts />
          </Item>
        </Grid>
      </Grid>
    </PriceProvider>
  )
}

export default DetailProvince
