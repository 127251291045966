import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Button,
  Stack,
} from '@mui/material'
import { dataAvailable, shiftName } from 'constants/partTimeStaff'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import staffsApi from 'services/staffsAPT'
import { StyleTableCell } from './style'
import { workCalendar } from 'services/staffAPI'
import { useAlert } from 'contexts/AlertContext'
import BasicModal from 'components/Modal'

type TShiftName = {
  shift1: string
  shift2: string
  shift3: string
}

interface MappedDataItem {
  day: number
  shifts: string[]
}

interface DataItem {
  day: number[]
  shifts: number[]
}

const WorkCalendarEdit = () => {
  const { id } = useParams()
  const [data, setData] = useState<any[]>([])
  // const [isOpenBt, setIsOpenBt] = useState(false)
  const [disableBt, setDisableBt] = useState(false)
  const [confirmAdd, setConfirmAdd] = useState(false)
  const { setAlertError, setAlertSuccess } = useAlert()

  const fetchDataCalendar = () => {
    staffsApi.getSchedule(id).then((result) => {
      const customData = result.data.scheduleRegister.map((item: any) => {
        const listDay = item.dayStaffWork.map((subItem: any) => subItem.day)
        return {
          ca: item.shiftName,
          order:
            item.shiftName === 'shift1'
              ? 1
              : item.shiftName == 'shift2'
              ? 2
              : 3,
          t2: listDay.includes(0),
          t3: listDay.includes(1),
          t4: listDay.includes(2),
          t5: listDay.includes(3),
          t6: listDay.includes(4),
          t7: listDay.includes(5),
          t8: listDay.includes(6),
          caFree: result.data.countFree[item.shiftName],
          caLR: `${result.data.countWork[item.shiftName]}/${
            result.data.countRegister[item.shiftName]
          }`,
        }
      })

      // if (customData.length == 0) {
      //   setIsOpenBt(true)
      // }

      const missingObjects = dataAvailable.filter((data) => {
        return !customData.some((custom: any) => custom.ca === data.ca)
      })

      customData.push(...missingObjects)

      setData(
        // customData.length == 0
        // ? dataAvailable
        // :
        customData.sort((a: any, b: any) => a.order - b.order)
      )
    })
  }

  const hasTrueValue = () => {
    if (!disableBt) {
      setConfirmAdd(true)
    } else {
      setAlertError('Lịch làm việc chưa được chọn!')
    }
  }

  const onSubmit = async () => {
    const customerData = data?.map((data: any) => {
      const trueElements = Object.keys(data).filter((key) => data[key] === true)
      const indexes = trueElements.map(
        (element) => Object.keys(data).indexOf(element) - 2
      )
      return { day: indexes, shifts: [data?.order] }
    })

    const mappedData: MappedDataItem[] = customerData.flatMap(
      (item: DataItem) =>
        item.day.map((day: number) => ({
          day,
          shifts: item.shifts.map((shift: number) => shift.toString()),
        }))
    )

    const mergedData = Array.from(
      mappedData
        .reduce((map, item) => {
          const { day, shifts } = item
          const existingItem = map.get(day)

          if (existingItem) {
            existingItem.shifts.push(...shifts)
          } else {
            map.set(day, { day, shifts: [...shifts] })
          }

          return map
        }, new Map())
        .values()
    )

    try {
      await workCalendar.create({ schedule: mergedData }, id as string)
      fetchDataCalendar()
      // setIsOpenBt(false)
      setConfirmAdd(false)
    } catch (error: any) {
      // setIsOpenBt(false)
      setConfirmAdd(false)
      fetchDataCalendar()
      setAlertError(error?.response?.data?.message)
    }
  }

  const renderCheckBox = (check: boolean, key: string, order: number) => {
    if (check === true) {
      return (
        <Checkbox
          sx={{ opacity: '0.7' }}
          defaultChecked={check}
          color="default"
          onChange={() => {
            setData((prevFormValues) => {
              const updatedForms = [...prevFormValues]
              updatedForms[order - 1] = {
                ...updatedForms[order - 1],
                [key]: !check,
              }
              return updatedForms
            })
          }}
        />
      )
    } else if (check === false) {
      return (
        <Checkbox
          sx={{ opacity: '0.7' }}
          color="default"
          onChange={() => {
            setData((prevFormValues) => {
              const updatedForms = [...prevFormValues]
              updatedForms[order - 1] = {
                ...updatedForms[order - 1],
                [key]: !check,
              }
              return updatedForms
            })
          }}
        />
      )
    } else {
      return (
        <IndeterminateCheckBoxIcon sx={{ opacity: '0.5', color: '#ddd728' }} />
      )
    }
  }

  useEffect(() => {
    fetchDataCalendar()
  }, [])

  useEffect(() => {
    const hasTrueValue = data.some((item) =>
      Object.values(item).some((value) => value === true)
    )
    if (!hasTrueValue) {
      setDisableBt(true)
    } else {
      setDisableBt(false)
    }
  }, [data])

  return (
    <>
      <TableContainer component={Paper} className="box-shadow">
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <StyleTableCell align="center">Ca</StyleTableCell>
              <StyleTableCell align="center">Thứ 2</StyleTableCell>
              <StyleTableCell align="center">Thứ 3</StyleTableCell>
              <StyleTableCell align="center">Thứ 4</StyleTableCell>
              <StyleTableCell align="center">Thứ 5</StyleTableCell>
              <StyleTableCell align="center">Thứ 6</StyleTableCell>
              <StyleTableCell align="center">Thứ 7</StyleTableCell>
              <StyleTableCell align="center">Chủ nhật</StyleTableCell>
              <StyleTableCell align="center">Số ca rảnh</StyleTableCell>
              <StyleTableCell align="center">
                Ca đang làm/đăng ký
              </StyleTableCell>
            </TableRow>
          </TableHead>
          {!!data.length && (
            <TableBody>
              {data?.map((row: any) => (
                <TableRow
                  key={row.ca}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {shiftName[row.ca as keyof TShiftName]}
                  </TableCell>
                  <TableCell align="center">
                    {renderCheckBox(row.t2, 't2', row.order)}
                  </TableCell>
                  <TableCell align="center">
                    {renderCheckBox(row.t3, 't3', row.order)}
                  </TableCell>
                  <TableCell align="center">
                    {renderCheckBox(row.t4, 't4', row.order)}
                  </TableCell>
                  <TableCell align="center">
                    {renderCheckBox(row.t5, 't5', row.order)}
                  </TableCell>
                  <TableCell align="center">
                    {renderCheckBox(row.t6, 't6', row.order)}
                  </TableCell>
                  <TableCell align="center">
                    {renderCheckBox(row.t7, 't7', row.order)}
                  </TableCell>
                  <TableCell align="center">
                    {renderCheckBox(row.t8, 't8', row.order)}
                  </TableCell>
                  <TableCell align="center">{row.caFree}</TableCell>
                  <TableCell align="center">{row.caLR}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {/* {isOpenBt && ( */}
      <Stack
        spacing={2}
        direction="row"
        sx={{
          justifyContent: 'flex-start',
          marginTop: '24px',
        }}
      >
        <Button
          variant="contained"
          type="submit"
          sx={{ width: '99px' }}
          className="bgr-global"
          onClick={hasTrueValue}
          disabled={disableBt}
        >
          Lưu
        </Button>
      </Stack>
      {/* )} */}

      <BasicModal open={confirmAdd} handleClose={() => setConfirmAdd(false)}>
        <Typography>Vui lòng xác nhận tạo lịch cho cộng tác viên!</Typography>
        <Button
          sx={{ margin: '8px', marginBottom: '0px' }}
          variant="contained"
          color="error"
          onClick={onSubmit}
        >
          Tạo
        </Button>
        <Button
          sx={{ margin: '8px', marginBottom: '0px' }}
          onClick={() => setConfirmAdd(false)}
          variant="outlined"
        >
          Hủy
        </Button>
      </BasicModal>

      <div
        style={{
          display: 'flex',
          marginTop: '24px',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Typography variant="h6" gutterBottom>
          *Thời gian các ca
        </Typography>
        <Typography variant="body1" gutterBottom>
          &nbsp; &nbsp; Ca sáng: 06:00 AM - 12:00 PM
        </Typography>
        <Typography variant="body1" gutterBottom>
          &nbsp; &nbsp; Ca chiều: 01:00 PM - 05:00 PM
        </Typography>
        <Typography variant="body1" gutterBottom>
          &nbsp; &nbsp; Ca tối: 05:00 PM - 00:00 AM
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '24px',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <Typography variant="h6" gutterBottom>
          *Ý nghĩa ký hiệu
        </Typography>
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          &nbsp;
          <CheckBoxOutlineBlankIcon sx={{ opacity: '0.5' }} />
          <Typography variant="body1" gutterBottom>
            Lịch rảnh (Ca NV không đăng ký làm việc)
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          &nbsp;
          <CheckBoxIcon sx={{ opacity: '0.5' }} />
          <Typography variant="body1" gutterBottom>
            Lịch trống (Ca làm NV đăng ký nhưng chưa có việc)
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          &nbsp;
          <IndeterminateCheckBoxIcon
            sx={{ opacity: '0.5', color: '#ddd728' }}
          />
          <Typography variant="body1" gutterBottom>
            Lịch ca đang làm (Ca làm NV đăng ký và đã có việc)
          </Typography>
        </div>
      </div>
    </>
  )
}

export default WorkCalendarEdit
