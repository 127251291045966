import { Button, Grid, Stack, Typography } from '@mui/material'
import Breadcrumb from 'components/Breadcrumbs'
import InputField from 'components/FormControl/InputField'
import Item from 'components/Item'
import BasicModal from 'components/Modal'
import { PART_TIME_STAFF_DETAIL_FILE } from 'constants/breadcrumbs'
import { useFile } from 'contexts/FileContext'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  StyleAddImage,
  StyleAddImageShow,
  StyleHeaderItem,
  StyleTitle,
  StyleWrapInput,
} from './style'
import downloadFile from 'components/DownloadFile'

interface IFormData {
  staffIdentifierLabelId: string
  title: string
  file: []
  note: string
}

const FileEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [modalOpenFile, setModalOpenFile] = useState(false)
  const [imageShow, setImageShow] = useState<any>([])
  const [formsData, setFormsData] = useState<IFormData[]>([
    // {staffIdentifierLabelId:'', title: '', file: [], note: '' },
  ])
  const { fileData } = useFile()

  const handleShowImage = (idForm: number, idItemFile: number) => {
    setModalOpenFile(true)
    const arrForm = formsData?.filter(
      (item: any, index: number) => idForm == index
    )

    const arrFile = arrForm[0]?.file?.filter(
      (file: any, index: number) => index == idItemFile
    )
    setImageShow(arrFile)
  }

  const fetchDataFile = () => {
    const dataFormat = fileData[0]?.map((item: any) => {
      return item
    })

    if (dataFormat) {
      setFormsData(dataFormat)
    }
  }

  useEffect(() => {
    fetchDataFile()
  }, [])

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={PART_TIME_STAFF_DETAIL_FILE} />
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <StyleHeaderItem>
            <StyleTitle>Bộ hồ sơ</StyleTitle>
          </StyleHeaderItem>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '24px',
              marginBottom: '14px',
            }}
          >
            {formsData.map((formData, indexForm) => (
              <div key={indexForm} style={{ width: '49%' }}>
                <div style={{ display: 'flex', gap: '24px' }}>
                  <Typography variant="h6" gutterBottom>
                    Hồ Sơ {indexForm + 1}
                  </Typography>
                </div>
                <div style={{ display: 'flex' }}>
                  <StyleWrapInput>
                    <InputField
                      label="Loại hồ sơ"
                      id={`title-${indexForm}`}
                      name="title"
                      value={formData?.staffIdentifierLabelId}
                      disabled
                      size="small"
                    />

                    {formData?.file
                      ? formData?.file?.map((item: any, indexFile: number) => (
                          <div
                            style={{
                              position: 'relative',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                            key={indexFile}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                marginTop: '10px',
                              }}
                            >
                              <StyleAddImage
                                src={item}
                                onClick={() =>
                                  handleShowImage(indexForm, indexFile)
                                }
                                style={{ cursor: 'pointer' }}
                              />

                              <Typography
                                sx={{
                                  position: 'absolute',
                                  marginLeft: '100px',
                                  cursor: 'pointer',
                                  maxWidth: '60%',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                                onClick={() => downloadFile(item, item)}
                              >
                                {item}
                              </Typography>
                            </div>
                          </div>
                        ))
                      : null}

                    <InputField
                      label="Ghi chú"
                      id={`note-${indexForm}`}
                      name="note"
                      value={formData.note}
                      disabled
                      size="small"
                    />
                  </StyleWrapInput>
                </div>
              </div>
            ))}
            <BasicModal
              open={modalOpenFile}
              handleClose={() => setModalOpenFile(false)}
            >
              <StyleAddImageShow src={imageShow} />
            </BasicModal>
          </div>

          <Stack
            spacing={2}
            direction="row"
            sx={{
              justifyContent: 'flex-start',
            }}
          >
            <Button
              variant="outlined"
              sx={{ width: '100px' }}
              onClick={() =>
                navigate(`/staff-manager/part-time-staff/detail/${id}`)
              }
            >
              Quay lại
            </Button>
          </Stack>
        </Item>
      </Grid>
    </Grid>
  )
}

export default FileEdit
