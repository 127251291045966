import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { StyleLoading } from './style'

export default function LoadingVersion2() {
  return (
    <StyleLoading>
      <CircularProgress
        sx={{
          width: '75px !important',
          height: '75px !important',
          color: '#f0a970',
        }}
      />
    </StyleLoading>
  )
}
