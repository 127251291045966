import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import Breadcrumb from 'components/Breadcrumbs'
import InputField from 'components/FormControl/InputField'
import Delete from 'components/Icons/components/Delete'
import Edit from 'components/Icons/components/Edit'
import BasicModal from 'components/Modal'
import { FilterBannerType, bannerPriority } from 'constants/banner'
import { BANNER_LIST } from 'constants/breadcrumbs'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/common'
import { useAlert } from 'contexts/AlertContext'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import bannerApi from 'services/bannerAPI'
import {
  IObjectBannerType,
  bannerType,
  colorBannerType,
  iconsBannerType,
} from 'utils/bannerType'
import {
  StyleAddImage,
  StyleBannerType,
  StyleDisplay,
  StyleHeaderItem,
  StyleHide,
  StyleLink,
  StyleLinks,
  StyleTableCell,
  StyleTableCellItem,
  StyleTableHeader,
  StyleTitle,
} from './style'

const BannerList = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [banner, setBanner] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const [status, setStatus] = useState({
    id: '',
  })
  const navigate = useNavigate()
  const { setAlertSuccess, setAlertError, setAlertClose } = useAlert()
  const [valuePromoType, setValuePromoType] = useState(1)

  const handleChangeBannerType = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setValuePromoType(newValue?.props?.value)
  }

  const fetchData = async (page: number) => {
    if (valuePromoType === FilterBannerType.ALL) {
      const { data } = await bannerApi.getBanner({
        page: page + 1,
        limit: rowsPerPage,
      })
      setBanner(data.rows)
      setCount(data.count)
      setPage(page)
    }
    if (valuePromoType === FilterBannerType.PRIORITY) {
      const { data } = await bannerApi.getBannerPriority({
        page: page + 1,
        limit: rowsPerPage,
      })
      setBanner(data.rows)
      setCount(data.count)
      setPage(page)
    }
  }

  const handleOnclick = (id?: string) => {
    if (id) {
      setModalOpen(true)
      setStatus({
        id,
      })
    }
  }

  const handleClose = () => setModalOpen(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    fetchData(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleDelete = async (id: string) => {
    setAlertClose()
    try {
      await bannerApi.delete(id)
      setModalOpen(false)
      setAlertSuccess('Xóa banner thành công')
      fetchData(page)
    } catch (err) {
      setAlertError('Xoá banner không thành công')
    }
  }

  useEffect(() => {
    fetchData(page)
  }, [page, rowsPerPage, valuePromoType])

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={BANNER_LIST} />
      <Grid item xs={12}>
        <Paper
          sx={{
            boxShadow:
              '0px 12px 33px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
            textAlign: 'center',
            height: '100%',
            padding: '0 24px 24px',
          }}
        >
          <StyleTableHeader>
            <StyleHeaderItem>
              <StyleTitle>Banner</StyleTitle>
            </StyleHeaderItem>
            <StyleHeaderItem>
              <StyleLinks onClick={() => navigate('/marketing/banners/create')}>
                <AddCircleIcon sx={{ marginRight: '10px', color: 'white' }} />
                <StyleLink>Thêm banner</StyleLink>
              </StyleLinks>
              <div>
                <InputField
                  id="typeAction"
                  label="Sắp xếp Banner"
                  select
                  value={valuePromoType}
                  onChange={handleChangeBannerType}
                  size="small"
                  sx={{ width: '170px' }}
                >
                  {bannerPriority.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
              </div>
            </StyleHeaderItem>
          </StyleTableHeader>
          <TableContainer component={Paper} className="box-shadow">
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <StyleTableCell align="center" sx={{ padding: '0px' }}>
                    STT
                  </StyleTableCell>
                  <StyleTableCell align="center">Hình ảnh</StyleTableCell>
                  <StyleTableCell align="left">Tiêu đề</StyleTableCell>
                  <StyleTableCell align="left">Loại</StyleTableCell>
                  <StyleTableCell align="left">Trạng thái</StyleTableCell>
                  <StyleTableCell align="left" sx={{ padding: '0px' }}>
                    Độ ưu tiên
                  </StyleTableCell>
                  <StyleTableCell align="center">Hành động</StyleTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {banner?.map((row: IBanner, index: number) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{ padding: '0px' }}
                    >
                      {banner && index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell align="center">
                      {row?.thumbnail ? (
                        <StyleAddImage src={row.thumbnail} />
                      ) : (
                        <AddPhotoAlternateIcon
                          sx={{
                            width: '75px !important',
                            height: '50px !important',
                          }}
                        />
                      )}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        '&:hover': { opacity: 0.6 },
                        cursor: 'pointer',
                        maxWidth: '200px',
                      }}
                      onClick={() =>
                        navigate(`/marketing/banners/detail/${row.id}`)
                      }
                      width={180}
                    >
                      <StyleTableCellItem>{row.title}</StyleTableCellItem>
                    </TableCell>
                    <TableCell align="left" sx={{ paddingLeft: '5px' }}>
                      <StyleBannerType
                        style={{
                          color:
                            colorBannerType[
                              row?.typeAction as keyof IObjectBannerType
                            ],
                        }}
                      >
                        {
                          iconsBannerType[
                            row?.typeAction as keyof IObjectBannerType
                          ]
                        }
                        {bannerType[row?.typeAction as keyof IObjectBannerType]}
                      </StyleBannerType>
                    </TableCell>
                    <TableCell align="left">
                      {row?.status === true ? (
                        <StyleDisplay>
                          <RemoveRedEyeOutlinedIcon />
                          Hiển thị
                        </StyleDisplay>
                      ) : (
                        <StyleHide>
                          <VisibilityOffOutlinedIcon />
                          Ẩn
                        </StyleHide>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <StyleTableCellItem>
                        {row.priority === 0 ? '-' : row.priority}
                      </StyleTableCellItem>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: '10px',
                        }}
                      >
                        <Edit
                          onClick={() =>
                            navigate(`/marketing/banners/edit/${row.id}`)
                          }
                        />

                        <Delete
                          onClick={() => {
                            handleOnclick(row?.id)
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {modalOpen && (
              <BasicModal open={modalOpen} handleClose={handleClose}>
                <Typography>
                  Bạn có chắc chắn muốn xoá banner này chứ?
                </Typography>
                <Button
                  sx={{ margin: '8px', marginBottom: '0px' }}
                  variant="contained"
                  color="error"
                  onClick={() => handleDelete(status.id)}
                >
                  Xóa
                </Button>
                <Button
                  sx={{ margin: '8px', marginBottom: '0px' }}
                  onClick={handleClose}
                  variant="outlined"
                >
                  Hủy
                </Button>
              </BasicModal>
            )}
            <TablePagination
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Số dòng trên một trang"
            />
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default BannerList
