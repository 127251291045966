import * as yup from 'yup'

export const promotionSchema_ = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required('Tên khuyến mãi không được để trống')
    .max(50, 'Tiêu đề có tối thiểu 3, tối đa 50 ký tự')
    .min(3, 'Tiêu đề có tối thiểu 3, tối đa 50 ký tự'),
  subTitle: yup.string().required('Nội dung ngắn không được để trống'),
  startDate: yup
    .string()
    .typeError('Ngày bắt đầu không được để trống')
    .required('Ngày bắt đầu không được để trống')
    .test(
      'startDate',
      'Ngày bắt đầu phải nhỏ hơn ngày kết thúc',
      function (value: string | undefined) {
        const { endDate } = this.parent
        if (!value) {
          return true
        }
        return !endDate || new Date(value) < new Date(endDate)
      }
    )
    .test('is-valid-date', 'Ngày không đúng định dạng', function (value: any) {
      return value && !isNaN(Date.parse(value))
    })
    .test(
      'startDate',
      'Ngày bắt đầu phải lớn hơn hoặc bằng ngày hiện tại',
      function (value: string | undefined) {
        const currentDate = new Date()
        const valueDate = new Date(value as any)
        const utcDate1 = Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        )
        const utcDate2 = Date.UTC(
          valueDate.getFullYear(),
          valueDate.getMonth(),
          valueDate.getDate()
        )
        if (!value) {
          return true
        }

        return utcDate1 <= utcDate2
      }
    ),

  endDate: yup
    .string()
    .typeError('Ngày kết thúc không được để trống')
    .required('Ngày kết thúc không được để trống')
    .test(
      'endDate',
      'Ngày kết thúc phải lớn hơn ngày bắt đầu',
      function (value: string | undefined) {
        const { startDate } = this.parent
        if (!value) {
          return true
        }
        return !startDate || new Date(value) > new Date(startDate)
      }
    )
    .test('is-valid-date', 'Ngày không đúng định dạng', function (value: any) {
      return value && !isNaN(Date.parse(value))
    })
    .test(
      'endDate',
      'Ngày kết thúc phải lớn hơn hoặc bằng ngày hiện tại',
      function (value: string | undefined) {
        const currentDate = new Date()
        const valueDate = new Date(value as any)
        const utcDate1 = Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        )
        const utcDate2 = Date.UTC(
          valueDate.getFullYear(),
          valueDate.getMonth(),
          valueDate.getDate()
        )
        if (!value) {
          return true
        }
        return utcDate1 <= utcDate2
      }
    ),
  promotionCode: yup
    .string()
    .required('Mã khuyến mãi không được để trống')
    .matches(/^[a-zA-Z0-9]+$/, 'Mã khuyến mãi phải là chữ hoặc số'),
  typePromo: yup.string().trim().required('Loại đổi điểm không được để trống'),
  totalCode: yup
    .string()
    .nullable()
    .typeError('Số lượng code không được để trống')
    .when('typePromo', {
      is: 'code',
      then: yup
        .string()
        .required('Số lượng code không được để trống')
        .matches(/^[1-9]\d*$/, 'Số lượng code sai định dạng'),
    }),

  maxNumberOfUse: yup
    .string()
    .nullable()
    .typeError('Giới hạn số lần sử dụng không được để trống')
    .when('typePromo', {
      is: 'code',
      then: yup
        .string()
        .required('Giới hạn số lần sử dụng không được để trống')
        .matches(/^[1-9]\d*$/, 'Giới hạn số lần sử dụng sai định dạng'),
    }),
  minPoint: yup.string().when('typePromo', {
    is: 'point',
    then: yup
      .string()
      .typeError('Số Point không được để trống')
      .required('Số Point không được để trống')
      .matches(/^[1-9]\d*$/, 'Số Point sai định dạng'),
  }),
  reduceType: yup
    .string()
    .trim()
    .required('Kiểu khuyến mãi không được để trống'),
  discount: yup.number().when('reduceType', {
    is: 'Giảm tiền',
    then: yup
      .number()
      .typeError('Giá tiền không được để trống')
      .required('Giá tiền không được để trống')
      .min(0, 'Giá tiền sai định dạng'),
  }),
  min: yup.number().when('reduceType', {
    is: 'Giảm tiền',
    then: yup
      .number()
      .typeError('Giá tiền không được để trống')
      .required('Giá tiền không được để trống')
      .min(0, 'Giá tiền sai định dạng'),
  }),
  percent: yup.string().when('reduceType', {
    is: 'Giảm phần trăm',
    then: yup
      .string()
      .typeError('Phần trăm không được để trống')
      .required('Phần trăm không được để trống')
      .matches(/^\d*\.?\d+$/, 'Phần trăm sai định dạng'),
  }),
  minPercent: yup.number().when('reduceType', {
    is: 'Giảm phần trăm',
    then: yup
      .number()
      .typeError('Giá tiền không được để trống')
      .required('Giá tiền không được để trống')
      .min(0, 'Giá tiền sai định dạng'),
  }),
  maxAmount: yup.number().when('reduceType', {
    is: 'Giảm phần trăm',
    then: yup
      .number()
      .typeError('Giá tiền không được để trống')
      .required('Giá tiền không được để trống')
      .min(0, 'Giá tiền sai định dạng'),
  }),
})
