import { TextField } from '@mui/material'
import React, { forwardRef } from 'react'
import { StyleInput } from './styleInputField'
/* eslint-disable react/display-name */
const InputField = forwardRef((props: any, ref: any) => {
  return (
    <StyleInput>
      <TextField fullWidth {...props} ref={ref} size="small" />
    </StyleInput>
  )
})
export default InputField
