import dayjs from 'dayjs'
import {
  VALIDATE_DATE_FORMAT,
  VALIDATE_STRING_CITIZEN_ID,
  VALIDATE_STRING_PHONE,
} from 'utils/regex'
import * as yup from 'yup'

export const partTimeStaffCreateSchema_ = yup.object().shape({
  fullName: yup
    .string()
    .trim()
    .required('Họ và tên không được để trống')
    .min(3, 'Họ và tên có tối thiểu 3 ký tự, tối đa 50 ký tự')
    .max(50, 'Họ và tên có tối thiểu 3 ký tự, tối đa 50 ký tự'),

  email: yup
    .string()
    .email('Email không đúng định dạng')
    .typeError('Email không đúng định dạng'),
  // .required('Email không được để trống'),

  phone: yup
    .string()
    .required('Số điện thoại không được để trống')
    .max(10, 'Số điện thoại không quá 10 số')
    .matches(VALIDATE_STRING_PHONE, 'Số điện thoại không hợp lệ'),

  subPhone: yup
    .string()
    .required('Số điện thoại không được để trống')
    .typeError('Số điện thoại không được để trống')
    .max(10, 'Số điện thoại không quá 10 số')
    .matches(VALIDATE_STRING_PHONE, 'Số điện thoại không hợp lệ'),

  // staffCode: yup.string().required('Mã cộng tác viên không được để trống'),

  birth: yup
    .string()
    .required('Ngày sinh không được để trống')
    .typeError('Ngày sinh không được để trống')
    .test(
      'birth',
      'Định dạng ngày tháng năm không hợp lệ',
      function (value: any) {
        const checkDate = dayjs(value).format('DD/MM/YYYY')
        if (!checkDate) return true // Allow empty value, if it's not required
        const dateFormat = /^(0[1-9]|[1-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
        if (!dateFormat.test(checkDate)) return false
        const [day, month, year] = checkDate.split('/')
        const isValidDate = !isNaN(Date.parse(`${year}-${month}-${day}`))
        return isValidDate
      }
    )
    .test(
      'birth',
      'Ngày sinh phải nhỏ hơn hoặc bằng ngày hiện tại',
      function (value: any) {
        return new Date(value) <= new Date()
      }
    ),

  born: yup
    .string()
    .required('Ngày sinh nhật không được để trống')
    .typeError('Ngày sinh không được để trống')
    .test(
      'born',
      'Định dạng ngày tháng năm không hợp lệ',
      function (value: any) {
        const checkDate = dayjs(value).format('DD/MM/YYYY')
        if (!checkDate) return true // Allow empty value, if it's not required
        const dateFormat = /^(0[1-9]|[1-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
        if (!dateFormat.test(checkDate)) return false
        const [day, month, year] = checkDate.split('/')
        const isValidDate = !isNaN(Date.parse(`${year}-${month}-${day}`))
        return isValidDate
      }
    )
    .test(
      'born',
      'Ngày sinh nhật phải nhỏ hơn hoặc bằng ngày hiện tại',
      function (value: any) {
        return new Date(value) <= new Date()
      }
    ),

  // recruitmentSpecialist: yup
  //   .string()
  //   .trim()
  //   .required('Chuyên viên tuyển dụng không được để trống'),

  // groups: yup.string().required('Tổ/đội/nhóm không được để trống'),

  zalo: yup
    .string()
    .required('Zalo không được để trống')
    .typeError('Zalo không được để trống')
    .max(10, 'Zalo không quá 10 số')
    .matches(VALIDATE_STRING_PHONE, 'Zalo không hợp lệ'),

  // facebook: yup.string().trim().typeError(''),
  // .required('Facebook không được để trống')
  // .url('Facebook không hợp lệ'),

  // contractsNumber: yup.string().required('Số hợp đồng không được để trống'),

  contractSigningDate: yup
    .string()
    .required('Ngày ký hợp đồng không được để trống')
    .typeError('Ngày ký hợp đồng không được để trống')
    .test(
      'contractSigningDate',
      'Định dạng ngày tháng năm không hợp lệ',
      function (value: any) {
        const checkDate = dayjs(value).format('DD/MM/YYYY')
        if (!checkDate) return true // Allow empty value, if it's not required
        const dateFormat = /^(0[1-9]|[1-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
        if (!dateFormat.test(checkDate)) return false
        const [day, month, year] = checkDate.split('/')
        const isValidDate = !isNaN(Date.parse(`${year}-${month}-${day}`))
        return isValidDate
      }
    ),
  // .matches(VALIDATE_DATE_FORMAT, 'Định dạng không hợp lệ'),

  DateStartWork: yup
    .string()
    .required('Ngày bắt đầu làm việc không được để trống')
    .typeError('Ngày bắt đầu làm việc không được để trống')
    .test(
      'DateStartWork',
      'Định dạng ngày tháng năm không hợp lệ',
      function (value: any) {
        const checkDate = dayjs(value).format('DD/MM/YYYY')
        if (!checkDate) return true // Allow empty value, if it's not required
        const dateFormat = /^(0[1-9]|[1-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
        if (!dateFormat.test(checkDate)) return false
        const [day, month, year] = checkDate.split('/')
        const isValidDate = !isNaN(Date.parse(`${year}-${month}-${day}`))
        return isValidDate
      }
    ),

  // gender: yup.string().required('Giới tính không được để trống'),

  // homeTown: yup.string().required('Nguyên quán không được để trống'),

  // typeOfIdentityDocument: yup
  //   .string()
  //   .required('Loại giấy tờ tuỳ thân không được để trống'),

  numberOfIdentityDocument: yup
    .string()
    .required('CCCD/CMND/Hộ chiếu không được để trống')
    .typeError('CCCD/CMND/Hộ chiếu không được để trống')
    .min(9, 'CMND bao gồm 9 số')
    .max(12, 'CCCD bao gồm 12 số')
    .matches(VALIDATE_STRING_CITIZEN_ID, 'CCCD/CMND/Hộ chiếu không hợp lệ'),

  registrationDate: yup
    .string()
    .required('Ngày cấp không được để trống')
    .typeError('Ngày cấp không được để trống')
    .test(
      'registrationDate',
      'Định dạng ngày tháng năm không hợp lệ',
      function (value: any) {
        const checkDate = dayjs(value).format('DD/MM/YYYY')
        if (!checkDate) return true // Allow empty value, if it's not required
        const dateFormat = /^(0[1-9]|[1-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
        if (!dateFormat.test(checkDate)) return false
        const [day, month, year] = checkDate.split('/')
        const isValidDate = !isNaN(Date.parse(`${year}-${month}-${day}`))
        return isValidDate
      }
    ),

  // place: yup.string().required('Nơi cấp không được để trống'),

  permanentAddress: yup
    .string()
    .required('Địa chỉ thường trú không được để trống')
    .typeError('Địa chỉ thường trú không được để trống'),
  temporaryAddress: yup
    .string()
    .typeError('Địa chỉ tạm trú không được để trống')
    .required('Địa chỉ tạm trú không được để trống'),

  // religion: yup.string().required('Tôn giáo không được để trống'),

  // vehicle: yup.string().required('Phương tiện không được để trống'),

  // maritalStatus: yup
  //   .string()
  //   .required('Tình trạng hôn nhân không được để trống'),

  // knownSource: yup.string().required('Nguồn biết đến không được để trống'),

  // pile: yup.string().required('Cọc đăng ký công việc không được để trống'),

  pileDate: yup
    .string()
    .required('Ngày cọc không được để trống')
    .typeError('Ngày cọc không được để trống')
    .test(
      'pileDate',
      'Định dạng ngày tháng năm không hợp lệ',
      function (value: any) {
        const checkDate = dayjs(value).format('DD/MM/YYYY')
        if (!checkDate) return true // Allow empty value, if it's not required
        const dateFormat = /^(0[1-9]|[1-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
        if (!dateFormat.test(checkDate)) return false
        const [day, month, year] = checkDate.split('/')
        const isValidDate = !isNaN(Date.parse(`${year}-${month}-${day}`))
        return isValidDate
      }
    ),

  // pileStatus: yup.string().required('Tình trạng cọc không được để trống'),

  depositRefundDate: yup
    .string()
    .required('Ngày hoàn cọc không được để trống')
    .typeError('Ngày hoàn cọc không được để trống')
    .test(
      'depositRefundDate',
      'Định dạng ngày tháng năm không hợp lệ',
      function (value: any) {
        const checkDate = dayjs(value).format('DD/MM/YYYY')
        if (!checkDate) return true // Allow empty value, if it's not required
        const dateFormat = /^(0[1-9]|[1-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
        if (!dateFormat.test(checkDate)) return false
        const [day, month, year] = checkDate.split('/')
        const isValidDate = !isNaN(Date.parse(`${year}-${month}-${day}`))
        return isValidDate
      }
    ),

  // desire: yup.string().required('Mong muốn của cộng tác viên không được để trống'),

  // PersonalTaxCode: yup
  //   .string()
  //   .required('Mã số thuế cá nhân không được để trống'),

  // accountNumber: yup.string().required('Số tài khoản không được để trống'),

  // accountName: yup.string().required('Tên tài khoản được để trống'),

  // bankName: yup.string().required('Tên ngân hàng không được để trống'),

  // bankBranch: yup.string().required('Chi nhánh không được để trống'),
})

export const labelSchema_ = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Giá trị không được để trống')
    .min(3, 'Giá trị có tối thiểu 3 ký tự, tối đa 50 ký tự')
    .max(50, 'Giá trị có tối thiểu 3 ký tự, tối đa 50 ký tự'),
})

export const TypeFileSchema_ = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Loại hồ sơ không được để trống')
    .min(3, 'Loại hồ sơ có tối thiểu 3 ký tự, tối đa 50 ký tự')
    .max(50, 'Loại hồ sơ có tối thiểu 3 ký tự, tối đa 50 ký tự'),
})

export const relativeSchema_ = yup.object().shape({
  relationship: yup
    .string()
    .trim()
    .required('Quan hệ với cộng tác viên không được để trống'),

  fullName: yup
    .string()
    .trim()
    .required('Họ và tên không được để trống')
    .min(3, 'Họ và tên có tối thiểu 3 ký tự, tối đa 50 ký tự')
    .max(50, 'Họ và tên có tối thiểu 3 ký tự, tối đa 50 ký tự'),

  phone: yup
    .string()
    .required('Số điện thoại không được để trống')
    .max(10, 'Số điện thoại không quá 10 số')
    .matches(VALIDATE_STRING_PHONE, 'Số điện thoại không hợp lệ'),

  address: yup.string().trim().required('Địa chỉ không được để trống'),
})

export const locationSchema_ = yup.object().shape({
  country: yup.string().trim().required('Quốc gia không được để trống'),

  province: yup.string().trim().required('Tỉnh/Thành phố không được để trống'),

  district: yup.string().required('Quận/Huyện không được để trống'),

  address: yup.string().trim().required('Địa chỉ không được để trống'),
})
