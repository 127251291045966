import { API_JSON_URL } from 'constants/common'
import axiosClient from '../utils/axios'

const campaignApi = {
  createCampaign: (data: ICampaign) => {
    const url = `/campaignList`
    return axiosClient.post(url, data, { baseURL: API_JSON_URL })
  },
  getAllCampaign: () => {
    const url = `/campaignList`
    return axiosClient.get(url, { baseURL: API_JSON_URL })
  },
  delete: (id: string) => {
    const url = `/campaignList/${id}`
    return axiosClient.delete(url, { baseURL: API_JSON_URL })
  },
  getCampaignById: (id: string) => {
    const url = `/campaignList/${id}`
    return axiosClient.get(url, { baseURL: API_JSON_URL })
  },
  editCampaign: (data: ICampaign, id: string) => {
    const url = `/campaignList/${id}`
    return axiosClient.patch(url, data, { baseURL: API_JSON_URL })
  },
  getReviewer: () => {
    const url = `/reviewer`
    return axiosClient.get(url, { baseURL: API_JSON_URL })
  },
}

export default campaignApi
