import { yupResolver } from '@hookform/resolvers/yup'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Link,
  Stack,
  Switch,
  Typography,
} from '@mui/material'
import Editor from 'components/Editor'
import InputField from 'components/FormControl/InputField'
import InputNumberFormat from 'components/InputNumberFormat'
import Item from 'components/Item'
import LoadingVersion2 from 'components/LoadingV2'
import BasicModal from 'components/Modal'
import { IMAGE_TYPE, MAX_FILE_SIZE } from 'constants/common'
import { useAlert } from 'contexts/AlertContext'
import { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'
import { servicesAPI } from 'services/servicesAPI'
import { checkChangeData } from 'utils/confirmBox'
import { FileTypeEnum, getFileSize } from 'utils/FileUtils'
import { uploadImage } from 'utils/uploadImage'
import { homeCleaningSchema_ } from 'validations/homeCleaningSchema'

import { useLoading } from 'contexts/LoadingContext'
import { useSidebar } from 'contexts/SideBarContext'
import {
  StyleAddImage,
  StyleAddItem,
  StyleButton,
  StyleContent,
  StyleContentInput,
  StyleContentText,
  StyledInput,
  StyleError,
  StyleErrorImage,
  StyleImage,
  StyleInfo,
  StyleItem,
  StyleLabel,
  StyleLink,
  StyleLinks,
  StyleTimeInput,
  StyleTitle,
  StyleToogle,
} from './style'

enum Status {
  active = 'active',
  deactivated = 'deactivated',
}
const HomeCleaning = () => {
  const { isListUpdated, setIsListUpdated } = useSidebar()
  const [content, setContent] = useState('')
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const [image, setImage] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [checked, setChecked] = useState(false)
  const [data, setData] = useState<IService>()
  const [dataDefault, setDataDefault] = useState<IDataDefault>()
  const [contentDefault, setContentDefault] = useState<string>()
  const [iconDefault, setIconDefault] = useState<string>()
  const [stateDefault, setStateDefault] = useState<string>()
  const [errorImage, setErrorImage] = useState('')
  const [errorContent, setErrorContent] = useState<string>()
  const { id } = useParams()
  const { setAlertSuccess, setAlertError } = useAlert()
  const navigate = useNavigate()
  const [dataSave, setDataSave] = useState<IService>()
  const [modalSave, setModaSave] = useState<boolean>(false)
  const [modalCancel, setModaCancel] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(homeCleaningSchema_),
    mode: 'onChange',
  })
  const [imageURL, setImageURL] = useState('')
  const handleEditorChange = (value: string) => {
    setContent(value)
    if (value === '<p><br></p>' || value === '') {
      setErrorContent('Premium không được để trống')
      return
    } else {
      setErrorContent(undefined)
    }
  }

  const handleCancelClose = () => setModaCancel(false)
  const handleClose = () => {
    setModalOpen(false)
  }

  const checkOtherCondition = () => {
    if (
      contentDefault !== content ||
      iconDefault !== imageURL ||
      stateDefault !== (checked ? Status.active : Status.deactivated)
    ) {
      return true
    }
    return false
  }

  const handleUpload = async (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
    if (file) {
      if (fileSize > MAX_FILE_SIZE) {
        setErrorImage(`Dung lượng hình ảnh không quá ${MAX_FILE_SIZE} MB`)
        return
      }
      if (!file?.type.match(IMAGE_TYPE)) {
        setErrorImage('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
        return
      }
      if (fileSize === 0) {
        setErrorImage(`Ảnh lỗi`)
        return
      }
      const response: IUploadImg = await uploadImage(file)
      setImageURL(response.origin)
      setImage(URL?.createObjectURL((target.files as FileList)[0]))
      setErrorImage('')
    }
  }
  const handleCheckDataSave = () => {
    const getValuesItem = getValues()
    const isChanged = checkChangeData({
      dataChange: getValuesItem,
      dataDefault: dataDefault,
    })

    const checkOrther = checkOtherCondition()
    if (content === '<p><br></p>' || content === '') {
      setErrorContent('Premium không được để trống')
      return
    } else {
      setErrorContent(undefined)
    }
    if (isChanged || checkOrther) {
      setModaSave(true)
    } else {
      return
    }
  }
  const handleChange = () => {
    setModalOpen(true)
  }
  const handleCheckDataCancel = () => {
    const getValuesItem = getValues()
    const isChanged = checkChangeData({
      dataChange: getValuesItem,
      dataDefault: dataDefault,
    })
    const checkOrther = checkOtherCondition()

    if (isChanged || checkOrther) {
      setModaCancel(true)
    } else {
      return
    }
  }
  const handleFormChange = (data: IService) => {
    setDataSave(data)
    handleCheckDataSave()
  }
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = event
    const inputValue = (target as HTMLInputElement).value

    if (!/[0-9.,]/.test(key)) {
      event.preventDefault()
    }
    if (
      (key === '.' || key === ',') &&
      (inputValue.includes('.') || inputValue.includes(','))
    ) {
      event.preventDefault()
    }
  }

  const onSubmit = async (values: any) => {
    setLoadingTrue()
    if (content === '<p><br></p>' || content === '') {
      setErrorContent('Premium không được để trống')
      setLoadingFalse()
      return
    } else {
      setErrorContent(undefined)
    }
    const service = {
      name: values.name,
      description: values.description,
      icon: imageURL,
      state: checked ? 'active' : 'deactivated',
    }
    const serviceSetting = {
      availableTime: values.serviceCondition,
      changeOrderInfoBefore: values.infoCondition,
      cancellationPolicy: {
        freeCancel: {
          before: {
            value: Number(values.freeCondition),
            unit: 'hours',
          },
          fee: {
            type: 'amount',
            value: 0,
          },
        },
        feeCancelLevel1: {
          before: {
            value: Number(values.timeBeforeCondition),
            unit: 'hours',
          },
          after: {
            value: Number(values.freeCondition),
            unit: 'hours',
          },
          fee: {
            value: Number(values?.price),
            type: 'amount',
          },
        },
        feeCancelLevel2: {
          before: {
            value: Number(values.timeBeforeCondition),
            unit: 'hours',
          },
          after: null,
          fee: {
            value: Number(values?.percentCondition),
            type: 'percent',
          },
        },
      },
    }

    const serviceItems = data?.serviceItems?.map((item, index) => {
      return {
        id: item?.id,
        price: values[`price${index}` as keyof IDataFormService],
        itemOptions: {
          price: {
            id: item?.itemOptions?.[0]?.price?.id,
            value: values[`premium${index}` as keyof IDataFormService],
          },
        },
      }
    })

    const autoPickStaff = data?.serviceExtraOptions?.find(
      (item) => item.configPrice
    )

    const serviceExtraOptions = [
      {
        id: autoPickStaff?.id,
        price: {
          id: autoPickStaff?.price?.id,
          value: values.chooseStaff,
        },
      },
    ]

    const itemExtraOptions = [
      {
        id: data?.itemExtraOptions?.[0]?.id,
        tooltip: content,
      },
    ]

    const dataUpdate = {
      service,
      serviceSetting,
      serviceItems,
      serviceExtraOptions,
      itemExtraOptions,
    }

    try {
      setLoadingFalse()
      await servicesAPI.updateService(dataUpdate, id as string)
      setIsListUpdated(true)
      setAlertSuccess('Cập nhật dịch vụ thành công')
      setModaSave(false)
      fetchServiceDetail()
    } catch (error) {
      setLoadingFalse()
      setAlertError('Cập nhật dịch vụ không thành công')
    }
  }

  const fetchServiceDetail = async () => {
    const { data }: { data?: IService } = await servicesAPI.getServiceById(
      id as string
    )
    if (data) {
      setContent(data.itemExtraOptions?.[0]?.tooltip)
      setData(data)
      if (data.state === 'active') {
        setChecked(true)
      } else {
        setChecked(false)
      }
      setImage(data.icon)
      setImageURL(data.icon)
      setValue(
        'price',
        data?.serviceSetting?.cancellationPolicy?.feeCancelLevel1?.fee?.value ??
          0
      )

      data?.serviceItems?.map((item, index) => {
        setValue(`price${index}`, item?.price)
        setValue(`premium${index}`, item?.itemOptions?.[0]?.price?.value ?? 0)
      })
      const priceSelectStaff = data?.serviceExtraOptions?.find(
        (item) => item.configPrice
      )
      setValue('chooseStaff', priceSelectStaff?.price.value ?? 0)
    }
  }
  useEffect(() => {
    fetchServiceDetail()
  }, [id])
  useEffect(() => {
    if (data) {
      setDataDefault({
        chooseStaff: data?.serviceExtraOptions?.[1]?.price.value,
        description: data?.description,
        freeCondition:
          data?.serviceSetting?.cancellationPolicy?.freeCancel.before.value,
        infoCondition: data?.serviceSetting?.changeOrderInfoBefore,
        name: data?.name,
        percentCondition:
          data?.serviceSetting?.cancellationPolicy?.feeCancelLevel2.fee?.value,
        premium0: data?.serviceItems?.[0]?.itemOptions?.[0]?.price.value,
        premium1: data?.serviceItems?.[1]?.itemOptions?.[0]?.price.value,
        premium2: data?.serviceItems?.[2]?.itemOptions?.[0]?.price.value,
        price:
          data?.serviceSetting?.cancellationPolicy?.feeCancelLevel1.fee?.value,
        price0: data?.serviceItems?.[0]?.price,
        price1: data?.serviceItems?.[1]?.price,
        price2: data?.serviceItems?.[2]?.price,
        serviceCondition: data?.serviceSetting?.availableTime,
        timeBeforeCondition:
          data?.serviceSetting?.cancellationPolicy?.feeCancelLevel1.before
            .value,
      })
      setContentDefault(data?.itemExtraOptions?.[0]?.tooltip)
      setIconDefault(data?.icon)
      setStateDefault(data?.state)
    }
  }, [data])
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="padding-bottom">
          <Item sx={{ padding: '12px 24px' }} className="bd-radius">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                sx={{
                  textTransform: 'capitalize',
                  cursor: 'default',
                  '&:hover': {},
                }}
                underline="none"
                color="inherit"
              >
                Quản lý dịch vụ
              </Link>
              <Link
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  cursor: 'default',
                  '&:hover': {},
                }}
                underline="none"
                color="inherit"
              >
                {data?.name}
              </Link>
            </Breadcrumbs>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={{ padding: '24px' }}>
            <StyleToogle>
              <StyleTitle>Thông tin dịch vụ</StyleTitle>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Ẩn</Typography>
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  color="secondary"
                />
                <Typography>Hiện</Typography>
              </Stack>
            </StyleToogle>
            {data ? (
              <form
                onSubmit={handleSubmit((data: IService) => {
                  handleFormChange(data)
                })}
              >
                <StyleItem
                  style={{
                    paddingTop: '0',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <StyleImage>
                    {image ? (
                      <StyleAddImage>
                        <img src={image} alt="Icon" />
                        <StyleAddItem>
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={handleUpload}
                            name="image"
                          />
                        </StyleAddItem>
                      </StyleAddImage>
                    ) : (
                      <StyleAddImage>
                        <img src={data.icon} alt="Icon" />
                        <StyleAddItem>
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={handleUpload}
                          />
                        </StyleAddItem>
                      </StyleAddImage>
                    )}
                    {errorImage && (
                      <StyleErrorImage>{errorImage}</StyleErrorImage>
                    )}
                  </StyleImage>
                  <StyleInfo>
                    <InputField
                      label="Tên"
                      defaultValue={data?.name ?? ''}
                      {...register('name')}
                    />
                    {errors.name && (
                      <StyleError>{errors.name.message as string}</StyleError>
                    )}
                    <InputField
                      label="Nội dung"
                      multiline
                      defaultValue={data?.description ?? ''}
                      {...register('description')}
                    />
                    {errors.description && (
                      <StyleError>
                        {errors.description.message as string}
                      </StyleError>
                    )}
                    <StyleLabel>Mô tả Premium</StyleLabel>
                    <Editor onChange={handleEditorChange} value={content} />
                    {!!errorContent && (
                      <StyleError style={{ marginTop: '10px' }}>
                        {errorContent}
                      </StyleError>
                    )}
                  </StyleInfo>
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleTitle>Điều kiện thời gian đặt dịch vụ</StyleTitle>
                  <StyleContent>
                    <StyleContentText style={{ marginRight: '8px' }}>
                      Có thể đặt sau
                    </StyleContentText>
                    <StyleTimeInput>
                      <StyledInput
                        sx={{ input: { textAlign: 'center' } }}
                        defaultValue={data?.serviceSetting?.availableTime}
                        size="small"
                        type="text"
                        inputProps={{
                          onKeyPress: handleKeyPress,
                          inputMode: 'numeric',
                        }}
                        {...register('serviceCondition')}
                      />
                    </StyleTimeInput>

                    <StyleContentText style={{ marginLeft: '8px' }}>
                      tiếng
                    </StyleContentText>
                  </StyleContent>
                  {errors.serviceCondition && (
                    <StyleError>
                      {errors.serviceCondition.message as string}
                    </StyleError>
                  )}
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleTitle>
                    Điều kiện thay đổi thông tin công việc
                  </StyleTitle>

                  <StyleContent>
                    <StyleContentText style={{ marginRight: '8px' }}>
                      Đổi thông tin đơn hàng trước
                    </StyleContentText>

                    <StyleTimeInput>
                      <StyledInput
                        sx={{ input: { textAlign: 'center' } }}
                        defaultValue={
                          data?.serviceSetting?.changeOrderInfoBefore
                        }
                        type="text"
                        inputProps={{
                          onKeyPress: handleKeyPress,
                          inputMode: 'numeric',
                        }}
                        size="small"
                        {...register('infoCondition')}
                      />
                    </StyleTimeInput>
                    <StyleContentText style={{ marginLeft: '8px' }}>
                      tiếng
                    </StyleContentText>
                  </StyleContent>
                  {errors.infoCondition && (
                    <StyleError>
                      {errors.infoCondition.message as string}
                    </StyleError>
                  )}
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleTitle>Điều kiện huỷ việc</StyleTitle>
                  <StyleContent>
                    <StyleContentText style={{ marginRight: '8px' }}>
                      Không mất phí trước:
                    </StyleContentText>

                    <StyleTimeInput>
                      <StyledInput
                        sx={{ input: { textAlign: 'center' } }}
                        defaultValue={
                          data?.serviceSetting?.cancellationPolicy?.freeCancel
                            ?.before?.value
                        }
                        type="text"
                        inputProps={{
                          onKeyPress: handleKeyPress,
                          inputMode: 'numeric',
                        }}
                        size="small"
                        {...register('freeCondition')}
                      />
                    </StyleTimeInput>

                    <StyleContentText style={{ marginLeft: '8px' }}>
                      tiếng
                    </StyleContentText>
                  </StyleContent>
                  {errors.freeCondition && (
                    <StyleError>
                      {errors.freeCondition.message as string}
                    </StyleError>
                  )}
                  <StyleContent>
                    <StyleContentText style={{ marginRight: '8px' }}>
                      Phí
                    </StyleContentText>
                    <StyleContentInput style={{ maxWidth: '110px' }}>
                      <InputNumberFormat
                        name="price"
                        control={control}
                        text-align="left"
                        endAdornment={
                          <InputAdornment position="end">đ</InputAdornment>
                        }
                      />
                    </StyleContentInput>
                    <StyleContentText style={{ marginLeft: '8px' }}>
                      từ
                    </StyleContentText>
                    <StyleContentText style={{ margin: '0 8px' }}>
                      {
                        data?.serviceSetting?.cancellationPolicy?.freeCancel
                          ?.before?.value
                      }{' '}
                      tiếng đến trước
                    </StyleContentText>
                    <StyleTimeInput>
                      <StyledInput
                        sx={{
                          input: { textAlign: 'center' },
                          margin: '10px 0',
                        }}
                        defaultValue={
                          data?.serviceSetting?.cancellationPolicy
                            ?.feeCancelLevel1?.before?.value
                        }
                        type="text"
                        inputProps={{
                          onKeyPress: handleKeyPress,
                          inputMode: 'numeric',
                        }}
                        size="small"
                        {...register('timeBeforeCondition')}
                      />
                    </StyleTimeInput>
                    <StyleContentText style={{ marginLeft: '8px' }}>
                      tiếng
                    </StyleContentText>
                  </StyleContent>
                  {errors?.price && (
                    <StyleError>{errors?.price.message as string}</StyleError>
                  )}
                  {errors.timeBeforeCondition && (
                    <StyleError>
                      {errors.timeBeforeCondition.message as string}
                    </StyleError>
                  )}
                  <StyleContent>
                    <StyleContentText style={{ marginRight: '8px' }}>
                      Phí
                    </StyleContentText>
                    <StyleContentInput>
                      <InputField
                        defaultValue={
                          data?.serviceSetting?.cancellationPolicy
                            ?.feeCancelLevel2?.fee?.value
                        }
                        size="small"
                        type="text"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        inputProps={{
                          step: 1,
                          onKeyPress: handleKeyPress,
                          inputMode: 'numeric',
                        }}
                        {...register('percentCondition')}
                        sx={{ textAlign: 'right' }}
                      />
                    </StyleContentInput>
                    <StyleContentText style={{ marginLeft: '8px' }}>
                      từ
                    </StyleContentText>
                    <StyleContentText style={{ marginLeft: '8px' }}>
                      {
                        data?.serviceSetting?.cancellationPolicy
                          ?.feeCancelLevel1?.before?.value
                      }{' '}
                      tiếng đến trước giờ làm
                    </StyleContentText>
                  </StyleContent>
                  {errors.percentCondition && (
                    <StyleError>
                      {errors.percentCondition.message as string}
                    </StyleError>
                  )}
                </StyleItem>
                {/* <Divider sx={{ margin: '0 -24px' }} /> */}
                <StyleItem style={{ display: 'flex', justifyContent: 'end' }}>
                  <StyleButton>
                    <StyleLinks
                      onClick={() =>
                        navigate(`/service/housecleaning/${id}/rules`)
                      }
                    >
                      <StyleLink>Điều khoản và lưu ý</StyleLink>
                    </StyleLinks>
                    <StyleLinks
                      onClick={() =>
                        navigate(`/service/${id}/price-configurations`)
                      }
                    >
                      <StyleLink>Cấu hình giá</StyleLink>
                    </StyleLinks>
                    <StyleLinks
                      onClick={() =>
                        navigate(`/service/housecleaning/${id}/range-of-active`)
                      }
                    >
                      <StyleLink>Phạm vi hoạt động</StyleLink>
                    </StyleLinks>
                  </StyleButton>
                </StyleItem>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ paddingTop: '10px', justifyContent: 'end' }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ background: 'var(--bgr-primary)' }}
                  >
                    Lưu
                  </Button>
                  <Button variant="outlined" onClick={handleCheckDataCancel}>
                    Hủy
                  </Button>
                </Stack>
              </form>
            ) : (
              <LoadingVersion2 />
            )}
          </Item>
        </Grid>
        <BasicModal open={modalOpen}>
          {checked ? (
            <Typography>Bạn có chắc chắn muốn ẩn dịch vụ?</Typography>
          ) : (
            <Typography>Bạn có chắc chắn muốn hiện dịch vụ?</Typography>
          )}
          <Button
            sx={{
              margin: '20px',
              marginBottom: '0px',
              paddingLeft: '43px',
              paddingRight: '43px',
            }}
            variant="contained"
            className="bgr-global"
            onClick={() => {
              setChecked(!checked)
              handleClose()
            }}
          >
            {checked ? 'Ẩn' : 'Hiện'}
          </Button>
          <Button
            sx={{
              margin: '20px',
              marginBottom: '-0.5px',
              paddingLeft: '30px',
              paddingRight: '30px',
            }}
            onClick={() => handleClose()}
            variant="outlined"
          >
            Không
          </Button>
        </BasicModal>
        <BasicModal open={modalSave}>
          <Typography>Bạn có chắc chắn muốn lưu thay đổi?</Typography>
          <Button
            sx={{
              margin: '20px',
              marginBottom: '0px',
              paddingLeft: '43px',
              paddingRight: '43px',
            }}
            variant="contained"
            onClick={() => onSubmit(dataSave)}
            className="bgr-global"
          >
            Lưu
          </Button>
          <Button
            sx={{
              margin: '20px',
              marginBottom: '-0.5px',
              paddingLeft: '30px',
              paddingRight: '30px',
            }}
            onClick={() => setModaSave(false)}
            variant="outlined"
          >
            Không
          </Button>
        </BasicModal>
        <BasicModal open={modalCancel} handleClose={handleCancelClose}>
          <Typography>Bạn có chắc chắn muốn hủy thay đổi?</Typography>
          <Button
            sx={{
              margin: '20px',
              marginBottom: '0px',
              paddingLeft: '43px',
              paddingRight: '43px',
            }}
            variant="contained"
            onClick={() => window.location.reload()}
            className="bgr-global"
          >
            Hủy
          </Button>
          <Button
            sx={{
              margin: '20px',
              marginBottom: '-0.5px',
              paddingLeft: '30px',
              paddingRight: '30px',
            }}
            onClick={() => setModaCancel(false)}
            variant="outlined"
          >
            Không
          </Button>
        </BasicModal>
      </Grid>
    </>
  )
}

export default HomeCleaning
