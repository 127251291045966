import logoTiktok from 'assets/images/logo-tiktok.png'
import logoYoutube from 'assets/images/logo-youtube.png'
import logoFacebook from 'assets/images/logo-facebook.png'
import logoFriends from 'assets/images/logo-friends.png'
import logoGoogle from 'assets/images/logo-google.png'
import logoOthers from 'assets/images/logo-others.png'

export enum SocialImgEnum {
    YOUTUBE = "Youtube",
    FACEBOOK = "Facebook",
    FRIENDS = "Bạn bè",
    TIKTOK = "Tiktok",
    GOOGLE = "Google",
    OTHERS = "Khác"
}

export function getSocialImg(social: SocialImgEnum) {
    let src
    switch (social) {
        case SocialImgEnum.YOUTUBE:
            src = logoYoutube
            break
        case SocialImgEnum.FACEBOOK:
            src = logoFacebook
            break
        case SocialImgEnum.FRIENDS:
            src = logoFriends
            break
        case SocialImgEnum.TIKTOK:
            src = logoTiktok
            break
        case SocialImgEnum.GOOGLE:
            src = logoGoogle
            break
        case SocialImgEnum.OTHERS:
            src = logoOthers
            break
        default:
    }
    return src
}
