import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/common'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import staffsApi from 'services/staffsAPT'
import { StyleTableCell, StyleTableCellItem } from './style'
import dayjs from 'dayjs'

const WorkHistoryDetail = () => {
  const [data, setData] = useState<any>([])
  const navigate = useNavigate()
  const { id } = useParams()
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const fetchData = async (page: number) => {
    const { data } = await staffsApi.getHistory(
      {
        page: page + 1,
        limit: rowsPerPage,
      },
      id as string
    )
    setData(data.rows)
    setCount(data.count)
    setPage(page)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    fetchData(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  useEffect(() => {
    fetchData(page)
  }, [page, rowsPerPage])

  const mapping: any = {
    unpaid: 'Chưa thanh toán',
    paid: 'Đã thanh toán',
  }

  if (data.length == 0)
    return (
      <Typography sx={{ color: '#857676' }}>
        Chưa có lịch sử làm việc
      </Typography>
    )

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }} className="box-shadow">
          <TableContainer>
            <Table
              sx={{ minWidth: 650, overflow: 'auto' }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <StyleTableCell
                    align="left"
                    sx={{
                      minWidth: '55px!important',
                      maxWidth: '55px!important',
                    }}
                  >
                    STT
                  </StyleTableCell>
                  <StyleTableCell
                    align="left"
                    sx={{
                      minWidth: '120px!important',
                      maxWidth: '120px!important',
                    }}
                  >
                    Mã đơn
                  </StyleTableCell>
                  <StyleTableCell align="left">Dịch vụ</StyleTableCell>
                  <StyleTableCell align="left">Phân loại DV</StyleTableCell>
                  <StyleTableCell align="left">
                    Trạng thái thanh toán
                  </StyleTableCell>
                  <StyleTableCell align="left">
                    Trạng thái đơn hàng
                  </StyleTableCell>
                  <StyleTableCell align="left">Khách hàng</StyleTableCell>
                  <StyleTableCell align="left">Địa chỉ</StyleTableCell>
                  <StyleTableCell align="left">Giờ làm</StyleTableCell>
                  <StyleTableCell align="left">Tổng giờ làm</StyleTableCell>
                  <StyleTableCell align="left">Bộ dụng cụ</StyleTableCell>
                  <StyleTableCell align="left">KH đánh giá</StyleTableCell>
                  <StyleTableCell align="left">Ý kiến KH</StyleTableCell>
                  <StyleTableCell align="left">KH nhận xét</StyleTableCell>
                  <StyleTableCell align="left">NV đánh giá</StyleTableCell>
                  <StyleTableCell align="left">Ý kiến NV</StyleTableCell>
                  <StyleTableCell align="left">NV nhận xét</StyleTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row: any, index: number) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{ padding: '0px' }}
                    >
                      {index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell
                      sx={{
                        '&:hover': { opacity: 0.6 },
                        cursor: 'pointer',
                        maxWidth: '180px',
                      }}
                      align="left"
                    >
                      {row?.displayId}
                    </TableCell>
                    <TableCell sx={{ minWidth: '160px' }} align="left">
                      {row?.rootServiceDetail?.name}
                    </TableCell>{' '}
                    <TableCell align="left" sx={{ minWidth: '160px' }}>
                      {row?.isPremium ? 'Premium' : 'Tiêu chuẩn'}
                    </TableCell>{' '}
                    <TableCell align="left" sx={{ minWidth: '160px' }}>
                      {mapping[row?.paymentStatus]}
                    </TableCell>{' '}
                    <TableCell
                      align="left"
                      sx={{ minWidth: '160px' }}
                    >{`Đã hoàn thành`}</TableCell>{' '}
                    <TableCell align="left" sx={{ minWidth: '160px' }}>
                      {row?.user?.fullName}
                    </TableCell>{' '}
                    <TableCell align="left" sx={{ minWidth: '160px' }}>
                      {row?.fullLocation
                        ?.split(',')
                        ?.slice(-3, -2)[0]
                        ?.replace('550000', '')}
                      ,
                      {row?.fullLocation
                        ?.split(',')
                        ?.slice(-2, -1)[0]
                        ?.replace('550000', '')}
                      ,
                      {row?.fullLocation
                        ?.split(',')
                        ?.pop()
                        ?.replace('550000', '')}
                    </TableCell>{' '}
                    <TableCell align="left" sx={{ minWidth: '140px' }}>
                      {dayjs(row?.createdAt).format('DD/MM/YYYY hh:mm')}
                    </TableCell>{' '}
                    <TableCell align="left" sx={{ minWidth: '100px' }}>
                      {row?.serviceItems?.[0]?.hourPeriods}
                    </TableCell>{' '}
                    <TableCell
                      align="left"
                      sx={{ minWidth: '100px' }}
                    ></TableCell>{' '}
                    <TableCell
                      align="left"
                      sx={{ minWidth: '100px' }}
                    ></TableCell>{' '}
                    <TableCell
                      align="left"
                      sx={{ minWidth: '100px' }}
                    ></TableCell>{' '}
                    <TableCell
                      align="left"
                      sx={{ minWidth: '100px' }}
                    ></TableCell>{' '}
                    <TableCell
                      align="left"
                      sx={{ minWidth: '100px' }}
                    ></TableCell>{' '}
                    <TableCell
                      align="left"
                      sx={{ minWidth: '100px' }}
                    ></TableCell>{' '}
                    <TableCell
                      align="left"
                      sx={{ minWidth: '100px' }}
                    ></TableCell>{' '}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Số dòng trên một trang"
          />
        </Paper>
      </Box>
    </>
  )
}

export default WorkHistoryDetail
