import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { Button, Stack, Switch, Typography } from '@mui/material'
import InputField from 'components/FormControl/InputField'
import BasicModal from 'components/Modal'
import { API_URL, IMAGE_TYPE, MAX_FILE_SIZE } from 'constants/common'
import { EDITOR_TOOL_BAR } from 'constants/editorTool'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import Cookies from 'js-cookie'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { extraContentAPI, servicesAPI } from 'services/servicesAPI'
import SunEditor from 'suneditor-react'
import { FileTypeEnum, getFileSize } from 'utils/FileUtils'
import { uploadImage } from 'utils/uploadImage'
import {
  StyleAddImage,
  StyleAddItem,
  StyleError,
  StyleImage,
  StyleInfo,
  StyleItem,
  StyleLink,
  StyleLinksAdd,
  StyleLinksDelete,
} from './style'

interface IFormData {
  id?: any
  title: string
  description: string
  icon: string
  onOff: boolean
}

interface IOnOff {
  onOff: boolean
  id: number
}

interface IConfirmDelete {
  id: string
  indexForm: number
}

const RulesNotes = () => {
  const paramId = useParams()
  const navigate = useNavigate()
  const { setAlertError, setAlertSuccess } = useAlert()
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const [formsData, setFormsData] = useState<IFormData[]>([])
  const [dataService, setDataService] = useState<any>([])
  const [errorTitle, setErrorTitle] = useState(false)
  const [errorDescription, setErrorDescription] = useState(false)
  const [errorIcon, setErrorIcon] = useState(false)
  const [modalOnOff, setModalOnOff] = useState(false)
  const [modalConfirm, setModalConfirm] = useState(false)
  const [checked, setChecked] = useState(false)
  const [formId, setFormId] = useState<IOnOff>({ onOff: true, id: 0 })
  const [confirmDelete, setConfirmDelete] = useState<IConfirmDelete>({
    id: '',
    indexForm: 0,
  })

  const fetchDataExtraContent = async () => {
    const { data } = await extraContentAPI.getExtraContent(
      paramId?.serviceId as string
    )

    setFormsData(data?.rows)
  }

  const handleAddForm = () => {
    setFormsData((prevFormsData) => [
      ...prevFormsData,
      { title: '', description: '', icon: '', onOff: true },
    ])
  }

  const fetchServiceDetail = async () => {
    const { data } = await servicesAPI.getServiceById(
      paramId?.serviceId as string
    )
    setDataService(data)
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target
    setFormsData((prevFormsData) =>
      prevFormsData.map((formData, i) =>
        i === index ? { ...formData, [name]: value } : formData
      )
    )
  }

  const handleUpload = async (e: ChangeEvent, index: number) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
    if (file) {
      if (fileSize > MAX_FILE_SIZE) {
        setAlertError(`Dung lượng hình ảnh không quá ${MAX_FILE_SIZE} MB`)
        return
      }
      if (!file?.type.match(IMAGE_TYPE)) {
        setAlertError('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
        return
      }
      if (fileSize === 0) {
        setAlertError(`Ảnh lỗi`)
        return
      }
      const response: IUploadImg = await uploadImage(file)
      setFormsData((prevFormValues) => {
        const updatedForms = [...prevFormValues]
        updatedForms[index] = {
          ...updatedForms[index],
          icon: response.origin as any,
        }

        return updatedForms
      })
    }
  }

  const handleEditorChange = (value: string, index: number) => {
    setFormsData((prevFormValues) => {
      const updatedForms = [...prevFormValues]
      updatedForms[index] = {
        ...updatedForms[index],
        description: value as any,
      }

      return updatedForms
    })
  }

  const handleDelete = (id: string, indexForm: number) => {
    setModalConfirm(true)
    setConfirmDelete({ id: id, indexForm: indexForm })
  }

  const handleRemoveForm = async (id: string, indexForm: number) => {
    if (id) {
      try {
        await extraContentAPI?.deleteExtraContent(
          paramId?.serviceId as string,
          id
        )
      } catch (error: any) {
        setAlertError(error?.response?.data?.message)
      }
    }

    setFormsData((prevFormsData) =>
      prevFormsData.filter((formData, i) => i !== indexForm)
    )
    setModalConfirm(false)
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoadingTrue()
    let hasError = false
    formsData?.map(async (item: any) => {
      if (!item?.title || item?.title == '') {
        setErrorTitle(true)
        hasError = true
        setLoadingFalse()
      }
      if (item?.description == '<p><br></p>' || item?.description == '') {
        setErrorDescription(true)
        hasError = true
        setLoadingFalse()
      }
      if (!item?.icon || item?.icon == '') {
        setErrorIcon(true)
        hasError = true
        setLoadingFalse()
      }
    })

    if (!hasError) {
      await Promise.all(
        formsData?.map(async (object: any, index: number) => {
          if (object?.id) {
            try {
              await extraContentAPI?.updateExtraContent(
                {
                  description: object?.description,
                  icon: object?.icon,
                  title: object?.title,
                },
                paramId?.serviceId as string,
                object?.id as string
              )
            } catch (error: any) {
              setAlertError(error?.response?.data?.message)
            }
          } else if (!object?.id) {
            try {
              await extraContentAPI?.createExtraContent(
                {
                  description: object?.description,
                  icon: object?.icon,
                  title: object?.title,
                },
                paramId?.serviceId as string
              )
            } catch (error: any) {
              setAlertError(error?.response?.data?.message)
            }
          }
        })
      )
      navigate(`/service/${paramId?.serviceId}`)
      setLoadingFalse()
      setAlertSuccess('Sửa điều khoản và lưu ý thành công!')
    }
  }

  const handleImageUploadBefore = async (
    files: File[],
    info: any,
    uploadHandler: any
  ) => {
    try {
      const uploaded = await uploadImage(files[0])
      const result = {
        result: [
          {
            url: uploaded.medium,
            size: files[0].size,
            name: files[0].name,
          },
        ],
      }
      uploadHandler(result)
    } catch (error: any) {
      setAlertError(error.response.data.message)
    }
  }

  function handleImageUploadError() {
    return
  }

  const handleOnOffChange = async (premium: boolean, id: string) => {
    setModalOnOff(true)
    // await rangeOfActiveApi.UpdateStatus(
    //   {
    //     isPremium: !premium,
    //   },
    //   id
    // )
    // fetchData()
  }

  const handleChangeOnOff = (onOff: boolean, indexForm: number) => {
    setModalOnOff(true)
    setFormId({ onOff: onOff, id: indexForm })
  }

  const handleClose = () => {
    setModalOnOff(false)
  }

  const handleChangeStatus = () => {
    setFormsData((prevFormValues) => {
      const updatedForms = [...prevFormValues]
      updatedForms[formId?.id] = {
        ...updatedForms[formId?.id],
        onOff: !formId?.onOff,
      }

      return updatedForms
    })
    // setChecked(!checked)
    handleClose()
  }

  useEffect(() => {
    fetchServiceDetail()
  }, [paramId?.serviceId])

  useEffect(() => {
    fetchDataExtraContent()
  }, [])

  return (
    <>
      <Typography sx={{ display: 'flex' }} variant="body2" gutterBottom>
        *Phần này xuất hiện ở cuối màn hình đầu tiên của các dịch vụ
      </Typography>
      <div
        style={{ width: '180px', marginTop: '24px' }}
        onClick={handleAddForm}
      >
        <StyleLinksAdd>
          <AddCircleIcon
            sx={{ marginRight: '10px', color: 'white', fontSize: '17px' }}
          />
          <StyleLink>Thêm điều khoản</StyleLink>
        </StyleLinksAdd>
      </div>
      <form onSubmit={handleSubmit}>
        {formsData?.map((formsData: IFormData, indexForm) => (
          <StyleItem key={indexForm}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', gap: '24px' }}>
                <Typography variant="h6" gutterBottom>
                  Nội dung {indexForm + 1}
                </Typography>
                <div
                  style={{ width: '120px' }}
                  onClick={() => handleDelete(formsData?.id, indexForm)}
                >
                  <StyleLinksDelete>
                    <ClearOutlinedIcon
                      sx={{
                        marginRight: '5px',
                        color: 'white',
                        fontSize: '16px',
                      }}
                    />
                    <StyleLink>Xoá nội dung</StyleLink>
                  </StyleLinksDelete>
                </div>
              </div>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2">Ẩn</Typography>
                <Switch
                  checked={formsData.onOff}
                  onChange={() => handleChangeOnOff(formsData.onOff, indexForm)}
                  // checked={item.premium}
                  // onChange={() => {
                  //   handleOnOffChange(item.premium, item.areaId)
                  // }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography variant="body2">Hiện</Typography>
              </Stack>
            </div>
            <div
              style={{
                paddingTop: '0',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <StyleImage>
                {formsData?.icon ? (
                  <StyleAddImage>
                    <img src={formsData?.icon} alt="Icon" />
                    <StyleAddItem>
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={(event) => handleUpload(event, indexForm)}
                        name="image"
                      />
                    </StyleAddItem>
                  </StyleAddImage>
                ) : (
                  <StyleAddImage>
                    <AddPhotoAlternateIcon
                      sx={{
                        width: '100px !important',
                        height: '100px !important',
                        color: '#1976d2',
                      }}
                    />
                    <StyleAddItem>
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={(event) => handleUpload(event, indexForm)}
                        name="image"
                      />
                    </StyleAddItem>
                  </StyleAddImage>
                )}

                <StyleError style={{ marginTop: '10px' }}>
                  {errorIcon && !formsData.icon
                    ? 'Ảnh không được để trống'
                    : null}
                </StyleError>
              </StyleImage>

              <StyleInfo>
                <InputField
                  label="Nội dung"
                  id={`title-${indexForm}`}
                  name="title"
                  value={formsData.title}
                  onChange={(event: any) => handleChange(event, indexForm)}
                  size="small"
                />
                <StyleError style={{ marginBottom: '10px' }}>
                  {errorTitle && !formsData.title
                    ? 'Tiêu đề không được để trống'
                    : null}
                </StyleError>

                <SunEditor
                  onChange={(value) => handleEditorChange(value, indexForm)}
                  setContents={formsData.description}
                  height="auto"
                  onImageUploadBefore={handleImageUploadBefore}
                  setOptions={{
                    showPathLabel: false,
                    buttonList: EDITOR_TOOL_BAR,
                    defaultStyle: 'text-align: left',
                    imageUploadUrl: `${API_URL}/media/uploadImage`,
                    imageUploadHeader: {
                      Authorization: `Bearer ${Cookies.get('token')}`,
                    },
                  }}
                  onImageUploadError={handleImageUploadError}
                />
                <StyleError style={{ marginTop: '10px' }}>
                  {(errorDescription &&
                    formsData.description == '<p><br></p>') ||
                  (errorDescription && !formsData.description)
                    ? 'Mô tả không được để trống'
                    : null}
                </StyleError>
              </StyleInfo>
            </div>
          </StyleItem>
        ))}
        <BasicModal open={modalOnOff}>
          <Typography>
            Bạn có chắc chắn muốn {formId?.onOff ? 'ẩn' : 'hiện'} điều khoản và
            lưu ý?
          </Typography>

          <Button
            sx={{
              margin: '20px',
              marginBottom: '0px',
              paddingLeft: '43px',
              paddingRight: '43px',
            }}
            variant="contained"
            className="bgr-global"
            onClick={handleChangeStatus}
          >
            {formId?.onOff ? 'Ẩn' : 'Hiện'}
          </Button>
          <Button
            sx={{
              margin: '20px',
              marginBottom: '-0.5px',
              paddingLeft: '30px',
              paddingRight: '30px',
            }}
            onClick={() => handleClose()}
            variant="outlined"
          >
            Không
          </Button>
        </BasicModal>
        <BasicModal
          open={modalConfirm}
          handleClose={() => setModalConfirm(false)}
        >
          <Typography>Bạn có chắc chắn muốn xoá nội dung này chứ?</Typography>
          <Button
            sx={{ margin: '8px', marginBottom: '0px' }}
            variant="contained"
            color="error"
            onClick={() =>
              handleRemoveForm(confirmDelete?.id, confirmDelete?.indexForm)
            }
          >
            Xóa
          </Button>
          <Button
            sx={{ margin: '8px', marginBottom: '0px' }}
            onClick={() => setModalConfirm(false)}
            variant="outlined"
          >
            Hủy
          </Button>
        </BasicModal>

        <Stack
          spacing={2}
          direction="row"
          sx={{
            justifyContent: 'flex-start',
            paddingTop: '14px',
          }}
        >
          <Button variant="contained" type="submit" className="bgr-global">
            Lưu
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate(`/service/${paramId?.serviceId}`)}
          >
            Huỷ
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default RulesNotes
