const API_URL =
  process.env.REACT_APP_API_URL || process.env.REACT_APP_API_JSON_URL
const API_JSON_URL = process.env.REACT_APP_API_JSON_URL
const API_MAP_4D_URL = process.env.REACT_APP_API_MAP_4D
const key = process.env.REACT_APP_KEY_MAP_4D
const KEY_MAP_4D = encodeURI(key as string)
const IMAGE_TYPE = /jpeg|jpg|png|svg/i
const MAX_FILE_SIZE = 15 // 15MB
const CMS = 'cms'
const DELETE_CODE = 'toimuonxoa'
const ROWS_PER_PAGE_OPTIONS = [10, 20, 50]
export {
  DELETE_CODE,
  API_URL,
  IMAGE_TYPE,
  API_JSON_URL,
  MAX_FILE_SIZE,
  ROWS_PER_PAGE_OPTIONS,
  API_MAP_4D_URL,
  KEY_MAP_4D,
  CMS,
}
