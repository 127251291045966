import styled from 'styled-components'

export const StyleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
`

export const StyleLogo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const StyleTitleLogo = styled.h6`
  font-weight: bold;
`
export const StyleLinks = styled.div`
  cursor: pointer;
  padding: 0 20px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  border-radius: 6px;
  border: none;
  margin: 9px 0;
  background: var(--bgr-primary);
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`

export const StyleLink = styled.span`
  font-size: 14px;
  font-weight: bold;
`
