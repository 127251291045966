import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Grid, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Breadcrumb from 'components/Breadcrumbs'
import Item from 'components/Item'
import { TabPanel, a11yProps } from 'components/TabPanel'
import { PART_TIME_STAFF_DETAIL } from 'constants/breadcrumbs'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import staffApi from 'services/staffAPI'
import Performance from './components/PerformanceDetail'
import PersonalInformation from './components/PersonalInformationDetail'
import WorkCalendar from './components/WorkCalendarDetail'
import WorkHistory from './components/WorkHistoryDetail'
import {
  StyleHeader,
  StyleHeaderItem,
  StyleLink,
  StyleLinks,
  StyleWrapBottom,
  StyleWrapLeft,
  StyleWrapRight,
  StyleWrapTop,
} from './style'
import { ExportToExcel } from 'utils/exportToExcel'
import { IObjectRank, rank } from 'utils/rankPartTimeStaff'
import dayjs from 'dayjs'

const PartTimeStaffDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0)
  const [valueStatusStaff, setValueStatusStaff] = useState('')
  const [valueOperatingStatusStaff, setValueOperatingStatusStaff] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [dataStaff, setDataStaff] = useState<IStaff>()

  const fetchDataStaff = async () => {
    const { data } = await staffApi.getStaffById(id as string)
    setDataStaff(data)
    setValueStatusStaff(data?.status)
    setValueOperatingStatusStaff(data?.activeStatus)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  useEffect(() => {
    fetchDataStaff()
  }, [])

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={PART_TIME_STAFF_DETAIL} />
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <Box sx={{ width: '100%' }}>
            <StyleHeaderItem>
              <div style={{ display: 'flex', gap: '24px' }}>
                <StyleLinks
                  onClick={() =>
                    navigate(`/staff-manager/part-time-staff/edit/${id}`)
                  }
                >
                  <DriveFileRenameOutlineOutlinedIcon
                    sx={{ marginRight: '10px', color: 'white' }}
                  />
                  <StyleLink>Chỉnh sửa</StyleLink>
                </StyleLinks>
                <StyleLinks
                  onClick={() => {
                    ExportToExcel([
                      {
                        'Mã nhân viên': dataStaff?.displayId,
                        'Họ và tên': dataStaff?.fullName,
                        'Số điện thoại': dataStaff?.phone,
                        'CMND/CCCD/Passport': dataStaff?.citizenId,
                        'Dịch vụ': dataStaff?.services
                          ?.map((service: any) => service?.staffService?.name)
                          .join(', '),
                        'Gắn nhãn': dataStaff?.staffLabel
                          ?.map((label: any) => label?.name)
                          .join(', '),
                        'Tình trạng': dataStaff?.status,
                        'Tình trạng hoạt động': dataStaff?.activeStatus,
                        'Kỹ nẵng':
                          dataStaff?.skill == null
                            ? null
                            : JSON.parse(dataStaff?.skill)
                                ?.map((skill: any) => skill)
                                .join(', '),
                        'Thành phố':
                          dataStaff?.districts?.[0]?.staffProvince?.fullName,
                        Quận: dataStaff?.districts?.[0]?.staffDistrict?.name,
                        'Vị trí làm việc':
                          dataStaff?.districts?.[0]?.fullLocation,
                        Hạng: rank[dataStaff?.rank as keyof IObjectRank],
                        'Số sao': dataStaff?.avgRate,
                        'Giờ công tháng': '',
                        'Giờ công tháng trước': '',
                        'Giờ công luỹ kế năm': '',
                        'Ca trống lịch': '',
                        'Ca đang làm/ ca đăng ký': '',
                        'Ngày ký hợp đồng': dayjs(
                          dataStaff?.signingDate
                        ).format('DD/MM/YYYY'),
                        'Ngày làm việc đầu tiên': dayjs(
                          dataStaff?.startDate
                        ).format('DD/MM/YYYY'),
                        'Nhân sự phụ trách/tuyển dụng':
                          dataStaff?.staffManagers?.recruitment,
                        'Ngày sinh nhật': dayjs(dataStaff?.birth).format(
                          'DD/MM/YYYY'
                        ),
                      },
                    ])
                  }}
                >
                  <FileDownloadOutlinedIcon
                    sx={{ marginRight: '10px', color: 'white' }}
                  />
                  <StyleLink>Xuất File Excel</StyleLink>
                </StyleLinks>
              </div>
            </StyleHeaderItem>
            <StyleHeader>
              <StyleWrapLeft>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Thông tin cá nhân"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Hiệu suất"
                    {...a11yProps(1)}
                  />
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Lịch làm việc"
                    {...a11yProps(2)}
                  />
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Lịch sử làm việc"
                    {...a11yProps(3)}
                  />
                </Tabs>
              </StyleWrapLeft>
              <StyleWrapRight>
                <StyleWrapTop>
                  <TextField
                    label="Tình trạng"
                    value={valueStatusStaff}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: 150 }}
                    disabled
                    size="small"
                  />

                  <TextField
                    label="Tình trạng hoạt động"
                    value={
                      valueOperatingStatusStaff == 'active'
                        ? 'Đang hoạt động'
                        : 'Khoá tài khoản'
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ width: 220 }}
                    disabled
                    size="small"
                  />
                </StyleWrapTop>
                {isOpen && (
                  <StyleWrapBottom>
                    <div>
                      <TextField
                        label="Ngày bắt đầu"
                        // value={'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: 150 }}
                        disabled
                        size="small"
                      />
                    </div>
                    <TextField
                      label="Lý do"
                      // value={'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: 220 }}
                      disabled
                      size="small"
                    />
                  </StyleWrapBottom>
                )}
              </StyleWrapRight>
            </StyleHeader>

            <TabPanel value={value} index={0}>
              <PersonalInformation />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Performance />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <WorkCalendar />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <WorkHistory />
            </TabPanel>
          </Box>
        </Item>
      </Grid>
    </Grid>
  )
}

export default PartTimeStaffDetail
