import * as React from 'react'
import PersonIcon from '@mui/icons-material/Person'
import CampaignIcon from '@mui/icons-material/Campaign'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import ListAltIcon from '@mui/icons-material/ListAlt'
import Person4Icon from '@mui/icons-material/Person4'
import HandshakeIcon from '@mui/icons-material/Handshake'
import RequestPageIcon from '@mui/icons-material/RequestPage'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import HelpIcon from '@mui/icons-material/Help'

const STRUCTURE = [
  {
    id: 2,
    label: 'Quản lý Marketing',
    icon: <CampaignIcon />,
    children: [
      {
        id: 3,
        label: 'Dashboard',
        link: '/marketing/dashboard',
      },
      {
        id: 4,
        label: 'Chiến dịch Marketing',
        link: '/marketing/campaign',
      },
      {
        id: 5,
        label: 'Quản lý khuyến mãi',
        link: '/marketing/promotion-code',
      },

      {
        id: 6,
        label: 'Báo cáo thống kê',
        link: '/marketing/reports',
      },
      {
        id: 7,
        label: 'Banner',
        link: '/marketing/banners',
      },
      {
        id: 8,
        label: 'Blog',
        link: '/marketing/news',
      },
    ],
  },
  {
    id: 9,
    label: 'Quản lý dịch vụ',
    icon: <DesignServicesIcon />,
    link: '/services/list',
    children: [],
  },
  {
    id: 11,
    label: 'Danh sách khách hàng',
    icon: <PersonIcon />,
    link: '/user',
  },
  {
    id: 13,
    label: 'Chăm sóc khách hàng',
    icon: <SupportAgentIcon />,
    children: [
      {
        id: 14,
        label: 'Xem lịch CSKH',
        link: '/care/schedule',
      },
      {
        id: 15,
        label: 'Phân công nhân viên',
        link: '/care/assignment',
      },
      {
        id: 16,
        label: 'Thông báo tự động',
        link: '/care/notifications',
      },
      {
        id: 17,
        label: 'Hỗ trợ khách hàng',
        link: '/care/support',
      },
      {
        id: 18,
        label: 'Quản lý ticket',
        link: '/care/ticket',
      },
    ],
  },
  {
    id: 19,
    label: 'Quản lý đơn hàng',
    icon: <ListAltIcon />,
    children: [
      {
        id: 20,
        label: 'Danh sách đơn hàng',
        link: '/order',
      },
      {
        id: 21,
        label: 'Phân công theo đơn',
        link: '/order/assignment',
      },
    ],
  },
  {
    id: 22,
    label: 'Quản lý nhân viên',
    icon: <Person4Icon />,
    children: [
      {
        id: 23,
        label: 'Dash board',
        link: '/staff/dashboard',
      },
      {
        id: 24,
        label: 'Tuyển dụng',
        link: '/staff/recruitment',
      },
      {
        id: 25,
        label: 'Danh sách cộng tác viên',
        link: '/staff-manager/part-time-staff',
      },
      {
        id: 26,
        label: 'Lịch nghỉ/làm việc',
        link: '/staff/schedule',
      },
      {
        id: 27,
        label: 'Chấm công',
        link: '/staff/timekeeping',
      },
      {
        id: 28,
        label: 'Lương thưởng',
        link: '/staff/salary',
      },
      {
        id: 29,
        label: 'Đánh giá hiệu suât',
        link: '/staff/evaluation',
      },
      {
        id: 30,
        label: 'Hỗ trợ nhân viên',
        link: '/staff/support',
      },
      {
        id: 31,
        label: 'Đào tạo và phát triển',
        link: '/staff/train',
      },
      {
        id: 32,
        label: 'Quản lý tài khoản',
        link: '/staff/account',
      },
      {
        id: 33,
        label: 'Phân quyền tài khoản',
        link: '/staff/authorization',
      },
    ],
  },
  {
    id: 34,
    label: 'Quản lý đối tác',
    icon: <HandshakeIcon />,
    children: [
      {
        id: 35,
        label: 'Dash board',
        link: '/partner/dashboard',
      },
      {
        id: 36,
        label: 'Danh sách đối tác',
        link: '/partner/list',
      },
      {
        id: 37,
        label: 'Chiết khấu',
        link: '/partner/discount',
      },
      {
        id: 38,
        label: 'Đánh giá hiệu suất',
        link: '/partner/evaluation',
      },
      {
        id: 39,
        label: 'Hỗ trợ đối tác',
        link: '/partner/support',
      },
    ],
  },
  {
    id: 40,
    label: 'Quản lý request',
    icon: <RequestPageIcon />,
    children: [
      {
        id: 41,
        label: 'Nghỉ',
        link: '/request/rest',
      },
      {
        id: 42,
        label: 'Đổi lịch',
        link: '/request/reschedule',
      },
      {
        id: 43,
        label: 'Ứng lương',
        link: '/request/salary',
      },
      {
        id: 44,
        label: 'Request chung',
        link: '/request/common',
      },
    ],
  },
  {
    id: 45,
    label: 'Tài chính-Kế toán',
    icon: <PointOfSaleIcon />,
    children: [
      {
        id: 46,
        label: 'Kết quả kinh doanh',
        link: '/finance/result',
      },
      {
        id: 47,
        label: 'Khoản thu-chi',
        link: '/finance/revenue',
      },
      {
        id: 48,
        label: 'Lương',
        link: '/finance/salary',
      },
      {
        id: 49,
        label: 'Ngân sách',
        link: '/finance/budget',
      },
      {
        id: 50,
        label: 'Hàng hoá và kho',
        link: '/finance/warehouse',
      },
      {
        id: 51,
        label: 'Tài liệu',
        link: '/finance/document',
      },
    ],
  },
  {
    id: 52,
    label: 'Trợ giúp',
    icon: <HelpIcon />,
    children: [
      {
        id: 53,
        label: 'Sổ tay nhân sự',
        link: '/help/handbook',
      },
      {
        id: 54,
        label: 'Quy định & chính sách',
        link: '/help/policies',
      },
    ],
  },
]

export { STRUCTURE }
