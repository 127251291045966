import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined'
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { DatePicker } from 'antd'
import Breadcrumb from 'components/Breadcrumbs'
import Delete from 'components/Icons/components/Delete'
import Edit from 'components/Icons/components/Edit'
import BasicModal from 'components/Modal'
import { PROMOTION_CODE } from 'constants/breadcrumbs'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/common'
import { useAlert } from 'contexts/AlertContext'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import promotionCodeApi from 'services/promotionCodeAPI'
import { promotionCode } from '../../../../constants/promotion'
import {
  StyleAddImage,
  StyleHeaderItem,
  StyleLink,
  StyleLinks,
  StyleStatusDisable,
  StyleStatusHide,
  StyleStatusShow,
  StyleSvLink,
  StyleSvService,
  StyleTableCell,
  StyleTableCellItem,
  StyleTableHeader,
  StyleTitle,
} from './style'

const PromotionCodeList = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [promoCode, setPromoCode] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const [status, setStatus] = useState({
    id: '',
  })
  const [valueTypes, setValueTypes] = useState('')
  const [dates, setDates] = useState<any>([])

  const navigate = useNavigate()
  const { setAlertSuccess, setAlertError } = useAlert()
  const { RangePicker } = DatePicker

  const fetchData = async (page: number) => {
    const { data } = await promotionCodeApi.getPromotionCode({
      page: page + 1,
      limit: rowsPerPage,
      typePromo: valueTypes ?? '',
      datePromotionStart: dates.start ?? '',
      datePromotionEnd: dates.end ?? '',
    })
    setPromoCode(data.rows)
    setCount(data.count)
    setPage(page)
  }

  const handleOnclick = (id?: string) => {
    if (id) {
      setModalOpen(true)
      setStatus({
        id,
      })
    }
  }

  const handleClose = () => setModalOpen(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    fetchData(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleDelete = async (id: string) => {
    try {
      await promotionCodeApi.delete(id)
      setModalOpen(false)
      setAlertSuccess('Xóa khuyến mãi thành công')
      fetchData(page)
    } catch (err) {
      setAlertError('Xoá khuyến mãi không thành công')
    }
  }

  const handleChangeTypes = (event: SelectChangeEvent, newValue: any) => {
    setValueTypes(newValue?.props?.value)
  }

  useEffect(() => {
    fetchData(page)
  }, [page, rowsPerPage, valueTypes, dates])

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={PROMOTION_CODE} />
      <Grid item xs={12}>
        <Paper
          sx={{
            boxShadow:
              '0px 12px 33px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
            textAlign: 'center',
            height: '100%',
            padding: '0 24px 24px',
          }}
        >
          <StyleTableHeader>
            <StyleHeaderItem>
              <StyleTitle>Quản lý khuyến mãi</StyleTitle>
            </StyleHeaderItem>
            <StyleHeaderItem>
              <StyleLinks
                onClick={() => navigate('/marketing/promotion-code/create')}
              >
                <AddCircleIcon sx={{ marginRight: '10px', color: 'white' }} />
                <StyleLink>Thêm khuyến mãi</StyleLink>
              </StyleLinks>

              <FormControl
                sx={{ width: 220, height: '40px', my: '10px' }}
                size="small"
                className="input-select"
              >
                <InputLabel id="demo-simple-select-label-status">
                  Loại
                </InputLabel>
                <Select
                  value={valueTypes}
                  label="Loại"
                  onChange={handleChangeTypes}
                >
                  <MenuItem value={''}>Tất cả</MenuItem>
                  {promotionCode?.map((item: any) => (
                    <MenuItem key={item?.value} value={item?.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div>
                <div
                  style={{
                    color: '#666666',
                    zIndex: 3,
                    position: 'absolute',
                    fontSize: '12px',
                    backgroundColor: 'white',
                    margin: '2px 10px',
                  }}
                >
                  Thời gian
                </div>
                <RangePicker
                  style={{ height: '40px', margin: '10px 0' }}
                  onChange={(values: any) => {
                    if (values === null) {
                      setDates([])
                      return
                    }
                    setDates({
                      start: dayjs(values[0]).format('YYYY-MM-DD'),
                      end: dayjs(values[1]).format('YYYY-MM-DD'),
                    })
                  }}
                />
              </div>
            </StyleHeaderItem>
          </StyleTableHeader>
          <TableContainer component={Paper} className="box-shadow">
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <StyleTableCell align="center">STT</StyleTableCell>
                  <StyleTableCell align="center">Hình ảnh</StyleTableCell>
                  <StyleTableCell align="left">Tiêu đề</StyleTableCell>
                  <StyleTableCell align="left">Mã</StyleTableCell>
                  <StyleTableCell align="left">Giá trị</StyleTableCell>
                  <StyleTableCell align="left">Loại</StyleTableCell>
                  <StyleTableCell align="left">Trạng thái</StyleTableCell>
                  <StyleTableCell align="left">Ngày bắt đầu</StyleTableCell>
                  <StyleTableCell align="left">Ngày kết thúc</StyleTableCell>
                  <StyleTableCell align="center">Hành động</StyleTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {promoCode?.map((row: IPromotionCode, index: number) => (
                  <TableRow
                    key={row?.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      sx={{ padding: '0px' }}
                    >
                      {promoCode && index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell align="center">
                      {row?.thumbnail ? (
                        <StyleAddImage src={row?.thumbnail} />
                      ) : (
                        <AddPhotoAlternateIcon
                          sx={{
                            width: '75px !important',
                            height: '50px !important',
                          }}
                        />
                      )}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        '&:hover': { opacity: 0.6 },
                        cursor: 'pointer',
                        maxWidth: '180px',
                      }}
                      onClick={() =>
                        navigate(`/marketing/promotion-code/detail/${row?.id}`)
                      }
                      width={180}
                    >
                      <StyleTableCellItem>{row?.title}</StyleTableCellItem>
                    </TableCell>
                    <TableCell align="left">{row?.promotionCode}</TableCell>
                    <TableCell align="left">
                      {row?.discount
                        ? (row?.discount as number)
                            .toLocaleString('vi-VN', {
                              style: 'currency',
                              currency: 'VND',
                            })
                            .replaceAll('.', ',')
                        : ((row?.percent as number) / 100).toLocaleString(
                            'en-US',
                            {
                              style: 'percent',
                            }
                          )}
                    </TableCell>
                    <TableCell align="left">
                      <StyleTableCellItem>
                        {row.typePromo === 'code' ? (
                          <StyleSvService>
                            <CodeOutlinedIcon
                              sx={{
                                background: '#ffa2443c',
                                borderRadius: '50%',
                                fontSize: '28px',
                                padding: '5px',
                              }}
                            />
                            Mã khuyến mãi
                          </StyleSvService>
                        ) : (
                          <StyleSvLink>
                            <StarsOutlinedIcon
                              sx={{
                                background: '#0dcfe92a',
                                borderRadius: '50%',
                                fontSize: '28px',
                                padding: '5px',
                              }}
                            />
                            Đổi điểm
                          </StyleSvLink>
                        )}
                      </StyleTableCellItem>
                    </TableCell>
                    <TableCell align="left">
                      {row?.status == 'show' ? (
                        <StyleStatusShow>
                          <RemoveRedEyeOutlinedIcon />
                          Hiển thị
                        </StyleStatusShow>
                      ) : row?.status == 'hide' ? (
                        <StyleStatusHide>
                          <VisibilityOffOutlinedIcon />
                          Ẩn
                        </StyleStatusHide>
                      ) : (
                        <StyleStatusDisable>
                          <CancelPresentationOutlinedIcon />
                          Vô hiệu hoá
                        </StyleStatusDisable>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <StyleTableCellItem>
                        {dayjs(row?.startDate).format('DD/MM/YYYY')}
                      </StyleTableCellItem>
                    </TableCell>
                    <TableCell align="left">
                      <StyleTableCellItem>
                        {dayjs(row?.endDate).format('DD/MM/YYYY')}
                      </StyleTableCellItem>
                    </TableCell>
                    <TableCell align="center">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: '10px',
                        }}
                      >
                        <Edit
                          onClick={() =>
                            navigate(
                              `/marketing/promotion-code/update/${row?.id}`
                            )
                          }
                        />

                        <Delete
                          onClick={() => {
                            handleOnclick(row?.id)
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {modalOpen && (
              <BasicModal open={modalOpen} handleClose={handleClose}>
                <Typography>
                  Bạn có chắc chắn muốn xoá khuyến mãi này?
                </Typography>
                <Button
                  sx={{ margin: '8px', marginBottom: '0px' }}
                  variant="contained"
                  color="error"
                  onClick={() => handleDelete(status.id)}
                >
                  Xóa
                </Button>
                <Button
                  sx={{ margin: '8px', marginBottom: '0px' }}
                  onClick={handleClose}
                  variant="outlined"
                >
                  Hủy
                </Button>
              </BasicModal>
            )}
            <TablePagination
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Số dòng trên một trang"
            />
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default PromotionCodeList
