import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import InputField from 'components/FormControl/InputField'
import { useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import {
  StyleAddImage,
  StyleAddItem,
  StyleError,
  StyleImage,
  StyleInput,
  StyleLabel,
  StyleLink,
  StyleLinks,
} from './style'
import { Paper, MenuItem, TextField, Autocomplete } from '@mui/material'
import { Grid } from '@mui/material'
import Breadcrumb from 'components/Breadcrumbs'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import bannerApi from 'services/bannerAPI'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import { bannerCreateSchema_ } from 'validations/bannerSchema'
import { useLoading } from 'contexts/LoadingContext'
import { useAlert } from 'contexts/AlertContext'
import { getFileSize } from 'utils/FileUtils'
import { FileTypeEnum } from 'utils/FileUtils'
import { IMAGE_TYPE, MAX_FILE_SIZE } from 'constants/common'
import { uploadImage } from 'utils/uploadImage'
import { bannerTypeAction, bannerStatus } from 'constants/banner'
import { BANNER_ADD } from 'constants/breadcrumbs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Editor from 'components/Editor'

const BannerCreate = () => {
  const [listService, setListService] = useState([])
  const [listPromoCode, setListPromoCode] = useState([])
  const [valueBannerType, setValueBannerType] = useState('service')
  const [valueService, setValueService] = useState()
  const [valueStatus, setValueStatus] = useState(true)
  const [image, setImage] = useState('')
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [fileChoose, setFileChoose] = useState<File | null>()
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertError, setAlertSuccess } = useAlert()
  const [content, setContent] = useState('')
  const [errorContent, setErrorContent] = useState<string>()
  const [promoCode, setPromoCode] = useState<any>()

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IBanner>({
    resolver: yupResolver(bannerCreateSchema_),
    mode: 'onChange',
  })

  const handleChangeService = (event: React.SyntheticEvent, newValue: any) => {
    setValueService(newValue?.props?.value)
  }

  const handleChangeBannerType = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setValueBannerType(newValue?.props?.value)
  }

  const handleChangeStatus = (event: React.SyntheticEvent, newValue: any) => {
    setValueStatus(newValue?.props?.value)
  }

  const handleCheckValidate = () => {
    if (!fileChoose) {
      setError('Ảnh bìa không được để trống')
    }
    if (content.trim() == '<p><br></p>' || content.trim() == '') {
      setErrorContent('Nội dung không được để trống')
      return
    } else {
      setErrorContent(undefined)
    }
  }

  const handlePromoCode = (event: any, value: any) => {
    setPromoCode(value)
  }

  const fetchData = async () => {
    const { data } = await bannerApi.getService()
    setListService(data)
  }

  const fetchDataPromoCode = async () => {
    const { data } = await bannerApi.getPromoCode()

    const code = await bannerApi.getPromoCode({ limit: data?.count })

    const listCode = code?.data?.rows.map((item: any, index: number) => {
      return {
        value: index,
        title: item.promotionCode,
      }
    })

    setListPromoCode(listCode)
  }

  const handleEditorChange = (value: string) => {
    setContent(value.trim())
    if (value.trim() === '<p><br></p>' || value.trim() === '') {
      setErrorContent('Nội dung không được để trống')
      return
    } else {
      setErrorContent(undefined)
    }
  }

  const handleUpload = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
    if (file) {
      const name = file?.name.split('.')
      if (fileSize > MAX_FILE_SIZE) {
        setError(`Dung lượng ảnh tối đa cho phép là ${MAX_FILE_SIZE} MB`)
        return
      }
      if (!name[name.length - 1].match(IMAGE_TYPE)) {
        setError('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
        return
      }
      if (fileSize === 0) {
        setError(`Ảnh lỗi`)
        return
      }
      setFileChoose(file)
      setImage(URL?.createObjectURL((target.files as FileList)[0]))
      setError('')
    }
  }

  const onSubmit = async (data: IBanner) => {
    setLoadingTrue()
    let isUploadSuccess = false
    const dataCreate = { ...data, content: content }

    if (content.trim() == '<p><br></p>' || content.trim() == '') {
      setLoadingFalse()
      setErrorContent('Nội dung không được để trống')
      return
    } else {
      setErrorContent(undefined)
    }

    try {
      const response: IUploadImg = await uploadImage(fileChoose as File)
      dataCreate.thumbnail = response.origin
      isUploadSuccess = true
    } catch (error: any) {
      setError('Ảnh bìa không được để trống')
    }
    if (!isUploadSuccess) {
      setLoadingFalse()
      return
    }

    if (dataCreate.priority === null) {
      dataCreate.priority = 0
    }

    try {
      if (valueBannerType == 'link') {
        delete dataCreate.serviceId
        await bannerApi.createBanner(dataCreate)
        setLoadingFalse()
        setAlertSuccess('Thêm thành công!')
        navigate('/marketing/banners')
      } else if (valueBannerType == 'service') {
        delete dataCreate.link
        await bannerApi.createBanner(dataCreate)
        setLoadingFalse()
        setAlertSuccess('Thêm thành công!')
        navigate('/marketing/banners')
      } else if (
        valueBannerType == 'inviteFriend' ||
        valueBannerType == 'voucher'
      ) {
        delete dataCreate.link
        delete dataCreate.serviceId
        await bannerApi.createBanner(dataCreate)
        setLoadingFalse()
        setAlertSuccess('Thêm thành công!')
        navigate('/marketing/banners')
      } else {
        setAlertError('Thêm thất bại!')
      }
    } catch (error: any) {
      setLoadingFalse()
      setAlertError(error.response?.data?.message)
    }
  }

  useEffect(() => {
    fetchData()
    fetchDataPromoCode()
  }, [])

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={BANNER_ADD} />
      <Grid item xs={12}>
        <Paper
          sx={{
            boxShadow:
              '0px 12px 33px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
            textAlign: 'center',
            height: '100%',
            padding: '24px',
          }}
        >
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '100ch', margin: 'auto' },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <StyleImage>
                {image ? (
                  <StyleAddImage>
                    <img src={image} alt="img news" />
                    <StyleAddItem>
                      <StyleLinks>
                        <CameraAltOutlinedIcon />
                        <StyleLink>Chỉnh sửa ảnh bìa</StyleLink>
                      </StyleLinks>
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={handleUpload}
                      />
                    </StyleAddItem>
                  </StyleAddImage>
                ) : (
                  <StyleAddImage>
                    <AddPhotoAlternateIcon
                      sx={{
                        width: '890px !important',
                        height: '300px !important',
                        color: '#1976d2',
                      }}
                    />
                    <StyleAddItem>
                      <StyleLinks>
                        <CameraAltOutlinedIcon />
                        <StyleLink>Thêm ảnh bìa</StyleLink>
                      </StyleLinks>
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={handleUpload}
                      />
                    </StyleAddItem>
                  </StyleAddImage>
                )}
              </StyleImage>
              <StyleError>{error}</StyleError>
            </div>

            <div>
              <InputField
                label="Tiêu đề"
                defaultValue=""
                {...register('title')}
                multiline={true}
              />
              <StyleError>{errors.title?.message as string}</StyleError>
            </div>

            <div style={{ marginBottom: '10px' }}>
              <StyleLabel>Nội dung</StyleLabel>
              <Editor onChange={handleEditorChange} value={content.trim()} />
              {!!errorContent && (
                <StyleError style={{ marginTop: '10px' }}>
                  {errorContent}
                </StyleError>
              )}
            </div>

            <StyleInput>
              <div style={{ width: '100%' }}>
                <InputField
                  id="typeAction"
                  label="Kiểu banner"
                  select
                  value={valueBannerType}
                  {...register('typeAction')}
                  onChange={handleChangeBannerType}
                >
                  {bannerTypeAction.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
              </div>

              <div style={{ width: '100%' }}>
                <InputField
                  id="status"
                  label="Trạng thái"
                  select
                  value={valueStatus}
                  {...register('status')}
                  onChange={handleChangeStatus}
                >
                  {bannerStatus.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
              </div>

              {valueBannerType === 'service' ? (
                <div style={{ width: '100%' }}>
                  <InputField
                    id="serviceId"
                    label="Tên dịch vụ"
                    select
                    value={valueService}
                    {...register('serviceId')}
                    sx={{
                      width: '100%',
                    }}
                    onChange={handleChangeService}
                  >
                    {listService.map((option: any) => (
                      <MenuItem
                        key={option?.id}
                        value={option?.id}
                        sx={{ justifyContent: 'center' }}
                      >
                        {option?.name}
                      </MenuItem>
                    ))}
                  </InputField>
                  <StyleError>
                    {!valueService && (errors.serviceId?.message as string)}
                  </StyleError>
                </div>
              ) : (
                <div style={{ width: '100%' }}>
                  <InputField
                    label="Độ ưu tiên"
                    defaultValue=""
                    {...register('priority')}
                    multiline={true}
                  />
                  <StyleError>{errors.priority?.message as string}</StyleError>
                </div>
              )}
            </StyleInput>

            <StyleInput>
              <div style={{ width: '32%' }}>
                <InputField
                  label="Nội dung nút bấm"
                  defaultValue=""
                  {...register('buttonName')}
                />
                <StyleError>{errors.buttonName?.message as string}</StyleError>
              </div>

              {valueBannerType === 'service' ? (
                <>
                  <div style={{ width: '32%' }}>
                    <Autocomplete
                      size="small"
                      sx={{ padding: '10px 0' }}
                      disablePortal
                      options={listPromoCode}
                      getOptionLabel={(option) => option?.title || ''}
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value?.value
                      }
                      defaultValue={promoCode}
                      noOptionsText={'Danh sách rỗng'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Mã khuyến mãi"
                          placeholder="Mã khuyến mãi"
                          {...register('code')}
                        />
                      )}
                      onChange={handlePromoCode}
                    />
                    <StyleError>
                      {!promoCode && (errors.code?.message as string)}
                    </StyleError>
                  </div>

                  <div style={{ width: '32%' }}>
                    <InputField
                      label="Độ ưu tiên"
                      defaultValue=""
                      {...register('priority')}
                      multiline={true}
                    />
                    <StyleError>
                      {errors.priority?.message as string}
                    </StyleError>
                  </div>
                </>
              ) : (
                valueBannerType === 'link' && (
                  <div style={{ width: '67%' }}>
                    <InputField
                      label="Link"
                      defaultValue=""
                      {...register('link')}
                      sx={{
                        width: '100%',
                      }}
                      multiline={true}
                    />
                    <StyleError>{errors.link?.message as string}</StyleError>
                  </div>
                )
              )}
            </StyleInput>

            {valueBannerType === 'service' && (
              <div style={{ display: 'flex', gap: '24px' }}>
                <div style={{ width: '31.5%' }}>
                  <Controller
                    control={control}
                    name="startDate"
                    defaultValue=""
                    render={({
                      field: { onChange, ref, onBlur, name, ...field },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          minDate={new Date()}
                          {...field}
                          inputRef={ref}
                          inputFormat="DD/MM/YYYY"
                          label="Ngày bắt đầu"
                          renderInput={(inputProps) => (
                            <InputField
                              {...inputProps}
                              onBlur={onBlur}
                              name={name}
                              error={false}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onChange={(e: Date | null) => {
                            onChange(e)
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <StyleError>{errors.startDate?.message as string}</StyleError>
                </div>
                <div style={{ width: '31.5%' }}>
                  <Controller
                    control={control}
                    name="endDate"
                    defaultValue=""
                    render={({
                      field: { onChange, ref, onBlur, name, ...field },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          minDate={new Date()}
                          {...field}
                          inputRef={ref}
                          inputFormat="DD/MM/YYYY"
                          label="Ngày kết thúc"
                          renderInput={(inputProps) => (
                            <InputField
                              {...inputProps}
                              onBlur={onBlur}
                              name={name}
                              error={false}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onChange={(e: Date | null) => {
                            onChange(e)
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <StyleError>{errors.endDate?.message as string}</StyleError>
                </div>
              </div>
            )}

            <Stack
              spacing={2}
              direction="row"
              sx={{
                justifyContent: 'flex-start',
                paddingTop: '14px',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                className="bgr-global"
                onClick={handleCheckValidate}
              >
                Lưu
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate('/marketing/banners')}
              >
                Huỷ
              </Button>
            </Stack>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default BannerCreate
