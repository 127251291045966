import styled from 'styled-components'
import { Button, Paper, TableCell } from '@mui/material'

export const StyleTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 23px;
  flex-direction: column;
`

export const StyleHeaderItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 24px;
`

export const StyleContentTitle = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 14px;
  width: 100%;
`

export const StyleTitle = styled.h2`
  font-size: 25px;
  color: var(--color-primary);
  font-weight: bold;
`

export const StyleTableCell = styled(TableCell)`
  font-weight: bold !important;
  color: var(--color-secondary) !important;
  min-width: 150px;
  border-bottom: none !important;
`

export const WrapStickyCell = styled.div`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid rgba(224, 224, 224, 1);
  th {
    min-width: 150px;
    border-bottom: none !important;
  }
`
export const WrapCell = styled.div`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  th {
    min-width: 150px;
    border-bottom: none !important;
  }
`

export const StyleFilter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 24px;
  flex-wrap: wrap;
`

export const StyleFilterItem = styled.div`
  height: 100% !important;
  margin: 0px !important;
`

export const StyleFilterItemSearch = styled.div`
  width: 220px;
`

export const StylePaper = styled(Paper)`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  height: 100%;
  padding: 24px;
`

export const ActionButton = styled(Button)`
  font-size: 12px !important;
  height: 28px !important;
  min-width: 46px !important;
  text-transform: capitalize !important;
  font-weight: bold !important;

  && {
    color: #fff;
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }
`

export const StyleLinks = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  width: 150px;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin: 9px 0;
  transition: 0.1s ease-in-out;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);

  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`

export const StyleLink = styled.span`
  margin-top: 2px;
  font-weight: bold;
`

export const StyleButton = styled.div`
  border: 1px double;
  text-align: center;
  padding: 2px;
  border-radius: 14px;
`
export const StyleAction = styled.div`
  display: flex;
  justify-content: center;
`
