import React, { useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'

interface ISelectProps {
  width: string
  id: string
  label: string
  options: any
  haveAll: boolean
  onChange: (value: string) => void
}

const SelectWithCheckbox: React.FC<ISelectProps> = (props) => {
  const { width, label, options, onChange } = props
  const [selectedOptions, setSelectedOptions] = useState<any>([])

  const handleOptionChange = (event: any) => {
    const value = event.target.value

    if (value.includes('all')) {
      setSelectedOptions(options.map((option: any) => option.value))
    } else {
      setSelectedOptions(value)
    }
    onChange(value)
  }

  const handleSelectAllChange = (event: any) => {
    if (event.target.checked) {
      setSelectedOptions(options.map((option: any) => option?.value))
    } else {
      setSelectedOptions([])
    }
  }

  const isAllSelected = selectedOptions.length === options.length

  return (
    <FormControl
      sx={{ width: `${width}}` }}
      size="small"
      className="input-select"
    >
      <InputLabel sx={{ backgroundColor: 'white' }}>{label}</InputLabel>
      <Select
        multiple
        value={selectedOptions}
        onChange={handleOptionChange}
        renderValue={(selected) => {
          return options
            .filter((f: any) => selected.includes(f.value))
            .map((m: any) => m.label)
            .join(', ')
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {props.haveAll && (
          <MenuItem value="all">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllSelected}
                  onChange={handleSelectAllChange}
                />
              }
              label="Tất cả"
            />
          </MenuItem>
        )}
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={selectedOptions.includes(option.value)} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectWithCheckbox
