import React from 'react'
import {
  StyleContainer,
  StyleOrder,
  StyleStatistics,
  StyleTotalOrder,
  StyleItem,
  StyleList,
  StyleTotalItem,
  StyleTableCell,
  StyleTableHeader,
  StyleHeaderItem,
  StyleTitle,
} from './style'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded'
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  //   TablePagination,
  TableRow,
} from '@mui/material'

export default function WalletCards() {
  // const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(10)
  // const navigate = useNavigate()

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage)
  // }

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10))
  //   setPage(0)
  // }

  return (
    <StyleContainer>
      <StyleStatistics>
        <StyleTotalOrder>
          <StyleList>
            <StyleItem> Tổng tiền trong ví </StyleItem>
            <StyleTotalItem>25.000.000</StyleTotalItem>
          </StyleList>
          <PaymentsRoundedIcon className="icons-style" />
        </StyleTotalOrder>
        <StyleTotalOrder>
          <StyleList>
            <StyleItem> Tổng số điểm </StyleItem>
            <StyleTotalItem>100</StyleTotalItem>
          </StyleList>
          <CurrencyExchangeIcon className="icons-style" />
        </StyleTotalOrder>
        <StyleTotalOrder>
          <StyleList>
            <StyleItem> Tổng số thẻ </StyleItem>
            <StyleTotalItem>10</StyleTotalItem>
          </StyleList>
          <CreditCardRoundedIcon className="icons-style" />
        </StyleTotalOrder>
      </StyleStatistics>
      <StyleOrder>
        {/* <StyleTableHeader> */}
          <StyleTitle>Lịch sử giao dịch</StyleTitle>
        {/* </StyleTableHeader> */}
        <TableContainer component={Paper} className="box-shadow">
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <StyleTableCell width={50}>STT</StyleTableCell>
                <StyleTableCell align="center"> Ngày giao dịch </StyleTableCell>
                <StyleTableCell align="left">Nội dung giao dịch</StyleTableCell>
                <StyleTableCell align="center">
                  Số tiền giao dịch
                </StyleTableCell>
                <StyleTableCell align="center">
                  Phương thức thanh toán
                </StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {datas
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => ( */}
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row" align="center">
                  1
                </TableCell>
                <TableCell align="center">21-11-023</TableCell>
                <TableCell align="left">Thanh toán tiền giặt ủi</TableCell>
                <TableCell align="center">1.000.000</TableCell>
                <TableCell align="center">tiền mặt</TableCell>
              </TableRow>
              {/* ))} */}
            </TableBody>
          </Table>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={datas?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </TableContainer>
      </StyleOrder>
    </StyleContainer>
  )
}
