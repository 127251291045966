export enum FileTypeEnum {
    MB = "MB",
    KB = "KB"
}

export function getFileSize(size: number, type: FileTypeEnum) {
    let result = 0
    switch (type) {
        case FileTypeEnum.KB:
            result = size / Math.pow(1024, 1)
            break
        case FileTypeEnum.MB:
            result = size / Math.pow(1024, 2)
            break
        default:
    }
    return result
}
