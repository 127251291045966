import React from 'react'
import Profile from 'pages/profile'
import AddNews from 'pages/news/components/AddNews'
import EditNews from 'pages/news/components/EditNews'
import UserList from 'pages/user'
import UserDetails from 'pages/user/UserDetails'
import DetailNews from 'pages/news/components/DetailNews'
import CampaignList from 'pages/campaign/components/CampaignList'
import CampaignCreate from 'pages/campaign/components/CampaignCreate'
import CampaignEdit from 'pages/campaign/components/CampaignEdit'
import CampaignDetail from 'pages/campaign/components/CampaignDetail'
import News from 'pages/news'
import Banner from 'pages/banner'
import BannerCreate from 'pages/banner/components/BannerCreate'
import BannerEdit from 'pages/banner/components/BannerEdit'
import BannerDetail from 'pages/banner/components/BannerDetail'
import HomeCleaning from 'pages/services/components/HomeCleaning'
import PriceConfigurations from 'pages/services/components/ConfigurePrice'
import RangeOfActiveEdit from 'pages/services/components/RangeOfActive/components/RangeOfActiveEdit'
import RangeOfActive from 'pages/services/components/RangeOfActive'
import RangeOfActiveCreate from 'pages/services/components/RangeOfActive/components/RangeOfActiveCreate'
import PromotionCode from 'pages/PromotionCode'
import PromotionCreate from 'pages/PromotionCode/components/PromotionCreate'
import PromotionEdit from 'pages/PromotionCode/components/PromotionEdit'
import PromotionDetail from 'pages/PromotionCode/components/PromotionDetail'
import PartTimeStaff from 'pages/partTimeStaff'
import OrderList from 'pages/order'
import AddOrder from 'pages/order/components/AddOrder'
import OrderDetail from 'pages/order/components/DetailOrder'
import OrderEdit from 'pages/order/components/EditOrder'
import DetailProvince from 'pages/services/components/ConfigurePrice/components/DetailsProvince'
import PartTimeStaffCreate from 'pages/partTimeStaff/components/PartTimeStaffCreate'
import PartTimeStaffEdit from 'pages/partTimeStaff/components/PartTimeStaffEdit'
import PartTimeStaffDetail from 'pages/partTimeStaff/components/PartTimeStaffDetail'
import FileCreate from 'pages/partTimeStaff/components/File/components/FileCreate'
import { FileProvider } from 'contexts/FileContext'
import { TemporaryDataStaffProvider } from 'contexts/TemporaryDataStaffContext'
import FileEdit from 'pages/partTimeStaff/components/File/components/FileEdit'
import FileDetail from 'pages/partTimeStaff/components/File/components/FileDetail'
import Rules from 'pages/services/components/Rules'

export const ROUTE_TEST = [
  { name: [{ name: 'Dash board' }], path: '/marketing/dashboard' },
  { name: [{ name: 'Báo cáo thống kê' }], path: '/marketing/reports' },
  { name: [{ name: 'Xem lịch CSKH' }], path: '/care/schedule' },
  { name: [{ name: 'Phân công nhân viên' }], path: '/care/assignment' },
  { name: [{ name: 'Thông báo tự động' }], path: '/care/notifications' },
  { name: [{ name: 'Hỗ trợ khách hàng' }], path: '/care/support' },
  { name: [{ name: 'Quản lý ticket' }], path: '/care/ticket' },
  { name: [{ name: 'Danh sách đơn hàng' }], path: '/order/list' },
  { name: [{ name: 'Phân công theo đơn' }], path: '/order/assignment' },
  { name: [{ name: 'Dash board' }], path: '/staff/dashboard' },
  { name: [{ name: 'Tuyển dụng' }], path: '/staff/recruitment' },
  { name: [{ name: 'Lịch nghỉ/làm việc' }], path: '/staff/schedule' },
  { name: [{ name: 'Chấm công' }], path: '/staff/timekeeping' },
  { name: [{ name: 'Lương thưởng' }], path: '/staff/salary' },
  { name: [{ name: 'Đánh giá hiệu suât' }], path: '/staff/evaluation' },
  { name: [{ name: 'Hỗ trợ nhân viên' }], path: '/staff/support' },
  { name: [{ name: 'Đào tạo và phát triển' }], path: '/staff/train' },
  { name: [{ name: 'Quản lý tài khoản' }], path: '/staff/account' },
  { name: [{ name: 'Phân quyền tài khoản' }], path: '/staff/authorization' },
  { name: [{ name: 'Dash board' }], path: '/partner/dashboard' },
  { name: [{ name: 'Danh sách đối tác' }], path: '/partner/list' },
  { name: [{ name: 'Đánh giá hiệu suất' }], path: '/partner/discount' },
  { name: [{ name: 'Hỗ trợ đối tác' }], path: '/partner/support' },
  { name: [{ name: 'Đánh giá hiệu suất' }], path: '/partner/evaluation' },
  { name: [{ name: 'Nghỉ' }], path: '/request/rest' },
  { name: [{ name: 'Đổi lịch' }], path: '/request/reschedule' },
  { name: [{ name: 'Ứng lương' }], path: '/request/salary' },
  { name: [{ name: 'Request chung' }], path: '/request/common' },
  { name: [{ name: 'Kết quả kinh doanh' }], path: '/finance/result' },
  { name: [{ name: 'Khoản thu-chi' }], path: '/finance/revenue' },
  { name: [{ name: 'Lương' }], path: '/finance/salary' },
  { name: [{ name: 'Ngân sách' }], path: '/finance/budget' },
  { name: [{ name: 'Hàng hoá và kho' }], path: '/finance/warehouse' },
  { name: [{ name: 'Tài liệu' }], path: '/finance/document' },
  { name: [{ name: 'Sổ tay nhân sự' }], path: '/help/handbook' },
  { name: [{ name: 'Quy định & chính sách' }], path: '/help/policies' },
]

export const ROUTE_STRUCTURE = [
  { name: <Profile />, path: '/profile' },
  { name: <Banner />, path: '/marketing/banners' },
  { name: <BannerCreate />, path: '/marketing/banners/create' },
  { name: <BannerEdit />, path: '/marketing/banners/edit/:id' },
  { name: <BannerDetail />, path: '/marketing/banners/detail/:id' },
  { name: <PromotionCreate />, path: '/marketing/promotion-code/create' },
  { name: <PromotionEdit />, path: '/marketing/promotion-code/update/:id' },
  { name: <PromotionDetail />, path: '/marketing/promotion-code/detail/:id' },
  { name: <News />, path: '/marketing/news' },
  { name: <DetailNews />, path: '/marketing/news/detail/:id' },
  { name: <EditNews />, path: '/marketing/news/edit/:id' },
  { name: <AddNews />, path: 'marketing/news/add' },
  { name: <CampaignList />, path: '/marketing/campaign' },
  { name: <CampaignCreate />, path: '/marketing/campaign/add' },
  { name: <CampaignEdit />, path: '/marketing/campaign/edit/:id' },
  { name: <CampaignDetail />, path: '/marketing/campaign/detail/:id' },
  { name: <UserList />, path: '/user' },
  { name: <UserDetails />, path: '/user/detail' },
  { name: <HomeCleaning />, path: '/service/:id' },
  {
    name: <RangeOfActive />,
    path: 'service/housecleaning/:serviceId/range-of-active',
  },
  {
    name: <RangeOfActiveCreate />,
    path: 'service/housecleaning/:serviceId/range-of-active-create',
  },
  {
    name: <RangeOfActiveEdit />,
    path: 'housecleaning/:serviceId/range-of-active-edit/:countryId',
  },
  {
    name: <Rules />,
    path: 'service/housecleaning/:serviceId/rules',
  },
  { name: <PriceConfigurations />, path: '/service/:id/price-configurations' },
  {
    name: <DetailProvince />,
    path: '/service/:id/price-configurations/:provinceId/:areaId',
  },
  {
    name: <PromotionCode />,
    path: '/marketing/promotion-code',
  },
  {
    name: <PartTimeStaff />,
    path: '/staff-manager/part-time-staff',
  },
  {
    name: (
      <TemporaryDataStaffProvider>
        <FileProvider>
          <PartTimeStaffCreate />
        </FileProvider>
      </TemporaryDataStaffProvider>
    ),
    path: '/staff-manager/part-time-staff/create',
  },
  {
    name: (
      <TemporaryDataStaffProvider>
        <FileProvider>
          <PartTimeStaffEdit />
        </FileProvider>
      </TemporaryDataStaffProvider>
    ),
    path: '/staff-manager/part-time-staff/edit/:id',
  },
  {
    name: (
      <TemporaryDataStaffProvider>
        <FileProvider>
          <PartTimeStaffDetail />
        </FileProvider>
      </TemporaryDataStaffProvider>
    ),
    path: '/staff-manager/part-time-staff/detail/:id',
  },
  {
    name: (
      <TemporaryDataStaffProvider>
        <FileProvider>
          <FileCreate />
        </FileProvider>
      </TemporaryDataStaffProvider>
    ),
    path: '/staff-manager/part-time-staff/create/file-create',
  },
  {
    name: (
      <TemporaryDataStaffProvider>
        <FileProvider>
          <FileEdit />
        </FileProvider>
      </TemporaryDataStaffProvider>
    ),
    path: '/staff-manager/part-time-staff/edit/:id/file-edit',
  },
  {
    name: (
      <TemporaryDataStaffProvider>
        <FileProvider>
          <FileDetail />
        </FileProvider>
      </TemporaryDataStaffProvider>
    ),
    path: '/staff-manager/part-time-staff/detail/:id/file-detail',
  },
  { name: <OrderList />, path: '/order' },
  { name: <AddOrder />, path: '/order/add' },
  { name: <OrderDetail />, path: '/order/detail/:id' },
  { name: <OrderEdit />, path: '/order/edit/:id' },
]
