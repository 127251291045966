import axiosClient from '../utils/axios'
import { useNavigate } from 'react-router-dom'
const ProfileApi = {
  createUser: (data: IProfile) => {
    const url = `/profile`
    return axiosClient.post(url, data)
  },
  getUser: () => {
    const url = `/profile`
    return axiosClient.get(url)
  },
  getUserById: (id: string) => {
    const url = `/profile/${id}`
    return axiosClient.get(url)
  },
  update: (data: IProfile) => {
    const url = `/profile`
    return axiosClient.patch(url, data)
  },
  delete: (id: string) => {
    const url = `/profile/${id}`
    return axiosClient.delete(url)
  },
  changePwd: (data: IChangePwd) => {
    const url = `/profile/changePwd`
    return axiosClient.post(url, data)
  },
}

export default ProfileApi
