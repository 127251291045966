import styled from 'styled-components'
import Typography from '@mui/material/Typography'

export const StyleError = styled.p`
  display: block;
  color: red;
  font-size: 14px;
  text-align: left;
  white-space: normal;
`
export const StyleTitle = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 25px;
    color: var(--color-primary);
    text-align: left;
  }
`
export const StyleDate = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`
export const StyleErrorSelect = styled.p`
  margin-top: -10px;
  margin-bottom: 10px;
  color: red;
  font-size: 14px;
  text-align: left;
`
export const StyleTimePicker = styled.div`
  width: 49%;
`
