import { Typography, Button } from '@mui/material'
import styled from 'styled-components'

export const ResendButton = styled(Typography)`
  && {
    color: #dc3545;
    font-weight: 700;
    cursor: pointer;
  }
`
export const ResendDisable = styled(Typography)`
  && {
    color: #909090;
    font-weight: 700;
    cursor: default;
  }
`
export const ButtonDisabled = styled(Button)`
  &:disabled {
    margin-top: 15px;
    font-size: 16px;
    height: 54px;
    background-color: #cccccc;
    color: #888888;
  }
`
