import axiosClient from '../utils/axios'
import { API_JSON_URL } from 'constants/common'

const userApi = {
  getUser: () => {
    const url = `/userlist`
    return axiosClient.get(url, { baseURL: API_JSON_URL })
  },
  delete: (id: string) => {
    const url = `/userlist/${id}`
    return axiosClient.delete(url)
  },
}

export default userApi
