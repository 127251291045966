// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-types
export function checkChangeData({
  dataChange,
  dataDefault,
}: {
  dataChange: object
  dataDefault?: object
}) {
  if (dataChange && dataDefault) {
    const listCheck = Object?.keys(dataChange)?.map((key: string) => {
      if (dataDefault[key as keyof object] == dataChange[key as keyof object]) {
        return false
      }
      return true
    })
    return listCheck.some((item: boolean) => !!item)
  }
  return false
}
