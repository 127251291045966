import { Button, TableCell, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyleTableCell = styled(TableCell)`
  font-weight: bold !important;
  color: var(--color-secondary) !important;
`

export const ActionButton = styled(Button)`
  font-weight: bold !important;
  && {
    color: #fff;
  }
  &:hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }
`

export const StyleDate = styled.div`
  margin: 14px 0;
  display: flex;
  justify-content: space-between;
  gap: 24px;
`
export const StyleTimePicker = styled.div`
  width: 50%;
`
export const StyleButton = styled.div`
  text-align: left;
  display: flex;
  gap: 24px;
  margin-top: 14px;
`

export const StyleError = styled.p`
  margin-bottom: 12px;
  display: block;
  color: red;
  font-size: 14px;
  text-align: left;
  white-space: normal;
`

export const StyleErrorSelect = styled.div`
  margin-top: 10px;
  color: red;
  font-size: 10px;
  text-align: left;
`

export const StyleTitleModal = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 20px;
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 5px;
  }
`

export const StyleTitle = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 20px;
    color: var(--color-primary);
    text-align: left;
    margin: 12px 0;
  }
`

export const StyleLinks = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  width: 150px;
  border-radius: 6px;
  margin-bottom: 24px;
  border: none;
  padding: 6px;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`

export const StyleLink = styled.span`
  font-weight: bold;
`

export const StyleCellHover = styled.div`
  &:hover {
    border: 1px solid rgba(224, 224, 224, 1);
    padding: 10px;
    cursor: pointer;
    border-radius: 3px;
  }
`
