import React, { createContext, useContext, useState } from 'react'

interface IChildren {
  children: React.ReactNode
}
interface ISidebarContextProps {
  isListUpdated: boolean
  setIsListUpdated: React.Dispatch<React.SetStateAction<boolean>>
}

const SidebarContext = createContext<ISidebarContextProps>({
  isListUpdated: false,
  setIsListUpdated: () => undefined,
})

export const useSidebar = () => useContext(SidebarContext)

export const SidebarProvider = ({ children }: IChildren) => {
  const [isListUpdated, setIsListUpdated] = useState(false)

  return (
    <SidebarContext.Provider value={{ isListUpdated, setIsListUpdated }}>
      {children}
    </SidebarContext.Provider>
  )
}
