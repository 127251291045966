import React, { useEffect } from 'react'
import {
  FormLogin,
  StyleLogin,
  Heading,
  StyleError,
  TitleLogin,
  StyleForgotPwd,
  SendButton,
  StyleForgot,
} from '../../style'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import { Stack, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useToken from 'hooks/useToken'
import { loginSchema_ } from 'validations/loginSchema'
import authAPI from 'services/authAPI'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'

const Login = ({ setToken }: { setToken: (accessToken: string) => void }) => {
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertClose, setAlertError } = useAlert()
  const { token } = useToken()
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<ILoginAuth>({
    resolver: yupResolver(loginSchema_),
  })

  const onSubmit = async (values: ILoginAuth) => {
    setLoadingTrue()
    setAlertClose()
    try {
      const response = await authAPI.login(values)
      setToken(response.data.accessToken)
      setLoadingFalse()
      setTimeout(() => {
        navigate('/')
      }, 300)
      // eslint-disable-next-line
    } catch (error: any) {
      setLoadingFalse()
      setAlertError(error.response.data.message)
      resetField('password')
      setLoadingFalse()
    }
  }
  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [])

  return (
    <FormLogin onSubmit={handleSubmit(onSubmit)}>
      <StyleLogin>
        <TitleLogin style={{ marginBottom: '24px' }}>Đăng nhập</TitleLogin>
        <Heading>
          <TextField
            sx={{ width: '100%', marginBottom: '5px' }}
            id="standard-textarea"
            label="Email"
            placeholder="Mời nhập email"
            variant="outlined"
            {...register('email')}
            autoFocus
          />
          {errors.email && (
            <StyleError>{errors.email?.message as string}</StyleError>
          )}
        </Heading>
        <Heading>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Mật khẩu
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Mật khẩu"
              {...register('password')}
            />
          </FormControl>
          {errors.password && (
            <StyleError>{errors.password?.message as string}</StyleError>
          )}
        </Heading>
        <StyleForgot>
          <StyleForgotPwd
            variant="body2"
            align="right"
            onClick={() => navigate('/auth/email')}
          >
            Quên mật khẩu ?
          </StyleForgotPwd>
        </StyleForgot>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <SendButton type="submit">Đăng nhập</SendButton>
        </Stack>
      </StyleLogin>
    </FormLogin>
  )
}

export default Login
