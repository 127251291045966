import {
  FormLogin,
  StyleLogin,
  TitleLogin,
  Heading,
  SendButton,
  StyleError,
} from 'pages/auth/style'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { resetPasswordSchema_ } from 'validations/resetPasswordSchema'

import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import useToken from 'hooks/useToken'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import authAPI from 'services/authAPI'

const FormChangePassword = () => {
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const [showConfirm, setShowConfirm] = useState(false)
  const handleConfirmPassword = () => setShowConfirm((show) => !show)
  const { setAlertClose, setAlertError, setAlertSuccess } = useAlert()
  const { email } = useParams()
  const { token } = useToken()

  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IChangePassword>({
    resolver: yupResolver(resetPasswordSchema_),
  })
  const onSubmit = async (values: IChangePassword) => {
    setLoadingTrue()
    setAlertClose()
    if (email) {
      try {
        const otp = localStorage.getItem('otp')
        await authAPI.changePassword({
          email: email,
          code: +(otp as string),
          password: values.password,
        })
        localStorage.removeItem('otp')
        setAlertSuccess('Đổi mật khẩu thành công')
        setLoadingFalse()
        setTimeout(() => {
          navigate('/auth/login')
        }, 300)
        // eslint-disable-next-line
      } catch (error: any) {
        setAlertError(error.response.data.message)
        setLoadingFalse()
        localStorage.removeItem('countdownTime')
        navigate(`/auth/otp/${email}`)
      }
    }
  }
  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [])
  return (
    <FormLogin onSubmit={handleSubmit(onSubmit)}>
      <StyleLogin>
        <TitleLogin style={{ marginBottom: '24px' }}>
          Cập nhật mật khẩu mới
        </TitleLogin>
        <Heading>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Mật khẩu mới
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Mật khẩu mới"
              {...register('password')}
            />
          </FormControl>
          {errors.password && (
            <StyleError>{errors.password?.message as string}</StyleError>
          )}
        </Heading>
        <Heading>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Xác nhận mật khẩu
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              fullWidth
              type={showConfirm ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Xác nhận mật khẩu"
              {...register('confirmPassword')}
            />
          </FormControl>
          {errors.confirmPassword && (
            <StyleError>{errors.confirmPassword?.message as string}</StyleError>
          )}
        </Heading>
        <SendButton type="submit" variant="contained">
          Cập nhật
        </SendButton>
      </StyleLogin>
    </FormLogin>
  )
}

export default FormChangePassword
