import { Avatar } from '@mui/material'
import InputField from 'components/FormControl/InputField'
import React from 'react'
import { useForm } from 'react-hook-form'
import {
  StyleAvatar,
  StyleContainer,
  StyleItemLeft,
  StyleItemRight,
  StyleListLeft,
  StyleListRight,
  StyleTitleUser
} from './style'

export default function UserInfo() {
  const imgUser = "https://scontent.fhan2-5.fna.fbcdn.net/v/t39.30808-1/275515350_3088836368043102_7735159430704432445_n.jpg?stp=c0.21.160.160a_dst-jpg_p160x160&_nc_cat=109&ccb=1-7&_nc_sid=7206a8&_nc_ohc=J2bpYz4E-DsAX_mkBgz&_nc_ht=scontent.fhan2-5.fna&oh=00_AfCm6WXCcLLAI0sy4xjK_JgZYpAQdNqQBmP7rxapJx_8Mg&oe=63F0432E"
  const form = useForm({
    mode: 'onChange',
  })

  return (
    <StyleContainer>
      <StyleListLeft>
        <StyleAvatar>
          <Avatar src={imgUser} sx={{ width: 120, height: 120 }} />
        </StyleAvatar>
        <StyleTitleUser variant="h6">
          Nguyen Van Nguyen 
        </StyleTitleUser>
      </StyleListLeft>
      <StyleListRight>
        <StyleItemRight>
          <InputField
            name="phone"
            label="Số điện thoại"
            form={form}
            autoCompleted="family-name"
            defaultValue="0344465816"
            inputProps = {{
              readOnly: true
            }}
          />
          <InputField
            name="email"
            label="Email"
            form={form}
            autoCompleted="family-name"
            defaultValue="v.nnguyen@gmail.com"
            inputProps = {{
              readOnly: true
            }}
          />
          <InputField
            name="dateBirth"
            label="	Ngày sinh"
            form={form}
            autoCompleted="family-name"
            defaultValue="19-07-1999"
            inputProps = {{
              readOnly: true
            }}
          />
          <InputField
            name="saveAddress"
            label="Địa chỉ đã lưu"
            form={form}
            autoCompleted="family-name"
            defaultValue="07 Lê Anh Xuân, Hoà Cường Nam, Hải Châu, Đà Nẵng"
            inputProps = {{
              readOnly: true
            }}
          />
        </StyleItemRight>
        <StyleItemLeft>
          <InputField
            name="rankMember"
            label="Hạng thành viên"
            form={form}
            autoCompleted="family-name"
            defaultValue="15-12-2023"
            inputProps = {{
              readOnly: true
            }}
          />
          <InputField
            name="review"
            label="Đánh giá từ NV"
            form={form}
            autoCompleted="family-name"
            defaultValue="Lịch sự, nhiệt tình"
            inputProps = {{
              readOnly: true
            }}
          />
          <InputField
            name="defaultAddress"
            label="Địa chỉ mặc định"
            form={form}
            autoCompleted="family-name"
            defaultValue="213 Đống Đa, Thạch Thang, Hải Châu, Đà Nẵng"
            inputProps = {{
              readOnly: true
            }}
          />
          <InputField
            name="source"
            label="Nguồn"
            form={form}
            autoCompleted="family-name"
            defaultValue="Facebook"
            inputProps = {{
              readOnly: true
            }}
          />
        </StyleItemLeft>
      </StyleListRight>
    </StyleContainer>
  )
}
