import React, { useEffect, useState } from 'react'
import { Grid, Stack } from '@mui/material'
import Breadcrumb from 'components/Breadcrumbs'
import Item from 'components/Item'
import InputField from 'components/FormControl/InputField'
import { useNavigate, useParams } from 'react-router'
import { useAlert } from 'contexts/AlertContext'
import campaignApi from 'services/campaignAPI'
import { StyleDate, StyleTimePicker, StyleTitle } from 'pages/campaign/style'
import { Button } from '@mui/material'
import dayjs from 'dayjs'
import { CAMPAIGN_DETAIL } from 'constants/breadcrumbs'
import LoadingVersion2 from 'components/LoadingV2'

const CampaignDetail = () => {
  const { id } = useParams()
  const [data, setData] = useState<ICampaign>()
  const { setAlertSuccess } = useAlert()
  const navigate = useNavigate()
  const fetchCampaign = async () => {
    const response: any = await campaignApi.getCampaignById(id as string)
    setData(response)
  }

  useEffect(() => {
    fetchCampaign()
  }, [id])

  return (
    <>
      <Grid container spacing={2}>
        <Breadcrumb paths={CAMPAIGN_DETAIL} />
        <Grid item xs={12}>
          <Item sx={{ padding: '24px' }}>
            <StyleTitle>Chi tiết chiến dịch</StyleTitle>
            {data ? (
              <form style={{ padding: '0 200px' }}>
                <InputField
                  label="Tên chiến dịch"
                  defaultValue={data.campaign}
                  inputProps={{ readOnly: true }}
                />
                <InputField
                  label="Mục đích"
                  inputProps={{ readOnly: true }}
                  multiline
                  defaultValue={data.purpose}
                />
                <StyleDate>
                  <StyleTimePicker>
                    <InputField
                      label="Ngày bắt đầu"
                      inputProps={{ readOnly: true }}
                      defaultValue={dayjs(data?.start).format('MM/DD/YYYY')}
                    />
                  </StyleTimePicker>
                  <StyleTimePicker>
                    <InputField
                      label="Ngày kết thúc"
                      inputProps={{ readOnly: true }}
                      defaultValue={dayjs(data?.end).format('MM/DD/YYYY')}
                    />
                  </StyleTimePicker>
                </StyleDate>
                <InputField
                  label="Người duyệt"
                  inputProps={{ readOnly: true }}
                  defaultValue={data?.reviewer}
                />
                <InputField
                  label="Nền tảng"
                  inputProps={{ readOnly: true }}
                  defaultValue={data?.flatform}
                />
                <InputField
                  label="Nội dung chiến dịch"
                  inputProps={{ readOnly: true }}
                  multiline={true}
                  defaultValue={data?.content}
                />
                <InputField
                  label="Chi phí dự kiến"
                  inputProps={{ readOnly: true }}
                  defaultValue={data?.expense}
                />
                <InputField
                  label="Doanh thu dự kiến"
                  inputProps={{ readOnly: true }}
                  defaultValue={data?.revenue}
                />
                <InputField
                  label="Lượng phản hồi dự kiến"
                  inputProps={{ readOnly: true }}
                  defaultValue={data?.feedbackNumbers}
                />

                <InputField
                  label="Số Lead sự kiến"
                  inputProps={{ readOnly: true }}
                  defaultValue={data?.leadNumbers}
                />
                <InputField
                  label="Số khách hàng dự kiến"
                  inputProps={{ readOnly: true }}
                  defaultValue={data?.customerNumbers}
                />
                <InputField
                  label="ROI mong đợi"
                  inputProps={{ readOnly: true }}
                  defaultValue={data?.profit}
                />
                <InputField
                  label="Ghi chú"
                  inputProps={{ readOnly: true }}
                  multiline={true}
                  defaultValue={data?.note}
                />
                <Stack spacing={2} direction="row" sx={{ paddingTop: '10px' }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate('/campaign'),
                        setAlertSuccess('Đã duyệt chiến dịch')
                    }}
                    color="primary"
                  >
                    Duyệt
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate('/campaign'),
                        setAlertSuccess('Không duyệt chiến dịch')
                    }}
                    color="secondary"
                  >
                    Không duyệt
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => navigate('/campaign')}
                  >
                    Hủy
                  </Button>
                </Stack>
              </form>
            ) : (
              <LoadingVersion2 />
            )}
          </Item>
        </Grid>
      </Grid>
    </>
  )
}

export default CampaignDetail
