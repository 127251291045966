import {
  Button,
  Grid,
  Stack,
  Divider,
  MenuItem,
  TextField,
  InputAdornment,
} from '@mui/material'
import Breadcrumb from 'components/Breadcrumbs'
import dayjs from 'dayjs'
import Editor from 'components/Editor'
import InputField from 'components/FormControl/InputField'

import Item from 'components/Item'
import Checkbox from '@mui/material/Checkbox'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'

import { PROMOTION_CODE_CREATE } from 'constants/breadcrumbs'

import { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router'

import {
  StyleAddImage,
  StyleChoose,
  StyleContent,
  StyleContentInput,
  StyleContentText,
  StyleErrorImage,
  StyleFilter,
  StyleHeader,
  StyleImage,
  StyleInfo,
  StyleInput,
  StyleItem,
  StyleLabel,
  StyleObject,
  StyleStatus,
  StyleTitle,
  StyleToogle,
} from '../../style'
import {
  promotionCode,
  promotionObject,
  promotionPrice,
  promotionStatus,
} from 'constants/promotion'
import { StyledInput } from 'pages/services/components/HomeCleaning/style'
import promoApi from 'services/promoAPI'
import LoadingVersion2 from 'components/LoadingV2'
import rangeOfActiveApi from 'services/houseCleaningAPI'

const PromotionDetail = () => {
  const { id } = useParams()
  const [data, setData] = useState<IPromotion>()
  const [content, setContent] = useState('')
  const [selectServices, setSelectServices] = useState<any[]>([])
  const [selectedServices, setSelectedServices] = useState<any>([])

  const [errorImage, setErrorImage] = useState('')
  const [selectUsers, setSelectUsers] = useState<any[]>([])
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [selectedLevel, setSelectedLevel] = useState<any[]>([])
  const [selectedGroups, setSelectedGroups] = useState<any>([])
  const [selectedRange, setSelectedRange] = useState<any[]>([])
  const [valueStatus, setValueStatus] = useState('')
  const [valueObject, setValueObject] = useState('')
  const [valueCode, setValueCode] = useState('')
  const [valueNation, setValueNation] = useState('')
  const [valuePrice, setValuePrice] = useState('')
  const [selectRange, setSelectRange] = useState<any[]>([])

  const [errorContent, setErrorContent] = useState<string>()

  const navigate = useNavigate()

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const handleInputChange = async (event: any, value: any) => {
    const { data } = await promoApi.getUser(value)
    const result = data.rows.filter(
      (item: any) =>
        !selectedUsers.find((element: any) => element.id === item.id)
    )
    setSelectUsers(result)
  }
  const handleEditorChange = (value: string) => {
    setContent(value)
    setErrorContent(undefined)
  }

  const handleChangeObject = (event: React.SyntheticEvent, newValue: any) => {
    setValueObject(newValue?.props?.value)
  }

  const handleChangePrice = (event: React.SyntheticEvent, newValue: any) => {
    setValuePrice(newValue?.props?.value)
  }

  const fetchServices = async () => {
    const { data } = await promoApi.getServices()
    setSelectServices(data)
  }

  const levelUser = [
    { title: 'Thường', id: '1' },
    { title: 'Bạc', id: '2' },
    { title: 'Vàng', id: '23' },
    { title: 'Bạch kim', id: '4' },
  ]
  const groupUser = [
    { title: 'Trung thành', id: '1' },
    { title: 'Vãng lai', id: '2' },
    { title: 'Khó tính', id: '3' },
  ]
  const range = [
    { name: 'Hồ chí Minh', id: '1' },
    { name: 'Hà Nội', id: '2' },
    { name: 'Đà Nẵng', id: '3' },
  ]
  useEffect(() => {
    fetchServices()
  }, [])

  useEffect(() => {
    const fetchPromotion = async () => {
      const { data } = await promoApi.getPromotionById(id as string)
      setData(data)
      setValueStatus(data.status)
      setValueCode(data.typePromo)
      setContent(data.description)
      if (data.isPercent === false) {
        setValuePrice('Giảm tiền')
      } else {
        setValuePrice('Giảm phần trăm')
      }
    }
    fetchPromotion()
  }, [id])
  useEffect(() => {
    if (data) {
      const countriesID = data.conditions?.countryId
      const fetchProvince = async () => {
        const { data } = await rangeOfActiveApi.getProvincesById(countriesID)
        if (data) {
          setSelectRange(data)
        }
      }
      fetchProvince()
    }
  }, [data])
  useEffect(() => {
    if (data) {
      const countriesID = data.conditions?.countryId
      const provinceId = data.conditions?.provinceIds?.toString()
      const fetchProvinceId = async () => {
        const { data } = await promoApi.getProvincesByIdCondition(
          countriesID,
          provinceId
        )
        if (data) {
          setSelectedRange(data)
        } else {
          setSelectedRange(selectRange)
        }
      }
      const fetchCountryById = async () => {
        const { data } = await promoApi.getCountryById(countriesID)
        if (data) {
          setValueNation(data.name)
        }
      }
      const usersId = data.conditions?.userIds?.toString()
      const fetchUsersId = async () => {
        const { data } = await promoApi.getUserCondition(usersId)
        setSelectedUsers(data.rows)
      }
      const servicesId = data.conditions?.serviceIds?.toString()
      const fetchServicesId = async () => {
        const { data } = await promoApi.getServiceCondition(servicesId)
        if (data) {
          setSelectedServices(data)
        } else {
          setSelectedServices(selectServices)
        }
      }
      fetchCountryById()
      fetchProvinceId()
      fetchUsersId()
      fetchServicesId()
    }
  }, [data, selectRange])

  useEffect(() => {
    if (
      !data?.conditions.customerGroup.length &&
      !data?.conditions.customerRanking.length &&
      !data?.conditions.userIds.length
    ) {
      setValueObject('Tất cả')
    } else {
      if (data?.conditions.customerGroup.length) {
        setValueObject('Nhóm khách hàng')
      } else if (data?.conditions.customerRanking.length) {
        setValueObject('Hạng khách hàng')
      } else if (data?.conditions.userIds.length) {
        setValueObject('Khách hàng cụ thể')
      }
    }
  }, [data])

  return (
    <>
      <Grid container spacing={2}>
        <Breadcrumb paths={PROMOTION_CODE_CREATE} />
        <Grid item xs={12}>
          <Item sx={{ padding: '24px' }}>
            <StyleHeader>
              {' '}
              <StyleToogle>
                <StyleTitle>Thông tin dịch vụ</StyleTitle>
              </StyleToogle>
              <StyleFilter style={{ width: '20%' }}>
                <StyleStatus style={{ width: '100%' }}>
                  <InputField
                    id="status"
                    size="small"
                    label="Trạng thái"
                    select
                    style={{ marginTop: '-10px' }}
                    value={valueStatus}
                    inputProps={{ readOnly: true }}
                    SelectProps={{ IconComponent: () => null }}
                  >
                    {promotionStatus.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </InputField>
                </StyleStatus>
              </StyleFilter>
            </StyleHeader>
            {data ? (
              <form>
                <StyleItem
                  style={{
                    paddingTop: '0',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <StyleImage>
                    <StyleAddImage>
                      {data?.thumbnail ? (
                        <img src={data?.thumbnail} alt="Uploaded Image" />
                      ) : (
                        <AddPhotoAlternateIcon
                          sx={{
                            borderRadius: '10px',
                            width: '890px',
                            height: '300px',
                            objectFit: 'contain',
                            background: '#cecece',
                          }}
                        />
                      )}
                    </StyleAddImage>
                    {errorImage && (
                      <StyleErrorImage>{errorImage}</StyleErrorImage>
                    )}
                  </StyleImage>
                  <StyleInfo>
                    <InputField
                      label="Tên"
                      inputProps={{ readOnly: true }}
                      defaultValue={data?.title}
                    />

                    <InputField
                      label="Nội dung ngắn"
                      defaultValue={data?.subTitle}
                      multiline
                      inputProps={{ readOnly: true }}
                    />
                    <StyleLabel>Mô tả </StyleLabel>
                    <Editor
                      onChange={handleEditorChange}
                      value={content}
                      disable={true}
                    />
                  </StyleInfo>
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleInput style={{ width: '70%' }}>
                    <StyleStatus>
                      {' '}
                      <InputField
                        id="status"
                        label="Loại"
                        select
                        value={valueCode}
                        inputProps={{ readOnly: true }}
                        SelectProps={{ IconComponent: () => null }}
                      >
                        {promotionCode.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                    </StyleStatus>

                    <div style={{ width: '30%' }}>
                      <InputField
                        label="Ngày bắt đầu"
                        defaultValue={dayjs(data?.startDate).format(
                          'DD/MM/YYYY'
                        )}
                        multiline
                        inputProps={{ readOnly: true }}
                      />
                    </div>
                    <div style={{ width: '30%' }}>
                      <InputField
                        label="Ngày kết thúc"
                        defaultValue={dayjs(data?.endDate).format('DD/MM/YYYY')}
                        multiline
                        inputProps={{ readOnly: true }}
                      />
                    </div>
                  </StyleInput>
                </StyleItem>

                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleInput style={{ width: '70%' }}>
                    <div>
                      <StyleContent>
                        {' '}
                        <StyleContentText style={{ marginRight: '8px' }}>
                          Mã:
                        </StyleContentText>
                        <StyleContentInput style={{ maxWidth: '200px' }}>
                          <TextField
                            size="small"
                            inputProps={{
                              readOnly: true,
                              style: { textTransform: 'uppercase' },
                            }}
                            defaultValue={data.promotionCode}
                          />
                        </StyleContentInput>
                      </StyleContent>
                    </div>
                    {valueCode === 'code' ? (
                      <>
                        <div>
                          <StyleContent>
                            {' '}
                            <StyleContentText style={{ marginRight: '8px' }}>
                              Số lượng code:
                            </StyleContentText>
                            <StyleContentInput style={{ maxWidth: '70px' }}>
                              <StyledInput
                                sx={{ input: { textAlign: 'center' } }}
                                type="number"
                                size="small"
                                inputProps={{ readOnly: true }}
                                defaultValue={data.totalCode}
                              />
                            </StyleContentInput>
                          </StyleContent>
                        </div>
                        <div>
                          <StyleContent>
                            <StyleContentText style={{ marginRight: '8px' }}>
                              Giới hạn số lần sử dụng
                            </StyleContentText>
                            <StyleContentInput style={{ maxWidth: '70px' }}>
                              <StyledInput
                                sx={{ input: { textAlign: 'center' } }}
                                type="number"
                                inputProps={{ readOnly: true }}
                                size="small"
                                defaultValue={data.maxNumberOfUse}
                              />
                            </StyleContentInput>
                          </StyleContent>
                        </div>
                      </>
                    ) : (
                      <div>
                        <StyleContent>
                          {' '}
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Số point tương đương:
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '70px' }}>
                            <StyledInput
                              sx={{ input: { textAlign: 'center' } }}
                              defaultValue={data.minPoint}
                              inputProps={{ readOnly: true }}
                              type="number"
                              size="small"
                            />
                          </StyleContentInput>
                          <StyleContentText
                            style={{ marginLeft: '8px', marginRight: '150px' }}
                          >
                            Point
                          </StyleContentText>
                        </StyleContent>
                      </div>
                    )}
                  </StyleInput>
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleTitle>Dịch vụ</StyleTitle>
                  {selectedServices?.length && (
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={selectServices}
                      fullWidth
                      readOnly
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      defaultValue={selectedServices}
                      onChange={(event, value: any) => {
                        setSelectedServices([...value])
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Dịch vụ" />
                      )}
                    />
                  )}

                  {/* <StyleError>{errorProvinces}</StyleError> */}
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleTitle>Dạng giảm</StyleTitle>
                  <StyleChoose>
                    <StyleInput style={{ marginTop: '-10px', width: '40%' }}>
                      {' '}
                      <InputField
                        id="status"
                        label="Dạng giảm"
                        select
                        size="small"
                        value={valuePrice}
                        inputProps={{ readOnly: true }}
                        SelectProps={{ IconComponent: () => null }}
                        onChange={handleChangePrice}
                      >
                        {promotionPrice.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                    </StyleInput>
                    {valuePrice === 'Giảm tiền' ? (
                      <div>
                        <StyleContent>
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Giảm
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '110px' }}>
                            <InputField
                              defaultValue={data.discount}
                              multiline
                              size="small"
                              inputProps={{ readOnly: true }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    đ
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </StyleContentInput>
                          <StyleContentText style={{ marginLeft: '8px' }}>
                            trên tổng đơn
                          </StyleContentText>
                        </StyleContent>

                        <StyleContent style={{ marginTop: '20px' }}>
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Đơn tối thiểu
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '110px' }}>
                            <InputField
                              defaultValue={data.minOrderAmount}
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    đ
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{ readOnly: true }}
                              sx={{ textAlign: 'right' }}
                            />
                          </StyleContentInput>
                        </StyleContent>
                      </div>
                    ) : (
                      <div>
                        <StyleContent style={{ margin: '-10px 0' }}>
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Giảm
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '110px' }}>
                            <InputField
                              size="small"
                              defaultValue={data.percent}
                              type="number"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{ readOnly: true }}
                              sx={{ textAlign: 'right' }}
                            />
                          </StyleContentInput>
                          <StyleContentText style={{ marginLeft: '8px' }}>
                            trên tổng đơn
                          </StyleContentText>
                        </StyleContent>

                        <StyleContent style={{ marginTop: '20px' }}>
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Đơn tối thiểu
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '110px' }}>
                            <InputField
                              defaultValue={data.minOrderAmount}
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    đ
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{ readOnly: true }}
                              sx={{ textAlign: 'right' }}
                            />
                          </StyleContentInput>
                        </StyleContent>

                        <StyleContent style={{ marginTop: '20px' }}>
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Giảm tối đa
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '110px' }}>
                            <InputField
                              defaultValue={data.maxAmount}
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    đ
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{ readOnly: true }}
                              sx={{ textAlign: 'right' }}
                            />
                          </StyleContentInput>
                        </StyleContent>
                      </div>
                    )}
                  </StyleChoose>
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleTitle>Đối tượng áp dụng</StyleTitle>
                  <InputField
                    id="status"
                    label="Đối tượng áp dụng"
                    select
                    sx={{ textAlign: 'left' }}
                    style={{ marginTop: '-10px' }}
                    value={valueObject}
                    inputProps={{ readOnly: true }}
                    SelectProps={{ IconComponent: () => null }}
                    onChange={handleChangeObject}
                  >
                    {promotionObject.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </InputField>
                  {valueObject === 'Hạng khách hàng' && (
                    <StyleObject>
                      <StyleItem style={{ width: '80%' }}>
                        <StyleTitle>Hạng khách hàng</StyleTitle>
                        <Autocomplete
                          multiple
                          id="checkboxes-tags-demo"
                          options={levelUser}
                          fullWidth
                          readOnly
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.title}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.title}
                            </li>
                          )}
                          onChange={(event, value) => {
                            setSelectedLevel(value)
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Hạng khách hàng" />
                          )}
                        />
                        {/* <StyleError>{errorProvinces}</StyleError> */}
                      </StyleItem>
                    </StyleObject>
                  )}
                  {valueObject === 'Nhóm khách hàng' && (
                    <StyleObject>
                      <StyleItem style={{ width: '80%' }}>
                        <StyleTitle>Nhóm khách hàng</StyleTitle>
                        <Autocomplete
                          multiple
                          id="checkboxes-tags-demo"
                          options={groupUser}
                          fullWidth
                          readOnly
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.title}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.title}
                            </li>
                          )}
                          onChange={(event, value) => {
                            setSelectedGroups(value)
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Nhóm khách hàng" />
                          )}
                        />
                        {/* <StyleError>{errorProvinces}</StyleError> */}
                      </StyleItem>
                    </StyleObject>
                  )}
                  {valueObject === 'Khách hàng cụ thể' && (
                    <StyleObject>
                      <StyleItem style={{ width: '80%' }}>
                        <StyleTitle>Khách hàng cụ thể</StyleTitle>
                        {selectedUsers.length && (
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            readOnly
                            options={selectUsers}
                            defaultValue={selectedUsers}
                            getOptionLabel={(option) =>
                              `${option?.fullName}  (${option?.phone})`
                            }
                            disableClearable
                            noOptionsText="Chưa có lựa chọn"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Khách hàng cụ thể"
                              />
                            )}
                            onInputChange={handleInputChange}
                            onChange={(event, value: any) => {
                              setSelectedUsers([...value])
                            }}
                          />
                        )}
                        {/* <StyleError>{errorProvinces}</StyleError> */}
                      </StyleItem>
                    </StyleObject>
                  )}
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleTitle>Phạm vi áp dụng</StyleTitle>
                  <StyleTitle>Quốc gia</StyleTitle>
                  <StyleInput>
                    {' '}
                    {valueNation && (
                      <InputField
                        id="status"
                        label="Quốc gia"
                        value={valueNation}
                        sx={{ textAlign: 'left' }}
                        inputProps={{ readOnly: true }}
                        SelectProps={{ IconComponent: () => null }}
                      ></InputField>
                    )}
                  </StyleInput>
                  <StyleTitle style={{ marginTop: '14px' }}>
                    Tỉnh/ Thành phố
                  </StyleTitle>
                  {selectedRange?.length && (
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={selectRange}
                      readOnly
                      fullWidth
                      disableCloseOnSelect
                      defaultValue={selectedRange}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                      onChange={(event, value) => {
                        setSelectedRange(value)
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Phạm vi áp dụng" />
                      )}
                    />
                  )}
                  {/* <StyleError>{errorProvinces}</StyleError> */}
                </StyleItem>
                <Stack spacing={2} direction="row" sx={{ paddingTop: '10px' }}>
                  <Button
                    variant="outlined"
                    onClick={() => navigate('/marketing/promotion-code/')}
                  >
                    Quay lại
                  </Button>
                </Stack>
              </form>
            ) : (
              <LoadingVersion2 />
            )}
          </Item>
        </Grid>
      </Grid>
    </>
  )
}

export default PromotionDetail
