import * as React from 'react'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import {
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate, useLocation } from 'react-router-dom'
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import { STRUCTURE } from 'constants/sidebarStructure'
import { servicesAPI } from 'services/servicesAPI'
import { useSidebar } from 'contexts/SideBarContext'

const drawerWidth = 248
const textColor = '#583985'

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

interface ISidebar {
  open: boolean
  handleDrawerOpen: () => void
}
interface IChildren {
  id: number
  label: string
  link: string
}
const Sidebar = ({ open, handleDrawerOpen }: ISidebar) => {
  const { isListUpdated, setIsListUpdated } = useSidebar()
  const fetchData = async () => {
    const { data } = await servicesAPI.getServices()
    const list = data.map((item: IServices, index: number) => {
      return {
        id: index + 1,
        label: item.name,
        link: `/service/${item.id}`,
      }
    })
    if (list) {
      const indexService = STRUCTURE.findIndex((obj) => obj.id == 9)
      STRUCTURE[indexService].children = list
    }
    setIsListUpdated(false)
  }

  React.useEffect(() => {
    fetchData()
  }, [isListUpdated])

  const [expanded, setExpanded] = React.useState(STRUCTURE.map(() => false))
  const location = useLocation()
  const navigate = useNavigate()
  const activeColor = (link?: string, color = '#f6f7ff') => {
    if (location.pathname === link) {
      return {
        color,
        background: 'var(--color-background-linear)',
      }
    }
    return {
      color: textColor,
      // background: color,
    }
  }

  const handleChange = (panel: number) => {
    setExpanded((prev: boolean[]) => {
      const clar = [...prev]
      clar[panel] = !clar[panel]
      return clar
    })
  }
  const handleItemClick = (index: number) => {
    if (!open) {
      handleDrawerOpen()
    }
    handleChange(index)
  }
  React.useEffect(() => {
    if (!open) {
      setExpanded(STRUCTURE.map(() => false))
    }
  }, [open])
  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader></DrawerHeader>
      <Divider />
      <List sx={{ maxWidth: '260px !important' }}>
        {STRUCTURE.map((item, index) => {
          return item?.children === undefined ? (
            <ListItem key={item.id} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => {
                  navigate(item.link)
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  background: activeColor(item.link).background,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: activeColor(item.link).color,
                  }}
                >
                  {item?.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item?.label}
                  sx={{
                    ml: -1,
                    opacity: open ? 1 : 0,
                    color: activeColor(item.link).color,
                  }}
                  className="font-sideBar"
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <ListItem key={item.id} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => {
                  handleItemClick(index)
                }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  paddingRight: '10px',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    color: textColor,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item?.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item?.label}
                  sx={{
                    opacity: open ? 1 : 0,
                    ml: -1,
                    color: textColor,
                  }}
                  className="font-sideBar"
                />
                {open &&
                  (expanded[index] ? (
                    <ExpandLess sx={{ color: textColor }} />
                  ) : (
                    <ExpandMore sx={{ color: textColor }} />
                  ))}
              </ListItemButton>
              <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                {item?.children?.map((child: IChildren) => (
                  <List key={child.id} component="div" disablePadding>
                    <ListItemButton
                      sx={{
                        background: activeColor(child.link).background,
                        paddingLeft: '26px',
                      }}
                      onClick={() => {
                        navigate(child.link)
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                          color: activeColor(child.link).color,
                        }}
                      >
                        <FiberManualRecordOutlinedIcon
                          sx={{ fontSize: '12px' }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={child.label}
                        sx={{
                          opacity: open ? 1 : 0,
                          marginLeft: '-2px',
                          color: activeColor(child.link).color,
                        }}
                        className="font-sideBar"
                      />
                    </ListItemButton>
                  </List>
                ))}
              </Collapse>
            </ListItem>
          )
        })}
      </List>
    </Drawer>
  )
}

export default Sidebar
