import React from 'react'
import { Typography } from '@mui/material'
import pageNotFound from 'assets/images/PageNotFound.webp'
import { StyleContainer, StyleLink, StyleLinks } from './style'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <StyleContainer>
      <div>
        <Typography
          gutterBottom
          sx={{
            fontWeight: '500',
            fontSize: '34px',
            color: '#4c4a54',
            marginBottom: '11px',
            textAlign: 'center',
          }}
        >
          Không tìm thấy trang :(
        </Typography>
        <Typography
          gutterBottom
          sx={{ fontSize: '16px', color: '#87858d', marginBottom: '12px' }}
        >
          Oops! 😖 Không tìm thấy URL được yêu cầu trên máy chủ này.
        </Typography>
      </div>
      <StyleLinks onClick={() => navigate('/')}>
        <StyleLink>Quay về trang chủ</StyleLink>
      </StyleLinks>
      <img
        src={pageNotFound}
        alt="My Image"
        style={{ marginTop: '40px', maxWidth: '200px' }}
      />
    </StyleContainer>
  )
}

export default PageNotFound
