import AddCircleIcon from '@mui/icons-material/AddCircle'
import BasicModal from 'components/Modal'
import React, { useEffect, useState } from 'react'
import {
  StyleHeaderItem,
  StyleLink,
  StyleLinks,
  StyleTableCell,
  StyleTableCellItem,
  StyleTitle,
} from './style'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useAlert } from 'contexts/AlertContext'
import staffApi from 'services/staffAPI'
import LabelCreate from '../LabelCreate'
import LabelEdit from '../LabelEdit'
import Close from 'components/Icons/components/Close'

interface IProps {
  modalLabel: boolean
  setModalLabel: any
  resetData: any
}

const LabelList: React.FC<IProps> = (props) => {
  const { modalLabel, setModalLabel, resetData } = props
  const [modalLabelCreate, setModalLabelCreate] = useState(false)
  const [modalLabelEdit, setModalLabelEdit] = useState(false)
  const [modalOpenDelete, setModalOpenDelete] = useState(false)
  const [status, setStatus] = useState({
    id: '',
  })

  const [idLabel, setIdLabel] = useState({ id: '' })
  const [selectLabel, setSelectLabel] = useState<any[]>([])
  const { setAlertSuccess, setAlertError } = useAlert()

  const fetchDataLabel = async () => {
    const { data } = await staffApi.getLabel()
    setSelectLabel(data)
  }

  const handleCloseDelete = () => {
    setModalOpenDelete(false)
  }

  const handleOnclickGetId = (id?: string) => {
    if (id) {
      setModalOpenDelete(true)
      setStatus({
        id,
      })
    }
  }

  const handleOnclickGetIdEdit = (id?: string) => {
    if (id) {
      setModalLabelEdit(true)
      setIdLabel({
        id,
      })
    }
  }

  const handleDelete = async (id: string) => {
    try {
      await staffApi.deleteLabel(id)
      setModalOpenDelete(false)
      setAlertSuccess('Xóa nhãn thành công')
      fetchDataLabel()
    } catch (err) {
      setAlertError('Xoá nhãn không thành công')
    }
  }

  const hexToRGBA = (hex: string, alpha: number): string => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  useEffect(() => {
    fetchDataLabel()
  }, [modalLabelCreate, modalLabelEdit])

  return (
    <>
      <BasicModal
        open={modalLabel}
        handleClose={() => {
          setModalLabel(false)
          resetData()
        }}
      >
        <StyleHeaderItem>
          <StyleTitle>Thiết lập giá trị</StyleTitle>
          <Close
            onClick={() => {
              setModalLabel(false)
              resetData()
            }}
          />
        </StyleHeaderItem>
        <div
          style={{ width: '130px', marginTop: '24px' }}
          onClick={() => setModalLabelCreate(true)}
        >
          <StyleLinks>
            <AddCircleIcon sx={{ marginRight: '10px', color: 'white' }} />
            <StyleLink>Thêm giá trị</StyleLink>
          </StyleLinks>
        </div>

        <TableContainer
          component={Paper}
          className="box-shadow"
          sx={{ maxHeight: '400px', overflow: 'auto' }}
        >
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow
                sx={{
                  position: 'sticky',
                  top: '0px',
                  background: 'white',
                }}
              >
                <StyleTableCell align="center" sx={{ padding: '0px' }}>
                  STT
                </StyleTableCell>
                <StyleTableCell align="center">Giá trị</StyleTableCell>
                <StyleTableCell align="center">Chức năng</StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectLabel?.map((row: any, index: number) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{ padding: '0px' }}
                  >
                    {index + 1}
                  </TableCell>

                  <TableCell align="center">
                    <StyleTableCellItem
                      style={{
                        color: `${hexToRGBA(row?.color, 1)}`,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          padding: '2px 12px',
                          backgroundColor: `${hexToRGBA(row?.color, 0.2)}`,
                          borderRadius: '50px',
                        }}
                      >
                        {row?.name}
                      </div>
                    </StyleTableCellItem>
                  </TableCell>
                  <TableCell align="center">
                    <StyleTableCellItem
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        justifyContent: 'center',
                      }}
                    >
                      <DriveFileRenameOutlineOutlinedIcon
                        color="primary"
                        sx={{
                          fontSize: '25px',
                          '&:hover': {
                            cursor: 'pointer',
                            transform: 'scale(1.3)',
                          },
                        }}
                        onClick={() => handleOnclickGetIdEdit(row?.id)}
                      />

                      <DeleteOutlineOutlinedIcon
                        color="error"
                        sx={{
                          fontSize: '25px',
                          '&:hover': {
                            cursor: 'pointer',
                            transform: 'scale(1.3)',
                          },
                        }}
                        onClick={() => handleOnclickGetId(row?.id)}
                      />
                    </StyleTableCellItem>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <BasicModal open={modalOpenDelete} handleClose={handleCloseDelete}>
            <Typography>Bạn có chắc chắn muốn xoá nhãn này?</Typography>
            <Button
              sx={{ margin: '8px', marginBottom: '0px' }}
              variant="contained"
              color="error"
              onClick={() => handleDelete(status.id)}
            >
              Xóa
            </Button>
            <Button
              sx={{ margin: '8px', marginBottom: '0px' }}
              onClick={handleCloseDelete}
              variant="outlined"
            >
              Hủy
            </Button>
          </BasicModal>
        </TableContainer>
      </BasicModal>
      <LabelCreate
        modalLabelCreate={modalLabelCreate}
        setModalLabelCreate={setModalLabelCreate}
      />
      <LabelEdit
        id={idLabel}
        modalLabelEdit={modalLabelEdit}
        setModalLabelEdit={setModalLabelEdit}
      />
    </>
  )
}

export default LabelList
