import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Stack, Typography } from '@mui/material'
import InputField from 'components/FormControl/InputField'
import BasicModal from 'components/Modal'
import React from 'react'
import { useForm } from 'react-hook-form'
import { relativeSchema_ } from 'validations/partTimeStaffSchema'
import { StyleError, StyleInput } from './style'

interface IProps {
  modalRelativesEdit: boolean
  setModalRelativesEdit: any
  DataRelativesEdit?: any
  DataRelativesEditAfter?: any
}

const RelativesEdit: React.FC<IProps> = (props) => {
  const {
    modalRelativesEdit,
    setModalRelativesEdit,
    DataRelativesEdit,
    DataRelativesEditAfter,
  } = props

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(relativeSchema_),
    mode: 'onChange',
  })

  const onSubmit = (data: any) => {
    DataRelativesEditAfter({ ...data })
  }

  return (
    <BasicModal
      open={modalRelativesEdit}
      handleClose={() => {
        setModalRelativesEdit(false)
        reset()
        clearErrors()
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'flex' }}>
          <Typography variant="h5" gutterBottom>
            Sửa thông tin người thân
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
          <StyleInput>
            <InputField
              {...register('relationship')}
              label="Quan hệ với cộng tác viên"
              defaultValue={DataRelativesEdit[0]?.relationship}
              size="small"
            />
            <StyleError>{errors.relationship?.message as string}</StyleError>
          </StyleInput>

          <StyleInput>
            <InputField
              {...register('fullName')}
              label="Họ và tên"
              defaultValue={DataRelativesEdit[0]?.fullName}
              size="small"
            />
            <StyleError>{errors.fullName?.message as string}</StyleError>
          </StyleInput>
        </div>
        <StyleInput>
          <InputField
            {...register('phone')}
            label="Số điện thoại"
            defaultValue={DataRelativesEdit[0]?.phone}
            size="small"
          />
          <StyleError>{errors.phone?.message as string}</StyleError>
        </StyleInput>

        <StyleInput>
          <InputField
            {...register('address')}
            label="Địa chỉ"
            defaultValue={DataRelativesEdit[0]?.address}
            size="small"
          />
          <StyleError>{errors.address?.message as string}</StyleError>
        </StyleInput>
        <InputField
          {...register('note')}
          label="Ghi chú"
          defaultValue={DataRelativesEdit[0]?.note}
          size="small"
        />
        <Stack
          spacing={2}
          direction="row"
          sx={{
            justifyContent: 'flex-start',
            paddingTop: '14px',
          }}
        >
          <Button variant="contained" type="submit" className="bgr-global">
            Lưu
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setModalRelativesEdit(false)
              reset()
              clearErrors()
            }}
          >
            Huỷ
          </Button>
        </Stack>
      </form>
    </BasicModal>
  )
}

export default RelativesEdit
