import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'

import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import Item from 'components/Item'
import BasicModal from 'components/Modal'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import rangeOfActiveApi from 'services/houseCleaningAPI'
import { checkChangeData } from 'utils/confirmBox'
import { StyleError, StyleNameRange, StyleTitle, StyleValidate } from './style'

const RangeOfActiveEdit = () => {
  const [data, setData] = useState([])
  const paramIds = useParams()
  const navigate = useNavigate()
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertClose, setAlertError, setAlertSuccess } = useAlert()
  const [modalCancel, setModalCancel] = useState<boolean>(false)

  const { getValues } = useForm()

  const [errorProvinces, setErrorProvinces] = useState('')
  const [selectCountries, setSelectCountries] = useState<any[]>([])
  const [selectProvinces, setSelectProvinces] = useState<any[]>([])
  const [selectedCountries, setSelectedCountries] = useState<any>()
  const [selectedProvinces, setSelectedProvinces] = useState<any>([])

  const fetchDataCountries = async () => {
    const { data } = await rangeOfActiveApi.getCountries()
    const dataFormat = data?.map((itemCountries: any) => {
      return { ...itemCountries, title: itemCountries.native }
    })
    setSelectCountries(dataFormat)
  }

  const fetchDataProvinces = async () => {
    const idCountries = selectedCountries.id
    if (idCountries) {
      const { data } = await rangeOfActiveApi.getProvincesById(
        idCountries as string
      )

      if (data) {
        const dataFormat = data?.map((itemProvinces: any) => {
          return {
            ...itemProvinces,
            title: itemProvinces.name,
          }
        })
        setSelectProvinces(dataFormat)
      } else {
        setSelectProvinces([])
        setAlertError('Quốc gia mà bạn chọn chưa có dữ liệu!')
      }
    }
  }
  const handleCountries = (event: any, value: any) => {
    if (value === null) {
      setSelectedCountries([])
      setSelectedProvinces([])
    } else {
      setSelectedCountries(value)
    }
  }

  const handleProvinces = (event: any, value: any) => {
    setSelectedProvinces(value)
    setErrorProvinces('')
  }

  const handleClose = () => {
    setModalCancel(false)
  }

  const handleCheckDataCancel = () => {
    const getValuesItem = getValues()
    const isChanged = checkChangeData({
      dataChange: getValuesItem,
      dataDefault: data,
    })

    if (!isChanged) {
      setModalCancel(true)
    } else {
      navigate(`/service/housecleaning/${paramIds.serviceId}/range-of-active`)
    }
  }

  const onSubmit = async (event: any) => {
    event.preventDefault()

    // const provinceIds = selectedProvinces?.map((provinces: any) => provinces.id)
    const provinceIds = selectedProvinces?.map((province: any) => province.id)
    const dataSubmit = {
      // country: selectedCountries,
      // provinceIds,
      provinceIds: provinceIds,
      serviceId: paramIds.serviceId,
    }

    if (selectedProvinces.length == 0) {
      setErrorProvinces('Vui lòng chọn tỉnh/thành phố')
    } else {
      setLoadingTrue()
      setAlertClose()
      setLoadingFalse()
      try {
        await rangeOfActiveApi.UpdateRangeOfActive(dataSubmit)
        setAlertSuccess('Lưu phạm vi hoạt động thành công')
        setLoadingFalse()
        navigate(`/service/housecleaning/${paramIds.serviceId}/range-of-active`)
      } catch (error) {
        setLoadingFalse()
        setAlertError('Lưu phạm vi hoạt động thất bại')
      }
    }
  }

  useEffect(() => {
    fetchDataCountries()
  }, [])

  useEffect(() => {
    fetchDataProvinces()
  }, [selectedCountries])

  useEffect(() => {
    const getCharacters = async () => {
      const { data } = await rangeOfActiveApi.getRangeOfActiveById({
        serviceId: paramIds.serviceId,
      })
      setData(data)

      setSelectedCountries({
        ...data[0]?.country,
        title: data[0]?.country?.native,
      })

      setSelectedProvinces(
        data[0]?.province?.map((itemProvinces: any) => {
          return {
            ...itemProvinces?.province,
            title: itemProvinces?.province?.name,
          }
        })
      )
      const result: any = []
      data[0]?.province
        ?.map((itemProvinces: any) =>
          itemProvinces?.districts?.map((itemDistricts: any) => {
            return {
              ...itemDistricts?.district,
              title: itemDistricts?.district?.name,
            }
          })
        )
        .flat()
        .forEach((obj: any) => {
          result.push(obj)
        })
    }
    getCharacters().catch(console.error)
  }, [])

  if (!selectedCountries || !selectedProvinces) {
    return <></>
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="padding-bottom">
        <Item sx={{ padding: '12px 24px' }} className="bd-radius">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              sx={{
                textTransform: 'capitalize',
                cursor: 'default',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
            >
              Quản lý dịch vụ
            </Link>
            <Link
              sx={{
                textTransform: 'capitalize',
                cursor: 'pointer',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
              onClick={() => navigate(`/service/${paramIds.serviceId}`)}
            >
              Dọn dẹp nhà gói lẻ
            </Link>
            <Link
              sx={{
                textTransform: 'capitalize',
                cursor: 'pointer',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
              onClick={() =>
                navigate(
                  `/service/housecleaning/${paramIds.serviceId}/range-of-active`
                )
              }
            >
              Phạm vi hoạt động
            </Link>
            <Link
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                cursor: 'default',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
            >
              Việt Nam
            </Link>
          </Breadcrumbs>
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item
          sx={{
            padding: '24px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
          }}
          component="form"
          onSubmit={onSubmit}
        >
          <StyleNameRange>
            <StyleTitle>Quốc Gia</StyleTitle>
          </StyleNameRange>

          <Autocomplete
            options={selectCountries}
            readOnly
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            noOptionsText="Danh sách rỗng"
            defaultValue={selectedCountries}
            onChange={handleCountries}
            renderInput={(params) => (
              <TextField {...params} label="Quốc gia" placeholder="Quốc gia" />
            )}
            disablePortal
            sx={{ width: 800 }}
          />

          <StyleNameRange>
            <StyleTitle>Tỉnh/Thành Phố</StyleTitle>
          </StyleNameRange>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={
              selectedCountries == null || selectedCountries.length == 0
                ? []
                : selectProvinces
            }
            sx={{ width: 800 }}
            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            noOptionsText="Danh sách rỗng"
            value={selectedProvinces}
            onChange={handleProvinces}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                {option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tỉnh/Thành phố"
                placeholder="Tỉnh/Thành phố"
              />
            )}
          />
          <StyleValidate>
            <StyleError>{errorProvinces}</StyleError>
          </StyleValidate>

          <div style={{ width: '800px' }}>
            <Stack
              spacing={2}
              direction="row"
              sx={{
                justifyContent: 'flex-start',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ width: '99px' }}
                className="bgr-global"
              >
                Lưu
              </Button>
              <Button
                variant="outlined"
                sx={{ width: '100px' }}
                onClick={handleCheckDataCancel}
              >
                Huỷ
              </Button>
            </Stack>
            <BasicModal open={modalCancel} handleClose={handleClose}>
              <Typography>Bạn có chắc chắn muốn hủy thay đổi?</Typography>
              <Button
                sx={{
                  margin: '20px',
                  marginBottom: '0px',
                  paddingLeft: '43px',
                  paddingRight: '43px',
                }}
                variant="contained"
                className="bgr-global"
                onClick={() =>
                  navigate(
                    `/service/housecleaning/${paramIds.serviceId}/range-of-active`
                  )
                }
              >
                Hủy
              </Button>
              <Button
                sx={{
                  margin: '20px',
                  marginBottom: '-0.5px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
                onClick={() => setModalCancel(false)}
                variant="outlined"
              >
                Không
              </Button>
            </BasicModal>
          </div>
        </Item>
      </Grid>
    </Grid>
  )
}

export default RangeOfActiveEdit
