export interface IPerformance {
  workComplete?: string
  attendanceRate?: string
  star?: string
  violation?: string
  rejectHours?: string
  corporateActions?: string
  corporateInaction?: string
  workingHours?: string
  cancelOrder?: string
}

export const performanceName: IPerformance = {
  workComplete: 'Số công việc',
  attendanceRate: 'Tỷ lệ chuyên cần',
  star: 'Trung bình sao',
  violation: 'Lỗi vi phạm ',
  rejectHours: 'Số giờ huỷ ca',
  corporateActions: 'Số lần tham gia hoạt động của công ty',
  corporateInaction: 'Số lần không tham gia hoạt động của công ty',
  workingHours: 'Số giờ làm việc',
  cancelOrder: 'Số lần huỷ ca',
}
