import styled from 'styled-components'

export const StyleValidate = styled.div`
  width: 800px;
`

export const StyleError = styled.p`
  display: flex;
  color: red;
  font-size: 14px;
  white-space: normal;
`
export const StyleNameRange = styled.div`
  display: flex;
  width: 800px;
  justify-content: flex-start;
`

export const StyleTitle = styled.span`
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
`
