import styled from 'styled-components';
import { Paper, TableCell } from '@mui/material';

export const StyleTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 23px;
`;

export const StyleHeaderItem = styled.div`
 
`;

export const StyleTitle = styled.h2`
  font-size: 25px;
  color: var(--color-primary);
  font-weight: bold;
`;

export const StyleTableCell = styled(TableCell)`
  font-weight: bold !important;
  color: var(--color-secondary) !important;
`

export const StyleFilter = styled.div`
  display:flex;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
`

export const StyleFilterItem = styled.div`
  height: 100% !important;
  margin: 0px !important;
`

export const StyleFilterItemSearch = styled.div`
`

export const StylePaper = styled(Paper)`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  height: 100%;
  padding: 24px;
`