//Banner

export const BANNER_LIST = [
  { name: 'Quản Lý Marketing' },
  { name: 'Danh Sách Banner' },
]
export const BANNER_ADD = [
  { name: 'Quản Lý Marketing' },
  { name: 'Danh sách Banner', path: '/marketing/banners' },
  { name: 'Thêm banner' },
]
export const BANNER_EDIT = [
  { name: 'Quản Lý Marketing' },
  { name: 'Danh sách Banner', path: '/marketing/banners' },
  { name: 'Sửa banner' },
]
export const BANNER_DETAIL = [
  { name: 'Quản Lý Marketing' },
  { name: 'Danh sách Banner', path: '/marketing/banners' },
  { name: 'Chi tiết banner' },
]

//Chiến dịch marketing
export const CAMPAIGN_LIST = [{ name: 'Chiến dịch' }]
export const MARKETING_DASHBOARD = [{ name: 'Dash board' }]
export const CAMPAIGN_ADD = [
  { name: 'Chiến dịch', path: '/marketing/campaign' },
  { name: 'Thêm chiến dịch' },
]
export const CAMPAIGN_DETAIL = [
  { name: 'Chiến dịch', path: '/marketing/campaign' },
  { name: 'Chi tiết chiến dịch' },
]
export const CAMPAIGN_EDIT = [
  { name: 'Chiến dịch', path: '/marketing/campaign' },
  { name: 'Sửa chiến dịch' },
]
export const PROFILE_EDIT = [{ name: 'Thông tin cá nhân' }]

export const MARKETING_LEAD = [{ name: 'Cơ hội(Lead)' }]
export const MARKETING_REPORT = [{ name: 'Báo cáo thống kê' }]
export const MARKETING_PROMOTION = [{ name: 'Khuyến mãi' }]

export const NEWS_LIST = [{ name: 'Blog' }]
export const NEWS_ADD = [
  { name: 'Blog', path: '/marketing/news' },
  { name: 'Thêm Blog' },
]
export const NEWS_EDIT = [
  { name: 'Blog', path: '/marketing/news' },
  { name: 'Sửa Blog' },
]
export const NEWS_DETAIL = [
  { name: 'Blog', path: '/marketing/news' },
  { name: 'Chi tiết Blog' },
]

//Quản lý dịch vụ
export const SERVICE_LIST = [
  { name: 'Quản lý dịch vụ' },
  { name: 'Dọn dẹp nhà gói lẻ' },
]
export const SERVICE_RANGE_OF_ACTIVE = [
  { name: 'Quản lý dịch vụ' },
  { name: 'Dọn dẹp nhà gói lẻ', path: '/service/Don-dep-nha-goi-le' },
  { name: 'Phạm vi hoạt động' },
]

export const SERVICE_RANGE_OF_ACTIVE_ADD = [
  { name: 'Quản lý dịch vụ' },
  { name: 'Dọn dẹp nhà gói lẻ', path: '/service/Don-dep-nha-goi-le' },
  { name: 'Phạm vi hoạt động', path: '/service/housecleaning/range-of-active' },
  { name: 'Thêm mới' },
]

export const SERVICE_RANGE_OF_ACTIVE_EDIT = [
  { name: 'Quản lý dịch vụ' },
  { name: 'Dọn dẹp nhà gói lẻ', path: '/service/Don-dep-nha-goi-le' },
  { name: 'Phạm vi hoạt động', path: '/service/housecleaning/range-of-active' },
  { name: 'Sửa' },
]
//dọn dẹp nhà gói lẻ
export const SERVICE_HOME_CLEANING = [{ name: 'Dọn dẹp nhà ca lẻ' }]
export const SERVICE_HOME_CLEANING_PRICE = [
  { name: 'Dọn dẹp nhà gói lẻ', path: '/service/Don-dep-nha-goi-le' },
  { name: 'Cấu hình giá', path: '/service/Don-dep-nha-goi-le/configurations' },
]

export const SERVICE_HOME_CLEANING_PRICE_COUNTRY = [
  { name: 'Dọn dẹp nhà gói lẻ', path: '/service/Don-dep-nha-goi-le' },
  { name: 'Cấu hình giá', path: '/service/Don-dep-nha-goi-le/nation' },
  {
    name: 'Chi tiết từng khu vực',
    path: '/service/Don-dep-nha-goi-le/nation/district',
  },
]

//Quản lý khách hàng
export const USER_LIST = [{ name: 'Khách hàng' }]
export const USER_DETAIL = [
  { name: 'Khách hàng', path: '/user' },
  { name: 'Chi tiết khách hàng' },
]

//Chăm sóc khách hàng
export const CARE_SCHEDULE = [{ name: 'Xem lịch CSKH' }]
export const CARE_ASSIGNMENT = [{ name: 'Phân công nhân viên' }]
export const CARE_NOTIFICATION = [{ name: 'Thông báo tự động' }]
export const CARE_SUPPORT = [{ name: 'Hỗ trợ khách hàng' }]
export const CARE_TICKET = [{ name: 'Quản lý ticket' }]

//Quản lý đơn hàng
export const ORDER_LIST = [{ name: 'Đơn hàng' }]
export const ORDER_ADD = [
  { name: 'Đơn hàng', path: '/order' },
  { name: 'Thêm đơn hàng' },
]
export const ORDER_DETAIL = [
  { name: 'Đơn hàng', path: '/order' },
  { name: 'Chi tiết đơn hàng' },
]
export const ORDER_EDIT = [
  { name: 'Đơn hàng', path: '/order' },
  { name: 'Sửa đơn hàng' },
]
export const ORDER_ASSIGNMENT = [{ name: 'Phân công theo đơn' }]

//Quản lý nhân viên
export const STAFF_DASHBOARD = [{ name: 'Dash board' }]
export const STAFF_RECRUITMENT = [{ name: 'Tuyển dụng' }]
export const STAFF_LIST = [{ name: 'Danh sách cộng tác viên' }]
export const STAFF_SCHEDULE = [{ name: 'Lịch nghỉ/làm việc' }]
export const STAFF_TIMEKEEPING = [{ name: 'Chấm công' }]
export const STAFF_SALARY = [{ name: 'Lương thưởng' }]
export const STAFF_EVALUATION = [{ name: 'Đánh giá hiệu suât' }]
export const STAFF_SUPPORT = [{ name: 'Hỗ trợ nhân viên' }]
export const STAFF_TRAIN = [{ name: 'Đào tạo và phát triển' }]
export const STAFF_ACCOUNT = [{ name: 'Quản lý tài khoản' }]
export const STAFF_AUTHORIZATION = [{ name: 'Phân quyền tài khoản' }]

//Quản lý đối tác
export const PARTNER_DASHBOARD = [{ name: 'Dash board' }]
export const PARTNER_LIST = [{ name: 'Danh sách đối tác' }]
export const PARTNER_DISCOUNT = [{ name: 'Chiết khấu' }]
export const PARTNER_EVALUATION = [{ name: 'Đánh giá hiệu suất' }]
export const PARTNER_SUPPORT = [{ name: 'Hỗ trợ đối tác' }]

//Quản lý request
export const REQUEST_REST = [{ name: 'Nghỉ' }]
export const REQUEST_RESCHEDULE = [{ name: 'Đổi lịch' }]
export const REQUEST_SALARY = [{ name: 'Ứng lương' }]
export const REQUEST_COMMON = [{ name: 'Request chung' }]

// Tài chính-kế toán
export const FINANCE_RESULT = [{ name: 'Kết quả kinh doanh' }]
export const FINANCE_REVENUE = [{ name: 'Khoản thu-chi' }]
export const FINANCE_SALARY = [{ name: 'Lương' }]
export const FINANCE_BUDGET = [{ name: 'Ngân sách' }]
export const FINANCE_WAREHOUSE = [{ name: 'Hàng hoá và kho' }]
export const FINANCE_DOCUMENT = [{ name: 'Tài liệu' }]

//Trợ giúp
export const HELP_HANBOOK = [{ name: 'Sổ tay nhân sự' }]
export const HELP_POLICIES = [{ name: 'Quy định & chính sách' }]

//Quản lý khuyến mãi
export const PROMOTION_CODE = [
  { name: 'Quản lý Marketing' },
  { name: 'Quản lý khuyến mãi' },
]
export const PROMOTION_CODE_CREATE = [
  { name: 'Quản lý marketing' },
  { name: 'Quản lý khuyến mãi', path: '/marketing/promotion-code' },
  { name: 'Thêm khuyến mãi' },
]

//Danh sách cộng tác viên
export const PART_TIME_STAFF = [
  { name: 'Quản lý nhân viên' },
  { name: 'Danh sách cộng tác viên' },
]
export const PART_TIME_STAFF_DETAIL = [
  { name: 'Quản lý nhân viên' },
  {
    name: 'Danh sách cộng tác viên',
    path: '/staff-manager/part-time-staff',
  },
  { name: 'Chi tiết cộng tác viên' },
]

export const PART_TIME_STAFF_CREATE_FILE = [
  { name: 'Quản lý nhân viên' },
  {
    name: 'Danh sách cộng tác viên',
    path: '/staff-manager/part-time-staff',
  },
  {
    name: 'Thêm mới cộng tác viên',
    path: '/staff-manager/part-time-staff/create',
  },
  { name: 'Bộ hồ sơ' },
]

export const PART_TIME_STAFF_EDIT_FILE = [
  { name: 'Quản lý nhân viên' },
  {
    name: 'Danh sách cộng tác viên',
    path: '/staff-manager/part-time-staff',
  },
  {
    name: 'Sửa cộng tác viên',
  },
  { name: 'Bộ hồ sơ' },
]

export const PART_TIME_STAFF_DETAIL_FILE = [
  { name: 'Quản lý nhân viên' },
  {
    name: 'Danh sách cộng tác viên',
    path: '/staff-manager/part-time-staff',
  },
  {
    name: 'Chi tiết cộng tác viên',
  },
  { name: 'Bộ hồ sơ' },
]

export const PART_TIME_STAFF_CREATE = [
  { name: 'Quản lý nhân viên' },
  {
    name: 'Danh sách cộng tác viên',
    path: '/staff-manager/part-time-staff',
  },
  { name: 'Thêm mới cộng tác viên' },
]

export const PART_TIME_STAFF_EDIT = [
  { name: 'Quản lý nhân viên' },
  {
    name: 'Danh sách cộng tác viên',
    path: '/staff-manager/part-time-staff',
  },
  { name: 'Sửa cộng tác viên' },
]
