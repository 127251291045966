import React, { createContext, useState, useContext } from 'react'

interface IChildren {
  children: React.ReactNode
}

interface IFileContext {
  fileData: any
  addFileData: (fileData: any) => void
  removeFileData: (fileData: any) => void
}

const FileContext = createContext<IFileContext>({
  fileData: [],
  addFileData: () => undefined,
  removeFileData: () => undefined,
})

const FileProvider = ({ children }: IChildren) => {
  const [fileData, setFileData] = useState<any>([])

  const addFileData = (data: any) => {
    setFileData([data])
    // setFileData([...fileData, data])
  }

  const removeFileData = (data: any) => {
    setFileData(fileData.filter((item: any) => item !== data))
  }

  return (
    <FileContext.Provider
      value={{
        fileData,
        addFileData,
        removeFileData,
      }}
    >
      {children}
    </FileContext.Provider>
  )
}
const useFile = () => {
  return useContext(FileContext)
}

export { FileProvider, useFile }
