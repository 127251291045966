export const styleModal = {
  position: 'absolute',
  maxHeight: '600px',
  minWidth: '600px',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',
  padding: '24px',
}
