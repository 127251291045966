import {
  Autocomplete,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import InputField from 'components/FormControl/InputField'
import BasicModal from 'components/Modal'
import { useAlert } from 'contexts/AlertContext'
import useDebounce from 'hooks/useDebounce'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import rangeOfActiveApi from 'services/houseCleaningAPI'
import staffApi from 'services/staffAPI'
import { StyleError } from './style'

interface IProps {
  modalLocationCreate: boolean
  setModalLocationCreate: any
  DataLocationCreate?: any
}

const LocationCreate: React.FC<IProps> = (props) => {
  const { modalLocationCreate, setModalLocationCreate, DataLocationCreate } =
    props
  const { register, handleSubmit, reset, clearErrors } = useForm<any>({
    // resolver: yupResolver(locationSchema_),
    mode: 'onChange',
  })
  const { setAlertError } = useAlert()
  const [selectLocation, setSelectLocation] = useState<any>([])
  const [keySearch, setKeySearch] = useState('')
  const [addLocation, setAddLocation] = useState<any>('')
  const debouncedValueMap = useDebounce<string>(keySearch, 500)
  const [errorCountries, setErrorCountries] = useState('')
  const [errorProvinces, setErrorProvinces] = useState('')
  const [errorDistricts, setErrorDistricts] = useState('')
  const [errorAddress, setErrorAddress] = useState('')

  const [selectCountries, setSelectCountries] = useState<any[]>([])
  const [selectProvinces, setSelectProvinces] = useState<any[]>([])
  const [selectDistricts, setSelectDistricts] = useState<any[]>([])

  const [selectedCountries, setSelectedCountries] = useState<any>([])
  const [selectedProvinces, setSelectedProvinces] = useState<any>([])
  const [selectedDistricts, setSelectedDistricts] = useState<any>([])

  const fetchDataCountries = async () => {
    const { data } = await rangeOfActiveApi.getCountries()
    setSelectCountries(data)
  }

  const fetchDataProvinces = async () => {
    const idCountries = selectedCountries?.id

    if (idCountries) {
      const { data } = await rangeOfActiveApi.getProvincesById(
        idCountries as string
      )
      if (data) {
        setSelectProvinces(data)
      } else {
        setSelectProvinces([])
        setAlertError('Quốc gia mà bạn chọn chưa có dữ liệu!')
      }
    }
  }

  const fetchDataDistricts = async () => {
    const idDistrict = selectedProvinces?.id

    if (idDistrict) {
      const { data } = await rangeOfActiveApi.getDistrictsById(
        idDistrict as string
      )
      if (data) {
        setSelectDistricts(data)
      } else {
        setSelectDistricts([])
        setAlertError('Thành phố mà bạn chọn chưa có dữ liệu!')
      }
    }
  }

  const handleCountries = (event: any, value: any) => {
    if (value === null) {
      setSelectedCountries([])
      setSelectedProvinces([])
      setSelectedDistricts([])
      setSelectDistricts([])
    } else {
      setSelectedCountries(value)
      setErrorCountries('')
    }
  }

  const handleProvinces = (event: any, value: any) => {
    setSelectedProvinces(value)
    setErrorProvinces('')
  }

  const handleDistrict = (event: any, value: any) => {
    setSelectedDistricts(value)
    setErrorDistricts('')
  }

  const handleInputChange = async (event: any, value: any) => {
    setKeySearch(event.target.value)
  }

  const onSubmit = (data: any) => {
    // e.preventDefault()
    // e.stoppropagation()
    if (selectedCountries.length == 0) {
      setErrorCountries('Vui lòng chọn quốc gia')
    } else if (selectedProvinces.length == 0) {
      setErrorProvinces('Vui lòng chọn tỉnh/thành phố')
    } else if (selectedDistricts.length == 0) {
      setErrorDistricts('Vui lòng chọn Quận/Huyện')
    } else if (addLocation.length == 0) {
      setErrorAddress('Vui lòng nhập địa chỉ cụ thể')
    } else {
      DataLocationCreate({
        country: selectedCountries,
        province: selectedProvinces,
        district: selectedDistricts,
        address: addLocation,
      })
      reset()
      clearErrors()
      setSelectedCountries([])
      setSelectedProvinces([])
      setSelectedDistricts([])
      setSelectProvinces([])
      setSelectDistricts([])
    }
  }

  useEffect(() => {
    fetchDataCountries()
  }, [])

  useEffect(() => {
    fetchDataProvinces()
  }, [selectedCountries])

  useEffect(() => {
    fetchDataDistricts()
  }, [selectedProvinces])

  useEffect(() => {
    if (keySearch) {
      setTimeout(() => {
        staffApi.getLocation(keySearch).then((response: any) => {
          if (response.data.result) {
            setSelectLocation(response.data.result)
          }
        })
      }, 500)
    }
  }, [debouncedValueMap])

  return (
    <BasicModal
      open={modalLocationCreate}
      handleClose={() => {
        setModalLocationCreate(false)
        reset()
        clearErrors()
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'flex' }}>
          <Typography variant="h5" gutterBottom>
            Thêm vị trí
          </Typography>
        </div>

        <Autocomplete
          size="small"
          disablePortal
          options={selectCountries}
          getOptionLabel={(option) => option.native || ''}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          noOptionsText="Danh sách rỗng"
          onChange={handleCountries}
          renderInput={(params) => (
            <InputField
              {...params}
              // {...register('country')}
              label="Quốc gia"
              placeholder="Quốc gia"
            />
          )}
        />

        <StyleError>{errorCountries}</StyleError>

        <Autocomplete
          size="small"
          disablePortal
          options={selectProvinces}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          noOptionsText="Danh sách rỗng"
          onChange={handleProvinces}
          renderInput={(params) => (
            <InputField
              {...params}
              {...register('province')}
              label="Tỉnh/Thành phố"
              placeholder="Tỉnh/Thành phố"
            />
          )}
        />

        <StyleError>{errorProvinces}</StyleError>

        <Autocomplete
          size="small"
          disablePortal
          options={selectDistricts}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          noOptionsText="Danh sách rỗng"
          onChange={handleDistrict}
          renderInput={(params) => (
            <InputField
              {...params}
              // {...register('district')}
              label="Quận/Huyện"
              placeholder="Quận/Huyện"
            />
          )}
        />
        <StyleError>{errorDistricts}</StyleError>

        <Autocomplete
          size="small"
          sx={{ padding: '10px 0' }}
          options={
            selectLocation ? selectLocation : [{ fulLocation: 'Option 1' }]
          }
          getOptionLabel={(option: any) => option.address}
          noOptionsText="Chưa có lựa chọn"
          renderInput={(params) => (
            <TextField
              // {...register('addLocation')}
              {...params}
              label="Địa chỉ làm việc"
            />
          )}
          onInputChange={handleInputChange}
          onChange={(event: any, newValue: any) => {
            setAddLocation(newValue)
            setErrorAddress('')
          }}
        />
        <StyleError>{errorAddress}</StyleError>

        <Stack
          spacing={2}
          direction="row"
          sx={{
            justifyContent: 'flex-start',
            paddingTop: '14px',
          }}
        >
          <Button variant="contained" type="submit" className="bgr-global">
            Lưu
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setModalLocationCreate(false)
              reset()
              clearErrors()
            }}
          >
            Huỷ
          </Button>
        </Stack>
      </form>
    </BasicModal>
  )
}

export default LocationCreate
