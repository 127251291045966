import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone'
import ConfirmationNumberTwoToneIcon from '@mui/icons-material/ConfirmationNumberTwoTone'
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone'
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone'
import DoneAllTwoToneIcon from '@mui/icons-material/DoneAllTwoTone'
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined'

export enum statusOrderEnum {
  WAITING = 'waiting',
  WAITINGCONFIRM = 'waitingConfirm',
  WAITINGMANUAL = 'waitingManual',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  DONE = 'done',
  STARTED = 'started',
}

export interface IObjectStatus {
  waiting: string
  waitingConfirm: string
  waitingManual: string
  approved: string
  rejected: string
  cancel: string
  done: string
  started?: string
}

export const statusOrder: IObjectStatus = {
  waiting: 'Chờ nhận việc - việc mới',
  waitingConfirm: 'Chờ xác nhận',
  waitingManual: 'Chờ xác nhận',
  approved: 'Chờ làm',
  started: 'Đang thực hiện',
  rejected: 'Đã hủy',
  cancel: 'Đã hủy',
  done: 'Đã hoàn thành',
}

export const colorStatus: IObjectStatus = {
  waiting: '#f0ad4e',
  waitingConfirm: '#5bc0de',
  waitingManual: '#5bc0de',
  approved: '#5cb85c',
  rejected: '#d9534f',
  cancel: '#d9534f',
  done: '#0275d8',
}

export const iconsStatus: Record<string, any> = {
  waiting: <HourglassTopTwoToneIcon sx={{ fontSize: '20px' }} />,
  waitingConfirm: <ConfirmationNumberTwoToneIcon sx={{ fontSize: '20px' }} />,
  waitingManual: <ConfirmationNumberTwoToneIcon sx={{ fontSize: '20px' }} />,
  approved: <CheckBoxTwoToneIcon sx={{ fontSize: '20px' }} />,
  rejected: <CancelPresentationTwoToneIcon sx={{ fontSize: '20px' }} />,
  cancel: <CancelPresentationTwoToneIcon sx={{ fontSize: '20px' }} />,
  done: <DoneAllTwoToneIcon sx={{ fontSize: '20px' }} />,
  started: <PendingOutlinedIcon sx={{ fontSize: '20px' }} />,
}
