import axiosClient from '../utils/axios'

export const uploadImgApi = {
  uploadImg: (data: File) => {
    const formData = new FormData()
    formData.append('file', data)
    const url = `/media/uploadImage`
    return axiosClient.post(url, formData)
  },
  uploadMultipleImg: (data: File[]) => {
    const url = `/media/uploadImages`
    return axiosClient.post(url, data)
  },
}
