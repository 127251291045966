import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Grid, Link } from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Item from 'components/Item'
import { TabPanel, a11yProps } from 'components/TabPanel'
import React, { useEffect, useState } from 'react'
import {
  StyleHeader,
  StyleHeaderItem,
  StyleTitle,
  StyleWrapLeft,
} from './style'

import { useNavigate, useParams } from 'react-router-dom'
import { servicesAPI } from 'services/servicesAPI'
import RulesNotes from './components/RulesNotes'
import TermsOfService from './components/TermsOfService'

const Rules = () => {
  const paramId = useParams()
  const navigate = useNavigate()
  const [value, setValue] = React.useState(0)
  const [dataService, setDataService] = useState<any>([])

  const fetchServiceDetail = async () => {
    const { data } = await servicesAPI.getServiceById(
      paramId?.serviceId as string
    )
    setDataService(data)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  useEffect(() => {
    fetchServiceDetail()
  }, [paramId?.serviceId])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="padding-bottom">
        <Item sx={{ padding: '12px 24px' }} className="bd-radius">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              sx={{
                textTransform: 'capitalize',
                cursor: 'default',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
            >
              Quản lý dịch vụ
            </Link>
            <Link
              sx={{
                textTransform: 'capitalize',
                cursor: 'pointer',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
              onClick={() => navigate(`/service/${paramId?.serviceId}`)}
            >
              {dataService?.name}
            </Link>
            <Link
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                cursor: 'default',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
            >
              Điều khoản và dịch vụ
            </Link>
          </Breadcrumbs>
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <Box sx={{ width: '100%' }}>
            <StyleHeaderItem>
              <StyleTitle>Điều khoản và lưu ý</StyleTitle>
            </StyleHeaderItem>
            <StyleHeader>
              <StyleWrapLeft>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Điều khoản và lưu ý"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Điều khoản dịch vụ"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </StyleWrapLeft>
            </StyleHeader>

            <TabPanel value={value} index={0}>
              <RulesNotes />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TermsOfService />
            </TabPanel>
          </Box>
        </Item>
      </Grid>
    </Grid>
  )
}

export default Rules
