import React, { forwardRef } from 'react'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import { ActionButton } from 'components/Icons/style'
/* eslint-disable react/display-name */
const Edit = forwardRef((props: any, ref: any) => {
  return (
    <div {...props}>
      <ActionButton>
        <DriveFileRenameOutlineOutlinedIcon
          sx={{ color: '#006aff' }}
          fullWidth
          {...props}
          ref={ref}
        />
      </ActionButton>
    </div>
  )
})
export default Edit
