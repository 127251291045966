import React from 'react'
import CloseIcon from '@mui/icons-material/Close'

const Close = (props: any) => {
  return (
    <CloseIcon
      {...props}
      sx={{
        marginTop: '-15px',
        marginRight: '-15px',
        color: 'black',
        fontSize: '30px',
        '&:hover': {
          color: 'red',
          cursor: 'pointer',
          animation: 'example1 .2s ',
          '@keyframes example1': {
            from: { transform: 'rotate(0deg)' },
            to: { transform: 'rotate(90deg)' },
          },
        },
        animation: 'example2 .2s ',
        '@keyframes example2': {
          from: { transform: 'rotate(90deg)' },
          to: { transform: 'rotate(0deg)' },
        },
      }}
    />
  )
}

export default Close
