import axiosClient from '../utils/axios'

const rangeOfActiveApi = {
  createRangeOfActive: (data: any) => {
    const url = `/price-area`
    return axiosClient.post(url, data)
  },

  UpdateRangeOfActive: (data: any) => {
    const url = `/price-area`
    return axiosClient.put(url, data)
  },

  UpdateStatus: (data: any, id: string) => {
    const url = `/price-area/${id}`
    return axiosClient.put(url, data)
  },
  UpdateCountry: (param: any) => {
    const url = `/price-area/country/?serviceId=${param.serviceId}&countryId=${param.countryId}`
    return axiosClient.post(url, param.data)
  },
  getRangeOfActive: (params: any) => {
    const url = `/price-area?serviceId=${params.serviceId}`
    return axiosClient.get(url)
  },

  getCountries: () => {
    const url = `/countries`
    return axiosClient.get(url)
  },

  getProvincesById: (id: string) => {
    const url = `/provinces/${id}`
    return axiosClient.get(url)
  },

  getDistrictsById: (id: string) => {
    const url = `/districts/${id}`
    return axiosClient.get(url)
  },

  getRangeOfActiveById: (params: any) => {
    const url = `/price-area?serviceId=${params.serviceId}`
    return axiosClient.get(url)
  },

  update: (data: IRangeOfActive) => {
    const url = `/rangeofactive`
    return axiosClient.patch(url, data)
  },

  delete: (id: string) => {
    const url = `/price-area/${id}`
    return axiosClient.delete(url)
  },
  deleteCountry: (param: any) => {
    const url = `/price-area-country?serviceId=${param.serviceId}&countryId=${param.id}`
    return axiosClient.delete(url)
  },
}

export default rangeOfActiveApi
