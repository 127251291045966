import { Routes, Route } from 'react-router-dom'
import DefaultRoute from './DefaultRoute'
import PrivateRoute from './PrivateRoute'
import React from 'react'
import { LayoutType } from 'layouts'
import useToken from 'hooks/useToken'
import Login from 'pages/auth/components/Login'
import FormEmail from 'pages/auth/components/ForgotPassword/FormEmail'
import FormOTP from 'pages/auth/components/ForgotPassword/FormOTP'
import FormChangePassword from 'pages/auth/components/ForgotPassword/FormChangePassword'
import Breadcrumb from 'components/Breadcrumbs'
import ComingSoon from 'components/ComingSoon'
import { ROUTE_TEST, ROUTE_STRUCTURE } from 'constants/route'
import PageNotFound from 'components/PageNotFound'

export default function Routers() {
  const { setToken } = useToken()
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute layout={LayoutType.basic} />}>
        {ROUTE_STRUCTURE.map((route, index) => (
          <Route key={index} path={route.path} element={route.name} />
        ))}

        {ROUTE_TEST.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <>
                <Breadcrumb paths={route.name} />
                <ComingSoon />
              </>
            }
          />
        ))}
      </Route>
      <Route path="/" element={<DefaultRoute layout={LayoutType.blank} />}>
        <Route path="/auth/login" element={<Login setToken={setToken} />} />
        <Route path="/auth/email" element={<FormEmail />} />
        <Route path="/auth/otp/:email" element={<FormOTP />} />
        <Route path="/auth/change/:email" element={<FormChangePassword />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}
