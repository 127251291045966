import React from 'react'
import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'

export default function InputSearch({ ...props }) {
  return (
    <TextField
      id="input-with-icon-textfield"
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      size="small"
    />
  )
}
