import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Breadcrumb from 'components/Breadcrumbs'
import Item from 'components/Item'
import { TabPanel, a11yProps } from 'components/TabPanel'
import { PART_TIME_STAFF_EDIT } from 'constants/breadcrumbs'
import React, { useEffect, useState } from 'react'

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import InputField from 'components/FormControl/InputField'
import {
  operatingStatusStaff,
  statusStaff,
  theReasonStaff,
} from 'constants/partTimeStaff'
import { Controller, useForm } from 'react-hook-form'
import PerformanceEdit from '../PartTimeStaffEdit/components/PerformanceEdit'
import WorkCalendarEdit from '../PartTimeStaffEdit/components/WorkCalendarEdit'
import WorkHistoryEdit from '../PartTimeStaffEdit/components/WorkHistoryEdit'
import PersonalInformationEdit from './components/PersonalInformationEdit'
import {
  StyleHeader,
  StyleHeaderItem,
  StyleLink,
  StyleLinks,
  StyleTitle,
  StyleWrapBottom,
  StyleWrapLeft,
  StyleWrapRight,
  StyleWrapTop,
} from './style'
import staffApi from 'services/staffAPI'
import { useParams } from 'react-router-dom'
import { ExportToExcel } from 'utils/exportToExcel'
import dayjs from 'dayjs'
import { IObjectRank, rank } from 'utils/rankPartTimeStaff'

const PartTimeStaffEdit = () => {
  const { id } = useParams()
  const { control } = useForm()
  const [value, setValue] = React.useState(0)
  const [valueStatusStaff, setValueStatusStaff] = useState('')
  const [valueOperatingStatusStaff, setValueOperatingStatusStaff] = useState('')
  const [valueTheReasonStaff, setValueTheReasonStaff] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [dataStaff, setDataStaff] = useState<IStaff>()

  const fetchDataStaff = async () => {
    const { data } = await staffApi.getStaffById(id as string)
    setDataStaff(data)
    setValueStatusStaff(data?.status)
    setValueOperatingStatusStaff(data?.activeStatus)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeStatusStaff = (event: SelectChangeEvent, newValue: any) => {
    if (newValue?.props?.value == 'Doing') {
      setValueOperatingStatusStaff('')
      setIsOpen(false)
    }
    if (newValue?.props?.value == 'TakeABreak') {
      setValueOperatingStatusStaff('TemporarilyLockTheCalendar')
      setIsOpen(true)
    }
    if (newValue?.props?.value == 'quitOneIsJob') {
      setValueOperatingStatusStaff('accountLock')
      setIsOpen(true)
    }
    setValueStatusStaff(newValue?.props?.value)
  }
  const handleChangeOperatingStatusStaff = (
    event: SelectChangeEvent,
    newValue: any
  ) => {
    setValueOperatingStatusStaff(newValue?.props?.value)
    setIsOpen(true)
  }
  const handleChangeTheReasonStaff = (
    event: SelectChangeEvent,
    newValue: any
  ) => {
    setValueTheReasonStaff(newValue?.props?.value)
  }

  useEffect(() => {
    fetchDataStaff()
  }, [])

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={PART_TIME_STAFF_EDIT} />
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <Box sx={{ width: '100%' }}>
            <StyleHeaderItem>
              <StyleLinks
                onClick={() => {
                  ExportToExcel([
                    {
                      'Mã nhân viên': dataStaff?.displayId,
                      'Họ và tên': dataStaff?.fullName,
                      'Số điện thoại': dataStaff?.phone,
                      'CMND/CCCD/Passport': dataStaff?.citizenId,
                      'Dịch vụ': dataStaff?.services
                        ?.map((service: any) => service?.staffService?.name)
                        .join(', '),
                      'Gắn nhãn': dataStaff?.staffLabel
                        ?.map((label: any) => label?.name)
                        .join(', '),
                      'Tình trạng': dataStaff?.status,
                      'Tình trạng hoạt động': dataStaff?.activeStatus,
                      'Kỹ nẵng':
                        dataStaff?.skill == null
                          ? null
                          : JSON.parse(dataStaff?.skill)
                              ?.map((skill: any) => skill)
                              .join(', '),
                      'Thành phố':
                        dataStaff?.districts?.[0]?.staffProvince?.fullName,
                      Quận: dataStaff?.districts?.[0]?.staffDistrict?.name,
                      'Vị trí làm việc':
                        dataStaff?.districts?.[0]?.fullLocation,
                      Hạng: rank[dataStaff?.rank as keyof IObjectRank],
                      'Số sao': dataStaff?.avgRate,
                      'Giờ công tháng': '',
                      'Giờ công tháng trước': '',
                      'Giờ công luỹ kế năm': '',
                      'Ca trống lịch': '',
                      'Ca đang làm/ ca đăng ký': '',
                      'Ngày ký hợp đồng': dayjs(dataStaff?.signingDate).format(
                        'DD/MM/YYYY'
                      ),
                      'Ngày làm việc đầu tiên': dayjs(
                        dataStaff?.startDate
                      ).format('DD/MM/YYYY'),
                      'Nhân sự phụ trách/tuyển dụng':
                        dataStaff?.staffManagers?.recruitment,
                      'Ngày sinh nhật': dayjs(dataStaff?.birth).format(
                        'DD/MM/YYYY'
                      ),
                    },
                  ])
                }}
              >
                <FileDownloadOutlinedIcon
                  sx={{ marginRight: '10px', color: 'white' }}
                />
                <StyleLink>Xuất File Excel</StyleLink>
              </StyleLinks>
            </StyleHeaderItem>
            <StyleHeader>
              <StyleWrapLeft>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Thông tin cá nhân"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Hiệu suất"
                    {...a11yProps(1)}
                  />
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Lịch làm việc"
                    {...a11yProps(2)}
                  />
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Lịch sử làm việc"
                    {...a11yProps(3)}
                  />
                </Tabs>
              </StyleWrapLeft>
              <StyleWrapRight>
                <StyleWrapTop>
                  <FormControl
                    sx={{ width: 150 }}
                    size="small"
                    className="input-select"
                  >
                    <InputLabel id="demo-simple-select-label-status">
                      Tình trạng
                    </InputLabel>
                    <Select
                      value={valueStatusStaff}
                      label="Tình trạng"
                      onChange={handleChangeStatusStaff}
                    >
                      {statusStaff?.map((item: any) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: 220 }}
                    size="small"
                    className="input-select"
                  >
                    <InputLabel id="demo-simple-select-label-status">
                      Tình trạng hoạt động
                    </InputLabel>
                    <Select
                      value={valueOperatingStatusStaff}
                      label="Tình trạng hoạt động"
                      onChange={handleChangeOperatingStatusStaff}
                    >
                      {operatingStatusStaff?.map((item: any) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyleWrapTop>
                {isOpen && (
                  <StyleWrapBottom>
                    <div>
                      <Controller
                        control={control}
                        name="startDate"
                        defaultValue=""
                        render={({
                          field: { onChange, ref, onBlur, name, ...field },
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...field}
                              inputRef={ref}
                              inputFormat="DD/MM/YYYY"
                              label="Ngày bắt đầu"
                              renderInput={(inputProps) => (
                                <InputField
                                  {...inputProps}
                                  onBlur={onBlur}
                                  size="small"
                                  name={name}
                                  error={false}
                                  sx={{ width: 150, margin: '-10px 0px' }}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                              onChange={(e: Date | null) => {
                                onChange(e)
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      {/* <StyleError>{errors.startDate?.message as string}</StyleError> */}
                    </div>
                    <FormControl
                      sx={{ width: 220 }}
                      size="small"
                      className="input-select"
                    >
                      <InputLabel id="demo-simple-select-label-status">
                        Lý do
                      </InputLabel>
                      <Select
                        value={valueTheReasonStaff}
                        label="Lý do"
                        onChange={handleChangeTheReasonStaff}
                      >
                        {theReasonStaff?.map((item: any) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyleWrapBottom>
                )}
              </StyleWrapRight>
            </StyleHeader>

            <TabPanel value={value} index={0}>
              <PersonalInformationEdit />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PerformanceEdit />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <WorkCalendarEdit />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <WorkHistoryEdit />
            </TabPanel>
          </Box>
        </Item>
      </Grid>
    </Grid>
  )
}

export default PartTimeStaffEdit
