import { VALIDATE_STRING_VN } from 'utils/regex'
import * as yup from 'yup'

export const homeCleaningSchema_ = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Tên dịch vụ không được để trống')
    .max(50, 'Tên dịch vụ có tối thiểu 3, tối đa 50 ký tự')
    .min(3, 'Tên dịch vụ có tối thiểu 3, tối đa 50 ký tự'),

  description: yup
    .string()
    .required('Nội dung không được để trống')
    .max(100, 'Nội dung có tối thiểu 5, tối đa 100 ký tự')
    .min(5, 'Nội dung có tối thiểu 5, tối đa 100 ký tự'),

  serviceCondition: yup
    .string()
    .required('Giờ không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giờ sai định dạng'),
  infoCondition: yup
    .string()
    .required('Giờ không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giờ sai định dạng'),
  freeCondition: yup
    .string()
    .required('Giờ không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giờ sai định dạng'),
  price: yup
    .string()
    .required('Giá tiền không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giá tiền sai định dạng'),
  price0: yup
    .string()
    .required('Giá tiền không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giá tiền sai định dạng'),
  price1: yup
    .string()
    .required('Giá tiền không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giá tiền sai định dạng'),
  price2: yup
    .string()
    .required('Giá tiền không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giá tiền sai định dạng'),
  premium0: yup
    .string()
    .required('Giá tiền không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giá tiền sai định dạng'),
  premium1: yup
    .string()
    .required('Giá tiền không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giá tiền sai định dạng'),
  premium2: yup
    .string()
    .required('Giá tiền không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giá tiền sai định dạng'),
  chooseStaff: yup
    .string()
    .required('Giá tiền chọn người làm không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giá tiền sai định dạng'),
  timeBeforeCondition: yup
    .string()
    .required('Giờ không được để trống')
    .matches(/^[0-9]+(\.[0-9]+)?$/, 'Giờ sai định dạng'),
  percentCondition: yup
  .string()
  .required('Phần trăm không được để trống')
  .matches(/^[0-9]+(\.[0-9]+)?$/, 'Phần trăm sai định dạng'),
})

export const editPriceDaysSchema_ = yup.object().shape({
  name: yup
    .string()
    .required('Thứ ngày không được để trống')
    .min(3, 'Thứ ngày tối thiểu 3 ,tối đa 50 ký tự')
    .max(50, 'Thứ ngày tối thiểu 3 ,tối đa 50 ký tự'),
  priceValue: yup
    .number()
    .required('Giá cộng thêm không được để trống')
    .typeError('Giá cộng thêm phải là số')
    .nullable(true)
    .transform((_, val) => (val !== '' ? Number(val) : null)),
})

export const editPriceHoursSchema_ = yup.object().shape({
  name: yup
    .string()
    .required('Nội dung giờ không được để trống')
    .min(3, 'Nội dung giờ tối thiểu 3 ,tối đa 50 ký tự')
    .max(50, 'Nội dung giờ tối thiểu 3 ,tối đa 50 ký tự'),
  startDateTime: yup.string(),
  endDateTime: yup.string(),
  priceValue: yup
    .number()
    .required('Giá cộng thêm không được để trống')
    .typeError('Giá cộng thêm phải là số')
    .nullable(true)
    .transform((_, val) => (val !== '' ? Number(val) : null)),
})
