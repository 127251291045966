import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import { StyleContainer } from './style'

const drawerWidth = 240

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

interface IDefaultLayout {
  children: React.ReactNode
}

export default function DefaultLayout({ children }: IDefaultLayout) {
  const [open, setOpen] = React.useState(true)
  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        sx={{ width: '100%' }}
        position="fixed"
        open={open}
        className="box-none"
      >
        <Header handleDrawerOpen={handleDrawerOpen} open={open} />
      </AppBar>
      <Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
      <StyleContainer
        style={{ width: open ? 'calc(100% - 250px)' : 'calc(100% - 65px)' }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: '24px',
            minHeight: '100vh',
          }}
        >
          <DrawerHeader />
          {children}
        </Box>
      </StyleContainer>
    </Box>
  )
}
