import React, { createContext, useContext, useState } from 'react'
import { ConfigurePriceAPI } from 'services/servicesAPI'

interface IAlertContextType {
  data: any
  fetchData: any
  setData: any
  provinceName: any
}
interface IChildren {
  children: React.ReactNode
}

const PriceContext = createContext<IAlertContextType>({
  provinceName: '',
  data: [],
  setData: () => undefined,
  fetchData: async () => undefined,
})

const PriceProvider = ({ children }: IChildren) => {
  const [data, setData] = useState<any[]>([])
  const [provinceName, setProvinceName] = useState()
  const fetchData = async (id: string, provinceId: string) => {
    const { data } = await ConfigurePriceAPI.getPriceListCountry({
      serviceId: id,
      province: provinceId,
    })
    setProvinceName(data?.[0]?.area?.location?.province?.name)
    setData(data)
  }
  return (
    <PriceContext.Provider
      value={{
        data,
        provinceName,
        setData,
        fetchData,
      }}
    >
      {children}
    </PriceContext.Provider>
  )
}
const usePrice = () => {
  return useContext(PriceContext)
}

export { PriceProvider, usePrice }
