export const options = [
  { title: 'Thứ 2' },
  { title: 'Thứ 3' },
  { title: 'Thứ 4' },
  { title: 'Thứ 5' },
  { title: 'Thứ 6' },
  { title: 'Thứ 7' },
  { title: 'Chủ Nhật' },
]

export const status = [
  { name: 'Chờ nhận việc - việc mới', value: 'waiting' },
  { name: 'Chờ xác nhận', value: 'waitingConfirm' },
  // { name: 'Chờ xác nhận', value: 'waitingManual' },
  { name: 'Chờ làm', value: 'approved' },
  { name: 'Đang thực hiện', value: 'started' },
  { name: 'Đã huỷ', value: 'rejected' },
  // { name: 'Đã huỷ', value: 'cancel' },
  { name: 'Đã hoàn thành', value: 'done' },
]

export const statusPayment = [
  { name: 'Đã thanh toán', value: 'paid' },
  { name: 'Chưa thanh toán', value: 'unpaid' },
]

export const cancelReason = [
  { name: 'Bận việc đột xuất', value: 'Bận việc đột xuất' },
  { name: 'Đăng nhầm ngày', value: 'Đăng nhầm ngày' },
  { name: 'Không còn nhu cầu', value: 'Không còn nhu cầu' },
  { name: 'Chưa có người nhận', value: 'Chưa có người nhận' },
  { name: 'Lý do khác', value: 'Lý do khác' },
]

export const likeOption = [
  { name: 'Đúng giờ', id: 'onTime' },
  { name: 'Sạch sẽ', id: 'clean' },
  { name: 'Thân thiện', id: 'friendly' },
]

export const paymentMethod = [
  { name: 'Tiền mặt', value: 'cash' },
  // { name: '3CleanPay', value: 'wallet' },
  // { name: 'Visa', value: 'visa' },
  // { name: 'Banking', value: 'banking' },
]

export const filterDateTimeOrders = {
  tomorrow: 'tomorrow',
  nextThreeDays: 'nextThreeDays',
  nextSevenDays: 'nextSevenDays',
  timeline: 'timeline',
}

export const filterDateTimeOrder = [
  {
    value: 'tomorrow',
    label: 'Ngày mai',
  },
  {
    value: 'nextThreeDays',
    label: '3 ngày đến',
  },
  {
    value: 'nextSevenDays',
    label: '7 ngày đến',
  },
  {
    value: 'timeline',
    label: 'Mốc thời gian',
  },
]

export const filterStatusOrder = status.map((item) => ({
  value: item.value,
  label: item.name,
}))

export const filterPaymentStatus = statusPayment.map((item) => ({
  value: item.value,
  label: item.name,
}))
