import { Button, Table, TableCell, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'

export const StyleHeaderItem = styled.div`
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const StyleTitleDetail = styled.h2`
  font-size: 25px;
  color: var(--color-primary);
  font-weight: bold;
  text-align: left;
`

export const StyleTableHead = styled(TableCell)`
  && {
    font-weight: bold;
    color: var(--color-secondary);
  }
`

export const StyleErrorImage = styled.div`
  margin-top: 10px;
  color: red;
  font-size: 14px;
  text-align: left;
`
export const StyledTable = styled(Table)`
  tr:last-child td:last-child {
    height: calc(100% - 64px);
  }
`

export const StyleError = styled.p`
  display: block;
  color: red;
  font-size: 14px;
  text-align: left;
  white-space: normal;
`

export const StyleTitle = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 25px;
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 14px;
  }
`

export const StyleItem = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 24px 0;
  gap: 24px;
`

export const StyleItemInformation = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 24px 0;
  gap: 24px;
`

export const StyleLabel = styled(Typography)`
  && {
    font-weight: 600 !important;
    line-height: 1.5;
    text-align: left;
    font-size: 1.2rem;
    color: #33303cad;
    margin-bottom: 14px;
  }
`
export const StyleTime = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`
export const StyleTimePicker = styled.div`
  width: 49%;
`

export const StyleHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyleButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 35%;
`
export const StyleLinks = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  min-width: 47%;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin-bottom: 24px;
  transition: 0.1s ease-in-out;
  height: 37px;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`

export const StyleLink = styled.span`
  margin-top: 2px;
  font-weight: bold;
`

export const StyleInfo = styled.div`
  margin-top: -8px;
  min-width: 80%;
  width: 80%;
`

export const StylePersonal = styled.div`
  margin-top: -10px;
  min-width: 80%;
  width: 100%;
`

export const StyleImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 284px;
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 30px;
`

export const StyleAddImage = styled.label`
  position: relative;
  margin: auto;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: contain;
  }
  input {
    display: none;
  }
`

export const StyleAddItem = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 0;
`

export const StyleContentInput = styled.div`
  max-width: 100px;
`
export const StyleTimeInput = styled.div`
  max-width: 60px;
`
export const StyleContentText = styled.div``

export const StyleContent = styled.div`
  display: flex;
  align-items: center;
`

export const StyleToogle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
`

export const StyledInput = styled(TextField)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

// export const StyleError = styled.p`
//   display: flex;
//   color: red;
//   font-size: 14px;
//   margin: auto !important;
//   white-space: normal;
// `

export const StyleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
`

export const StyleListRight = styled.div`
  width: 82%;
  display: flex;
  gap: 24px;
`
export const StyleListLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18%;
  max-height: 284px;
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 30px;
  margin: 10px 0px;
`

// export const StyleTitle = styled.h2`
//   font-size: 25px;
//   color: #333333;
//   font-weight: bold;
//   margin: 12px 0 20px 0;
// `

export const StyleAvatar = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 3px dotted #536dfe;
  border-radius: 50%;
  padding: 4px;
`

export const StyleItemRight = styled.div`
  width: 100%;
  gap: 20px;
`
export const StyleItemLeft = styled.div`
  width: 100%;
`

export const StyleInput = styled.div`
  width: 100%;
`

export const StyleTitleUser = styled(Typography)`
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  line-height: 1.5rem;
  letter-spacing: 0.0125em !important;
  color: var(--color-primary);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyleTableCell = styled(TableCell)`
  && {
    font-weight: bold;
    color: var(--color-secondary);
  }
`
export const StyleTableCellItem = styled.p`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const ActionButton = styled(Button)`
  && {
    color: #fff;
  }
  &:hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }
`
