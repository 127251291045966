import React from 'react'
import {
  StyleContainer,
  StyleOrder,
  StyleStatistics,
  StyleTotalOrder,
  StyleItem,
  StyleList,
  StyleTotalItem,
  StyleTableCell,
  StyleTableHeader,
  StyleHeaderItem,
  StyleTitle,
  StyleFilterItem,
} from './style'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import ProductionQuantityLimitsTwoToneIcon from '@mui/icons-material/ProductionQuantityLimitsTwoTone'
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
} from '@mui/material'
// import { useNavigate } from 'react-router-dom'

export default function OrderHistory() {
  // const navigate = useNavigate()

  // const [page, setPage] = useState(0)
  // const [rowsPerPage, setRowsPerPage] = useState(10)
  // const navigate = useNavigate()

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage)
  // }

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10))
  //   setPage(0)
  // }

  return (
    <StyleContainer>
      <StyleStatistics>
        <StyleTotalOrder>
          <StyleList>
            <StyleItem>Tổng số đơn đã đặt</StyleItem>
            <StyleTotalItem>52</StyleTotalItem>
          </StyleList>
          <AccountBalanceWalletRoundedIcon className="icons-style" />
        </StyleTotalOrder>
        <StyleTotalOrder>
          <StyleList>
            <StyleItem>Tổng số tiền đã đặt</StyleItem>
            <StyleTotalItem>48</StyleTotalItem>
          </StyleList>
          <AttachMoneyIcon className="icons-style" />
        </StyleTotalOrder>
        <StyleTotalOrder>
          <StyleList>
            <StyleItem>Số đơn đang cần làm</StyleItem>
            <StyleTotalItem>100</StyleTotalItem>
          </StyleList>
          <ProductionQuantityLimitsTwoToneIcon className="icons-style" />
        </StyleTotalOrder>
      </StyleStatistics>
      <StyleOrder>
        <StyleTableHeader>
          <StyleTitle>Lịch sử đặt đơn</StyleTitle>
          <StyleHeaderItem>
            <StyleFilterItem>
              <FormControl sx={{ width: 155 }} size="small">
                <InputLabel id="demo-simple-select-label">
                  Trạng thái
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={status}
                  label="Tình trạng"
                  // onChange={handleStatus}
                >
                  <MenuItem value="Mới đăng">Mới đăng</MenuItem>
                  <MenuItem value="Chờ xác nhận">Chờ xác nhận</MenuItem>
                  <MenuItem value="Xác nhận">Xác nhận</MenuItem>
                  <MenuItem value="Đã hủy">Đã hủy</MenuItem>
                  <MenuItem value="Đã hoàn thành">Đã hoàn thành</MenuItem>
                </Select>
              </FormControl>
            </StyleFilterItem>
          </StyleHeaderItem>
        </StyleTableHeader>
        <TableContainer component={Paper} className="box-shadow">
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <StyleTableCell width={50}>STT</StyleTableCell>
                <StyleTableCell align="left"> Mã đơn hàng </StyleTableCell>
                <StyleTableCell align="left"> Tên DV </StyleTableCell>
                <StyleTableCell align="left"> Tên NV nhận đơn </StyleTableCell>
                <StyleTableCell align="center">
                  Ngày (KH) đặt đơn
                </StyleTableCell>
                <StyleTableCell align="center">
                  Ngày (NV) nhận đơn
                </StyleTableCell>
                <StyleTableCell align="center">Giá</StyleTableCell>
                <StyleTableCell align="left">Tình trạng</StyleTableCell>
                <StyleTableCell align="center">
                  Ngày hủy đơn (nếu có)
                </StyleTableCell>
                <StyleTableCell align="center">
                  Lý do huỷ (nếu có)
                </StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {datas
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => ( */}
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row" align="center">
                  1
                </TableCell>
                <TableCell align="left">4A1111</TableCell>
                <TableCell align="left">Giat ui</TableCell>
                <TableCell align="left">Nguyen Van</TableCell>
                <TableCell align="center">20-11-2023</TableCell>
                <TableCell align="center">20-11-2023</TableCell>
                <TableCell align="center">15.000</TableCell>
                <TableCell align="left">Da Xong</TableCell>
                <TableCell align="center">-</TableCell>
                <TableCell align="center">-</TableCell>
              </TableRow>
              {/* ))} */}
            </TableBody>
          </Table>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={datas?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </TableContainer>
      </StyleOrder>
    </StyleContainer>
  )
}
