import axiosClient from '../utils/axios'

export const newsAPI = {
  createNews: (data: INews) => {
    const url = `/news/create`
    return axiosClient.post(url, data)
  },
  getNews: (params: any) => {
    const url = `/news?page=${params.page}&limit=${params.limit}&sortType='ASC'`
    return axiosClient.get(url, params)
  },
  getSortNews: (params: any) => {
    const url = `/news?page=${params.page}&limit=${params.limit}&sortBy=priority&sortType=DESC`
    return axiosClient.get(url, params)
  },
  getNewsById: (id: string) => {
    const url = `/news/${id}`
    return axiosClient.get(url)
  },
  updateNews: (data: INews, id: string) => {
    const url = `/news/update/${id}`
    return axiosClient.patch(url, data)
  },
  deleteNews: (id: number) => {
    const url = `/news/delete/${id}`
    return axiosClient.delete(url)
  },
}
