import React, { useState } from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import { uploadImage } from 'utils/uploadImage'
import Cookies from 'js-cookie'
import { EDITOR_TOOL_BAR } from 'constants/editorTool'
import { API_URL } from 'constants/common'
import { useAlert } from 'contexts/AlertContext'

type TEditorProps = {
  onChange: (content: string) => void
  value?: string
  height?: number
  disable?: boolean
}

const Editor = ({ onChange, value, disable }: TEditorProps) => {
  const [content, setContent] = useState(value)
  const { setAlertError } = useAlert()

  const handleChange = (value: string) => {
    setContent(value)
    onChange(value)
  }

  const handleImageUploadBefore = async (
    files: File[],
    info: any,
    uploadHandler: any
  ) => {
    try {
      const uploaded = await uploadImage(files[0])
      const result = {
        result: [
          {
            url: uploaded.medium,
            size: files[0].size,
            name: files[0].name,
          },
        ],
      }
      uploadHandler(result)
    } catch (error: any) {
      setAlertError(error.response.data.message)
    }
  }

  function handleImageUploadError() {
    return
  }

  return (
    <SunEditor
      onChange={handleChange}
      setContents={content}
      height="auto"
      onImageUploadBefore={handleImageUploadBefore}
      disable={disable}
      setOptions={{
        showPathLabel: false,
        buttonList: EDITOR_TOOL_BAR,
        defaultStyle: 'text-align: left',
        imageUploadUrl: `${API_URL}/media/uploadImage`,
        imageUploadHeader: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      }}
      onImageUploadError={handleImageUploadError}
    />
  )
}

export default Editor
