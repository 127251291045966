import React, { useState } from 'react'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { changePasswordSchema_ } from 'validations/editProfileSchema'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { StyleError } from './style'
import { useLoading } from 'contexts/LoadingContext'
import { useAlert } from 'contexts/AlertContext'
import ProfileApi from 'services/profileAPI'
import BasicModal from 'components/Modal'
import { Typography } from '@mui/material'
import useToken from 'hooks/useToken'

interface IChangePassWord {
  handleClose?: () => void
  handleOff?: any
}

const ChangePassWord = ({ handleClose, handleOff }: IChangePassWord) => {
  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [modalSave, setModaSave] = useState<boolean>(false)
  const [modalOpen, setModaOpen] = useState<boolean>(true)
  const { setLoadingTrue, setLoadingFalse } = useLoading()
  const { setAlertError, setAlertSuccess } = useAlert()
  const { remove } = useToken()
  const handleClickShowPasswordCurrent = () =>
    setShowPasswordCurrent((show) => !show)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show)
  const handleContinue = () => setModaSave(true)

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(changePasswordSchema_),
    mode: 'onChange',
  })

  const logout = async () => {
    setLoadingTrue()
    const timer = setTimeout(() => {
      setLoadingFalse()
      remove()
      location.reload()
    }, 100)
    return () => clearTimeout(timer)
  }

  const onSubmit = async (data: IChangePwd) => {
    setLoadingTrue()
    try {
      setLoadingFalse()
      await ProfileApi.changePwd({
        oldPassword: data?.oldPassword,
        password: data?.password,
      })
      setAlertSuccess('Thay đổi mật khẩu thành công!')
      setModaOpen(false)
      setModaSave(true)
    } catch (error: any) {
      setLoadingFalse()
      setAlertError('Mật Khẩu cũ không chính xác!')
    }
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  return (
    <>
      {modalSave && (
        <BasicModal open={modalSave} handleClose={handleContinue}>
          <Typography>Bạn muốn tiếp tục hay đăng nhập lại?</Typography>
          <Button
            sx={{
              margin: '24px 24px 0px 0px',
              width: '150px',
            }}
            variant="contained"
            onClick={() => {
              handleOff()
              setModaSave(false)
            }}
            className="bgr-global"
          >
            Tiếp tục
          </Button>
          <Button
            sx={{
              margin: '24px 0px 0px',
              width: '150px',
            }}
            onClick={() => {
              handleOff()
              setModaSave(false)
              logout()
            }}
            variant="outlined"
          >
            Đăng nhập
          </Button>
        </BasicModal>
      )}
      {modalOpen && (
        <Box
          component="form"
          sx={{
            '& > :not(style)': { width: '50.8ch' },
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-oldPassword">
              Nhập mật khẩu cũ
            </InputLabel>

            <OutlinedInput
              id="outlined-adornment-oldPassword"
              type={showPasswordCurrent ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle oldPassword visibility"
                    onClick={handleClickShowPasswordCurrent}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswordCurrent ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Nhập mật khẩu cũ"
              {...register('oldPassword')}
              sx={{ marginBottom: '10px' }}
            />
            <StyleError>{errors.oldPassword?.message}</StyleError>
          </FormControl>

          <FormControl sx={{ margin: '10px 0px' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Nhập mật khẩu mới
            </InputLabel>

            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Nhập mật khẩu mới"
              {...register('password')}
              sx={{ marginBottom: '10px' }}
            />
            <StyleError>{errors.password?.message}</StyleError>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-confirmPassword">
              Xác nhận lại mật khẩu
            </InputLabel>

            <OutlinedInput
              id="outlined-adornment-confirmPassword"
              type={showPasswordConfirm ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordConfirm}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Xác nhận lại mật khẩu"
              {...register('confirmPassword')}
              sx={{ marginBottom: '10px' }}
            />
            <StyleError>{errors.confirmPassword?.message}</StyleError>
          </FormControl>

          <Stack
            spacing={2}
            direction="row"
            sx={{
              paddingTop: '14px',
              justifyContent: 'space-between',
              margin: 'auto !important',
            }}
          >
            <Button variant="contained" type="submit" className="bgr-global">
              Lưu thay đổi
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Huỷ bỏ
            </Button>
          </Stack>
        </Box>
      )}
    </>
  )
}

export default ChangePassWord
