import {
  VALIDATE_STRING_CITIZEN_ID,
  VALIDATE_STRING_PHONE,
  VALIDATE_STRING_VN,
  VALIDATE_STRING_PASSWORD,
} from 'utils/regex'
import * as yup from 'yup'

export const editProfileSchema_ = yup.object().shape({
  fullName: yup
    .string()
    .required('Họ và tên không được để trống')
    .min(3, 'Họ và tên tối thiểu 3 ký tự')
    .max(50, 'Họ và tên tối đa 50 ký tự')
    .matches(
      VALIDATE_STRING_VN,
      'Họ và tên bao gồm chữ, space min 3 ký tự và max là 50 ký tự'
    ),

  phone: yup
    .string()
    .required('Số điện thoại không được để trống')
    .max(10, 'Số điện thoại không quá 10 số')
    .matches(VALIDATE_STRING_PHONE, 'Số điện thoại không hợp lệ'),

  email: yup
    .string()
    .email('Email không đúng định dạng')
    .required('Email không được để trống'),

  citizenId: yup
    .string()
    .required('Căn cước công dân không được để trống')
    .min(9, 'Chứng minh nhân dân bao gồm 9 số')
    .max(12, 'Căn cước công dân bao gồm 12 số')
    .matches(
      VALIDATE_STRING_CITIZEN_ID,
      'Căn cước công dân hoặc chứng minh nhân dân không hợp lệ'
    ),

  registrationDate: yup
    .string()
    .required('Ngày cấp không được để trống')
    .test(
      'registrationDate',
      'Ngày cấp phải nhỏ hơn hoặc bằng ngày hiện tại',
      function (value: any) {
        return new Date(value) <= new Date()
      }
    ),

  issuedBy: yup.string().required('Nơi cấp không được để trống'),

  permanentAddress: yup
    .string()
    .required('Địa chỉ thường trú không được để trống'),

  temporaryResidenceAddress: yup
    .string()
    .required('Địa chỉ tạm trú không được để trống'),

  jobPosition: yup.string().required('Vị trí công việc không được để trống'),
})

export const changePasswordSchema_ = yup.object().shape({
  oldPassword: yup
    .string()
    .required('Mật khẩu không được để trống')
    .min(
      8,
      'Mật khẩu tối thiểu 8 ký tự, ít nhất có 1 ký tự viết hoa, 1 ký tự số và 1 ký tự đặc biệt'
    )
    .max(
      20,
      'Mật khẩu tối thiểu 8 ký tự, ít nhất có 1 ký tự viết hoa, 1 ký tự số và 1 ký tự đặc biệt'
    )
    .matches(
      VALIDATE_STRING_PASSWORD,
      'Mật khẩu tối thiểu 8 ký tự, ít nhất có 1 ký tự viết hoa, 1 ký tự số và 1 ký tự đặc biệt'
    ),

  password: yup
    .string()
    .required('Mật khẩu không được để trống')
    .min(
      8,
      'Mật khẩu tối thiểu 8 ký tự, ít nhất có 1 ký tự viết hoa, 1 ký tự số và 1 ký tự đặc biệt'
    )
    .max(
      20,
      'Mật khẩu tối thiểu 8 ký tự, ít nhất có 1 ký tự viết hoa, 1 ký tự số và 1 ký tự đặc biệt'
    )
    .matches(
      VALIDATE_STRING_PASSWORD,
      'Mật khẩu tối thiểu 8 ký tự, ít nhất có 1 ký tự viết hoa, 1 ký tự số và 1 ký tự đặc biệt'
    ),

  confirmPassword: yup
    .string()
    .required('Mật khẩu không trùng khớp')
    .oneOf([yup.ref('password'), null], 'Mật khẩu phải trùng khớp'),
})
