import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import { StyleError } from 'pages/auth/style'
import { StyleAction } from 'pages/order/style'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ConfigurePriceAPI } from 'services/servicesAPI'
import { usePrice } from '../../../context/PriceContext'
import {
  ActionButton,
  StyleCellHover,
  StyleTableCell,
  StyleTitle,
} from './style'

export default function TableWeek() {
  const editingRowRef = useRef<HTMLTableRowElement>(null)
  const [editingRowId, setEditingRowId] = useState<number | null>(null)
  const [editValue, setEditValue] = useState<any>()

  const [errorPrice, setErrorPrice] = useState('')
  const { data, fetchData } = usePrice()
  const { id, provinceId } = useParams()
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertSuccess, setAlertError } = useAlert()

  const handleStartEditing = (id: number) => {
    setEditingRowId(id)
  }

  const handleCancelEditing = () => {
    setEditingRowId(null)
    setErrorPrice('')

    setEditValue(undefined)
  }

  const handleSave = async (idSave: string) => {
    if (errorPrice) {
      return
    }
    setLoadingTrue()

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await ConfigurePriceAPI.updatePriceListCountryById(editValue, idSave)
      setLoadingFalse()
      setAlertSuccess('Lưu thay đổi thành công')
      fetchData(id, provinceId)
      setEditingRowId(null)
      setEditValue(undefined)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoadingFalse()
      setAlertError(error.response?.data?.message)
    }
  }
  const handleChange = (id: string, field: any, value: any) => {
    const regex = /^[0-9]\d*$/

    if (value) {
      if (!regex.test(value)) {
        setErrorPrice('Giá tiền sai định dạng')
      } else {
        setErrorPrice('')
      }
    } else {
      setErrorPrice('Giá tiền không được để trống')
    }

    const filteredArray = data.filter((item: any) => item.id === id)
    const newObj = {
      filteredArray,
      ...editValue,
      [field]: value,
    } as any
    setEditValue({
      name: newObj.name,
      priceValue: newObj.priceValue,
      startDateTime: newObj.startDateTime,
      endDateTime: newObj.endDateTime,
    })
  }

  useEffect(() => {
    const handleDocumentMouseDown = (event: MouseEvent) => {
      if (
        editingRowRef.current &&
        !editingRowRef.current.contains(event.target as Node)
      ) {
        setEditingRowId(null)
        setErrorPrice('')
      }
    }
    document.addEventListener('mousedown', handleDocumentMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleDocumentMouseDown)
    }
  }, [editingRowRef, setEditingRowId])
  useEffect(() => {
    setErrorPrice('')
  }, [editingRowId])
  return (
    <>
      <StyleTitle style={{ marginTop: '30px' }}>Thứ ngày</StyleTitle>
      {data && (
        <TableContainer
          component={Paper}
          className="box-shadow"
          ref={editingRowRef}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <StyleTableCell width={50} align="center">
                  STT
                </StyleTableCell>

                <StyleTableCell align="center">Ngày</StyleTableCell>
                <StyleTableCell align="center">Giá cộng thêm</StyleTableCell>
                {editingRowId && (
                  <StyleTableCell align="center">Chức năng</StyleTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ?.filter((item: any) => item?.rule?.name === 'weekend')
                ?.map((row: IPriceCountry, index: number) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      height: '60px',
                    }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">
                      Cuối tuần(Thứ 7 và chủ nhật)
                    </TableCell>
                    <TableCell align="center">
                      {editingRowId === row.id ? (
                        <>
                          <TextField
                            size="small"
                            id={`price-${row.id}`}
                            sx={{ width: '100%' }}
                            defaultValue={
                              row.priceValue == null ? '0' : row.priceValue
                            }
                            onChange={(e: any) =>
                              handleChange(row.id, 'priceValue', e.target.value)
                            }
                            type="number"
                            inputProps={{
                              step: 1000,
                              inputMode: 'numeric',
                            }}
                          />
                          {errorPrice && (
                            <StyleError
                              style={{
                                textAlign: 'left',
                                paddingTop: '5px',
                              }}
                            >
                              {errorPrice as string}
                            </StyleError>
                          )}
                        </>
                      ) : (
                        <StyleCellHover
                          onClick={() => handleStartEditing(row.id)}
                        >
                          {row.priceValue == null ? '0' : row.priceValue}
                        </StyleCellHover>
                      )}
                    </TableCell>

                    {editingRowId === row.id && (
                      <>
                        <TableCell align="center">
                          <StyleAction>
                            <ActionButton
                              variant="contained"
                              style={{
                                backgroundColor: '#1BC19D',
                              }}
                              color="primary"
                              size="small"
                              onClick={() => handleSave(row.id)}
                            >
                              Lưu
                            </ActionButton>
                            <ActionButton
                              variant="contained"
                              style={{
                                backgroundColor: '#fff',
                                color: '#6C6C6C',
                                border: '1px solid #6C6C6C',
                                marginLeft: '10px',
                              }}
                              size="small"
                              onClick={() => handleCancelEditing()}
                            >
                              Huỷ
                            </ActionButton>
                          </StyleAction>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
