import React from 'react'
import PromotionCodeList from './components/PromotionCodeList'

const PromotionCode = () => {
  return (
    <>
      <PromotionCodeList />
    </>
  )
}

export default PromotionCode
