import AddCircleIcon from '@mui/icons-material/AddCircle'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import SaveIcon from '@mui/icons-material/Save'
import WidgetsIcon from '@mui/icons-material/Widgets'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Breadcrumb from 'components/Breadcrumbs'
import InputField from 'components/FormControl/InputField'
import Item from 'components/Item'
import BasicModal from 'components/Modal'
import { PART_TIME_STAFF_CREATE_FILE } from 'constants/breadcrumbs'
import { useAlert } from 'contexts/AlertContext'
import { useFile } from 'contexts/FileContext'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import staffApi from 'services/staffAPI'
import { uploadImage } from 'utils/uploadImage'
import TypeFileList from '../TypeFile/TypeFileList'
import {
  StyleAddImage,
  StyleAddImageShow,
  StyleError,
  StyleHeaderItem,
  StyleLink,
  StyleLinksAdd,
  StyleLinksDelete,
  StyleTitle,
} from './style'
import { useLoading } from 'contexts/LoadingContext'
import { FileTypeEnum, getFileSize } from 'utils/FileUtils'
import { MAX_FILE_SIZE } from 'constants/common'
import { IMAGE_TYPE } from 'constants/common'

interface IFormData {
  staffIdentifierLabelId: string
  title: string
  file: []
  note: string
}

const FileCreate = () => {
  const navigate = useNavigate()
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const [modalFile, setModalFile] = useState(false)
  const [upload, setUpload] = useState(false)
  const [modalOpenFile, setModalOpenFile] = useState(false)
  const [imageShow, setImageShow] = useState<any>([])
  const [formsData, setFormsData] = useState<IFormData[]>([
    // {staffIdentifierLabelId:'', title: '', file: [], note: '' },
  ])
  const [selectTypeFile, setSelectTypeFile] = useState<any[]>([])
  const { setAlertError, setAlertSuccess } = useAlert()
  const { fileData, addFileData } = useFile()

  const [errorTypeFile, setErrorTypeFile] = useState(false)
  const [errorFile, setErrorFile] = useState(false)

  const fetchDataTypeFile = async () => {
    const { data } = await staffApi.getIdentifierLabel()
    const dataFormat = data?.filter((item: any) => item.status === true)
    const dataFinal = dataFormat?.map((item: any) => {
      return { ...item, title: item?.name }
    })
    setSelectTypeFile(dataFinal)
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target
    setFormsData((prevFormsData) =>
      prevFormsData.map((formData, i) =>
        i === index ? { ...formData, [name]: value } : formData
      )
    )
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setUpload(true)
    const files = event.target.files
    try {
      if (files) {
        Array.from(files).map(async (file: File) => {
          const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
          const name = file?.name.split('.')

          if (fileSize > MAX_FILE_SIZE) {
            setAlertError(
              `Dung lượng ảnh tối đa cho phép là ${MAX_FILE_SIZE} MB`
            )
            setUpload(false)
            return
          }

          if (!name[name.length - 1].match(IMAGE_TYPE)) {
            setAlertError('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
            setUpload(false)
            return
          }

          if (fileSize === 0) {
            setAlertError(`Ảnh lỗi`)
            setUpload(false)
            return
          }

          const response: IUploadImg = await uploadImage(file)

          setFormsData((prevFormValues) => {
            const updatedForms = [...prevFormValues]
            updatedForms[index] = {
              ...updatedForms[index],
              // file: [...updatedForms[index].file, ...Array.from(files)],
              file: [...updatedForms[index].file, response.origin] as any,
            }
            return updatedForms
          })
          setUpload(false)
          setAlertSuccess('Tải ảnh thành công')
        })
      }
    } catch (e: any) {
      setAlertError(e.response.data.message)
      setUpload(false)
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setLoadingTrue()
    let hasError = false

    formsData?.map((item: any) => {
      if (!item?.title) {
        setErrorTypeFile(true)
        hasError = true
        setLoadingFalse()
      }
      if (item?.file.length == 0) {
        setErrorFile(true)
        hasError = true
        setLoadingFalse()
      }
    })

    if (!hasError) {
      setLoadingFalse()
      addFileData(formsData)
      navigate(`/staff-manager/part-time-staff/create`)
      setAlertSuccess('Thêm bộ hồ sơ thành công')
    }
  }

  const handleAddForm = () => {
    setFormsData((prevFormsData) => [
      ...prevFormsData,
      { staffIdentifierLabelId: '', title: '', file: [], note: '' },
    ])
    setErrorTypeFile(false)
    setErrorFile(false)
  }

  const handleRemoveForm = (index: number) => {
    setFormsData((prevFormsData) =>
      prevFormsData.filter((formData, i) => i !== index)
    )
  }

  const handleRemoveFile = (formIndex: number, fileIndex: number) => {
    setFormsData((prevFormList) => {
      const updatedForms = [...prevFormList]
      // updatedForms[formIndex].file.splice(fileIndex, 1)

      const arrNew = updatedForms[formIndex]?.file?.filter(
        (formData, i) => i !== fileIndex
      )

      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        file: arrNew as any,
      }

      return updatedForms
    })
  }

  const handleTypeFile = (event: any, value: any) => {
    setFormsData((prevFormsData) =>
      prevFormsData.map((formData, i) =>
        i === value?.index
          ? {
              ...formData,
              title: value?.name,
              staffIdentifierLabelId: value?.id,
            }
          : formData
      )
    )
  }

  const handleShowImage = (idForm: number, idItemFile: number) => {
    setModalOpenFile(true)
    const arrForm = formsData?.filter(
      (item: any, index: number) => idForm == index
    )

    const arrFile = arrForm[0]?.file?.filter(
      (file: any, index: number) => index == idItemFile
    )
    setImageShow(arrFile)
  }

  useEffect(() => {
    const dataFormat = fileData[0]?.map((item: any) => {
      return { ...item, title: item?.title }
    })

    if (dataFormat) {
      setFormsData(dataFormat)
    }

    fetchDataTypeFile()
  }, [])

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={PART_TIME_STAFF_CREATE_FILE} />
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <StyleHeaderItem>
            <StyleTitle>Bộ hồ sơ</StyleTitle>
          </StyleHeaderItem>
          <div
            style={{ width: '180px', marginTop: '24px' }}
            onClick={handleAddForm}
          >
            <StyleLinksAdd>
              <AddCircleIcon sx={{ marginRight: '10px', color: 'white' }} />
              <StyleLink>Thêm hồ sơ</StyleLink>
            </StyleLinksAdd>
          </div>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '24px',
                marginBottom: '14px',
              }}
            >
              {formsData.map((formData, indexForm) => (
                <div key={indexForm} style={{ width: '49%' }}>
                  <div style={{ display: 'flex', gap: '24px' }}>
                    <Typography variant="h6" gutterBottom>
                      Hồ Sơ {indexForm + 1}
                    </Typography>
                    <div
                      style={{ width: '120px' }}
                      onClick={() => handleRemoveForm(indexForm)}
                    >
                      <StyleLinksDelete>
                        <ClearOutlinedIcon
                          sx={{ marginRight: '5px', color: 'white' }}
                        />
                        <StyleLink>Xoá hồ sơ</StyleLink>
                      </StyleLinksDelete>
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '100%' }}>
                      <Autocomplete
                        size="small"
                        id={`title-${indexForm}`}
                        disablePortal
                        options={selectTypeFile}
                        sx={{ width: '100%' }}
                        getOptionLabel={(option) => option?.title || ''}
                        isOptionEqualToValue={(option, value) =>
                          option?.title === value?.title
                        }
                        noOptionsText="Danh sách rỗng"
                        value={formData}
                        onChange={(event, value) =>
                          handleTypeFile(event, { ...value, index: indexForm })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // value={formData.title}
                            label="Loại hồ sơ"
                            placeholder="Loại hồ sơ"
                          />
                        )}
                      />

                      <StyleError style={{ marginTop: '10px' }}>
                        {errorTypeFile && !formData.title
                          ? 'Loại giấy tờ không được để trống'
                          : null}
                      </StyleError>

                      <div style={{ display: 'flex' }}>
                        {upload ? (
                          <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                            sx={{
                              textTransform: 'none',
                              marginTop: '10px',
                              padding: '3px 6px',
                            }}
                          >
                            Tải lên
                          </LoadingButton>
                        ) : (
                          <Button
                            component="label"
                            variant="contained"
                            sx={{
                              textTransform: 'none',
                              marginTop: '10px',
                              padding: '3px 6px',
                            }}
                            className="bgr-global"
                          >
                            <FileUploadOutlinedIcon />
                            <input
                              id={`file-${indexForm}`}
                              hidden
                              accept="*/*"
                              type="file"
                              multiple
                              onChange={(event) =>
                                handleFileChange(event, indexForm)
                              }
                            />
                            Tải lên
                          </Button>
                        )}
                      </div>

                      <StyleError style={{ marginTop: '10px' }}>
                        {errorFile && formData.file.length == 0
                          ? 'File không được để trống'
                          : null}
                      </StyleError>

                      {formData?.file
                        ? formData?.file?.map(
                            (item: any, indexFile: number) => (
                              <div
                                style={{
                                  position: 'relative',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                }}
                                key={indexFile}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                    marginTop: '10px',
                                  }}
                                >
                                  <StyleAddImage
                                    src={item}
                                    onClick={() =>
                                      handleShowImage(indexForm, indexFile)
                                    }
                                    style={{ cursor: 'pointer' }}
                                  />

                                  <Typography
                                    sx={{
                                      position: 'absolute',
                                      marginLeft: '100px',
                                      cursor: 'pointer',
                                      maxWidth: '50%',
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {item}
                                  </Typography>
                                </div>
                                <DeleteOutlineOutlinedIcon
                                  color="error"
                                  sx={{
                                    marginTop: '10px',
                                    fontSize: '25px',
                                    '&:hover': {
                                      cursor: 'pointer',
                                      transform: 'scale(1.3)',
                                    },
                                  }}
                                  onClick={() =>
                                    handleRemoveFile(indexForm, indexFile)
                                  }
                                />
                              </div>
                            )
                          )
                        : null}

                      <InputField
                        label="Ghi chú"
                        id={`note-${indexForm}`}
                        name="note"
                        value={formData.note}
                        onChange={(event: any) =>
                          handleChange(event, indexForm)
                        }
                        size="small"
                      />
                    </div>
                    <div>
                      <IconButton
                        sx={{ color: 'black' }}
                        aria-label="title"
                        component="label"
                      >
                        <WidgetsIcon
                          sx={{ height: 25, width: 25, cursor: 'pointer' }}
                          onClick={() => setModalFile(true)}
                        />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}
              <BasicModal
                open={modalOpenFile}
                handleClose={() => setModalOpenFile(false)}
              >
                <StyleAddImageShow src={imageShow} />
              </BasicModal>
            </div>
            {formsData.length > 0 ? (
              <Stack
                spacing={2}
                direction="row"
                sx={{
                  justifyContent: 'flex-start',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ width: '99px' }}
                  className="bgr-global"
                >
                  Lưu
                </Button>
                <Button
                  variant="outlined"
                  sx={{ width: '100px' }}
                  onClick={() =>
                    navigate(`/staff-manager/part-time-staff/create`)
                  }
                >
                  Huỷ
                </Button>
              </Stack>
            ) : (
              <Stack
                spacing={2}
                direction="row"
                sx={{
                  justifyContent: 'flex-start',
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ width: '100px' }}
                  onClick={() =>
                    navigate(`/staff-manager/part-time-staff/create`)
                  }
                >
                  Huỷ
                </Button>
              </Stack>
            )}
          </form>
          <TypeFileList
            modalTypeFile={modalFile}
            setModalTypeFile={setModalFile}
            resetData={fetchDataTypeFile}
          />
        </Item>
      </Grid>
    </Grid>
  )
}

export default FileCreate
