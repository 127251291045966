import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import InputField from 'components/FormControl/InputField'
import { StyleError } from 'components/FormControl/styleInputField'
import BasicModal from 'components/Modal'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import dayjs from 'dayjs'
import { StyleAction } from 'pages/order/style'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ConfigurePriceAPI } from 'services/servicesAPI'
import { usePrice } from '../../../context/PriceContext'
import {
  ActionButton,
  StyleCellHover,
  StyleLink,
  StyleLinks,
  StyleTableCell,
  StyleTitle,
} from '../TableWeek/style'

interface IErrorState {
  name: string
  price: string
  startTime: string
  endTime: string
}
export default function TableTime() {
  const editingRowRef = useRef<HTMLTableRowElement>(null)
  const [modalOpenDelete, setModalOpenDelete] = useState(false)
  const [editingRowId, setEditingRowId] = useState<number | null>(null)
  const [editValue, setEditValue] = useState<any>()

  const [error, setError] = useState<IErrorState>({
    name: '',
    price: '',
    startTime: '',
    endTime: '',
  })
  const [status, setStatus] = useState({
    id: 1,
  })
  const [isAdd, setIsAdd] = useState(false)
  const { data, fetchData } = usePrice()
  const { id, provinceId, areaId } = useParams()
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertSuccess, setAlertError, setAlertClose } = useAlert()
  const [addValue, setAddValue] = useState<any>()
  const handleStartEditing = (id: number) => {
    setEditingRowId(id)
  }

  const handleCancelEditing = () => {
    setEditingRowId(null)
    setError({
      name: '',
      price: '',
      startTime: '',
      endTime: '',
    })
    setEditValue(undefined)
  }

  const handleSave = async (idSave: string) => {
    const hasNonEmptyValue = Object.values(error).some(
      (value) => typeof value === 'string' && value.trim() !== ''
    )
    if (hasNonEmptyValue) {
      return
    }
    setLoadingTrue()
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await ConfigurePriceAPI.updatePriceListCountryById(
        {
          name: editValue.name,
          priceValue: editValue.priceValue,
          startDateTime: editValue.startDateTime
            ? new Date(
                `${'2023-04-28'} ${editValue.startDateTime}`
              ).toISOString()
            : undefined,

          endDateTime: editValue.endDateTime
            ? new Date(`${'2023-04-28'} ${editValue.endDateTime}`).toISOString()
            : undefined,
        },
        idSave
      )
      setLoadingFalse()
      setAlertSuccess('Lưu thay đổi thành công')
      fetchData(id, provinceId)
      setEditingRowId(null)
      setEditValue(undefined)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setEditingRowId(null)
      setLoadingFalse()
      setAlertError(error.response?.data?.message)
    }
  }
  const handleChange = (id: string, field: any, value: any) => {
    const regex = /^[0-9]\d*$/
    if (field === 'name') {
      if (!value) {
        setError((prevState) => ({
          ...prevState,
          name: 'Tên ngày lễ không được để trống',
        }))
      } else {
        setError((prevState) => ({
          ...prevState,
          name: '',
        }))
      }
    }
    if (field === 'startDateTime') {
      if (!value) {
        setError((prevState) => ({
          ...prevState,
          startTime: 'Tên ngày lễ không được để trống',
        }))
      } else {
        setError((prevState) => ({
          ...prevState,
          startTime: '',
        }))
      }
    }
    if (field === 'endDateTime') {
      if (!value) {
        setError((prevState) => ({
          ...prevState,
          endTime: 'Tên ngày lễ không được để trống',
        }))
      } else {
        setError((prevState) => ({
          ...prevState,
          endTime: '',
        }))
      }
    }
    if (field === 'priceValue') {
      if (value) {
        if (!regex.test(value)) {
          setError((prevState) => ({
            ...prevState,
            price: 'Giá tiền không đúng định dạng',
          }))
        } else {
          setError((prevState) => ({
            ...prevState,
            price: '',
          }))
        }
      } else {
        setError((prevState) => ({
          ...prevState,
          price: 'Giá tiền không được để trống',
        }))
      }
    }

    const newObj = {
      ...editValue,
      [field]: value,
    } as any
    setEditValue({
      name: newObj.name,
      priceValue: newObj.priceValue,
      startDateTime: newObj.startDateTime,
      endDateTime: newObj.endDateTime,
    })
  }
  const handleAdd = (field: any, value: any) => {
    const regex = /^[0-9]\d*$/
    if (field === 'name') {
      if (!value) {
        setError((prevState) => ({
          ...prevState,
          name: 'Tên ngày lễ không được để trống',
        }))
      } else {
        setError((prevState) => ({
          ...prevState,
          name: '',
        }))
      }
    }

    if (field === 'startDateTime') {
      if (!value) {
        setError((prevState) => ({
          ...prevState,
          startTime: 'Tên ngày lễ không được để trống',
        }))
      } else {
        setError((prevState) => ({
          ...prevState,
          startTime: '',
        }))
      }
    }
    if (field === 'endDateTime') {
      if (!value) {
        setError((prevState) => ({
          ...prevState,
          endTime: 'Tên ngày lễ không được để trống',
        }))
      } else {
        setError((prevState) => ({
          ...prevState,
          endTime: '',
        }))
      }
    }
    if (field === 'priceValue') {
      if (value) {
        if (!regex.test(value)) {
          setError((prevState) => ({
            ...prevState,
            price: 'Giá tiền không đúng định dạng',
          }))
        } else {
          setError((prevState) => ({
            ...prevState,
            price: '',
          }))
        }
      } else {
        setError((prevState) => ({
          ...prevState,
          price: 'Giá tiền không được để trống',
        }))
      }
    }
    const newObj = {
      ...addValue,
      [field]: value,
    } as any
    setAddValue({
      areaId: areaId,
      ruleName: 'time',
      name: newObj.name,
      priceValue: newObj.priceValue,
      startDateTime: newObj.startDateTime,
      endDateTime: newObj.endDateTime,
    })
  }
  const onCreated = async () => {
    const regex = /^[0-9]\d*$/
    const tmpErr: any = { ...error }
    if (!addValue?.name) {
      tmpErr['name'] = 'Tên ngày lễ không được để trống'
    }
    if (!addValue?.priceValue) {
      tmpErr['price'] = 'Tên ngày lễ không được để trống'
    } else if (!regex.test(addValue?.priceValue)) {
      tmpErr['price'] = 'Giá tiền sai định dạng'
    }
    if (!addValue?.startDateTime) {
      tmpErr['startTime'] = 'Ngày bắt đầu không được để trống'
    }
    if (!addValue?.endDateTime) {
      tmpErr['endTime'] = 'Ngày kết thúc không được để trống'
    }
    if (addValue?.name) {
      tmpErr['name'] = ''
    }
    if (addValue?.priceValue) {
      tmpErr['price'] = ''
    }
    if (addValue?.startDateTime) {
      tmpErr['startTime'] = ''
    }
    if (addValue?.endDateTime) {
      tmpErr['endTime'] = ''
    }
    setError(tmpErr)
    if (Object.values(tmpErr).filter((f) => f).length) {
      return
    }
    setLoadingTrue()
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await ConfigurePriceAPI.createPriceListCountry({
        areaId: areaId,
        ruleName: 'time',
        name: addValue.name,
        priceValue: addValue.priceValue,
        startDateTime: new Date(
          `${'2023-04-28'} ${addValue.startDateTime}`
        ).toISOString(),

        endDateTime: new Date(
          `${'2023-04-28'} ${addValue.endDateTime}`
        ).toISOString(),
      })
      setLoadingFalse()
      setAlertSuccess('Lưu thay đổi thành công')

      fetchData(id, provinceId)
      setIsAdd(false)
      setAddValue(undefined)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoadingFalse()
      setAlertError(error.response?.data?.message)
    }
  }
  const handleModalDelete = (id: number) => {
    setModalOpenDelete(true)
    setStatus({
      id: id,
    })
  }
  const handleDelete = async (idDelete: number) => {
    setAlertClose()
    try {
      await ConfigurePriceAPI.deletePriceListCountry(idDelete)
      setModalOpenDelete(false)
      setAlertSuccess('Xóa ngày lễ  thành công')
      fetchData(id, provinceId)
    } catch (err) {
      setAlertError('Xoá ngày lễ  không thành công')
    }
  }
  useEffect(() => {
    const handleDocumentMouseDown = (event: MouseEvent) => {
      if (
        editingRowRef.current &&
        !editingRowRef.current.contains(event.target as Node)
      ) {
        setEditingRowId(null)

        setError({
          name: '',
          price: '',
          startTime: '',
          endTime: '',
        })
      }
    }
    document.addEventListener('mousedown', handleDocumentMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleDocumentMouseDown)
    }
  }, [editingRowRef, setEditingRowId])
  useEffect(() => {
    setError({
      name: '',
      price: '',
      startTime: '',
      endTime: '',
    })
  }, [editingRowId])

  return (
    <>
      <StyleTitle style={{ marginTop: '30px' }}>Giờ</StyleTitle>
      <StyleLinks onClick={() => setIsAdd(true)}>
        <AddCircleIcon sx={{ marginRight: '5px' }} />
        <StyleLink
          onClick={() => {
            setEditingRowId(null)
            setError({
              name: '',
              price: '',
              startTime: '',
              endTime: '',
            })
          }}
        >
          Thêm giờ
        </StyleLink>
      </StyleLinks>
      {data && (
        <TableContainer
          component={Paper}
          className="box-shadow"
          ref={editingRowRef}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <StyleTableCell width={50} align="center">
                  STT
                </StyleTableCell>
                <StyleTableCell align="left" width={200}>
                  Nội dung giờ
                </StyleTableCell>
                <StyleTableCell align="center">Giờ bắt đầu</StyleTableCell>
                <StyleTableCell align="center">Giờ kết thúc</StyleTableCell>
                <StyleTableCell align="center">Giá cộng thêm</StyleTableCell>
                <StyleTableCell align="center">Chức năng</StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ?.filter((item: any) => item?.rule?.name === 'time')
                ?.map((row: IPriceCountry, index: number) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      height: '60px',
                    }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">
                      {editingRowId === row.id ? (
                        <>
                          <TextField
                            size="small"
                            id={`name-${row.id}`}
                            defaultValue={row.name}
                            onChange={(e: any) => {
                              handleChange(row.id, 'name', e.target.value)
                            }}
                          />
                          {error.name && (
                            <StyleError
                              style={{
                                textAlign: 'left',
                                paddingTop: '5px',
                              }}
                            >
                              {error.name as string}
                            </StyleError>
                          )}
                        </>
                      ) : (
                        <StyleCellHover
                          onClick={() => handleStartEditing(row.id)}
                        >
                          {row.name}
                        </StyleCellHover>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRowId === row.id ? (
                        <>
                          <InputField
                            type="time"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            defaultValue={dayjs(row.startDateTime)
                              .locale('vi')
                              .format('HH:mm')}
                            onChange={(e: any) => {
                              handleChange(
                                row.id,
                                'startDateTime',
                                e.target.value
                              )
                            }}
                          />
                          {error.startTime && (
                            <StyleError
                              style={{
                                textAlign: 'left',
                                paddingTop: '5px',
                              }}
                            >
                              {error.startTime as string}
                            </StyleError>
                          )}
                        </>
                      ) : (
                        <StyleCellHover
                          onClick={() => handleStartEditing(row.id)}
                        >
                          {`${dayjs(row.startDateTime).format('hh:mm A')}`}
                        </StyleCellHover>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRowId === row.id ? (
                        <>
                          <InputField
                            type="time"
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            defaultValue={dayjs(row.endDateTime)
                              .locale('vi')
                              .format('HH:mm')}
                            onChange={(e: any) => {
                              handleChange(
                                row.id,
                                'endDateTime',
                                e.target.value
                              )
                            }}
                          />
                          {error.endTime && (
                            <StyleError
                              style={{
                                textAlign: 'left',
                                paddingTop: '5px',
                              }}
                            >
                              {error.endTime as string}
                            </StyleError>
                          )}
                        </>
                      ) : (
                        <StyleCellHover
                          onClick={() => handleStartEditing(row.id)}
                        >
                          {`${dayjs(row.endDateTime).format('hh:mm A')}`}
                        </StyleCellHover>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRowId === row.id ? (
                        <>
                          <TextField
                            size="small"
                            id={`price-${row.id}`}
                            defaultValue={
                              row.priceValue == null ? '0' : row.priceValue
                            }
                            onChange={(e: any) =>
                              handleChange(row.id, 'priceValue', e.target.value)
                            }
                          />
                          {error.price && (
                            <StyleError
                              style={{
                                textAlign: 'left',
                                paddingTop: '5px',
                              }}
                            >
                              {error.price as string}
                            </StyleError>
                          )}
                        </>
                      ) : (
                        <StyleCellHover
                          onClick={() => handleStartEditing(row.id)}
                        >
                          {row.priceValue == null ? '0' : row.priceValue}
                        </StyleCellHover>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {editingRowId === row.id ? (
                        <>
                          <StyleAction>
                            <ActionButton
                              variant="contained"
                              style={{
                                backgroundColor: '#1BC19D',
                              }}
                              color="primary"
                              size="small"
                              onClick={() => handleSave(row.id)}
                            >
                              Lưu
                            </ActionButton>
                            <ActionButton
                              variant="contained"
                              style={{
                                backgroundColor: '#fff',
                                color: '#6C6C6C',
                                border: '1px solid #6C6C6C',
                                marginLeft: '10px',
                              }}
                              size="small"
                              onClick={() => handleCancelEditing()}
                            >
                              Huỷ
                            </ActionButton>
                          </StyleAction>
                        </>
                      ) : (
                        <ActionButton
                          style={{
                            backgroundColor: 'red',
                          }}
                          onClick={() => {
                            handleModalDelete(row?.id)
                          }}
                          size="small"
                        >
                          Xoá
                        </ActionButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {isAdd && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {data?.filter((item: any) => item?.rule?.name === 'time')
                      .length + 1}
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      size="small"
                      onChange={(e: any) => handleAdd('name', e.target.value)}
                    />
                    {error.name && (
                      <StyleError
                        style={{
                          textAlign: 'left',
                          paddingTop: '5px',
                        }}
                      >
                        {error.name as string}
                      </StyleError>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <>
                      <InputField
                        type="time"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e: any) => {
                          handleAdd('startDateTime', e.target.value)
                        }}
                      />
                      {error.startTime && (
                        <StyleError
                          style={{
                            textAlign: 'left',
                            paddingTop: '5px',
                          }}
                        >
                          {error.startTime as string}
                        </StyleError>
                      )}
                    </>
                  </TableCell>
                  <TableCell align="center">
                    <InputField
                      type="time"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e: any) => {
                        handleAdd('endDateTime', e.target.value)
                      }}
                    />
                    {error.endTime && (
                      <StyleError
                        style={{
                          textAlign: 'left',
                          paddingTop: '5px',
                        }}
                      >
                        {error.endTime as string}
                      </StyleError>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      size="small"
                      onChange={(e: any) =>
                        handleAdd('priceValue', e.target.value)
                      }
                    />
                    {error.price && (
                      <StyleError
                        style={{
                          textAlign: 'left',
                          paddingTop: '5px',
                        }}
                      >
                        {error.price as string}
                      </StyleError>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <StyleAction>
                      <ActionButton
                        variant="contained"
                        style={{
                          backgroundColor: '#1BC19D',
                        }}
                        color="primary"
                        size="small"
                        onClick={() => {
                          onCreated()
                        }}
                      >
                        Lưu
                      </ActionButton>
                      <ActionButton
                        variant="contained"
                        style={{
                          backgroundColor: '#fff',
                          color: '#6C6C6C',
                          border: '1px solid #6C6C6C',
                          marginLeft: '10px',
                        }}
                        size="small"
                        onClick={() => setIsAdd(false)}
                      >
                        Huỷ
                      </ActionButton>
                    </StyleAction>
                  </TableCell>
                </TableRow>
              )}
              <BasicModal
                open={modalOpenDelete}
                handleClose={() => setModalOpenDelete(false)}
              >
                <Typography>
                  Bạn có chắc chắn muốn xoá ngày lễ này chứ?
                </Typography>
                <Button
                  sx={{ margin: '8px', marginBottom: '0px' }}
                  variant="contained"
                  color="error"
                  onClick={() => handleDelete(status.id)}
                >
                  Xóa
                </Button>
                <Button
                  sx={{ margin: '8px', marginBottom: '0px' }}
                  onClick={() => setModalOpenDelete(false)}
                  variant="outlined"
                >
                  Hủy
                </Button>
              </BasicModal>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
