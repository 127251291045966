import styled from 'styled-components'
import { CSVLink } from 'react-csv'
import Button from '@mui/material/Button'
import { TableCell } from '@mui/material'

export const StyleTableHeader = styled.div`
  width: 100%;
  padding: 12px 0;
  justify-content: space-between;
  align-items: center;
`
export const StyleHeaderItem = styled.div`
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const StyleAddItem = styled.div`
  display: flex;
  margin: 10px 0px 23px;
`

export const StyleLinks = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  width: 150px;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin: 12px 0;
  transition: 0.1s ease-in-out;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`
export const StyleTitle = styled.h2`
  font-size: 25px;
  color: var(--color-primary);
  font-weight: bold;
  text-align: left;
`
export const StyleLink = styled.span`
  font-weight: bold;
`
export const StyleCSVLink = styled(CSVLink)`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #566787;
  font-size: 13px;
  background: #fff;
  min-width: 50px;
  margin-right: 10px;
  border-radius: 2px;
  border: none;
  padding: 6px 12px;
  transition: 0.3s ease-in-out 0s;
  &:hover {
    transform: scale(1.02);
  }
`
export const StyleButton = styled.button`
  position: relative;
  display: inline-flex;
  border: none;
  background-color: #fff;
  border-radius: 50%;
  padding: 8px;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgba(0, 0, 0, 0.54);
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
  }
`
export const StyleAction = styled.div`
  background-color: rgb(255, 255, 255);
  color: rgb(74, 74, 74);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  position: absolute;
  outline: 0px;
  opacity: 1;
  transform: none;
  transition: opacity 232ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 155ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 45px;
  left: 0px;
  transform-origin: 0px 0px;
  z-index: 99999;
`
export const StyleList = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 8px 0px;
  outline: 0px;
`

export const StyleItem = styled.li`
  min-height: auto;
  margin: 0px;
  border-radius: 0px;
  cursor: pointer;
  vertical-align: middle;
  color: inherit;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  padding: 6px 16px;
`
export const ActionButton = styled(Button)`
  && {
    color: #fff;
  }
  &:hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }
`

export const StyleAvatar = styled.div`
  display: flex !important;
  align-items: center;
  width: 100;
  height: 100;
  gap: 8px;
`
export const StyleBannerType = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: left;
`

export const StyleHide = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #aeafb3;
  width: 70px;
  border-radius: 5px;
  justify-content: left;
`

export const StyleDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #28c76f;
  width: 110px;
  border-radius: 5px;
  justify-content: left;
`
export const StyleTableCell = styled(TableCell)`
  && {
    font-weight: bold;
    color: var(--color-secondary);
  }
`
export const StyleAddImage = styled.img`
  width: 75px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
`
export const StyleTableCellItem = styled.p`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`
