import { yupResolver } from '@hookform/resolvers/yup'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import WidgetsIcon from '@mui/icons-material/Widgets'
import {
  Autocomplete,
  Avatar,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Breadcrumb from 'components/Breadcrumbs'
import InputField from 'components/FormControl/InputField'
import Item from 'components/Item'
import { PART_TIME_STAFF_EDIT } from 'constants/breadcrumbs'
import { IMAGE_TYPE, MAX_FILE_SIZE } from 'constants/common'
import {
  bank,
  dataHR,
  dataLeader,
  dataRecruiter,
  gender,
  maritalStatus,
  pileStatus,
  skills,
  source,
  typePaper,
} from 'constants/partTimeStaff'
import { useAlert } from 'contexts/AlertContext'
import { useFile } from 'contexts/FileContext'
import { useLoading } from 'contexts/LoadingContext'
import { useTemporaryDataStaff } from 'contexts/TemporaryDataStaffContext'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import staffApi from 'services/staffAPI'
import { FileTypeEnum, getFileSize } from 'utils/FileUtils'
import { uploadImage } from 'utils/uploadImage'
import { partTimeStaffCreateSchema_ } from 'validations/partTimeStaffSchema'
import LabelList from '../../../Label/components/LabelList'
import LocationCreate from '../../../Location/components/LocationCreate'
import LocationEdit from '../../../Location/components/LocationEdit'
import RelativesCreate from '../../../Relative/components/RelativesCreate'
import RelativesEdit from '../../../Relative/components/RelativesEdit'
import {
  StyleAvatar,
  StyleError,
  StyleHeaderItem,
  StyleImage,
  StyleInfo,
  StyleInput,
  StyleItem,
  StyleItemInformation,
  StyleLink,
  StyleLinks,
  StylePersonal,
  StyleTableCell,
  StyleTableCellItem,
  StyleTitleDetail,
} from './style'

const PersonalInformationEdit = () => {
  const { id } = useParams()
  const [staff, setStaff] = useState<any>([])
  const navigate = useNavigate()
  const { setAlertError, setAlertSuccess } = useAlert()
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { fileData, addFileData } = useFile()
  const { temporaryDataStaff, addTemporaryDataStaff } = useTemporaryDataStaff()
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<any>({
    resolver: yupResolver(partTimeStaffCreateSchema_),
    mode: 'onChange',
  })

  const formValues = watch()
  const [modalLabel, setModalLabel] = useState(false)
  const [image, setImage] = useState('')
  const [imageData, setDataImg] = useState('')
  const [fileChoose, setFileChoose] = useState<File | null>()
  const [error, setError] = useState('')
  const [selectService, setSelectService] = useState<any[]>([])
  const [selectLabel, setSelectLabel] = useState<any[]>([])
  const [selectedService, setSelectedService] = useState<any>([])
  const [selectedLabel, setSelectedLabel] = useState<any>([])
  const [recruiter, setRecruiter] = useState<any>()
  const [hRSpecialists, setHRSpecialists] = useState<any>()
  const [leader, setLeader] = useState<any>()
  const [selectedSkills, setSelectedSkills] = useState<any>([])
  const [dataRelatives, setDataRelatives] = useState<any[]>([])
  const [dataLocation, setDataLocation] = useState<any[]>([])

  // Khai báo thêm người thân
  const [modalRelativesCreate, setModalRelativesCreate] = useState(false)
  const [dataRelativeStorage, setDataRelativeStorage] = useState<any>([])
  const handleCloseRelativesCreate = () => setModalRelativesCreate(false)

  //Khai báo sửa người thân
  const [modalRelativesEdit, setModalRelativesEdit] = useState(false)
  const [dataRelativesId, setDataRelativesId] = useState<any[]>([])
  const [getIdRelatives, setGetIdRelatives] = useState<number>()
  const handleCloseRelativesEdit = () => setModalRelativesEdit(false)

  //Khai báo thêm vị trí
  const [modalLocation, setModalLocation] = useState(false)
  const [dataLocationStorage, setDataLocationStorage] = useState<any>([])
  const handleCloseLocation = () => setModalLocation(false)

  //Khai báo sửa vị trí
  const [modalLocationEdit, setModalLocationEdit] = useState(false)
  const [dataLocationId, setDataLocationId] = useState<any[]>([])
  const [getIdLocation, setGetIdLocation] = useState<number>()
  const handleCloseLocationEdit = () => setModalLocationEdit(false)

  //set value
  const [valuesGender, setValuesGender] = useState<any>([])
  const [valuesTypePaper, setValuesTypePaper] = useState<any>([])
  const [valuesMaritalStatus, setValuesMaritalStatus] = useState<any>([])
  const [valuesSource, setValuesSource] = useState<any>([])
  const [valuesPileStatus, setValuesPileStatus] = useState<any>([])
  const [valuesBank, setValuesBank] = useState<any>([])

  const handleChangeGender = (event: React.SyntheticEvent, newValue: any) => {
    setValuesGender(newValue?.props?.value)
  }

  const handleChangeTypePaper = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setValuesTypePaper(newValue?.props?.value)
  }

  const handleChangeMaritalStatus = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setValuesMaritalStatus(newValue?.props?.value)
  }

  const handleChangeSource = (event: React.SyntheticEvent, newValue: any) => {
    setValuesSource(newValue?.props?.value)
  }

  const handleChangePileStatus = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setValuesPileStatus(newValue?.props?.value)
  }

  const handleChangeBank = (event: React.SyntheticEvent, newValue: any) => {
    setValuesBank(newValue?.props?.value)
  }

  //Thêm người thân
  const handleDataRelativesCreate = (data: any) => {
    const dataFormat = dataRelativeStorage
    dataFormat.push(data)
    setDataRelativeStorage(dataFormat)
    setDataRelatives(dataFormat)
    handleCloseRelativesCreate()
    setAlertSuccess('Thêm người thân thành công')
  }

  //Sửa người thân (lấy data theo id)
  const handleRelativesEdit = (id: number) => {
    setModalRelativesEdit(true)
    const filteredArr = dataRelatives.filter((_, index: number) => index == id)
    setDataRelativesId(filteredArr)
  }

  //Sửa người thân (sau khi chỉnh sửa data)
  const handleRelativesEditAfter = (data: any) => {
    const index = dataRelatives.findIndex((_, index) => index == getIdRelatives) // tìm vị trí của object cần thay thế
    if (index !== -1) {
      // nếu tìm thấy
      dataRelatives.splice(index, 1, data) // thay thế object tại vị trí đó bằng object mới
      handleCloseRelativesEdit()
      setAlertSuccess('Sửa người thân thành công')
    } else {
      handleCloseRelativesEdit()
      setAlertError('Sửa người thân thất bại')
    }
  }

  //Xoá người thân
  const handleDeleteRelatives = (id: number) => {
    const filteredArr = dataRelatives.filter((_, index: number) => index != id)
    setDataRelatives(filteredArr)
    setDataRelativeStorage(filteredArr)
  }

  //Thêm vị trí
  const handleDataLocation = (data: any) => {
    if (dataLocation.length < 3) {
      const dataFormat = dataLocationStorage
      dataFormat.push(data)
      setDataLocationStorage(dataFormat)
      setDataLocation(dataFormat)
      handleCloseLocation()
      setAlertSuccess('Thêm vị trí làm việc thành công')
    } else {
      handleCloseLocation()
      setAlertError('Thêm được tối đa 3 vị trí làm việc')
    }
  }

  //Sửa vị trí (lấy data theo id)
  const handleLocationEdit = (id: number) => {
    setModalLocationEdit(true)
    const filteredArr = dataLocation.filter((_, index: number) => index == id)
    setDataLocationId(filteredArr)
  }

  //Sửa vị trí (sau khi chỉnh sửa data)
  const handleLocationEditAfter = (data: any) => {
    const index = dataLocation.findIndex((_, index) => index == getIdLocation) // tìm vị trí của object cần thay thế
    if (index !== -1) {
      // nếu tìm thấy
      dataLocation.splice(index, 1, data) // thay thế object tại vị trí đó bằng object mới
      handleCloseLocationEdit()
      setAlertSuccess('Sửa vị trí thành công')
    } else {
      handleCloseLocationEdit()
      setAlertError('Sửa vị trí thất bại')
    }
  }

  // Xoá vị trí
  const handleDeleteLocation = (id: number) => {
    const filteredArr = dataLocation.filter((_, index: number) => index != id)
    setDataLocation(filteredArr)
    setDataLocationStorage(filteredArr)
  }

  const fetchDataStaff = async () => {
    const { data } = await staffApi.getStaffById(id as string)

    addFileData(
      data?.staffIdentification?.map((staffIdent: any) => {
        return {
          ...staffIdent,
          // title: staffIdent?.identifierType,
        }
      })
    )

    setDataImg(data?.avatar)
    setImage(data?.avatar)

    setSelectedSkills(
      JSON.parse(data?.skill)?.map((skill: any) => {
        return { title: skill }
      })
    )
    setRecruiter({ title: data?.staffManagers?.recruitment })
    setHRSpecialists({ title: data?.staffManagers?.hrExecutive })
    setLeader({ title: data?.staffManagers?.leader })
    setSelectedLabel(
      data?.staffLabel?.map((label: any) => {
        return { ...label, title: label.name }
      })
    )

    setSelectedService(
      data?.services?.map((service: any) => {
        return {
          ...service,
          title: service?.staffService?.name,
          id: service?.staffService?.id,
        }
      })
    )

    setValuesGender(data?.gender)
    setValuesTypePaper(data?.citizenIdType)
    setValuesMaritalStatus(data?.maritalStatus)
    setValuesSource(data?.socialSource)
    setValuesPileStatus(data?.staffDeposit?.depositStatus)
    setValuesBank(data?.staffFinance?.[0]?.bankName)

    setDataRelatives(data?.relatives)
    setDataRelativeStorage(data?.relatives)
    const location = data?.districts?.map((location: any) => {
      return {
        country: { native: 'Việt Nam' },
        province: { name: location?.staffProvince?.fullName },
        district: { name: location?.staffDistrict?.fullName },
        address: {
          address: location?.fullLocation,
          location: {
            lng: location?.locationPoint?.coordinates?.[0],
            lat: location?.locationPoint?.coordinates?.[1],
          },
        },
      }
    })

    setDataLocation(location)
    setDataLocationStorage(location)

    setStaff(data)
    setValue('avatar', data?.avatar)
    setValue('fullName', data?.fullName)
    setValue('email', data?.email)
    setValue('phone', data?.phone)
    setValue('subPhone', data?.subPhone)
    setValue('staffCode', data?.displayId)
    setValue('born', data?.born)
    setValue('birth', data?.birth)
    setValue('groups', data?.staffManagers?.groups)
    setValue('zalo', data?.zalo)
    setValue('facebook', data?.facebook)
    setValue('contractsNumber', data?.contractNumber)
    setValue('contractSigningDate', data?.signingDate)
    setValue('DateStartWork', data?.startDate)
    setValue('homeTown', data?.homeTown)
    // setValue('typeOfIdentityDocument', data?.citizenIdType)
    setValue('numberOfIdentityDocument', data?.citizenId)
    setValue('registrationDate', data?.citizenIdDate)
    setValue('place', data?.placeCitizenId)
    setValue('permanentAddress', data?.permanentAddress)
    setValue('temporaryAddress', data?.shelterAddress)
    setValue('religion', data?.religion)
    setValue('vehicle', data?.vehicle)
    setValue('pile', data?.staffDeposit?.deposit)
    setValue('pileDate', data?.staffDeposit?.dateDeposit)
    setValue('depositRefundDate', data?.staffDeposit?.dateDepositBack)
    setValue('desire', data?.note)
    setValue('PersonalTaxCode', data?.personalTaxCode)
    setValue('accountNumber', data?.staffFinance?.[0]?.accountNumber)
    setValue('accountName', data?.staffFinance?.[0]?.accountName)
    setValue('bankBranch', data?.staffFinance?.[0]?.agency)
  }

  const fetchDataService = async () => {
    const { data } = await staffApi.getService()
    const dataFormat = data?.map((data: any) => {
      return { ...data, title: data?.name }
    })
    setSelectService(dataFormat)
  }

  const fetchDataLabel = async () => {
    const { data } = await staffApi.getLabel()
    const dataFormat = data?.map((data: any) => {
      return { ...data, title: data?.name }
    })
    setSelectLabel(dataFormat)
  }

  const handleService = (event: any, value: any) => {
    setSelectedService(value)
  }

  const handleLabel = (event: any, value: any) => {
    setSelectedLabel(value)
  }

  const handleSkills = (event: any, value: any) => {
    setSelectedSkills(value)
  }

  const handleRecruiter = (event: any, value: any) => {
    setRecruiter(value)
  }

  const handleHRSpecialists = (event: any, value: any) => {
    setHRSpecialists(value)
  }

  const handleLeader = (event: any, value: any) => {
    setLeader(value)
  }

  const handleUploadImage = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
    if (file) {
      const name = file?.name.split('.')
      if (fileSize > MAX_FILE_SIZE) {
        setError(`Dung lượng ảnh tối đa cho phép là ${MAX_FILE_SIZE} MB`)
        return
      }
      if (!name[name.length - 1].match(IMAGE_TYPE)) {
        setError('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
        return
      }
      if (fileSize === 0) {
        setError(`Ảnh lỗi`)
        return
      }
      setFileChoose(file)
      setImage(URL?.createObjectURL((target.files as FileList)[0]))
      setError('')
    }
  }

  const onSubmit = async (data: any) => {
    setLoadingTrue()
    let isUploadSuccess = false
    const dataCreate = { ...data }
    if (fileChoose) {
      try {
        const response: IUploadImg = await uploadImage(
          (fileChoose as File) ?? (temporaryDataStaff?.fileChoose as File)
        )
        dataCreate.avatar = response.origin
        isUploadSuccess = true
      } catch (error: any) {
        setError('Ảnh bìa không được để trống')
      }
      if (!isUploadSuccess) {
        setLoadingFalse()
        return
      }
    }

    if (dataRelatives.length < 2) {
      setLoadingFalse()
      setAlertError('Thêm tối thiểu 2 người thân')
    } else if (dataLocation.length < 1) {
      setLoadingFalse()
      setAlertError('Thêm tối thiểu 1 vị trí')
    } else {
      try {
        await staffApi.updateStaff(
          {
            avatar: dataCreate?.avatar,
            fullName: data?.fullName,
            email: data?.email,
            citizenId: data?.numberOfIdentityDocument,
            birth: dayjs(data?.birth)?.format('DD/MM/YYYY'),
            // phone: data?.phone,
            phonePrefix: '84',
            contractNumber: data?.contractsNumber,
            signingDate: data?.contractSigningDate,
            startDate: data?.DateStartWork,
            homeTown: data?.homeTown,
            citizenIdDate: data?.registrationDate,
            placeCitizenId: data?.place,
            permanentAddress: data?.permanentAddress,
            religion: data?.religion,
            vehicle: data?.vehicle,
            gender: valuesGender,
            shelterAddress: data?.temporaryAddress,
            subPhone: data?.subPhone,
            born: data?.born,
            skill: selectedSkills?.map((item: any) => item?.title),
            zalo: data?.zalo,
            timezone: 'Asian',
            services: selectedService?.map((item: any) => item.id),
            label: selectedLabel?.map((item: any) => item.id),
            maritalStatus: valuesMaritalStatus,
            citizenIdType: valuesTypePaper,
            deposit: {
              deposit: data?.pile,
              depositStatus: valuesPileStatus,
              dateDeposit: data?.pileDate,
              dateDepositBack: data?.depositRefundDate,
            },
            managers: {
              recruitment: recruiter?.title,
              hrExecutive: hRSpecialists?.title,
              leader: leader?.title,
              groups: data?.groups,
            },
            identification: fileData?.[0]?.map((data: any) => {
              return {
                id: data?.id,
                file: data?.file,
                note: data?.note,
                identifierType: data?.title,
                staffIdentifierLabelId: data?.staffIdentifierLabelId,
              }
            }),
            facebook: data?.facebook,
            socialSource: valuesSource,
            note: data?.desire,
            personalTaxCode: data?.PersonalTaxCode,
            district: dataLocation?.map((item: any) => {
              return {
                fullLocation: item?.address?.address,
                longitude: item?.address?.location?.lng,
                latitude: item?.address?.location?.lat,
              }
            }),
            relatives: dataRelatives?.map((item: any) => {
              delete item.staffId
              delete item.createdBy
              delete item.createdAt
              delete item.updatedBy
              delete item.updatedAt
              return item
            }),
            finance: {
              accountNumber: data?.accountNumber,
              accountName: data?.accountName,
              bankName: valuesBank,
              agency: data?.bankBranch,
            },
          },
          id as string
        )
        setLoadingFalse()
        setAlertSuccess('Sửa cộng tác viên thành công!')
        navigate('/staff-manager/part-time-staff')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setLoadingFalse()
        setAlertError(error?.response?.data?.message)
      }
    }
  }

  const handleTemporaryData = () => {
    addTemporaryDataStaff({
      ...formValues,
      fileChoose: fileChoose,
      avatar: image,
      skills: selectedSkills,
      recruiter: recruiter,
      hRSpecialists: hRSpecialists,
      leader: leader,
      labels: selectedLabel,
      servicerId: selectedService,
      gender: valuesGender,
      typePaper: valuesTypePaper,
      maritalStatus: valuesMaritalStatus,
      source: valuesSource,
      pileStatus: valuesPileStatus,
      bank: valuesBank,
      relatives: dataRelatives,
      locations: dataLocation,
    })
    navigate(`/staff-manager/part-time-staff/edit/${id}/file-edit`)
  }

  const hexToRGBA = (hex: string, alpha: number): string => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  useEffect(() => {
    fetchDataService()
  }, [])

  useEffect(() => {
    fetchDataLabel()
  }, [])

  // useEffect(() => {
  //   fetchDataStaff()
  // }, [])

  useEffect(() => {
    if (temporaryDataStaff.length != 0) {
      setImage(temporaryDataStaff?.avatar)
      setFileChoose(temporaryDataStaff?.fileChoose)
      setSelectedSkills(temporaryDataStaff?.skills)
      setSelectedService(temporaryDataStaff?.servicerId)
      setRecruiter(temporaryDataStaff?.recruiter)
      setHRSpecialists(temporaryDataStaff?.hRSpecialists)
      setLeader(temporaryDataStaff?.leader)
      setSelectedLabel(temporaryDataStaff?.labels)
      setValuesGender(temporaryDataStaff?.gender)
      setValuesTypePaper(temporaryDataStaff?.typePaper)
      setValuesMaritalStatus(temporaryDataStaff?.maritalStatus)
      setValuesSource(temporaryDataStaff?.source)
      setValuesPileStatus(temporaryDataStaff?.pileStatus)
      setValuesBank(temporaryDataStaff?.bank)
      if (temporaryDataStaff?.relatives == undefined) {
        setDataRelatives([])
      } else {
        setDataRelatives(temporaryDataStaff?.relatives)
        setDataRelativeStorage(temporaryDataStaff?.relatives)
      }
      if (temporaryDataStaff?.locations == undefined) {
        setDataLocation([])
      } else {
        setDataLocation(temporaryDataStaff?.locations)
        setDataLocationStorage(temporaryDataStaff?.locations)
      }
    } else {
      fetchDataStaff()
    }
  }, [])

  if (!staff) return <></>

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ListItem>
          <Typography variant="h6" gutterBottom>
            Thông tin cá nhân
          </Typography>
        </ListItem>
        <Divider />
        <StyleItem>
          <StyleImage>
            <StyleAvatar>
              <Button
                sx={{
                  borderRadius: '50%',
                  padding: 0,
                }}
                component="label"
              >
                {image ? (
                  <Avatar src={image} sx={{ width: 150, height: 150 }} />
                ) : imageData ? (
                  <Avatar src={imageData} sx={{ width: 150, height: 150 }} />
                ) : temporaryDataStaff?.avatar ? (
                  <Avatar
                    src={temporaryDataStaff?.avatar}
                    sx={{ width: 150, height: 150 }}
                  />
                ) : (
                  <Avatar src={''} sx={{ width: 150, height: 150 }} />
                )}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  // {...register('avatar')}
                  onChange={handleUploadImage}
                />
              </Button>
              <Button
                component="label"
                sx={{
                  position: 'absolute',
                  bottom: '-14px',
                  right: '16px',
                  backgroundColor: '#DCDCDC !important',
                  color: '#696969',
                  borderRadius: '50%',
                  minWidth: '0px !important',
                }}
              >
                <CameraAltOutlinedIcon sx={{ fontSize: '2rem' }} />
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  // {...register('avatar')}
                  onChange={handleUploadImage}
                />
              </Button>
            </StyleAvatar>
            <Typography
              sx={{ color: 'red', fontSize: '14px', marginTop: '10px' }}
            >
              {error}
            </Typography>
          </StyleImage>
          <StyleInfo>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '24px',
              }}
            >
              <StyleInput>
                <InputField
                  label="Mã cộng tác viên"
                  disabled
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.staffCode : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('staffCode')}
                  size="small"
                />
                <StyleError>{errors.staffCode?.message as string}</StyleError>
              </StyleInput>
              <StyleInput>
                <InputField
                  label="Họ và tên*"
                  defaultValue={
                    temporaryDataStaff
                      ? temporaryDataStaff?.fullName
                      : staff?.fullName
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('fullName')}
                  size="small"
                />
                <StyleError>{errors.fullName?.message as string}</StyleError>
              </StyleInput>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <InputField
                  label="Số điện thoại chính*"
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.phone : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  {...register('phone')}
                  size="small"
                />
                <StyleError>{errors.phone?.message as string}</StyleError>
              </StyleInput>
              <StyleInput>
                <InputField
                  label="Số điện thoại phụ"
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.subPhone : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  {...register('subPhone')}
                  size="small"
                />
                <StyleError>{errors.subPhone?.message as string}</StyleError>
              </StyleInput>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <InputField
                  label="Email"
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.email : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('email')}
                  size="small"
                />
                <StyleError>{errors.email?.message as string}</StyleError>
              </StyleInput>
              <StyleInput>
                <Autocomplete
                  size="small"
                  multiple
                  disableCloseOnSelect
                  options={skills}
                  sx={{ width: '100%' }}
                  getOptionLabel={(option) => option.title || ''}
                  isOptionEqualToValue={(option, value) =>
                    option?.title === value?.title
                  }
                  value={temporaryDataStaff?.skills ?? selectedSkills ?? []}
                  noOptionsText="Danh sách rỗng"
                  onChange={handleSkills}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <InputField
                      {...params}
                      label="Kỹ năng"
                      placeholder="Kỹ năng"
                      {...register('skill')}
                    />
                  )}
                />
                <StyleError>{errors.skill?.message as string}</StyleError>
              </StyleInput>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <Controller
                  control={control}
                  name="born"
                  defaultValue={temporaryDataStaff?.born ?? ''}
                  render={({
                    field: { onChange, ref, onBlur, name, ...field },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        maxDate={new Date()}
                        {...field}
                        inputRef={ref}
                        inputFormat="DD/MM/YYYY"
                        label="Ngày sinh*"
                        renderInput={(inputProps) => (
                          <InputField
                            {...inputProps}
                            onBlur={onBlur}
                            name={name}
                            error={false}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                          />
                        )}
                        onChange={(e: Date | null) => {
                          onChange(e)
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                <StyleError>{errors.born?.message as string}</StyleError>
              </StyleInput>
              <StyleInput>
                <Controller
                  control={control}
                  name="birth"
                  defaultValue={temporaryDataStaff?.birth ?? ''}
                  render={({
                    field: { onChange, ref, onBlur, name, ...field },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        maxDate={new Date()}
                        {...field}
                        inputRef={ref}
                        inputFormat="DD/MM/YYYY"
                        label="Ngày sinh nhật"
                        renderInput={(inputProps) => (
                          <InputField
                            {...inputProps}
                            onBlur={onBlur}
                            name={name}
                            error={false}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                          />
                        )}
                        onChange={(e: Date | null) => {
                          onChange(e)
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                <StyleError>{errors.birth?.message as string}</StyleError>
              </StyleInput>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <Autocomplete
                  size="small"
                  sx={{ padding: '10px 0' }}
                  disablePortal
                  options={dataRecruiter}
                  getOptionLabel={(option) => option?.title || ''}
                  isOptionEqualToValue={(option, value) =>
                    option?.title === value?.title
                  }
                  value={temporaryDataStaff?.recruiter ?? recruiter ?? []}
                  noOptionsText={'Danh sách rỗng'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Chuyên viên tuyển dụng"
                      placeholder="Chuyên viên tuyển dụng"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  onChange={handleRecruiter}
                  // onInputChange={handleSearchUser}
                  // onChange={(event, value: any) => {
                  //   setIdUser(value)
                  //   setIsChanged(true)
                  // }}
                  // onKeyPress={handleKeyPress}
                />

                <StyleError>
                  {errors.recruitmentSpecialist?.message as string}
                </StyleError>
              </StyleInput>
              <StyleInput>
                <Autocomplete
                  size="small"
                  sx={{ padding: '10px 0' }}
                  disablePortal
                  options={dataLeader}
                  getOptionLabel={(option) => option?.title || ''}
                  isOptionEqualToValue={(option, value) =>
                    option?.title === value?.title
                  }
                  value={
                    temporaryDataStaff?.hRSpecialists ?? hRSpecialists ?? []
                  }
                  noOptionsText={'Danh sách rỗng'}
                  onChange={handleHRSpecialists}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Chuyên viên nhân sự"
                      placeholder="Chuyên viên nhân sự"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
                {/* {errors.phone && (
                      <StyleError>
                        {!isChanged ? (errors.phone.message as string) : null}
                      </StyleError>
                    )} */}
              </StyleInput>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <Autocomplete
                  size="small"
                  sx={{ padding: '10px 0' }}
                  disablePortal
                  options={dataHR}
                  getOptionLabel={(option) => option?.title || ''}
                  isOptionEqualToValue={(option, value) =>
                    option?.title === value?.title
                  }
                  value={temporaryDataStaff?.leader ?? leader ?? []}
                  noOptionsText={'Danh sách rỗng'}
                  onChange={handleLeader}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tổ trưởng"
                      placeholder="Tổ trưởng"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
                {/* {errors.phone && (
                      <StyleError>
                        {!isChanged ? (errors.phone.message as string) : null}
                      </StyleError>
                    )} */}
              </StyleInput>
              <StyleInput>
                <InputField
                  label="Tổ/đội/nhóm"
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.groups : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('groups')}
                  size="small"
                />
                <StyleError>{errors.groups?.message as string}</StyleError>
              </StyleInput>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <InputField
                  label="Zalo"
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.zalo : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('zalo')}
                  size="small"
                  type="number"
                />
                <StyleError>{errors.zalo?.message as string}</StyleError>
              </StyleInput>
              <StyleInput>
                <InputField
                  label="Facebook"
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.facebook : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('facebook')}
                  size="small"
                />
                <StyleError>{errors.facebook?.message as string}</StyleError>
              </StyleInput>
            </div>

            <div style={{ display: 'flex', width: '50%' }}>
              <Autocomplete
                size="small"
                multiple
                disableCloseOnSelect
                options={selectLabel ?? []}
                sx={{ width: '100%' }}
                getOptionLabel={
                  (option) => option?.title ?? ''
                  // (
                  //   <span style={{ color: `${option?.color}` }}>
                  //     {option?.title ?? ''}
                  //   </span>
                  // ) as any
                }
                renderTags={(value) =>
                  value.map((option, index) => (
                    <Chip
                      key={`chip-${option.id}-${index}`}
                      label={option.title}
                      style={{
                        backgroundColor: `${hexToRGBA(option?.color, 0.2)}`,
                        color: `${hexToRGBA(option?.color, 1)}`,
                        padding: '0px',
                        margin: '2px',
                      }}
                    />
                  ))
                }
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                value={temporaryDataStaff?.labels ?? selectedLabel ?? []}
                noOptionsText="Danh sách rỗng"
                onChange={handleLabel}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option.title}
                  </li>
                )}
                renderInput={(params) => (
                  <InputField
                    {...params}
                    label="Gán nhãn"
                    placeholder="Gán nhãn"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
              <div style={{ alignSelf: 'center' }}>
                <IconButton
                  sx={{ color: 'black' }}
                  aria-label="label"
                  component="label"
                >
                  <WidgetsIcon
                    sx={{
                      height: 25,
                      width: 25,
                      cursor: 'pointer',
                    }}
                    onClick={() => setModalLabel(true)}
                  />
                </IconButton>
              </div>
            </div>
          </StyleInfo>
        </StyleItem>
        <ListItem>
          <Typography variant="h6" gutterBottom>
            Thông tin hợp đồng
          </Typography>
        </ListItem>
        <Divider />
        <div style={{ width: '130px', margin: '24px 0px -24px 0px' }}>
          <StyleLinks onClick={handleTemporaryData}>
            <AttachFileIcon sx={{ marginRight: '10px', color: 'white' }} />
            <StyleLink>Xem hồ sơ</StyleLink>
          </StyleLinks>
        </div>
        <StyleItemInformation>
          <StylePersonal>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <Autocomplete
                  size="small"
                  multiple
                  disableCloseOnSelect
                  options={selectService ?? []}
                  sx={{ width: '100%' }}
                  getOptionLabel={(option) => option.title || ''}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  value={
                    temporaryDataStaff?.servicerId ?? selectedService ?? []
                  }
                  noOptionsText="Danh sách rỗng"
                  onChange={handleService}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <InputField
                      {...params}
                      label="Dịch vụ*"
                      placeholder="Dịch vụ*"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </StyleInput>
              <StyleInput>
                <InputField
                  label="Số hợp đồng"
                  defaultValue={
                    temporaryDataStaff
                      ? temporaryDataStaff?.contractsNumber
                      : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('contractsNumber')}
                  size="small"
                />
                <StyleError>
                  {errors.contractsNumber?.message as string}
                </StyleError>
              </StyleInput>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <Controller
                  control={control}
                  name="contractSigningDate"
                  defaultValue={temporaryDataStaff?.contractSigningDate ?? ''}
                  render={({
                    field: { onChange, ref, onBlur, name, ...field },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        maxDate={new Date()}
                        {...field}
                        inputRef={ref}
                        inputFormat="DD/MM/YYYY"
                        label="Ngày ký hợp đồng"
                        renderInput={(inputProps) => (
                          <InputField
                            {...inputProps}
                            onBlur={onBlur}
                            name={name}
                            error={false}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                          />
                        )}
                        onChange={(e: Date | null) => {
                          onChange(e)
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                <StyleError>
                  {errors.contractSigningDate?.message as string}
                </StyleError>
              </StyleInput>
              <StyleInput>
                <Controller
                  control={control}
                  name="DateStartWork"
                  defaultValue={temporaryDataStaff?.DateStartWork ?? ''}
                  render={({
                    field: { onChange, ref, onBlur, name, ...field },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        minDate={new Date()}
                        {...field}
                        inputRef={ref}
                        inputFormat="DD/MM/YYYY"
                        label="Ngày bắt đầu làm việc"
                        renderInput={(inputProps) => (
                          <InputField
                            {...inputProps}
                            onBlur={onBlur}
                            name={name}
                            error={false}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                          />
                        )}
                        onChange={(e: Date | null) => {
                          onChange(e)
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                <StyleError>
                  {errors.DateStartWork?.message as string}
                </StyleError>
              </StyleInput>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <InputField
                  size="small"
                  id="gender"
                  label="Giới tính"
                  select
                  value={valuesGender}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeGender}
                >
                  {gender?.map((option: any) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{ justifyContent: 'center' }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
                <StyleError>{errors.gender?.message as string}</StyleError>
              </StyleInput>
              <StyleInput>
                <InputField
                  label="Nguyên quán"
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.homeTown : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  {...register('homeTown')}
                  size="small"
                />
                <StyleError>{errors.homeTown?.message as string}</StyleError>
              </StyleInput>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <InputField
                  size="small"
                  id="type-of-identity-document"
                  label="Loại giấy tờ tuỳ thân*"
                  select
                  value={valuesTypePaper}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('typeOfIdentityDocument')}
                  onChange={handleChangeTypePaper}
                >
                  {typePaper?.map((option: any) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{ justifyContent: 'center' }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
                <StyleError>
                  {!valuesTypePaper &&
                    (errors.typeOfIdentityDocument?.message as string)}
                </StyleError>
              </StyleInput>
              <StyleInput>
                <InputField
                  label="Số giấy tờ tuỳ thân*"
                  defaultValue={
                    temporaryDataStaff
                      ? temporaryDataStaff?.numberOfIdentityDocument
                      : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="number"
                  {...register('numberOfIdentityDocument')}
                  size="small"
                />
                <StyleError>
                  {errors.numberOfIdentityDocument?.message as string}
                </StyleError>
              </StyleInput>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <Controller
                  control={control}
                  name="registrationDate"
                  defaultValue={temporaryDataStaff?.registrationDate ?? ''}
                  render={({
                    field: { onChange, ref, onBlur, name, ...field },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        maxDate={new Date()}
                        {...field}
                        inputRef={ref}
                        inputFormat="DD/MM/YYYY"
                        label="Ngày cấp"
                        renderInput={(inputProps) => (
                          <InputField
                            {...inputProps}
                            onBlur={onBlur}
                            name={name}
                            error={false}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                          />
                        )}
                        onChange={(e: Date | null) => {
                          onChange(e)
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                <StyleError>
                  {errors.registrationDate?.message as string}
                </StyleError>
              </StyleInput>
              <StyleInput>
                <InputField
                  label="Nơi cấp"
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.place : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('place')}
                  multiline
                  size="small"
                />
                <StyleError>{errors.place?.message as string}</StyleError>
              </StyleInput>
            </div>

            <InputField
              label="Địa chỉ thường trú*"
              multiline
              defaultValue={
                temporaryDataStaff ? temporaryDataStaff?.permanentAddress : ''
              }
              InputLabelProps={{
                shrink: true,
              }}
              {...register('permanentAddress')}
              size="small"
            />
            <StyleError>
              {errors.permanentAddress?.message as string}
            </StyleError>
            <InputField
              label="Địa chỉ tạm trú*"
              multiline
              defaultValue={
                temporaryDataStaff ? temporaryDataStaff?.temporaryAddress : ''
              }
              InputLabelProps={{
                shrink: true,
              }}
              {...register('temporaryAddress')}
              size="small"
            />
            <StyleError>
              {errors.temporaryAddress?.message as string}
            </StyleError>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <InputField
                  label="Tôn giáo"
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.religion : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('religion')}
                  size="small"
                />
                <StyleError>{errors.religion?.message as string}</StyleError>
              </StyleInput>
              <StyleInput>
                <InputField
                  label="Phương tiện"
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.vehicle : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('vehicle')}
                  size="small"
                />
                <StyleError>{errors.vehicle?.message as string}</StyleError>
              </StyleInput>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <InputField
                  size="small"
                  id="marital-status"
                  label="Tình trạng hôn nhân"
                  select
                  value={valuesMaritalStatus}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeMaritalStatus}
                >
                  {maritalStatus?.map((option: any) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{ justifyContent: 'center' }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
                <StyleError>
                  {errors.maritalStatus?.message as string}
                </StyleError>
              </StyleInput>
              <StyleInput>
                <InputField
                  size="small"
                  id="knownSource"
                  label="Nguồn (biết đến 3Clean qua)"
                  select
                  value={valuesSource}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeSource}
                >
                  {source?.map((option: any) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{ justifyContent: 'center' }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
                <StyleError>{errors.knownSource?.message as string}</StyleError>
              </StyleInput>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <InputField
                  label="Cọc đăng ký công việc"
                  defaultValue={
                    temporaryDataStaff ? temporaryDataStaff?.pile : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('pile')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">đ</InputAdornment>
                    ),
                  }}
                  size="small"
                />
                <StyleError>{errors.pile?.message as string}</StyleError>
              </StyleInput>
              <StyleInput>
                <Controller
                  control={control}
                  name="pileDate"
                  defaultValue={temporaryDataStaff?.pileDate ?? ''}
                  render={({
                    field: { onChange, ref, onBlur, name, ...field },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        maxDate={new Date()}
                        {...field}
                        inputRef={ref}
                        inputFormat="DD/MM/YYYY"
                        label="Ngày cọc"
                        renderInput={(inputProps) => (
                          <InputField
                            {...inputProps}
                            onBlur={onBlur}
                            name={name}
                            error={false}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                          />
                        )}
                        onChange={(e: Date | null) => {
                          onChange(e)
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                <StyleError>{errors.pileDate?.message as string}</StyleError>
              </StyleInput>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <StyleInput>
                <InputField
                  size="small"
                  id="pile-status"
                  label="Tình trạng cọc"
                  select
                  value={valuesPileStatus}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangePileStatus}
                >
                  {pileStatus?.map((option: any) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{ justifyContent: 'center' }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
                <StyleError>{errors.pileStatus?.message as string}</StyleError>
              </StyleInput>
              <StyleInput>
                <Controller
                  control={control}
                  name="depositRefundDate"
                  defaultValue={temporaryDataStaff?.depositRefundDate ?? ''}
                  render={({
                    field: { onChange, ref, onBlur, name, ...field },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        minDate={new Date()}
                        {...field}
                        inputRef={ref}
                        inputFormat="DD/MM/YYYY"
                        label="Ngày hoàn cọc"
                        renderInput={(inputProps) => (
                          <InputField
                            {...inputProps}
                            onBlur={onBlur}
                            name={name}
                            error={false}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            size="small"
                          />
                        )}
                        onChange={(e: Date | null) => {
                          onChange(e)
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                <StyleError>
                  {errors.depositRefundDate?.message as string}
                </StyleError>
              </StyleInput>
            </div>
            <InputField
              label="Mong muốn của cộng tác viên"
              defaultValue={
                temporaryDataStaff ? temporaryDataStaff?.desire : ''
              }
              InputLabelProps={{
                shrink: true,
              }}
              {...register('desire')}
              size="small"
            />
            <StyleError>{errors.desire?.message as string}</StyleError>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
              <div style={{ display: 'flex', width: '50%' }}>
                <StyleInput>
                  <InputField
                    label="Mã số thuế cá nhân"
                    defaultValue={
                      temporaryDataStaff
                        ? temporaryDataStaff?.PersonalTaxCode
                        : ''
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register('PersonalTaxCode')}
                    size="small"
                  />
                  <StyleError>
                    {errors.PersonalTaxCode?.message as string}
                  </StyleError>
                </StyleInput>
              </div>
            </div>
          </StylePersonal>
        </StyleItemInformation>
        <ListItem>
          <Typography variant="h6" gutterBottom>
            Thông tin tài chính
          </Typography>
        </ListItem>
        <Divider />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '24px',
            marginTop: '14px',
          }}
        >
          <StyleInput>
            <InputField
              label="Số tài khoản"
              defaultValue={
                temporaryDataStaff ? temporaryDataStaff?.accountNumber : ''
              }
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              {...register('accountNumber')}
              size="small"
            />
            <StyleError>{errors.accountNumber?.message as string}</StyleError>
          </StyleInput>
          <StyleInput>
            <InputField
              label="Tên tài khoản"
              defaultValue={
                temporaryDataStaff ? temporaryDataStaff?.accountName : ''
              }
              InputLabelProps={{
                shrink: true,
              }}
              {...register('accountName')}
              size="small"
            />
            <StyleError>{errors.accountName?.message as string}</StyleError>
          </StyleInput>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
          <StyleInput>
            <InputField
              size="small"
              id="bankName"
              label="Ngân hàng"
              select
              value={valuesBank}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChangeBank}
            >
              {bank?.map((option: any) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{ justifyContent: 'center' }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </InputField>
            <StyleError>{errors.bankName?.message as string}</StyleError>
          </StyleInput>
          <StyleInput>
            <InputField
              label="Chi nhánh"
              defaultValue={
                temporaryDataStaff ? temporaryDataStaff?.bankBranch : ''
              }
              InputLabelProps={{
                shrink: true,
              }}
              {...register('bankBranch')}
              size="small"
            />
            <StyleError>{errors.bankBranch?.message as string}</StyleError>
          </StyleInput>
        </div>

        <ListItem>
          <Typography variant="h6" gutterBottom>
            Người liên hệ khẩn cấp*
          </Typography>
        </ListItem>
        <Divider />

        <div style={{ width: '180px', marginTop: '24px' }}>
          <StyleLinks
            onClick={() => {
              setModalRelativesCreate(true)
            }}
          >
            <AddCircleIcon sx={{ marginRight: '10px', color: 'white' }} />
            <StyleLink>Thêm người thân</StyleLink>
          </StyleLinks>
        </div>

        <Typography sx={{ display: 'flex' }} variant="body2" gutterBottom>
          *Tối thiểu 2 người thân
        </Typography>

        <TableContainer component={Paper} className="box-shadow">
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <StyleTableCell align="left">STT</StyleTableCell>
                <StyleTableCell align="left">Quan hệ</StyleTableCell>
                <StyleTableCell align="left">Họ tên</StyleTableCell>
                <StyleTableCell align="left">Số điện thoại</StyleTableCell>
                <StyleTableCell align="left">Địa chỉ</StyleTableCell>
                <StyleTableCell align="left">Ghi chú</StyleTableCell>
                <StyleTableCell align="left">Chức năng</StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRelatives?.map((row: any, index: number) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align="left" component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="left">
                    <StyleTableCellItem>{row?.relationship}</StyleTableCellItem>
                  </TableCell>
                  <TableCell align="left">
                    <StyleTableCellItem>{row?.fullName}</StyleTableCellItem>
                  </TableCell>
                  <TableCell align="left">
                    <StyleTableCellItem>{row?.phone}</StyleTableCellItem>
                  </TableCell>
                  <TableCell align="left">{row?.address}</TableCell>
                  <TableCell align="left">
                    <StyleTableCellItem>{row?.note}</StyleTableCellItem>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                    }}
                  >
                    <DriveFileRenameOutlineOutlinedIcon
                      color="primary"
                      sx={{
                        fontSize: '25px',
                        '&:hover': {
                          cursor: 'pointer',
                          transform: 'scale(1.3)',
                        },
                      }}
                      onClick={() => {
                        handleRelativesEdit(index)
                        setGetIdRelatives(index)
                      }}
                    />

                    <DeleteOutlineOutlinedIcon
                      color="error"
                      sx={{
                        fontSize: '25px',
                        '&:hover': {
                          cursor: 'pointer',
                          transform: 'scale(1.3)',
                        },
                      }}
                      onClick={() => handleDeleteRelatives(index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <ListItem>
          <Typography variant="h6" gutterBottom>
            Vị trí công việc
          </Typography>
        </ListItem>
        <Divider />

        <div style={{ width: '180px', marginTop: '24px' }}>
          <StyleLinks
            onClick={() => {
              if (dataLocation.length < 3) {
                setModalLocation(true)
              } else {
                setAlertError('Thêm được tối đa 3 vị trí làm việc')
              }
            }}
          >
            <AddCircleIcon sx={{ marginRight: '10px', color: 'white' }} />
            <StyleLink>Thêm vị trí</StyleLink>
          </StyleLinks>
        </div>

        <Typography sx={{ display: 'flex' }} variant="body2" gutterBottom>
          *Tối thiểu 1 ví trí, tối đa 3 vị trí
        </Typography>

        <TableContainer
          component={Paper}
          className="box-shadow"
          sx={{ marginBottom: '24px' }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <StyleTableCell align="center" sx={{ padding: '0px' }}>
                  STT
                </StyleTableCell>
                <StyleTableCell align="left">Quốc gia</StyleTableCell>
                <StyleTableCell align="left">Tỉnh/Thành phố</StyleTableCell>
                <StyleTableCell align="left">Quận/Huyện</StyleTableCell>
                <StyleTableCell align="left">Địa chỉ</StyleTableCell>
                <StyleTableCell align="left">Chức năng</StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataLocation?.map((row: any, index: number) => (
                <TableRow
                  key={row.index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{ padding: '0px' }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell align="left">
                    <StyleTableCellItem>
                      {row?.country?.native}
                    </StyleTableCellItem>
                  </TableCell>
                  <TableCell align="left">
                    <StyleTableCellItem>
                      {row?.province?.name}
                    </StyleTableCellItem>
                  </TableCell>
                  <TableCell align="left">
                    <StyleTableCellItem>
                      {row?.district?.name}
                    </StyleTableCellItem>
                  </TableCell>
                  <TableCell align="left">
                    <StyleTableCellItem>
                      {row?.address?.address}
                    </StyleTableCellItem>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                    }}
                  >
                    <DriveFileRenameOutlineOutlinedIcon
                      color="primary"
                      sx={{
                        fontSize: '25px',
                        '&:hover': {
                          cursor: 'pointer',
                          transform: 'scale(1.3)',
                        },
                      }}
                      onClick={() => {
                        handleLocationEdit(index)
                        setGetIdLocation(index)
                      }}
                    />

                    <DeleteOutlineOutlinedIcon
                      color="error"
                      sx={{
                        fontSize: '25px',
                        '&:hover': {
                          cursor: 'pointer',
                          transform: 'scale(1.3)',
                        },
                      }}
                      onClick={() => handleDeleteLocation(index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Stack
          spacing={2}
          direction="row"
          sx={{
            justifyContent: 'flex-start',
          }}
        >
          <Button
            variant="contained"
            type="submit"
            sx={{ width: '99px' }}
            className="bgr-global"
          >
            Lưu
          </Button>
          <Button
            variant="outlined"
            sx={{ width: '100px' }}
            onClick={() => navigate('/staff-manager/part-time-staff')}
          >
            Huỷ
          </Button>
        </Stack>
      </form>

      <LabelList
        modalLabel={modalLabel}
        setModalLabel={setModalLabel}
        resetData={fetchDataLabel}
      />
      <LocationCreate
        modalLocationCreate={modalLocation}
        setModalLocationCreate={setModalLocation}
        DataLocationCreate={handleDataLocation}
      />
      <LocationEdit
        modalLocationEdit={modalLocationEdit}
        setModalLocationEdit={setModalLocationEdit}
        DataLocationEdit={dataLocationId}
        DataLocationEditAfter={handleLocationEditAfter}
      />
      <RelativesCreate
        modalRelativesCreate={modalRelativesCreate}
        setModalRelativesCreate={setModalRelativesCreate}
        DataRelativesCreate={handleDataRelativesCreate}
      />
      <RelativesEdit
        modalRelativesEdit={modalRelativesEdit}
        setModalRelativesEdit={setModalRelativesEdit}
        DataRelativesEdit={dataRelativesId}
        DataRelativesEditAfter={handleRelativesEditAfter}
      />
    </>
  )
}

export default PersonalInformationEdit
