import React, { useState } from 'react'
import Breadcrumb from 'components/Breadcrumbs'
import { Grid, Button, Stack, Typography } from '@mui/material'
import Item from 'components/Item'
import { useForm } from 'react-hook-form'
import InputField from 'components/FormControl/InputField'
import { useNavigate } from 'react-router-dom'
import {
  StyleAddImage,
  StyleAddItem,
  StyleContentRight,
  StyleError,
  StyleErrorImage,
  StyleForm,
  StyleHeaderItem,
  StyleImage,
  StyleLink,
  StyleLinks,
  StyleTitle,
} from './style'
import { useAlert } from 'contexts/AlertContext'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { newsSchema_ } from 'validations/newsSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { newsAPI } from 'services/newsAPI'
import { useLoading } from 'contexts/LoadingContext'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import { IMAGE_TYPE, MAX_FILE_SIZE } from 'constants/common'
import { getFileSize, FileTypeEnum } from 'utils/FileUtils'
import { NEWS_ADD } from 'constants/breadcrumbs'
import { uploadImage } from 'utils/uploadImage'
import BasicModal from 'components/Modal'

const AddNews = () => {
  const [image, setImage] = useState('')
  const [bannerFile, setBannerFile] = useState<File | null>()
  const [openModalCancel, setOpenModalCancel] = useState(false)
  const navigate = useNavigate()

  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertSuccess } = useAlert()
  const [error, setError] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<INews>({
    resolver: yupResolver(newsSchema_),
    mode: 'onChange',
  })

  const onSubmit = async (dataNews: INews) => {
    setLoadingTrue()
    let isUploadSuccess = false
    const dataCreate = { ...dataNews }
    try {
      const response: IUploadImg = await uploadImage(bannerFile as File)
      dataCreate.thumbnail = response.origin
      isUploadSuccess = true
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError('Ảnh bìa không được để trống')
    }
    if (!isUploadSuccess) {
      setLoadingFalse()
      return
    }
    if (dataCreate.priority == null) {
      dataCreate.priority = 0
    }
    try {
      await newsAPI.createNews(dataCreate)
      setLoadingFalse()
      setAlertSuccess('Thêm blog thành công!')
      navigate('/marketing/news')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoadingFalse()
    }
  }

  const handleUpload = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
    if (file) {
      const name = file?.name.split('.')
      if (fileSize > MAX_FILE_SIZE) {
        setError(`Dung lượng ảnh tối đa cho phép là ${MAX_FILE_SIZE} MB`)
        return
      }
      if (!name[name.length - 1].match(IMAGE_TYPE)) {
        setError('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
        return
      }
      if (fileSize === 0) {
        setError(`Ảnh lỗi`)
        return
      }
      setImage(URL?.createObjectURL((target.files as FileList)[0]))
      setBannerFile(file)
      setError('')
    }
  }

  const handleClick = () => {
    if (!bannerFile) {
      setError('Ảnh bìa không được để trống')
    }
  }

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={NEWS_ADD} />
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <StyleHeaderItem>
            <StyleTitle>Thêm Blog</StyleTitle>
          </StyleHeaderItem>
          <StyleForm autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <StyleImage>
              {image ? (
                <StyleAddImage>
                  <img src={image} alt="img news" />
                  <StyleAddItem>
                    <StyleLinks>
                      <CameraAltOutlinedIcon />
                      <StyleLink>Chỉnh sửa ảnh bìa</StyleLink>
                    </StyleLinks>
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleUpload(e)
                      }
                    />
                  </StyleAddItem>
                </StyleAddImage>
              ) : (
                <StyleAddImage>
                  <AddPhotoAlternateIcon
                    sx={{
                      width: '500px !important',
                      height: '300px !important',
                      color: '#1976d2',
                    }}
                  />
                  <StyleAddItem>
                    <StyleLinks>
                      <CameraAltOutlinedIcon />
                      <StyleLink>Thêm ảnh bìa</StyleLink>
                    </StyleLinks>
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={handleUpload}
                    />
                  </StyleAddItem>
                </StyleAddImage>
              )}
              {error && <StyleErrorImage>{error}</StyleErrorImage>}
            </StyleImage>
            <StyleContentRight className="d-flex justify-center">
              <InputField
                {...register('title')}
                label="Tiêu đề "
                multiline={true}
              />
              {errors.title && (
                <StyleError>{errors.title.message as string}</StyleError>
              )}
              <InputField
                {...register('description')}
                label="Nội dung ngắn"
                multiline={true}
              />
              {errors.description && (
                <StyleError>{errors.description.message as string}</StyleError>
              )}
              <InputField
                {...register('link')}
                label="Link"
                autoCompleted="famili-name"
                multiline={true}
              />
              {errors.link && (
                <StyleError>{errors.link.message as string}</StyleError>
              )}
              <InputField
                {...register('priority')}
                label="Độ ưu tiên của bài viết"
                multiline={true}
                sx={{
                  width: '192px !important',
                  float: 'left !important',
                }}
              />
              {errors.priority && (
                <StyleError>{errors.priority.message as string}</StyleError>
              )}
              <Stack
                spacing={2}
                direction="row"
                sx={{
                  paddingTop: '10px',
                  gap: '8px',
                  m: 1,
                  position: 'relative',
                }}
              >
                <Button variant="contained" type="submit" onClick={handleClick}>
                  Lưu
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setOpenModalCancel(true)}
                >
                  Hủy
                </Button>
              </Stack>
            </StyleContentRight>
          </StyleForm>
          <BasicModal open={openModalCancel}>
            <Typography>Bạn có chắc chắn muốn hủy thay đổi?</Typography>
            <Button
              sx={{
                margin: '20px',
                marginBottom: '0px',
                paddingLeft: '43px',
                paddingRight: '43px',
              }}
              variant="contained"
              onClick={() => navigate('/marketing/news')}
              className="bgr-global"
            >
              Hủy
            </Button>
            <Button
              sx={{
                margin: '20px',
                marginBottom: '-0.5px',
                paddingLeft: '30px',
                paddingRight: '30px',
              }}
              onClick={() => setOpenModalCancel(false)}
              variant="outlined"
            >
              Không
            </Button>
          </BasicModal>
        </Item>
      </Grid>
    </Grid>
  )
}

export default AddNews
