import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Grid,
  Stack,
  Divider,
  MenuItem,
  TextField,
  InputAdornment,
  Typography,
} from '@mui/material'
import Breadcrumb from 'components/Breadcrumbs'
import dayjs from 'dayjs'
import Editor from 'components/Editor'
import InputField from 'components/FormControl/InputField'
import InputNumberFormat from 'components/InputNumberFormat'
import Item from 'components/Item'
import Checkbox from '@mui/material/Checkbox'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import BasicModal from 'components/Modal'
import { checkChangeData } from 'utils/confirmBox'
import { PROMOTION_CODE_CREATE } from 'constants/breadcrumbs'
import { IMAGE_TYPE, MAX_FILE_SIZE } from 'constants/common'
import { useAlert } from 'contexts/AlertContext'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useNavigate, useParams } from 'react-router'
import { FileTypeEnum, getFileSize } from 'utils/FileUtils'
import { uploadImage } from 'utils/uploadImage'
import SendIcon from '@mui/icons-material/Send'
import {
  StyleAddImage,
  StyleAddItem,
  StyleChoose,
  StyleContent,
  StyleContentInput,
  StyleContentText,
  StyleError,
  StyleErrorImage,
  StyleFilter,
  StyleHeader,
  StyleImage,
  StyleInfo,
  StyleInput,
  StyleItem,
  StyleLabel,
  StyleLink,
  StyleLinks,
  StyleObject,
  StyleStatus,
  StyleTitle,
  StyleToogle,
} from '../../style'
import { useLoading } from 'contexts/LoadingContext'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { promotionSchema_ } from 'validations/promotionSchena'
import {
  promotionCode,
  promotionObject,
  promotionPrice,
  promotionStatus,
} from 'constants/promotion'
import { StyledInput } from 'pages/services/components/HomeCleaning/style'
import promoApi from 'services/promoAPI'
import LoadingVersion2 from 'components/LoadingV2'
import MultiSelect from 'components/Autocomplete'
import rangeOfActiveApi from 'services/houseCleaningAPI'

const PromotionEdit = () => {
  const { id } = useParams()
  const [data, setData] = useState<IPromotion>()
  const [content, setContent] = useState('')
  const [isPercent, setIsPercent] = useState(false)
  const [dataNation, setDataNation] = useState<any>([])
  const [selectServices, setSelectServices] = useState<any[]>([])
  const [selectedServices, setSelectedServices] = useState<any>()
  const [selectedServicesDefault, setSelectedServicesDefault] = useState<any[]>(
    []
  )
  const [errorUser, setErrorUser] = useState<string>()
  const [errorImage, setErrorImage] = useState('')
  const [selectUsers, setSelectUsers] = useState<any>([])
  const [selectedUsers, setSelectedUsers] = useState<any>()
  const [selectedUsersDefault, setSelectedUsersDefault] = useState<any[]>([])
  const [selectLevel, setSelectLevel] = useState<any[]>([])
  const [selectedLevel, setSelectedLevel] = useState<any[]>([])
  const [selectedLevelDefault, setSelectedLevelDefault] = useState<any[]>([])
  const [selectGroups, setSelectGroups] = useState<any[]>([])
  const [selectedGroups, setSelectedGroups] = useState<any>([])
  const [selectedGroupsDefault, setSelectedGroupsDefault] = useState<any[]>([])
  const [selectRange, setSelectRange] = useState<any>()
  const [selectedRange, setSelectedRange] = useState<any>()
  const [selectedRangeDefault, setSelectedRangeDefault] = useState<any[]>([])
  const [image, setImage] = useState('')
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const [valueStatus, setValueStatus] = useState('')
  const [valueObject, setValueObject] = useState('')
  const [valueCode, setValueCode] = useState('')
  const [valueNation, setValueNation] = useState()
  const [valuePrice, setValuePrice] = useState('')
  const [contentDefault, setContentDefault] = useState<string>()
  const [iconDefault, setIconDefault] = useState<string>()
  const [errorContent, setErrorContent] = useState<string>()
  const { setAlertSuccess, setAlertError } = useAlert()
  const navigate = useNavigate()
  const [modalCancel, setModaCancel] = useState<boolean>(false)
  const [dataDefault, setDataDefault] = useState<IPromotion>()
  const [imageURL, setImageURL] = useState('')

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(promotionSchema_),
    mode: 'onChange',
  })

  const checkOtherCondition = () => {
    const isServices =
      selectedServices.length === selectedServicesDefault.length &&
      selectedServices.every(
        (value: any, index: number) =>
          value.id === selectedServicesDefault[index]
      )
    const isUsers =
      selectedUsers.length === selectedUsersDefault.length &&
      selectedUsers.every(
        (value: any, index: number) => value.id === selectedUsersDefault[index]
      )
    const isGroups =
      selectedGroups.length === selectedGroupsDefault.length &&
      selectedGroups.every(
        (value: any, index: number) => value === selectedGroupsDefault[index]
      )
    const isLevel =
      selectedLevel.length === selectedLevelDefault.length &&
      selectedLevel.every(
        (value: any, index: number) => value === selectedLevelDefault[index]
      )
    const isRange =
      selectedRange.length === selectedRangeDefault.length &&
      selectedRange.every(
        (value: any, index: number) => value.id == selectedRangeDefault[index]
      )

    if (
      contentDefault !== content ||
      iconDefault !== imageURL ||
      !isServices ||
      !isUsers ||
      !isGroups ||
      !isRange ||
      !isLevel
    ) {
      return true
    }
    return false
  }
  const handleClose = () => setModaCancel(false)

  const handleCheckDataCancel = () => {
    const getValuesItem = getValues()
    const isChanged = checkChangeData({
      dataChange: getValuesItem,
      dataDefault: dataDefault,
    })
    const checkOrther = checkOtherCondition()
    if (isChanged || checkOrther) {
      setModaCancel(true)
    } else {
      navigate('/marketing/promotion-code/')
    }
  }
  const handleChangeNation = async (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setValueNation(newValue?.props?.value)
    const { data } = await rangeOfActiveApi.getProvincesById(
      newValue?.props?.value
    )
    if (data) {
      setSelectRange(data)
    } else {
      setSelectRange([])
      setAlertError('Quốc gia mà bạn chọn chưa có dữ liệu!')
    }
  }
  const handleInputChange = async (event: any, value: any) => {
    const { data } = await promoApi.getUser(value)
    const result = data.rows.filter(
      (item: any) =>
        !selectedUsers.find((element: any) => element.id === item.id)
    )
    setSelectUsers(result)
  }
  const handleEditorChange = (value: string) => {
    setContent(value)
    if (value === '<p><br></p>' || value === '') {
      setErrorContent('Nội dung premium không được để trống')
      return
    } else {
      setErrorContent(undefined)
    }
  }
  const handleChangeStatus = (event: React.SyntheticEvent, newValue: any) => {
    setValueStatus(newValue?.props?.value)
  }
  const handleChangeObject = (event: React.SyntheticEvent, newValue: any) => {
    setValueObject(newValue?.props?.value)
  }
  const handleChangeCode = (event: React.SyntheticEvent, newValue: any) => {
    setValueCode(newValue?.props?.value)
  }
  const handleChangePrice = (event: React.SyntheticEvent, newValue: any) => {
    setValuePrice(newValue?.props?.value)
  }
  const handleUpload = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
    if (file) {
      if (fileSize > MAX_FILE_SIZE) {
        setErrorImage(`Dung lượng hình ảnh không quá ${MAX_FILE_SIZE} MB`)
        return
      }
      if (!file?.type.match(IMAGE_TYPE)) {
        setErrorImage('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
        return
      }
      if (fileSize === 0) {
        setErrorImage(`Ảnh lỗi`)
        return
      }
      const response: IUploadImg = await uploadImage(file)
      setImageURL(response.origin)
      setImage(URL?.createObjectURL((target.files as FileList)[0]))
      setErrorImage('')
    }
  }
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = event
    const inputValue = (target as HTMLInputElement).value

    if (!/[0-9.,]/.test(key)) {
      event.preventDefault()
    }
    if (
      (key === '.' || key === ',') &&
      (inputValue.includes('.') || inputValue.includes(','))
    ) {
      event.preventDefault()
    }
  }
  const fetchServices = async () => {
    const { data } = await promoApi.getServices()
    setSelectServices(data)
  }

  const fetchDataCountries = async () => {
    const { data } = await promoApi.getCountries()
    if (data) {
      setDataNation(data)
    }
  }

  const onSubmit = async (data: IFormPromotion) => {
    if (errorUser) {
      return
    }
    if (content === '') {
      setErrorContent('Nội dung premium không được để trống')
      setLoadingFalse()
      return
    } else {
      setErrorContent(undefined)
    }
    setLoadingTrue()
    const dataCreate: IPromotion = { ...data }
    dataCreate.startDate = new Date(dataCreate.startDate).toISOString()
    dataCreate.endDate = new Date(dataCreate.endDate).toISOString()
    dataCreate.promotionCode = dataCreate.promotionCode?.toUpperCase()
    dataCreate.status = valueStatus
    delete dataCreate.promotionCode
    if (valuePrice === 'Giảm tiền') {
      delete dataCreate.min
      delete dataCreate.minPercent
      delete dataCreate.maxAmount
      dataCreate.minOrderAmount = data.min
      setIsPercent(false)
    } else {
      delete dataCreate.min
      delete dataCreate.minPercent
      delete dataCreate.discount
      dataCreate.minOrderAmount = data.minPercent
      setIsPercent(true)
    }
    try {
      const levelIds: string[] = []
      selectedLevel.map((level: any) => {
        return levelIds.push(`${level.id}`)
      })
      const groupIds: string[] = []
      selectedGroups.map((group: any) => {
        return groupIds.push(`${group.id}`)
      })
      const rangeIds: string[] = []
      selectedRange.map((range: any) => {
        return rangeIds.push(`${range.id}`)
      })
      const serviceIds: string[] = []
      selectedServices.map((service: any) => {
        return serviceIds.push(`${service.id}`)
      })
      const userIds: string[] = []
      selectedUsers.map((user: any) => {
        return userIds.push(`${user.id}`)
      })

      await promoApi.updatePromotion(
        {
          ...dataCreate,
          description: content,
          isPercent: isPercent,
          userIds: userIds,
          serviceIds: serviceIds,
          customerRanking: levelIds,
          customerGroup: groupIds,
          provinceIds: rangeIds,
          countryId: valueNation,
        },
        id as string
      )
      setAlertSuccess('Lưu thay đổi thành công')
      setLoadingFalse()
      navigate('/marketing/promotion-code')
    } catch (error: any) {
      setLoadingFalse()
      setAlertError(error.response?.data?.message)
    }
  }

  const levelUser = [
    { name: 'Thường', id: '1' },
    { name: 'Bạc', id: '2' },
    { name: 'Vàng', id: '23' },
    { name: 'Bạch kim', id: '4' },
  ]
  const groupUser = [
    { name: 'Trung thành', id: '1' },
    { name: 'Vãng lai', id: '2' },
    { name: 'Khó tính', id: '3' },
  ]

  useEffect(() => {
    fetchServices()
  }, [])

  useEffect(() => {
    if (data) {
      if (valuePrice === 'Giảm tiền') {
        setDataDefault({
          discount: data.discount,
          endDate: data.endDate,
          maxNumberOfUse: data.maxNumberOfUse,
          min: data.minOrderAmount,
          promotionCode: data.promotionCode,
          startDate: data.startDate,
          status: '',
          reduceType: valuePrice,
          subTitle: data.subTitle,
          title: data.title,
          totalCode: data.totalCode,
          typePromo: data.typePromo,
        })
      } else {
        setDataDefault({
          discount: 0,
          endDate: data.endDate,
          maxAmount: data.maxAmount,
          maxNumberOfUse: data.maxNumberOfUse,
          minPercent: data.minOrderAmount,
          percent: data.percent,
          promotionCode: data.promotionCode,
          reduceType: valuePrice,
          startDate: data.startDate,
          status: '',
          subTitle: data.subTitle,
          title: data.title,
          totalCode: data.totalCode,
          typePromo: data.typePromo,
        })
      }
      setContentDefault(data?.description)
      setIconDefault(data?.thumbnail)
      setSelectedServicesDefault(data?.conditions.serviceIds)
      setSelectedUsersDefault(data?.conditions.userIds)
      setSelectedLevelDefault(data?.conditions.customerRanking)
      setSelectedGroupsDefault(data?.conditions.customerGroup)
      setSelectedRangeDefault(data?.conditions.provinceIds)
    }
  }, [valuePrice])
  useEffect(() => {
    const fetchPromotion = async () => {
      const { data } = await promoApi.getPromotionById(id as string)
      setValue('discount', data?.discount ?? 0)
      setData(data)
      setImageURL(data.thumbnail)
      setValueStatus(data.status)
      setValueCode(data.typePromo)
      setValue('startDate', data.startDate)
      setValue('endDate', data.endDate)
      setContent(data.description)
      if (data.percent === null) {
        setValuePrice('Giảm tiền')
        setValue('min', data.minOrderAmount)
      } else {
        setValuePrice('Giảm phần trăm')
        setValue('percent', data.percent)
        setValue('minPercent', data.minOrderAmount)
        setValue('maxAmount', data.maxAmount)
      }
    }
    fetchPromotion()
  }, [id])
  useEffect(() => {
    if (valuePrice === 'Giảm tiền') {
      setIsPercent(false)
    } else if (valuePrice === 'Giảm phần trăm') {
      setIsPercent(true)
    }
  }, [valuePrice])
  useEffect(() => {
    if (data) {
      const countriesID = data.conditions?.countryId
      const fetchProvince = async () => {
        const { data } = await rangeOfActiveApi.getProvincesById(countriesID)
        if (data) {
          setSelectRange(data)
        }
      }
      fetchProvince()
    }
  }, [data])
  useEffect(() => {
    if (data) {
      const provinceId = data.conditions?.provinceIds?.toString()
      const countryID = data.conditions?.countryId
      const fetchProvinceId = async () => {
        const { data } = await promoApi.getProvincesByIdCondition(
          countryID,
          provinceId
        )
        if (data) {
          setSelectedRange(data)
        } else {
          setSelectedRange(selectRange)
        }
      }
      fetchProvinceId()
    }
  }, [data, selectRange])
  useEffect(() => {
    if (data) {
      const countriesID = data?.conditions?.countryId
      const fetchCountryById = async () => {
        const { data } = await promoApi.getCountryById(countriesID)
        if (data) {
          setValueNation(data.id)
        }
      }

      const usersId = data.conditions?.userIds?.toString()
      const fetchUsersId = async () => {
        const { data } = await promoApi.getUserCondition(usersId)
        if (data) {
          setSelectedUsers(data.rows)
        }
      }
      const servicesId = data.conditions?.serviceIds?.toString()
      const fetchServicesId = async () => {
        const { data } = await promoApi.getServiceCondition(servicesId)
        if (data) {
          setSelectedServices(data)
        } else {
          setSelectedServices(selectServices)
        }
      }
      fetchUsersId()
      fetchServicesId()

      fetchCountryById()
    }
  }, [data])

  useEffect(() => {
    if (valueObject === 'Tất cả') {
      setSelectedLevel([])
      setSelectedGroups([])
      setSelectedUsers([])
    } else if (valueObject === 'Hạng khách hàng') {
      setSelectedGroups([])
      setSelectedUsers([])
    } else if (valueObject === 'Nhóm khách hàng') {
      setSelectedLevel([])
      setSelectedUsers([])
    } else if (valueObject === 'Khách hàng cụ thể') {
      setSelectedLevel([])
      setSelectedGroups([])
    }
  }, [valueObject])
  useEffect(() => {
    if (data) {
      if (
        !data?.conditions.customerGroup.length &&
        !data?.conditions.customerRanking.length &&
        !data?.conditions.userIds.length
      ) {
        setValueObject('Tất cả')
      } else {
        if (data?.conditions.customerGroup.length) {
          setValueObject('Nhóm khách hàng')
        } else if (data?.conditions.customerRanking.length) {
          setValueObject('Hạng khách hàng')
        } else if (data?.conditions.userIds.length) {
          setValueObject('Khách hàng cụ thể')
        }
      }
    }
  }, [data])
  useEffect(() => {
    if (valueObject === 'Tất cả') {
      setErrorUser(undefined)
    }
    if (valueObject === 'Khách hàng cụ thể' && selectedUsers?.length) {
      setErrorUser(undefined)
    } else if (valueObject === 'Khách hàng cụ thể' && !selectedUsers?.length) {
      setErrorUser('Khách hàng cụ thể không được để trống')
    }
  }, [valueObject, selectedUsers])
  useEffect(() => {
    fetchDataCountries()
  }, [])
  return (
    <>
      <Grid container spacing={2}>
        <Breadcrumb paths={PROMOTION_CODE_CREATE} />
        <Grid item xs={12}>
          <Item sx={{ padding: '24px' }}>
            <StyleHeader>
              {' '}
              <StyleToogle>
                <StyleTitle>Thông tin dịch vụ</StyleTitle>
              </StyleToogle>
              <StyleFilter>
                <StyleStatus>
                  <InputField
                    id="status"
                    size="small"
                    label="Trạng thái"
                    select
                    style={{ marginTop: '-10px' }}
                    value={valueStatus}
                    {...register('status')}
                    onChange={handleChangeStatus}
                  >
                    {promotionStatus.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </InputField>
                </StyleStatus>

                <StyleLinks>
                  <SendIcon sx={{ color: 'white', marginRight: '5px' }} />
                  <StyleLink>Gửi thông báo về app</StyleLink>
                </StyleLinks>
              </StyleFilter>
            </StyleHeader>
            {data ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <StyleItem
                  style={{
                    paddingTop: '0',
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <StyleImage>
                    {image ? (
                      <StyleAddImage>
                        <img src={image} alt="Icon" />
                        <StyleAddItem>
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={handleUpload}
                            name="image"
                          />
                        </StyleAddItem>
                      </StyleAddImage>
                    ) : (
                      <StyleAddImage>
                        <img src={data.thumbnail} alt="Icon" />
                        <StyleAddItem>
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={handleUpload}
                          />
                        </StyleAddItem>
                      </StyleAddImage>
                    )}
                    {errorImage && (
                      <StyleErrorImage>{errorImage}</StyleErrorImage>
                    )}
                  </StyleImage>
                  <StyleInfo>
                    <InputField
                      label="Tên"
                      defaultValue={data?.title}
                      {...register('title')}
                    />
                    {errors.title && (
                      <StyleError>{errors.title.message as string}</StyleError>
                    )}
                    <InputField
                      label="Nội dung ngắn"
                      defaultValue={data?.subTitle}
                      multiline
                      {...register('subTitle')}
                    />
                    {errors.subTitle && (
                      <StyleError>
                        {errors.subTitle.message as string}
                      </StyleError>
                    )}
                    <StyleLabel>Mô tả </StyleLabel>
                    <Editor onChange={handleEditorChange} value={content} />
                    {!!errorContent && (
                      <StyleError style={{ marginTop: '10px' }}>
                        {errorContent}
                      </StyleError>
                    )}
                  </StyleInfo>
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleInput style={{ width: '70%' }}>
                    <StyleStatus>
                      {' '}
                      <InputField
                        id="status"
                        label="Loại"
                        select
                        value={valueCode}
                        inputProps={{ readOnly: true }}
                        {...register('typePromo')}
                        onChange={handleChangeCode}
                      >
                        {promotionCode.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                    </StyleStatus>

                    <div style={{ width: '30%' }}>
                      <Controller
                        control={control}
                        name="startDate"
                        defaultValue={dayjs(data.startDate).format(
                          'YYYY-MM-DD'
                        )}
                        render={({
                          field: { onChange, ref, onBlur, name, ...field },
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              minDate={new Date()}
                              {...field}
                              inputRef={ref}
                              inputFormat="DD/MM/YYYY"
                              label="Ngày bắt đầu"
                              renderInput={(inputProps) => (
                                <InputField
                                  {...inputProps}
                                  onBlur={onBlur}
                                  name={name}
                                  error={false}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                              onChange={(e: Date | null) => {
                                onChange(e)
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      {errors.startDate && (
                        <StyleError>
                          {errors.startDate.message as string}
                        </StyleError>
                      )}
                    </div>
                    <div style={{ width: '30%' }}>
                      <Controller
                        control={control}
                        name="endDate"
                        defaultValue={dayjs(data.endDate).format('YYYY-MM-DD')}
                        render={({
                          field: { onChange, ref, onBlur, name, ...field },
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              minDate={new Date()}
                              {...field}
                              inputRef={ref}
                              inputFormat="DD/MM/YYYY"
                              label="Ngày kết thúc"
                              renderInput={(inputProps) => (
                                <InputField
                                  {...inputProps}
                                  onBlur={onBlur}
                                  name={name}
                                  error={false}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                              onChange={(e: Date | null) => {
                                onChange(e)
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      {errors.endDate && (
                        <StyleError>
                          {errors.endDate.message as string}
                        </StyleError>
                      )}
                    </div>
                  </StyleInput>
                </StyleItem>

                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleInput style={{ width: '70%' }}>
                    <div>
                      <StyleContent>
                        {' '}
                        <StyleContentText style={{ marginRight: '8px' }}>
                          Mã:
                        </StyleContentText>
                        <StyleContentInput style={{ maxWidth: '200px' }}>
                          <TextField
                            size="small"
                            inputProps={{
                              readOnly: true,
                              style: { textTransform: 'uppercase' },
                            }}
                            defaultValue={data.promotionCode}
                            {...register('promotionCode')}
                          />
                        </StyleContentInput>
                      </StyleContent>
                      {errors.promotionCode && (
                        <StyleError style={{ marginTop: '10px' }}>
                          {errors.promotionCode.message as string}
                        </StyleError>
                      )}
                    </div>
                    {valueCode === 'code' ? (
                      <>
                        <div>
                          <StyleContent>
                            {' '}
                            <StyleContentText style={{ marginRight: '8px' }}>
                              Số lượng code:
                            </StyleContentText>
                            <StyleContentInput style={{ maxWidth: '70px' }}>
                              <StyledInput
                                sx={{ input: { textAlign: 'center' } }}
                                type="text"
                                inputProps={{
                                  onKeyPress: handleKeyPress,
                                  inputMode: 'numeric',
                                }}
                                size="small"
                                defaultValue={data.totalCode}
                                {...register('totalCode')}
                              />
                            </StyleContentInput>
                          </StyleContent>
                          {errors.totalCode && (
                            <StyleError style={{ marginTop: '10px' }}>
                              {errors.totalCode.message as string}
                            </StyleError>
                          )}
                        </div>
                        <div>
                          <StyleContent>
                            <StyleContentText style={{ marginRight: '8px' }}>
                              Giới hạn số lần sử dụng
                            </StyleContentText>
                            <StyleContentInput style={{ maxWidth: '70px' }}>
                              <StyledInput
                                sx={{ input: { textAlign: 'center' } }}
                                type="text"
                                inputProps={{
                                  onKeyPress: handleKeyPress,
                                  inputMode: 'numeric',
                                }}
                                size="small"
                                defaultValue={data.maxNumberOfUse}
                                {...register('maxNumberOfUse')}
                              />
                            </StyleContentInput>
                          </StyleContent>
                          {errors.maxNumberOfUse && (
                            <StyleError style={{ marginTop: '10px' }}>
                              {errors.maxNumberOfUse.message as string}
                            </StyleError>
                          )}
                        </div>
                      </>
                    ) : (
                      <div>
                        <StyleContent>
                          {' '}
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Số point tương đương:
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '70px' }}>
                            <StyledInput
                              sx={{ input: { textAlign: 'center' } }}
                              type="text"
                              size="small"
                              inputProps={{
                                onKeyPress: handleKeyPress,
                                inputMode: 'numeric',
                              }}
                              defaultValue={data.minPoint}
                              {...register('minPoint')}
                            />
                          </StyleContentInput>
                          <StyleContentText
                            style={{ marginLeft: '8px', marginRight: '150px' }}
                          >
                            Point
                          </StyleContentText>
                        </StyleContent>
                        {errors.minPoint && (
                          <StyleError style={{ marginTop: '10px' }}>
                            {errors.minPoint.message as string}
                          </StyleError>
                        )}
                      </div>
                    )}
                  </StyleInput>
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleTitle>Dịch vụ</StyleTitle>
                  {selectedServices && (
                    <MultiSelect
                      items={selectServices}
                      name="Dịch vụ"
                      readonly={true}
                      defaultValue={selectedServices}
                      selectAllLabel="Tất cả"
                      onChange={setSelectedServices}
                    />
                  )}
                  {/* <StyleError>{errorProvinces}</StyleError> */}
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleTitle>Dạng giảm</StyleTitle>
                  <StyleChoose>
                    <StyleInput style={{ marginTop: '-10px', width: '40%' }}>
                      {' '}
                      <InputField
                        id="status"
                        label="Dạng giảm"
                        select
                        size="small"
                        inputProps={{ readOnly: true }}
                        SelectProps={{ IconComponent: () => null }}
                        value={valuePrice}
                        {...register('reduceType')}
                        onChange={handleChangePrice}
                      >
                        {promotionPrice.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>
                    </StyleInput>
                    {valuePrice === 'Giảm tiền' ? (
                      <div>
                        <StyleContent>
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Giảm
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '110px' }}>
                            <InputNumberFormat
                              name="discount"
                              control={control}
                              text-align="left"
                              readOnly={true}
                              endAdornment={
                                <InputAdornment position="end">
                                  đ
                                </InputAdornment>
                              }
                            />
                          </StyleContentInput>
                          <StyleContentText style={{ marginLeft: '8px' }}>
                            trên tổng đơn
                          </StyleContentText>
                        </StyleContent>

                        <StyleContent style={{ marginTop: '20px' }}>
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Đơn tối thiểu
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '110px' }}>
                            <InputNumberFormat
                              name="min"
                              control={control}
                              text-align="left"
                              endAdornment={
                                <InputAdornment position="end">
                                  đ
                                </InputAdornment>
                              }
                            />
                          </StyleContentInput>
                        </StyleContent>
                      </div>
                    ) : (
                      <div>
                        <StyleContent style={{ margin: '-10px 0' }}>
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Giảm
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '110px' }}>
                            <InputField
                              size="small"
                              type="text"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              inputProps={{
                                step: 1,
                                readOnly: true,
                                onKeyPress: handleKeyPress,
                                inputMode: 'numeric',
                              }}
                              {...register('percent')}
                              sx={{ textAlign: 'right' }}
                            />
                          </StyleContentInput>
                          <StyleContentText style={{ marginLeft: '8px' }}>
                            trên tổng đơn
                          </StyleContentText>
                        </StyleContent>
                        {errors.percent && (
                          <StyleError style={{ marginTop: '10px' }}>
                            {errors.percent.message as string}
                          </StyleError>
                        )}
                        <StyleContent style={{ marginTop: '20px' }}>
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Đơn tối thiểu
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '110px' }}>
                            <InputNumberFormat
                              name="minPercent"
                              control={control}
                              text-align="left"
                              endAdornment={
                                <InputAdornment position="end">
                                  đ
                                </InputAdornment>
                              }
                            />
                          </StyleContentInput>
                        </StyleContent>
                        {errors.minPercent && (
                          <StyleError style={{ marginTop: '10px' }}>
                            {errors.minPercent.message as string}
                          </StyleError>
                        )}
                        <StyleContent style={{ marginTop: '20px' }}>
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Giảm tối đa
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '110px' }}>
                            <InputNumberFormat
                              name="maxAmount"
                              control={control}
                              text-align="left"
                              endAdornment={
                                <InputAdornment position="end">
                                  đ
                                </InputAdornment>
                              }
                            />
                          </StyleContentInput>
                        </StyleContent>
                        {errors.maxAmount && (
                          <StyleError style={{ marginTop: '10px' }}>
                            {errors.maxAmount.message as string}
                          </StyleError>
                        )}
                      </div>
                    )}
                  </StyleChoose>
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleTitle>Đối tượng áp dụng</StyleTitle>
                  <InputField
                    id="status"
                    label="Đối tượng áp dụng"
                    select
                    sx={{ textAlign: 'left' }}
                    style={{ marginTop: '-10px' }}
                    value={valueObject}
                    onChange={handleChangeObject}
                  >
                    {promotionObject.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </InputField>
                  {valueObject === 'Hạng khách hàng' && (
                    <StyleObject>
                      <StyleItem style={{ width: '80%' }}>
                        <StyleTitle>Hạng khách hàng</StyleTitle>
                        <MultiSelect
                          items={levelUser}
                          name="Hạng khách hàng"
                          selectAllLabel="Tất cả"
                          onChange={setSelectedLevel}
                        />
                        {/* <StyleError>{errorProvinces}</StyleError> */}
                      </StyleItem>
                    </StyleObject>
                  )}
                  {valueObject === 'Nhóm khách hàng' && (
                    <StyleObject>
                      <StyleItem style={{ width: '80%' }}>
                        <StyleTitle>Nhóm khách hàng</StyleTitle>
                        <MultiSelect
                          items={groupUser}
                          name="Nhóm khách hàng"
                          selectAllLabel="Tất cả"
                          onChange={setSelectedGroups}
                        />
                        {/* <StyleError>{errorProvinces}</StyleError> */}
                      </StyleItem>
                    </StyleObject>
                  )}
                  {valueObject === 'Khách hàng cụ thể' && (
                    <StyleObject>
                      <StyleItem style={{ width: '80%' }}>
                        <StyleTitle>Khách hàng cụ thể</StyleTitle>
                        {selectedUsers && (
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={selectUsers}
                            getOptionLabel={(option) =>
                              `${option?.fullName}  (${option?.phone})`
                            }
                            disableClearable
                            defaultValue={selectedUsers}
                            noOptionsText="Chưa có lựa chọn"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Khách hàng cụ thể"
                              />
                            )}
                            onInputChange={handleInputChange}
                            onChange={(event, value: any) => {
                              setSelectedUsers([...value])
                            }}
                          />
                        )}
                        {!!errorUser && (
                          <StyleError style={{ marginTop: '10px' }}>
                            {errorUser}
                          </StyleError>
                        )}
                      </StyleItem>
                    </StyleObject>
                  )}
                </StyleItem>
                <Divider sx={{ margin: '0 -24px' }} />
                <StyleItem>
                  <StyleTitle>Phạm vi áp dụng</StyleTitle>
                  <StyleTitle>Quốc gia</StyleTitle>
                  <StyleInput>
                    {' '}
                    {valueNation && (
                      <InputField
                        id="status"
                        label="Quốc gia"
                        select
                        value={valueNation}
                        sx={{ textAlign: 'left' }}
                        onChange={handleChangeNation}
                      >
                        {dataNation.map((option: any) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </InputField>
                    )}
                  </StyleInput>
                  <StyleTitle style={{ marginTop: '14px' }}>
                    Tỉnh/ Thành phố
                  </StyleTitle>
                  {selectedRange && (
                    <MultiSelect
                      items={selectRange}
                      name="Tỉnh/ Thành phố"
                      defaultValue={selectedRange}
                      selectAllLabel="Tất cả"
                      onChange={setSelectedRange}
                    />
                  )}
                  {/* <StyleError>{errorProvinces}</StyleError> */}
                </StyleItem>
                <Stack spacing={2} direction="row" sx={{ paddingTop: '10px' }}>
                  <Button variant="contained" type="submit" color="primary">
                    Lưu
                  </Button>
                  <Button variant="outlined" onClick={handleCheckDataCancel}>
                    Hủy
                  </Button>
                </Stack>
              </form>
            ) : (
              <LoadingVersion2 />
            )}
          </Item>
        </Grid>
        <BasicModal open={modalCancel} handleClose={handleClose}>
          <Typography>Bạn có chắc chắn muốn hủy thay đổi?</Typography>
          <Button
            sx={{
              margin: '20px',
              marginBottom: '0px',
              paddingLeft: '43px',
              paddingRight: '43px',
            }}
            variant="contained"
            onClick={() => navigate('/marketing/promotion-code')}
            className="bgr-global"
          >
            Hủy
          </Button>
          <Button
            sx={{
              margin: '20px',
              marginBottom: '-0.5px',
              paddingLeft: '30px',
              paddingRight: '30px',
            }}
            onClick={() => setModaCancel(false)}
            variant="outlined"
          >
            Không
          </Button>
        </BasicModal>
      </Grid>
    </>
  )
}

export default PromotionEdit
