import * as yup from 'yup'

export const newsSchema_ = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required('Tiêu đề  không được để trống')
    .min(3, 'Tiêu đề tối thiếu 3 ký tự, tối đa là 50 ký tự')
    .max(50, 'Tiêu đề tối thiếu 3 ký tự, tối đa là 50 ký tự'),
  description: yup
    .string()
    .trim()
    .required('Nội dung ngắn không được để trống')
    .min(5, 'Nội dung ngắn tối thiếu 5 ký tự, tối đa là 100 ký tự')
    .max(100, 'Nội dung ngắn tối thiếu 5 ký tự, tối đa là 100 ký tự'),
  link: yup
    .string()
    .trim()
    .required('Link không được để trống')
    .url('Link không hợp lệ'),
  priority: yup
    .number()
    .typeError('Nhập số, giới hạn 0-100')
    .nullable(true)
    .min(0, 'Nhập số, giới hạn 0-100')
    .max(100, 'Nhập số, giới hạn 0-100')
    .transform((_, val) => (val !== '' ? Number(val) : null)),
})
