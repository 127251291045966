import { TextField, TextFieldProps, InputAdornment } from '@mui/material'
import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, value, ...other } = props

  return (
    <NumericFormat
      {...other}
      value={new Intl.NumberFormat('en-US').format(value)}
      getInputRef={inputRef}
      thousandSeparator={','}
      decimalSeparator={'.'}
      onValueChange={(val: any) => onChange(val.value)}
      style={{ textAlign: 'right' }}
    />
  )
}

export default function InputNumberFormat({
  name,
  control,
  sx,
  endAdornment,
  readOnly,
}: {
  name: string
  endAdornment?: any
  control: any
  sx?: any
  readOnly?: boolean
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          size="small"
          sx={{ ...sx, textAlign: 'left' }}
          InputProps={{
            endAdornment: endAdornment,
            inputComponent: NumberFormatCustom,
            inputProps: {
              readOnly: readOnly,
              onChange,
              value: value ?? 0,
            },
          }}
        />
      )}
    />
  )
}
