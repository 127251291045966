import React, { useEffect, useState } from 'react'
import TablePagination from '@mui/material/TablePagination'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import campaignApi from 'services/campaignAPI'
import Breadcrumb from 'components/Breadcrumbs'
import { Grid, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  StyleAvatar,
  StyleAction,
  ActionButton,
  Campaigntitle,
  StyleTableHead,
  StyleFilter,
  StyleHeader,
  StyleAddItem,
  StyleLinks,
  StyleLink,
  StyleFilterItem,
} from './style'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CancelIcon from '@mui/icons-material/Cancel'
import { useNavigate } from 'react-router-dom'
import { useAlert } from 'contexts/AlertContext'
import BasicModal from 'components/Modal'
import SelectBox from 'components/Selectbox'
import dayjs from 'dayjs'
import { Button } from '@mui/material'
import { StyleTitle } from 'pages/campaign/style'
import { CAMPAIGN_LIST } from 'constants/breadcrumbs'
import { DatePicker } from 'antd'
import SelectBoxCheckBox from '../../../../components/SelectboxCheckBox'
import { dataSelectStatus } from '../../../../constants/campaignList'

export default function CampaignList() {
  const { setAlertClose, setAlertError, setAlertSuccess } = useAlert()
  const [modalOpen, setModalOpen] = useState(false)
  const [status, setStatus] = useState({
    id: '',
  })
  const handleClose = () => {
    setModalOpen(true)
  }
  const [datas, setDatas] = useState([])

  const [page, setPage] = useState(0)

  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [valueDateTime, setValueDateTime] = useState('')
  const [dates, setDates] = useState<any>([])

  const navigate = useNavigate()
  const { RangePicker } = DatePicker

  const handleEditButton = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    navigate(`/marketing/campaign/edit/${id}`)
    event.stopPropagation()
  }
  const handleDeleteButton = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    setModalOpen(true)
    setStatus({
      id: id,
    })
    event.stopPropagation()
  }
  const handleDelete = async (id: string) => {
    setAlertClose()
    try {
      await campaignApi.delete(id)
      fetchData()
      setModalOpen(false)
      setAlertSuccess('Đã xoá chiến dịch')
    } catch (err) {
      setAlertError('Xoá không thành công')
    }
  }
  const fetchData = async () => {
    const data: any = await campaignApi.getAllCampaign()
    setDatas(data)
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleSelectChange = (value: string) => {
    setValueDateTime(value)
    fetchData()
  }

  const handleCheckBoxChange = (values: string) => {
    fetchData()
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datas.length) : 0
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={CAMPAIGN_LIST} />
      <Grid item xs={12}>
        <Paper
          sx={{
            boxShadow:
              '0px 12px 33px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
            textAlign: 'center',
            height: '100%',
            padding: '24px',
          }}
        >
          <Campaigntitle>
            <StyleTitle>Chiến dịch Marketing</StyleTitle>
          </Campaigntitle>
          <StyleHeader>
            <StyleAddItem>
              <StyleLinks onClick={() => navigate('/marketing/campaign/add')}>
                <AddCircleIcon sx={{ color: 'white', marginRight: '5px' }} />
                <StyleLink>Thêm chiến dịch</StyleLink>
              </StyleLinks>
            </StyleAddItem>
          </StyleHeader>

          <StyleFilter>
            <StyleFilterItem>
              <SelectBox
                width="224px"
                id="select-1"
                label="Lọc theo thời gian"
                options={[
                  'Tất cả',
                  'Tháng này',
                  'Tháng trước',
                  'Quý này',
                  'Năm này',
                  'Mốc thời gian',
                ]}
                onChange={handleSelectChange}
              />
            </StyleFilterItem>

            <StyleFilterItem>
              <SelectBox
                width="224px"
                id="select-3"
                label="Người tạo"
                options={['Tất cả']}
                onChange={() => {
                  return true
                }}
              />
            </StyleFilterItem>

            <StyleFilterItem>
              <SelectBox
                width="224px"
                id="select-4"
                label="Người duyệt"
                options={['Tất cả']}
                onChange={() => {
                  return true
                }}
              />
            </StyleFilterItem>

            <StyleFilterItem>
              <SelectBoxCheckBox
                width="224px"
                id="select-2"
                label="Trạng thái"
                options={dataSelectStatus}
                onChange={handleCheckBoxChange}
                haveAll={true}
              />
            </StyleFilterItem>

            {valueDateTime == 'Mốc thời gian' && (
              <RangePicker
                style={{ height: '40px', width: '224px' }}
                onChange={(values: any) => {
                  setDates(
                    values?.map((item: any) => {
                      return {
                        start: dayjs(item).format('YYYY-MM-DD'),
                        end: dayjs(item).format('YYYY-MM-DD'),
                      }
                    })
                  )
                }}
              />
            )}
          </StyleFilter>

          <TableContainer component={Paper} className="box-shadow">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyleTableHead>STT</StyleTableHead>
                  <StyleTableHead>Tên chiến dịch</StyleTableHead>
                  <StyleTableHead>Mục đích</StyleTableHead>
                  <StyleTableHead>Người tạo</StyleTableHead>
                  <StyleTableHead>Người duyệt</StyleTableHead>
                  <StyleTableHead>Nền tản</StyleTableHead>
                  <StyleTableHead>Chi phí dự kiến</StyleTableHead>
                  <StyleTableHead>Roi dự kiến</StyleTableHead>
                  <StyleTableHead>Roi thực hiện</StyleTableHead>
                  <StyleTableHead>Ngày tạo</StyleTableHead>
                  <StyleTableHead>Tình trạng</StyleTableHead>
                  <StyleTableHead align="center" sx={{ paddingRight: '80px' }}>
                    Chức năng
                  </StyleTableHead>
                </TableRow>
              </TableHead>
              <TableBody>
                {datas
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: ICampaign, index) => {
                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell align="left" sx={{ paddingLeft: '26px' }}>
                          {index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell
                          sx={{ cursor: 'pointer' }}
                          align="left"
                          onClick={() =>
                            navigate(`/marketing/campaign/detail/${row.id}`)
                          }
                        >
                          {row.campaign}
                        </TableCell>
                        <TableCell align="left">{row.purpose}</TableCell>
                        <TableCell component="th" scope="row">
                          <StyleAvatar>
                            <Avatar alt={row.name} src={row.avatar} />
                            <Typography sx={{ marginLeft: '5px' }}>
                              {row.name}
                            </Typography>
                          </StyleAvatar>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <StyleAvatar>
                            <Avatar alt={row.name} src={row.avatar} />
                            <Typography sx={{ marginLeft: '5px' }}>
                              wait
                            </Typography>
                          </StyleAvatar>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <StyleAvatar>
                            <Avatar alt={row.name} src={row.avatar} />
                            <Typography sx={{ marginLeft: '5px' }}>
                              wait
                            </Typography>
                          </StyleAvatar>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <StyleAvatar>
                            <Avatar alt={row.name} src={row.avatar} />
                            <Typography sx={{ marginLeft: '5px' }}>
                              wait
                            </Typography>
                          </StyleAvatar>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <StyleAvatar>
                            <Avatar alt={row.name} src={row.avatar} />
                            <Typography sx={{ marginLeft: '5px' }}>
                              wait
                            </Typography>
                          </StyleAvatar>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <StyleAvatar>
                            <Avatar alt={row.name} src={row.avatar} />
                            <Typography sx={{ marginLeft: '5px' }}>
                              wait
                            </Typography>
                          </StyleAvatar>
                        </TableCell>

                        <TableCell align="left">
                          {dayjs(row.start).format('MM/DD/YYYY')}
                        </TableCell>
                        <TableCell align="left">
                          <StyleAvatar>
                            {row.status === 'Không duyệt' ? (
                              <CancelIcon sx={{ color: '#ff0101' }} />
                            ) : row.status === 'Đã duyệt' ? (
                              <DoneAllIcon sx={{ color: '#6ccc99' }} />
                            ) : (
                              <AccessTimeIcon sx={{ color: '#f39a13' }} />
                            )}
                            <Typography
                              fontSize="14px"
                              color={
                                row.status === 'Không duyệt'
                                  ? '#ff0101'
                                  : row.status === 'Đã duyệt'
                                  ? '#6ccc99'
                                  : '#f39a13'
                              }
                              marginLeft="5px"
                            >
                              {row.status}
                            </Typography>
                          </StyleAvatar>
                        </TableCell>
                        <TableCell align="center">
                          <StyleAction>
                            <ActionButton
                              style={{
                                backgroundColor: '#3CD4A0',
                              }}
                              onClick={(event) => {
                                handleEditButton(event, row.id)
                              }}
                            >
                              Sửa
                            </ActionButton>
                            <ActionButton
                              style={{
                                backgroundColor: 'red',
                                marginLeft: '10px',
                              }}
                              onClick={(event) => {
                                handleDeleteButton(event, row.id)
                              }}
                            >
                              Xoá
                            </ActionButton>
                          </StyleAction>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={datas.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Số dòng trên một trang"
            />
          </TableContainer>
        </Paper>
      </Grid>
      <BasicModal open={modalOpen} handleClose={handleClose}>
        <Typography>Bạn có chắc muốn xoá chiến dịch này chứ ?</Typography>
        <Button
          sx={{ margin: '8px', marginBottom: '0px' }}
          variant="contained"
          color="error"
          onClick={() => handleDelete(status.id)}
        >
          Xóa chiến dịch
        </Button>
        <Button
          sx={{ margin: '8px', marginBottom: '0px' }}
          onClick={() => setModalOpen(false)}
          variant="outlined"
        >
          Hủy
        </Button>
      </BasicModal>
    </Grid>
  )
}
