import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { DatePicker } from 'antd'
import Breadcrumb from 'components/Breadcrumbs'
import Delete from 'components/Icons/components/Delete'
import Edit from 'components/Icons/components/Edit'
import SearchInput from 'components/InputSearch'
import SelectBoxCheckBox from 'components/SelectboxCheckBox'
import { ORDER_LIST } from 'constants/breadcrumbs'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/common'
import { useAlert } from 'contexts/AlertContext'
import dayjs from 'dayjs'
import useDebounce from 'hooks/useDebounce'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import orderAPI from 'services/orderAPI'
import { servicesAPI } from 'services/servicesAPI'
import {
  IObjectPaymentStatus,
  colorPaymentStatus,
  iconsPaymentStatus,
  paymentStatusOrder,
} from 'utils/paymentStatusOrder'
import {
  IObjectStatus,
  colorStatus,
  iconsStatus,
  statusOrder,
} from 'utils/statusOrder'
import BasicModal from '../../components/Modal/index'
import {
  filterDateTimeOrder,
  filterDateTimeOrders,
  filterPaymentStatus,
  filterStatusOrder,
} from '../../constants/order'
import {
  StyleContentTitle,
  StyleFilter,
  StyleFilterItem,
  StyleFilterItemSearch,
  StyleHeaderItem,
  StyleLink,
  StyleLinks,
  StylePaper,
  StyleTableCell,
  StyleTableHeader,
  StyleTitle,
  WrapCell,
  WrapStickyCell,
} from './style'

interface IPriceValueFilter {
  maxPrice: number
  minPrice: number
}

interface IPriceValue {
  key: number
  label: string
  value: IPriceValueFilter
}

const listFilterPrice = [
  {
    key: 1,
    value: {},
    label: 'Tất cả',
  },
  {
    key: 2,
    value: {
      maxPrice: 99000,
    },
    label: 'Dưới 100.000đ',
  },
  {
    key: 3,
    value: {
      minPrice: 100000,
      maxPrice: 5000000,
    },
    label: 'Từ 100.000đ đến 5.000.000',
  },
  {
    key: 4,
    value: {
      minPrice: 5010000,
    },
    label: 'Trên 5.000.000đ',
  },
]

export default function OrderList() {
  const [modalOpen, setModalOpen] = useState(false)

  const [data, setData] = useState([])
  const [dataServices, setDataServices] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const [status, setStatus] = useState('')
  const [deleteId, setDeleteId] = useState({
    id: 1,
  })
  const [valueServices, setValueServices] = useState('')
  const [valuePrice, setValuePrice] = useState<IPriceValue>()
  const [keySearch, setKeySearch] = useState('')

  const debouncedValue = useDebounce<string>(keySearch, 500)

  const navigate = useNavigate()

  const { setAlertSuccess, setAlertClose, setAlertError } = useAlert()
  const [isFocused, setIsFocused] = useState(false)
  const [valueDateTime, setValueDateTime] = useState('')
  const [valueDateTimeOrder, setValueDateTimeOrder] = useState<any>([])
  const [statusPayment, setStatusPayment] = useState('')

  const { RangePicker } = DatePicker
  const [dates, setDates] = useState<any>([])

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const fetchData = async (page: number) => {
    const { data } = await orderAPI.getOrder({
      page: page + 1,
      limit: rowsPerPage,
      serviceId: valueServices,
      status: status,
      search: keySearch,
      dateCreateOrderStart: dates.start ?? '',
      dateCreateOrderEnd: dates.end ?? '',
      minPrice: valuePrice?.value?.minPrice || '',
      maxPrice: valuePrice?.value?.maxPrice || '',
    })
    setData(data.rows)
    setCount(data.count)
    setPage(page)
  }

  const fetchDataServices = async () => {
    const { data } = await servicesAPI.getServices()
    setDataServices(
      data?.map((item: any) => ({ value: item.id, label: item.name }))
    )
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    fetchData(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleModalDelete = (id: number) => {
    setModalOpen(true)
    setDeleteId({
      id: id,
    })
  }

  const handleSearch = (event: SelectChangeEvent) => {
    setKeySearch(event.target.value as string)
  }

  const handleChangeStatus = (value: any) => {
    setStatus(value)
  }

  const handleChangeStatusPayment = (value: any) => {
    setStatusPayment(value)
  }

  const handleChangeServices = (value: any) => {
    setValueServices(value)
  }

  const handleChangePrice = (event: SelectChangeEvent, newValue: any) => {
    const newFilterPrice = listFilterPrice.find(
      (item) => item.key == newValue.props.value
    )
    setValuePrice(newFilterPrice as IPriceValue)
  }

  const handleChangeDateTime = (event: SelectChangeEvent, newValue: any) => {
    const today = dayjs()
    let start, end, dateChoose

    if (newValue?.props?.value === filterDateTimeOrders.tomorrow) {
      const tomorrow = dayjs().add(1, 'day')
      dateChoose = tomorrow
      start = dayjs(today).format('YYYY-MM-DD')
      end = dayjs(tomorrow).format('YYYY-MM-DD')
    } else if (newValue?.props?.value === filterDateTimeOrders.nextThreeDays) {
      const nextThreeDays = today.add(3, 'day')
      dateChoose = nextThreeDays

      start = dayjs(today).format('YYYY-MM-DD')
      end = dayjs(nextThreeDays).format('YYYY-MM-DD')
    } else if (newValue?.props?.value === filterDateTimeOrders.nextSevenDays) {
      const nextSevenDays = today.add(7, 'day')
      dateChoose = nextSevenDays
      start = dayjs(today).format('YYYY-MM-DD')
      end = dayjs(nextSevenDays).format('YYYY-MM-DD')
    } else {
      setDates([])
      dateChoose = null
      start = null
      end = null
    }

    setValueDateTimeOrder(dateChoose ? [today, dateChoose] : [])
    setDates(start && end ? { start, end } : [])
    setValueDateTime(newValue?.props?.value)
  }

  const handleDelete = async (id: number) => {
    setAlertClose()
    try {
      await orderAPI.deleteOrder(id)
      setModalOpen(false)
      setAlertSuccess('Xóa đơn hàng thành công')
      fetchData(page)
    } catch (err) {
      setAlertError('Xoá đơn hàng không thành công')
    }
  }

  useEffect(() => {
    fetchData(page)
  }, [
    rowsPerPage,
    page,
    debouncedValue,
    status,
    valueServices,
    valuePrice,
    statusPayment,
  ])

  useEffect(() => {
    fetchDataServices()
  }, [])
  const textFieldStyles = {
    width: isFocused ? '250px' : '224px',
    transition: 'width 0.3s ease-in-out',
  }
  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={ORDER_LIST} />
      <Grid item xs={12}>
        <StylePaper className="bd-radius">
          <StyleTableHeader>
            <StyleContentTitle>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <StyleLinks onClick={() => navigate('/order/add')}>
                  <AddCircleIcon sx={{ marginRight: '5px' }} />
                  <StyleLink>Thêm đơn hàng</StyleLink>
                </StyleLinks>
                <Box>
                  <SearchInput
                    onChange={handleSearch}
                    placeholder="Tìm kiếm đơn hàng"
                    value={keySearch}
                    variant="outlined"
                    size="small"
                    style={textFieldStyles}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </Box>
              </Box>
              <StyleTitle>Danh sách đơn hàng</StyleTitle>
            </StyleContentTitle>
            <StyleHeaderItem>
              <StyleFilter>
                {/* <StyleFilterItem className="d-flex justify-center">
                  <FormControl
                    sx={{ width: 220 }}
                    size="small"
                    className="input-select"
                  >
                    <InputLabel id="demo-simple-select-label-status">
                      Khu vực
                    </InputLabel>
                    <Select label="Khu vực"></Select>
                  </FormControl>
                </StyleFilterItem> */}
                <StyleFilterItem>
                  <SelectBoxCheckBox
                    width="224px"
                    id="select-2"
                    label="Dịch vụ"
                    options={dataServices}
                    onChange={handleChangeServices}
                    haveAll={false}
                  />
                </StyleFilterItem>
                <StyleFilterItem>
                  <SelectBoxCheckBox
                    width="224px"
                    id="select-3"
                    label="Trạng thái"
                    options={filterStatusOrder}
                    onChange={handleChangeStatus}
                    haveAll={false}
                  />
                </StyleFilterItem>
                <StyleFilterItem className="d-flex justify-center">
                  <FormControl
                    sx={{ width: 240 }}
                    size="small"
                    className="input-select"
                  >
                    <InputLabel id="demo-simple-select-label-status">
                      Tổng tiền
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-status"
                      id="demo-simple-select-status"
                      value={valuePrice?.key.toString()}
                      label="Tổng tiền"
                      onChange={handleChangePrice}
                    >
                      {listFilterPrice?.map((item) => (
                        <MenuItem value={item.key} key={item.key}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyleFilterItem>

                <StyleFilterItem>
                  <SelectBoxCheckBox
                    width="224px"
                    id="select-4"
                    label="Trạng thái thanh toán"
                    options={filterPaymentStatus}
                    onChange={handleChangeStatusPayment}
                    haveAll={false}
                  />
                </StyleFilterItem>

                {/* <StyleFilterItem className="d-flex justify-center">
                  <FormControl
                    sx={{ width: 220 }}
                    size="small"
                    className="input-select"
                  >
                    <InputLabel id="demo-simple-select-label-status">
                      Trạng thái thanh toán
                    </InputLabel>
                    <Select
                      value={statusPayment}
                      label="Trạng thái thanh toán"
                      onChange={handleChangeStatusPayment}
                    >
                      <MenuItem value="">Tất cả</MenuItem>
                      <MenuItem value="waiting">Đã thanh toán</MenuItem>
                      <MenuItem value="waitingManual , waitingConfirm">
                        Chưa thanh toán
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyleFilterItem> */}

                <StyleFilterItemSearch>
                  <FormControl
                    sx={{ width: 220 }}
                    size="small"
                    className="input-select"
                  >
                    <InputLabel id="demo-simple-select-label-status">
                      Ngày giờ
                    </InputLabel>
                    <Select
                      value={valueDateTime}
                      label="Ngày giờ"
                      onChange={handleChangeDateTime}
                    >
                      <MenuItem value={''}>Tất cả</MenuItem>
                      {filterDateTimeOrder?.map((item: any) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyleFilterItemSearch>

                {valueDateTime !== '' && (
                  <RangePicker
                    style={{ height: '40px', width: '220px' }}
                    value={valueDateTimeOrder}
                    onChange={(values: any) => {
                      setValueDateTimeOrder(values)
                      setDates({
                        start: dayjs(values[0]).format('YYYY-MM-DD'),
                        end: dayjs(values[1]).format('YYYY-MM-DD'),
                      })
                    }}
                  />
                )}
              </StyleFilter>
            </StyleHeaderItem>
          </StyleTableHeader>
          <TableContainer
            component={Paper}
            className="box-shadow"
            sx={{
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                height: 12,
              },
              '@media only screen and (max-height: 715px)': {
                height: 545,
              },
              '@media only screen and (min-height: 925px)': {
                height: 750,
              },
            }}
          >
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
              // stickyHeader
            >
              <TableHead
                sx={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'white',
                  zIndex: 1,
                }}
              >
                <TableRow sx={{ display: 'flex' }}>
                  <WrapStickyCell>
                    <StyleTableCell width={50}>STT</StyleTableCell>
                    <StyleTableCell align="left">Mã đơn</StyleTableCell>
                    <StyleTableCell align="left">Dịch vụ</StyleTableCell>
                    <StyleTableCell align="left">Tên KH</StyleTableCell>
                  </WrapStickyCell>
                  <WrapCell>
                    <StyleTableCell align="center">
                      Số điện thoại
                    </StyleTableCell>
                    <StyleTableCell align="left">Ngày đặt đơn</StyleTableCell>
                    <StyleTableCell align="left">Người làm</StyleTableCell>
                    <StyleTableCell align="left">
                      Nhân viên kinh doanh
                    </StyleTableCell>
                    <StyleTableCell align="left">
                      Nhân viên phụ trách đơn
                    </StyleTableCell>
                    <StyleTableCell align="left">Khu vực</StyleTableCell>
                    <StyleTableCell align="center">Tổng tiền</StyleTableCell>
                    <StyleTableCell align="center">
                      Trạng thái thanh toán
                    </StyleTableCell>
                    <StyleTableCell align="center">Trạng thái</StyleTableCell>
                    <StyleTableCell
                      align="center"
                      sx={{
                        position: 'sticky',
                        right: 0,
                        backgroundColor: 'white',
                      }}
                    >
                      Chức năng
                    </StyleTableCell>
                  </WrapCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row: any, index: number) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      display: 'flex',
                    }}
                  >
                    <WrapStickyCell>
                      <TableCell component="th" scope="row" align="center">
                        {data && index + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{
                          '&:hover': { opacity: 0.6 },
                          cursor: 'pointer',
                        }}
                        onClick={() => navigate(`/order/detail/${row.id}`)}
                      >
                        {row.displayId}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: '180px',
                        }}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {row.rootServiceDetail.name}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {row.user.fullName}
                      </TableCell>
                    </WrapStickyCell>
                    <WrapCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.user.phone}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {dayjs(row?.startTime).format('DD/MM/YYYY hh:mm')}
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {row.staffs.map(
                          (item: any) =>
                            item.staffOrderStatus.status === 'assigned' &&
                            item?.fullName
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                      ></TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                      ></TableCell>
                      <TableCell component="th" scope="row" align="left">
                        {row?.fullLocation
                          ?.split(',')
                          ?.slice(-3, -2)[0]
                          ?.replace('550000', '')}
                        ,
                        {row?.fullLocation
                          ?.split(',')
                          ?.slice(-2, -1)[0]
                          ?.replace('550000', '')}
                        ,
                        {row?.fullLocation
                          ?.split(',')
                          ?.pop()
                          ?.replace('550000', '')}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {(row?.grandTotalPrice as number).toLocaleString(
                          'vi-VN',
                          {
                            style: 'currency',
                            currency: 'VND',
                          }
                        )}
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <div
                          style={{
                            color:
                              colorPaymentStatus[
                                row?.paymentStatus as keyof IObjectPaymentStatus
                              ],
                            fontSize: '14px',
                            gap: '4px',
                          }}
                          className="d-flex justify-center align-center"
                        >
                          {iconsPaymentStatus[row?.paymentStatus]}
                          {
                            paymentStatusOrder[
                              row?.paymentStatus as keyof IObjectPaymentStatus
                            ]
                          }
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <div
                          style={{
                            color:
                              colorStatus[row?.status as keyof IObjectStatus],
                            fontSize: '14px',
                            gap: '4px',
                          }}
                          className="d-flex justify-center align-center"
                        >
                          {iconsStatus[row?.status]}
                          {statusOrder[row?.status as keyof IObjectStatus]}
                        </div>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={{
                          minWidth: '120px',
                          maxWidth: '120px',
                          position: 'sticky',
                          right: 0,
                          backgroundColor: 'white',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '10px',
                          }}
                        >
                          <div
                          // style={
                          //   row?.status === 'rejected' ||
                          //   row?.status === 'done'
                          //     ? {
                          //         pointerEvents: 'none',
                          //         color: '#BBBBBB',
                          //       }
                          //     : {
                          //         pointerEvents: 'auto',
                          //       }
                          // }
                          >
                            <Edit
                              // style={
                              //   row?.status === 'rejected' ||
                              //   row?.status === 'done'
                              //     ? {
                              //         pointerEvents: 'none',
                              //         color: '#BBBBBB',
                              //       }
                              //     : {
                              //         pointerEvents: 'auto',
                              //       }
                              // }
                              onClick={() => navigate(`/order/edit/${row.id}`)}
                            />
                          </div>

                          <Delete
                            onClick={() => {
                              handleModalDelete(row?.id)
                            }}
                          />
                        </div>
                      </TableCell>
                    </WrapCell>
                  </TableRow>
                ))}
              </TableBody>
              <BasicModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
              >
                <Typography variant="body2" gutterBottom>
                  Bạn có chắc chắn muốn xoá đơn hàng này chứ?
                </Typography>
                <Button
                  sx={{
                    margin: '8px',
                    marginBottom: '0px',
                    fontSize: '12px',
                    height: '28px',
                    minWidth: '46px',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="error"
                  onClick={() => handleDelete(deleteId.id)}
                >
                  Xóa
                </Button>
                <Button
                  sx={{
                    margin: '8px',
                    marginBottom: '0px',
                    fontSize: '12px',
                    height: '28px',
                    minWidth: '46px',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => setModalOpen(false)}
                  variant="outlined"
                >
                  Hủy
                </Button>
              </BasicModal>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Số dòng trên một trang"
          />
        </StylePaper>
      </Grid>
    </Grid>
  )
}
