import { TableCell, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyleHeaderItem = styled.div`
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const StyleTitle = styled.h2`
  font-size: 20px;
  color: var(--color-primary);
  font-weight: bold;
  text-align: left;
`

export const StyleLinksAdd = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  min-width: 47%;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin-bottom: 24px;
  transition: 0.1s ease-in-out;
  height: 32px;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`
export const StyleLinksDelete = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  min-width: 47%;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin-bottom: 24px;
  transition: 0.1s ease-in-out;
  height: 28px;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`

export const StyleLink = styled.span`
  margin-top: 2px;
  font-weight: bold;
`
export const StyleTableCell = styled(TableCell)`
  && {
    font-weight: bold;
    color: var(--color-secondary);
  }
`
export const StyleTableCellItem = styled.p`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyleAddImageShow = styled.img`
  width: 500px;
  height: 400px;
  border-radius: 8px;
  object-fit: cover;
`

export const StyleItem = styled.div`
  padding: 20px 0;
`
export const StyleImage = styled.div`
  max-width: 18%;
  padding: 0;
  height: 100%;
  margin-right: 20px;
  width: 20%;
`

export const StyleAddImage = styled.label`
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  margin: auto;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 100%;
    height: 184px;
    border-radius: 8px;
    object-fit: contain;
  }
  input {
    display: none;
  }
`

export const StyleAddItem = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 0;
`
export const StyleInfo = styled.div`
  margin-top: -8px;
  min-width: 80%;
  width: 80%;
`
export const StyleLabel = styled(Typography)`
  && {
    font-weight: 600 !important;
    line-height: 1.5;
    text-align: left;
    font-size: 1rem;
    color: #33303cad;
    margin-bottom: 14px;
  }
`

export const StyleError = styled.p`
  display: block;
  color: red;
  font-size: 14px;
  text-align: left;
  white-space: normal;
`
