import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { TabPanel, a11yProps } from 'components/TabPanel'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import Item from 'components/Item'
import { PART_TIME_STAFF_CREATE } from 'constants/breadcrumbs'
import Breadcrumb from 'components/Breadcrumbs'

import {
  StyleHeader,
  StyleWrapTop,
  StyleWrapBottom,
  StyleWrapLeft,
  StyleWrapRight,
  StyleHeaderItem,
  StyleTitle,
  StyleLink,
  StyleLinks,
} from './style'
import { Controller, useForm } from 'react-hook-form'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import InputField from 'components/FormControl/InputField'
import {
  theReasonStaff,
  operatingStatusStaff,
  statusStaff,
} from 'constants/partTimeStaff'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import PersonalInformationCreate from './components/PersonalInformationCreate'
import WorkCalendarCreate from '../PartTimeStaffCreate/components/WorkCalendarCreate'

const PartTimeStaffCreate = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [value, setValue] = React.useState(0)
  const [valueStatusStaff, setValueStatusStaff] = useState('')
  const [valueOperatingStatusStaff, setValueOperatingStatusStaff] = useState('')
  const [valueTheReasonStaff, setValueTheReasonStaff] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleChangeStatusStaff = (event: SelectChangeEvent, newValue: any) => {
    if (newValue?.props?.value == 'Doing') {
      setValueOperatingStatusStaff('')
      setIsOpen(false)
    }
    if (newValue?.props?.value == 'TakeABreak') {
      setValueOperatingStatusStaff('TemporarilyLockTheCalendar')
      setIsOpen(true)
    }
    if (newValue?.props?.value == 'quitOneIsJob') {
      setValueOperatingStatusStaff('accountLock')
      setIsOpen(true)
    }
    setValueStatusStaff(newValue?.props?.value)
  }
  const handleChangeOperatingStatusStaff = (
    event: SelectChangeEvent,
    newValue: any
  ) => {
    setValueOperatingStatusStaff(newValue?.props?.value)
    setIsOpen(true)
  }
  const handleChangeTheReasonStaff = (
    event: SelectChangeEvent,
    newValue: any
  ) => {
    setValueTheReasonStaff(newValue?.props?.value)
  }

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={PART_TIME_STAFF_CREATE} />
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <Box sx={{ width: '100%' }}>
            <StyleHeaderItem>
              <StyleTitle>Thêm mới cộng tác viên</StyleTitle>
              <StyleLinks>
                <FileDownloadOutlinedIcon
                  sx={{ marginRight: '10px', color: 'white' }}
                />
                <StyleLink>Xuất File Excel</StyleLink>
              </StyleLinks>
            </StyleHeaderItem>
            <StyleHeader>
              <StyleWrapLeft>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Thông tin cá nhân"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Hiệu suất"
                    {...a11yProps(1)}
                  />
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Lịch làm việc"
                    {...a11yProps(2)}
                  />
                  <Tab
                    sx={{ textTransform: 'capitalize' }}
                    label="Lịch sử làm việc"
                    {...a11yProps(3)}
                  />
                </Tabs>
              </StyleWrapLeft>
              <StyleWrapRight>
                <StyleWrapTop>
                  <FormControl
                    sx={{ width: 220 }}
                    size="small"
                    className="input-select"
                  >
                    <InputLabel id="demo-simple-select-label-status">
                      Tình trạng
                    </InputLabel>
                    <Select
                      value={valueStatusStaff}
                      label="Tình trạng"
                      onChange={handleChangeStatusStaff}
                    >
                      {statusStaff?.map((item: any) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ width: 220 }}
                    size="small"
                    className="input-select"
                  >
                    <InputLabel id="demo-simple-select-label-status">
                      Tình trạng hoạt động
                    </InputLabel>
                    <Select
                      value={valueOperatingStatusStaff}
                      label="Tình trạng hoạt động"
                      onChange={handleChangeOperatingStatusStaff}
                    >
                      {operatingStatusStaff?.map((item: any) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyleWrapTop>
                {isOpen && (
                  <StyleWrapBottom>
                    <div>
                      <Controller
                        control={control}
                        name="startDate"
                        defaultValue=""
                        render={({
                          field: { onChange, ref, onBlur, name, ...field },
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...field}
                              inputRef={ref}
                              inputFormat="DD/MM/YYYY"
                              label="Ngày bắt đầu"
                              renderInput={(inputProps) => (
                                <InputField
                                  {...inputProps}
                                  onBlur={onBlur}
                                  size="small"
                                  name={name}
                                  error={false}
                                  sx={{ width: 220, margin: '-10px 0px' }}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                              onChange={(e: Date | null) => {
                                onChange(e)
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      {/* <StyleError>{errors.startDate?.message as string}</StyleError> */}
                    </div>
                    <FormControl
                      sx={{ width: 220 }}
                      size="small"
                      className="input-select"
                    >
                      <InputLabel id="demo-simple-select-label-status">
                        Lý do
                      </InputLabel>
                      <Select
                        value={valueTheReasonStaff}
                        label="Lý do"
                        onChange={handleChangeTheReasonStaff}
                      >
                        {theReasonStaff?.map((item: any) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyleWrapBottom>
                )}
              </StyleWrapRight>
            </StyleHeader>

            <TabPanel value={value} index={0}>
              <PersonalInformationCreate />
            </TabPanel>
            <TabPanel value={value} index={1}></TabPanel>
            <TabPanel value={value} index={2}>
              {/* <WorkCalendarCreate /> */}
            </TabPanel>
            <TabPanel value={value} index={3}></TabPanel>
          </Box>
        </Item>
      </Grid>
    </Grid>
  )
}

export default PartTimeStaffCreate
