import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

export const ExportToExcel = (data: any[]) => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  })
  const fileData = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  })
  saveAs(fileData, 'data.xlsx')
}
