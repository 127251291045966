import { yupResolver } from '@hookform/resolvers/yup'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import SendIcon from '@mui/icons-material/Send'
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import MultiSelect from 'components/Autocomplete'
import Breadcrumb from 'components/Breadcrumbs'
import Editor from 'components/Editor'
import InputField from 'components/FormControl/InputField'
import InputNumberFormat from 'components/InputNumberFormat'
import Item from 'components/Item'
import BasicModal from 'components/Modal'
import { PROMOTION_CODE_CREATE } from 'constants/breadcrumbs'
import { IMAGE_TYPE, MAX_FILE_SIZE } from 'constants/common'
import {
  promotionCode,
  promotionObject,
  promotionPrice,
  promotionStatus,
} from 'constants/promotion'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import { StyledInput } from 'pages/services/components/HomeCleaning/style'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import promoApi from 'services/promoAPI'
import { checkChangeData } from 'utils/confirmBox'
import { FileTypeEnum, getFileSize } from 'utils/FileUtils'
import { uploadImage } from 'utils/uploadImage'
import { promotionSchema_ } from 'validations/promotionSchena'
import {
  StyleAddImage,
  StyleAddItem,
  StyleChoose,
  StyleContent,
  StyleContentInput,
  StyleContentText,
  StyleError,
  StyleErrorImage,
  StyleFilter,
  StyleHeader,
  StyleImage,
  StyleInfo,
  StyleInput,
  StyleItem,
  StyleLabel,
  StyleLink,
  StyleLinks,
  StyleObject,
  StyleStatus,
  StyleTitle,
  StyleToogle,
} from '../../style'

const PromotionCreate = () => {
  const [content, setContent] = useState('')
  const [isPercent, setIsPercent] = useState(false)
  const [selectServices, setSelectServices] = useState<any[]>([])
  const [selectedServices, setSelectedServices] = useState<any>([])
  const [selectedServicesDefault, setSelectedServicesDefault] = useState<any[]>(
    []
  )

  const [selectUsers, setSelectUsers] = useState<any[]>([])
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [selectedUsersDefault, setSelectedUsersDefault] = useState<any[]>([])
  const [selectedLevel, setSelectedLevel] = useState<any[]>([])
  const [selectedLevelDefault, setSelectedLevelDefault] = useState<any[]>([])
  const [selectedGroups, setSelectedGroups] = useState<any>([])
  const [selectedGroupsDefault, setSelectedGroupsDefault] = useState<any[]>([])
  const [selectRange, setSelectRange] = useState<any[]>([])
  const [selectedRange, setSelectedRange] = useState<any[]>([])
  const [selectedRangeDefault, setSelectedRangeDefault] = useState<any[]>([])
  const [image, setImage] = useState('')
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const [valueStatus, setValueStatus] = useState('show')
  const [valueObject, setValueObject] = useState('Tất cả')
  const [valueCode, setValueCode] = useState('code')
  const [valuePrice, setValuePrice] = useState('Giảm tiền')
  const [valueNation, setValueNation] = useState()
  const [dataNation, setDataNation] = useState<any>([])
  const [contentDefault, setContentDefault] = useState<string>()
  const [iconDefault, setIconDefault] = useState<string>()
  const [errorContent, setErrorContent] = useState<string>()
  const [errorNation, setErrorNation] = useState<string>()
  const [errorUser, setErrorUser] = useState<string>()
  const { setAlertSuccess, setAlertError } = useAlert()
  const navigate = useNavigate()
  const [modalCancel, setModaCancel] = useState<boolean>(false)
  const [error, setError] = useState('')
  const [dataDefault, setDataDefault] = useState<IPromotion>()
  const [fileChoose, setFileChoose] = useState<File | null>()
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(promotionSchema_),
    mode: 'onChange',
  })

  const checkOtherCondition = () => {
    const isServices =
      selectedServices.length === selectedServicesDefault.length &&
      selectedServices.every(
        (value: any, index: number) => value === selectedServicesDefault[index]
      )
    const isUsers =
      selectedUsers.length === selectedUsersDefault.length &&
      selectedUsers.every(
        (value: any, index: number) => value === selectedUsersDefault[index]
      )
    const isGroups =
      selectedGroups.length === selectedGroupsDefault.length &&
      selectedGroups.every(
        (value: any, index: number) => value === selectedGroupsDefault[index]
      )
    const isLevel =
      selectedLevel.length === selectedLevelDefault.length &&
      selectedLevel.every(
        (value: any, index: number) => value === selectedLevelDefault[index]
      )
    const isRange =
      selectedRange.length === selectedRangeDefault.length &&
      selectedRange.every(
        (value, index) => value === selectedRangeDefault[index]
      )

    if (
      contentDefault !== content ||
      iconDefault !== image ||
      !isServices ||
      !isUsers ||
      !isGroups ||
      !isRange ||
      !isLevel
    ) {
      return true
    }
    return false
  }
  const handleCheckFile = () => {
    if (!fileChoose) {
      setError('Ảnh bìa không được để trống')
    }
    if (!valueNation) {
      setErrorNation('Quốc gia không được để trống')
    } else {
      setErrorNation(undefined)
    }

    if (content === '<p><br></p>' || content === '') {
      setErrorContent('Nội dung premium không được để trống')
      return
    } else {
      setErrorContent(undefined)
    }
  }
  const handleClose = () => setModaCancel(false)
  const handleCheckDataCancel = () => {
    const getValuesItem = getValues()
    const isChanged = checkChangeData({
      dataChange: getValuesItem,
      dataDefault: dataDefault,
    })
    const checkOrther = checkOtherCondition()
    if (isChanged || checkOrther) {
      setModaCancel(true)
    } else {
      navigate('/marketing/promotion-code/')
    }
  }

  const handleInputChange = async (event: any, value: any) => {
    const { data } = await promoApi.getUser(value)

    const result = data.rows.filter(
      (item: any) =>
        !selectedUsers.find((element: any) => element.id === item.id)
    )
    setSelectUsers(result)
  }

  const handleEditorChange = (value: string) => {
    setContent(value)
    if (value === '<p><br></p>' || value === '') {
      setErrorContent('Nội dung premium không được để trống')
      return
    } else {
      setErrorContent(undefined)
    }
  }

  const handleChangeStatus = (event: React.SyntheticEvent, newValue: any) => {
    setValueStatus(newValue?.props?.value)
  }
  const handleChangeObject = (event: React.SyntheticEvent, newValue: any) => {
    setValueObject(newValue?.props?.value)
  }
  const handleChangeCode = (event: React.SyntheticEvent, newValue: any) => {
    setValueCode(newValue?.props?.value)
  }
  const handleChangePrice = (event: React.SyntheticEvent, newValue: any) => {
    setValuePrice(newValue?.props?.value)
  }
  const handleChangeNation = async (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setValueNation(newValue?.props?.value)
    if (!newValue?.props?.value) {
      setErrorNation('Quốc gia không được để trống')
      return
    } else {
      setErrorNation(undefined)
    }
    const { data } = await promoApi.getProvincesById(newValue?.props?.value)
    if (data) {
      setSelectRange(data)
    } else {
      setSelectRange([])
      setAlertError('Quốc gia mà bạn chọn chưa có dữ liệu!')
    }
  }
  const handleUpload = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
    if (file) {
      const name = file?.name.split('.')
      if (fileSize > MAX_FILE_SIZE) {
        setError(`Dung lượng ảnh tối đa cho phép là ${MAX_FILE_SIZE} MB`)
        return
      }
      if (!name[name.length - 1].match(IMAGE_TYPE)) {
        setError('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
        return
      }
      if (fileSize === 0) {
        setError(`Ảnh lỗi`)
        return
      }
      setFileChoose(file)
      setImage(URL?.createObjectURL((target.files as FileList)[0]))
      setError('')
    }
  }
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key, target } = event
    const inputValue = (target as HTMLInputElement).value

    if (!/[0-9.,]/.test(key)) {
      event.preventDefault()
    }
    if (
      (key === '.' || key === ',') &&
      (inputValue.includes('.') || inputValue.includes(','))
    ) {
      event.preventDefault()
    }
  }
  const fetchServices = async () => {
    const { data } = await promoApi.getServices()
    setSelectServices(data)
  }
  const fetchDataCountries = async () => {
    const { data } = await promoApi.getCountries()
    setDataNation(data)
  }
  const onSubmit = async (data: IFormPromotion) => {
    if (content === '') {
      setErrorContent('Nội dung premium không được để trống')
      return
    } else {
      setErrorContent(undefined)
    }
    if (errorUser) {
      return
    }
    if (!valueNation) {
      setErrorNation('Quốc gia không được để trống')
      return
    } else {
      setErrorNation(undefined)
    }
    setLoadingTrue()

    let isUploadSuccess = false
    const dataCreate: IPromotion = { ...data }

    try {
      const response: IUploadImg = await uploadImage(fileChoose as File)
      dataCreate.thumbnail = response.origin
      isUploadSuccess = true
    } catch (error: any) {
      setError('Ảnh bìa không được để trống')
    }
    if (!isUploadSuccess) {
      setLoadingFalse()
      return
    }
    dataCreate.startDate = new Date(dataCreate.startDate).toISOString()
    dataCreate.endDate = new Date(dataCreate.endDate).toISOString()
    dataCreate.promotionCode = dataCreate.promotionCode?.toUpperCase()
    if (valuePrice === 'Giảm tiền') {
      delete dataCreate.min
      delete dataCreate.minPercent
      delete dataCreate.maxAmount
      delete dataCreate.percent
      dataCreate.minOrderAmount = data.min
      setIsPercent(false)
    } else if (valuePrice === 'Giảm phần trăm') {
      delete dataCreate.min
      delete dataCreate.minPercent
      delete dataCreate.discount
      dataCreate.minOrderAmount = data.minPercent
      setIsPercent(true)
    }
    if (valueCode === 'code') {
      delete dataCreate.minPoint
      setIsPercent(false)
    } else if (valueCode === 'point') {
      delete dataCreate.totalCode
      delete dataCreate.maxNumberOfUse
    }

    try {
      const levelIds: string[] = []
      selectedLevel.map((level: any) => {
        return levelIds.push(`${level.id}`)
      })
      const groupIds: string[] = []
      selectedGroups.map((group: any) => {
        return groupIds.push(`${group.id}`)
      })
      const rangeIds: string[] = []
      selectedRange.map((range: any) => {
        return rangeIds.push(`${range.id}`)
      })
      const serviceIds: string[] = []
      selectedServices.map((service: any) => {
        return serviceIds.push(`${service.id}`)
      })
      const userIds: string[] = []
      selectedUsers.map((user: any) => {
        return userIds.push(`${user.id}`)
      })

      await promoApi.createPromotion({
        ...dataCreate,
        description: content,
        isPercent: isPercent,
        userIds: userIds,
        serviceIds: serviceIds,
        customerRanking: levelIds,
        customerGroup: groupIds,
        provinceIds: rangeIds,
        countryId: valueNation,
      })
      setAlertSuccess('Thêm thành công!')
      setLoadingFalse()
      navigate('/marketing/promotion-code')
    } catch (error: any) {
      setLoadingFalse()
      setAlertError(error.response?.data?.message)
    }
  }

  const levelUser = [
    { name: 'Thường', id: '1' },
    { name: 'Bạc', id: '2' },
    { name: 'Vàng', id: '23' },
    { name: 'Bạch kim', id: '4' },
  ]
  const groupUser = [
    { name: 'Trung thành', id: '1' },
    { name: 'Vãng lai', id: '2' },
    { name: 'Khó tính', id: '3' },
  ]

  useEffect(() => {
    fetchServices()
  }, [])

  useEffect(() => {
    if (valuePrice === 'Giảm tiền') {
      setDataDefault({
        discount: undefined,
        endDate: '',
        maxNumberOfUse: '',
        min: undefined,
        object: 'Tất cả',
        promotionCode: '',
        reduceType: 'Giảm tiền',
        startDate: '',
        status: 'show',
        subTitle: '',
        title: '',
        totalCode: '',
        typePromo: 'code',
      })
    } else {
      setDataDefault({
        discount: undefined,
        endDate: '',
        maxAmount: undefined,
        maxNumberOfUse: '',
        min: undefined,
        minPercent: undefined,
        object: 'Tất cả',
        percent: '',
        promotionCode: '',
        reduceType: 'Giảm phần trăm',
        startDate: '',
        status: 'show',
        subTitle: '',
        title: '',
        totalCode: '',
        typePromo: 'code',
      })
    }
    setContentDefault('')
    setIconDefault('')
    setSelectedServicesDefault([])
    setSelectedServicesDefault([])
  }, [valuePrice])

  useEffect(() => {
    if (valuePrice === 'Giảm tiền') {
      setIsPercent(false)
    } else if (valuePrice === 'Giảm phần trăm') {
      setIsPercent(true)
    }
  }, [valuePrice])
  useEffect(() => {
    if (valueObject === 'Tất cả') {
      setSelectedLevel([])
      setSelectedGroups([])
      setSelectedUsers([])
    } else if (valueObject === 'Hạng khách hàng') {
      setSelectedGroups([])
      setSelectedUsers([])
    } else if (valueObject === 'Nhóm khách hàng') {
      setSelectedLevel([])
      setSelectedUsers([])
    } else if (valueObject === 'Khách hàng cụ thể') {
      setSelectedLevel([])
      setSelectedGroups([])
    }
  }, [valueObject])
  useEffect(() => {
    if (valueObject === 'Tất cả') {
      setErrorUser(undefined)
    }
    if (valueObject === 'Khách hàng cụ thể' && selectedUsers.length) {
      setErrorUser(undefined)
    } else if (valueObject === 'Khách hàng cụ thể' && !selectedUsers.length) {
      setErrorUser('Khách hàng cụ thể không được để trống')
    }
  }, [valueObject, selectedUsers])
  useEffect(() => {
    fetchDataCountries()
  }, [])
  return (
    <>
      <Grid container spacing={2}>
        <Breadcrumb paths={PROMOTION_CODE_CREATE} />
        <Grid item xs={12}>
          <Item sx={{ padding: '24px' }}>
            <StyleHeader>
              {' '}
              <StyleToogle>
                <StyleTitle>Thông tin dịch vụ</StyleTitle>
              </StyleToogle>
              <StyleFilter>
                <StyleStatus>
                  <InputField
                    id="status"
                    size="small"
                    label="Trạng thái"
                    select
                    style={{ marginTop: '-10px' }}
                    value={valueStatus}
                    {...register('status')}
                    onChange={handleChangeStatus}
                  >
                    {promotionStatus.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </InputField>
                </StyleStatus>

                <StyleLinks>
                  <SendIcon sx={{ color: 'white', marginRight: '5px' }} />
                  <StyleLink>Gửi thông báo về app</StyleLink>
                </StyleLinks>
              </StyleFilter>
            </StyleHeader>

            <form onSubmit={handleSubmit(onSubmit)}>
              <StyleItem
                style={{
                  paddingTop: '0',
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <StyleImage>
                  {image ? (
                    <StyleAddImage>
                      <img src={image} alt="img news" />
                      <StyleAddItem>
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleUpload(e)
                          }
                        />
                      </StyleAddItem>
                    </StyleAddImage>
                  ) : (
                    <StyleAddImage>
                      <AddPhotoAlternateIcon
                        sx={{
                          marginTop: '-10px',
                          width: '100% !important',
                          height: '100% !important',
                          color: '#1976d2',
                        }}
                      />
                      <StyleAddItem>
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          onChange={handleUpload}
                        />
                      </StyleAddItem>
                    </StyleAddImage>
                  )}
                  {error && <StyleErrorImage>{error}</StyleErrorImage>}
                </StyleImage>
                <StyleInfo>
                  <InputField label="Tên" {...register('title')} />
                  {errors.title && (
                    <StyleError>{errors.title.message as string}</StyleError>
                  )}
                  <InputField
                    label="Nội dung ngắn"
                    multiline
                    {...register('subTitle')}
                  />
                  {errors.subTitle && (
                    <StyleError>{errors.subTitle.message as string}</StyleError>
                  )}
                  <StyleLabel>Mô tả </StyleLabel>
                  <Editor onChange={handleEditorChange} value={content} />
                  {!!errorContent && (
                    <StyleError style={{ marginTop: '10px' }}>
                      {errorContent}
                    </StyleError>
                  )}
                </StyleInfo>
              </StyleItem>
              <Divider sx={{ margin: '0 -24px' }} />
              <StyleItem>
                <StyleInput style={{ width: '70%' }}>
                  <StyleStatus>
                    {' '}
                    <InputField
                      id="status"
                      label="Loại"
                      select
                      value={valueCode}
                      {...register('typePromo')}
                      onChange={handleChangeCode}
                    >
                      {promotionCode.map((option: any) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </StyleStatus>

                  <div style={{ width: '30%' }}>
                    <Controller
                      control={control}
                      name="startDate"
                      defaultValue=""
                      render={({
                        field: { onChange, ref, onBlur, name, ...field },
                      }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            minDate={new Date()}
                            {...field}
                            inputRef={ref}
                            inputFormat="DD/MM/YYYY"
                            label="Ngày bắt đầu"
                            renderInput={(inputProps) => (
                              <InputField
                                {...inputProps}
                                onBlur={onBlur}
                                name={name}
                                error={false}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                            onChange={(e: Date | null) => {
                              onChange(e)
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    {errors.startDate && (
                      <StyleError>
                        {errors.startDate.message as string}
                      </StyleError>
                    )}
                  </div>
                  <div style={{ width: '30%' }}>
                    <Controller
                      control={control}
                      name="endDate"
                      defaultValue=""
                      render={({
                        field: { onChange, ref, onBlur, name, ...field },
                      }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            minDate={new Date()}
                            {...field}
                            inputRef={ref}
                            inputFormat="DD/MM/YYYY"
                            label="Ngày kết thúc"
                            renderInput={(inputProps) => (
                              <InputField
                                {...inputProps}
                                onBlur={onBlur}
                                name={name}
                                error={false}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                            onChange={(e: Date | null) => {
                              onChange(e)
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    {errors.endDate && (
                      <StyleError>
                        {errors.endDate.message as string}
                      </StyleError>
                    )}
                  </div>
                </StyleInput>
              </StyleItem>

              <Divider sx={{ margin: '0 -24px' }} />
              <StyleItem>
                <StyleInput style={{ width: '70%' }}>
                  <div>
                    <StyleContent>
                      {' '}
                      <StyleContentText style={{ marginRight: '8px' }}>
                        Mã:
                      </StyleContentText>
                      <StyleContentInput style={{ maxWidth: '200px' }}>
                        <TextField
                          size="small"
                          inputProps={{
                            style: { textTransform: 'uppercase' },
                          }}
                          {...register('promotionCode')}
                        />
                      </StyleContentInput>
                    </StyleContent>
                    {errors.promotionCode && (
                      <StyleError style={{ marginTop: '10px' }}>
                        {errors.promotionCode.message as string}
                      </StyleError>
                    )}
                  </div>
                  {valueCode === 'code' ? (
                    <>
                      <div>
                        <StyleContent>
                          {' '}
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Số lượng code:
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '70px' }}>
                            <StyledInput
                              sx={{ input: { textAlign: 'center' } }}
                              type="text"
                              inputProps={{
                                onKeyPress: handleKeyPress,
                                inputMode: 'numeric',
                              }}
                              size="small"
                              {...register('totalCode')}
                            />
                          </StyleContentInput>
                        </StyleContent>
                        {errors.totalCode && (
                          <StyleError style={{ marginTop: '10px' }}>
                            {errors.totalCode.message as string}
                          </StyleError>
                        )}
                      </div>
                      <div>
                        <StyleContent>
                          <StyleContentText style={{ marginRight: '8px' }}>
                            Giới hạn số lần sử dụng
                          </StyleContentText>
                          <StyleContentInput style={{ maxWidth: '70px' }}>
                            <StyledInput
                              sx={{ input: { textAlign: 'center' } }}
                              type="text"
                              inputProps={{
                                onKeyPress: handleKeyPress,
                                inputMode: 'numeric',
                              }}
                              size="small"
                              {...register('maxNumberOfUse')}
                            />
                          </StyleContentInput>
                        </StyleContent>
                        {errors.maxNumberOfUse && (
                          <StyleError style={{ marginTop: '10px' }}>
                            {errors.maxNumberOfUse.message as string}
                          </StyleError>
                        )}
                      </div>
                    </>
                  ) : (
                    <div>
                      <StyleContent>
                        {' '}
                        <StyleContentText style={{ marginRight: '8px' }}>
                          Số point tương đương:
                        </StyleContentText>
                        <StyleContentInput style={{ maxWidth: '70px' }}>
                          <StyledInput
                            sx={{ input: { textAlign: 'center' } }}
                            type="text"
                            size="small"
                            inputProps={{
                              onKeyPress: handleKeyPress,
                              inputMode: 'numeric',
                            }}
                            {...register('minPoint')}
                          />
                        </StyleContentInput>
                        <StyleContentText
                          style={{ marginLeft: '8px', marginRight: '150px' }}
                        >
                          Point
                        </StyleContentText>
                      </StyleContent>
                      {errors.minPoint && (
                        <StyleError style={{ marginTop: '10px' }}>
                          {errors.minPoint.message as string}
                        </StyleError>
                      )}
                    </div>
                  )}
                </StyleInput>
              </StyleItem>
              <Divider sx={{ margin: '0 -24px' }} />
              <StyleItem>
                <StyleTitle>Dịch vụ</StyleTitle>
                <MultiSelect
                  items={selectServices}
                  name="Dịch vụ"
                  selectAllLabel="Tất cả"
                  onChange={setSelectedServices}
                />
                {/* <StyleError>{errorProvinces}</StyleError> */}
              </StyleItem>
              <Divider sx={{ margin: '0 -24px' }} />
              <StyleItem>
                <StyleTitle>Dạng giảm</StyleTitle>
                <StyleChoose>
                  <StyleInput style={{ marginTop: '-10px', width: '40%' }}>
                    {' '}
                    <InputField
                      id="status"
                      label="Dạng giảm"
                      select
                      size="small"
                      value={valuePrice}
                      {...register('reduceType')}
                      onChange={handleChangePrice}
                    >
                      {promotionPrice.map((option: any) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </InputField>
                  </StyleInput>
                  {valuePrice === 'Giảm tiền' ? (
                    <div>
                      <StyleContent>
                        <StyleContentText style={{ marginRight: '8px' }}>
                          Giảm
                        </StyleContentText>
                        <StyleContentInput style={{ maxWidth: '110px' }}>
                          <InputNumberFormat
                            name="discount"
                            control={control}
                            text-align="left"
                            endAdornment={
                              <InputAdornment position="end">đ</InputAdornment>
                            }
                          />
                        </StyleContentInput>
                        <StyleContentText style={{ marginLeft: '8px' }}>
                          trên tổng đơn
                        </StyleContentText>
                      </StyleContent>
                      {errors.discount && (
                        <StyleError style={{ marginTop: '10px' }}>
                          {errors.discount.message as string}
                        </StyleError>
                      )}
                      <StyleContent style={{ marginTop: '20px' }}>
                        <StyleContentText style={{ marginRight: '8px' }}>
                          Đơn tối thiểu
                        </StyleContentText>
                        <StyleContentInput style={{ maxWidth: '110px' }}>
                          <InputNumberFormat
                            name="min"
                            control={control}
                            text-align="left"
                            endAdornment={
                              <InputAdornment position="end">đ</InputAdornment>
                            }
                          />
                        </StyleContentInput>
                      </StyleContent>
                      {errors.min && (
                        <StyleError style={{ marginTop: '10px' }}>
                          {errors.min.message as string}
                        </StyleError>
                      )}
                    </div>
                  ) : (
                    <div>
                      <StyleContent style={{ margin: '-10px 0' }}>
                        <StyleContentText style={{ marginRight: '8px' }}>
                          Giảm
                        </StyleContentText>
                        <StyleContentInput style={{ maxWidth: '110px' }}>
                          <InputField
                            size="small"
                            type="text"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              step: 1,
                              onKeyPress: handleKeyPress,
                              inputMode: 'numeric',
                            }}
                            {...register('percent')}
                            sx={{ textAlign: 'right' }}
                          />
                        </StyleContentInput>
                        <StyleContentText style={{ marginLeft: '8px' }}>
                          trên tổng đơn
                        </StyleContentText>
                      </StyleContent>
                      {errors.percent && (
                        <StyleError style={{ marginTop: '10px' }}>
                          {errors.percent.message as string}
                        </StyleError>
                      )}
                      <StyleContent style={{ marginTop: '20px' }}>
                        <StyleContentText style={{ marginRight: '8px' }}>
                          Đơn tối thiểu
                        </StyleContentText>
                        <StyleContentInput style={{ maxWidth: '110px' }}>
                          <InputNumberFormat
                            name="minPercent"
                            control={control}
                            text-align="left"
                            endAdornment={
                              <InputAdornment position="end">đ</InputAdornment>
                            }
                          />
                        </StyleContentInput>
                      </StyleContent>
                      {errors.minPercent && (
                        <StyleError style={{ marginTop: '10px' }}>
                          {errors.minPercent.message as string}
                        </StyleError>
                      )}
                      <StyleContent style={{ marginTop: '20px' }}>
                        <StyleContentText style={{ marginRight: '8px' }}>
                          Giảm tối đa
                        </StyleContentText>
                        <StyleContentInput style={{ maxWidth: '110px' }}>
                          <InputNumberFormat
                            name="maxAmount"
                            control={control}
                            text-align="left"
                            endAdornment={
                              <InputAdornment position="end">đ</InputAdornment>
                            }
                          />
                        </StyleContentInput>
                      </StyleContent>
                      {errors.maxAmount && (
                        <StyleError style={{ marginTop: '10px' }}>
                          {errors.maxAmount.message as string}
                        </StyleError>
                      )}
                    </div>
                  )}
                </StyleChoose>
              </StyleItem>
              <Divider sx={{ margin: '0 -24px' }} />
              <StyleItem>
                <StyleTitle>Đối tượng áp dụng</StyleTitle>
                <InputField
                  id="status"
                  label="Đối tượng áp dụng"
                  select
                  sx={{ textAlign: 'left' }}
                  style={{ marginTop: '-10px' }}
                  value={valueObject}
                  {...register('object')}
                  onChange={handleChangeObject}
                >
                  {promotionObject.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
                {valueObject === 'Hạng khách hàng' && (
                  <StyleObject>
                    <StyleItem style={{ width: '80%' }}>
                      <StyleTitle>Hạng khách hàng</StyleTitle>
                      <MultiSelect
                        items={levelUser}
                        name="Hạng khách hàng"
                        selectAllLabel="Tất cả"
                        onChange={setSelectedLevel}
                      />
                      {/* <StyleError>{errorProvinces}</StyleError> */}
                    </StyleItem>
                  </StyleObject>
                )}
                {valueObject === 'Nhóm khách hàng' && (
                  <StyleObject>
                    <StyleItem style={{ width: '80%' }}>
                      <StyleTitle>Nhóm khách hàng</StyleTitle>
                      <MultiSelect
                        items={groupUser}
                        name="Nhóm khách hàng"
                        selectAllLabel="Tất cả"
                        onChange={setSelectedGroups}
                      />
                      {/* <StyleError>{errorProvinces}</StyleError> */}
                    </StyleItem>
                  </StyleObject>
                )}
                {valueObject === 'Khách hàng cụ thể' && (
                  <StyleObject>
                    <StyleItem style={{ width: '80%' }}>
                      <StyleTitle>Khách hàng cụ thể</StyleTitle>
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        options={selectUsers}
                        getOptionLabel={(option) =>
                          `${option?.fullName}  (${option?.phone})`
                        }
                        disableClearable
                        noOptionsText="Chưa có lựa chọn"
                        renderInput={(params) => (
                          <TextField {...params} label="Khách hàng cụ thể" />
                        )}
                        onInputChange={handleInputChange}
                        onChange={(event, value: any) => {
                          setSelectedUsers([...value])
                        }}
                      />
                      {!!errorUser && (
                        <StyleError style={{ marginTop: '10px' }}>
                          {errorUser}
                        </StyleError>
                      )}
                    </StyleItem>
                  </StyleObject>
                )}
              </StyleItem>
              <Divider sx={{ margin: '0 -24px' }} />
              <StyleItem>
                <StyleTitle>Phạm vi áp dụng</StyleTitle>
                <StyleTitle>Quốc gia</StyleTitle>
                <StyleInput>
                  {' '}
                  <InputField
                    id="status"
                    label="Quốc gia"
                    select
                    value={valueNation}
                    sx={{ textAlign: 'left' }}
                    onChange={handleChangeNation}
                  >
                    {dataNation.map((option: any) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </InputField>
                </StyleInput>
                {!!errorNation && (
                  <StyleError style={{ marginTop: '10px' }}>
                    {errorNation}
                  </StyleError>
                )}
                <StyleTitle style={{ marginTop: '14px' }}>
                  Tỉnh/ Thành phố
                </StyleTitle>
                <MultiSelect
                  items={selectRange}
                  name="Tỉnh/ Thành phố"
                  selectAllLabel="Tất cả"
                  onChange={setSelectedRange}
                />
                {/* <StyleError>{errorProvinces}</StyleError> */}
              </StyleItem>
              <Stack spacing={2} direction="row" sx={{ paddingTop: '10px' }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={handleCheckFile}
                >
                  Lưu
                </Button>
                <Button variant="outlined" onClick={handleCheckDataCancel}>
                  Hủy
                </Button>
              </Stack>
            </form>
          </Item>
        </Grid>
        <BasicModal open={modalCancel} handleClose={handleClose}>
          <Typography>Bạn có chắc chắn muốn hủy thay đổi?</Typography>
          <Button
            sx={{
              margin: '20px',
              marginBottom: '0px',
              paddingLeft: '43px',
              paddingRight: '43px',
            }}
            variant="contained"
            onClick={() => navigate('/marketing/promotion-code')}
            className="bgr-global"
          >
            Hủy
          </Button>
          <Button
            sx={{
              margin: '20px',
              marginBottom: '-0.5px',
              paddingLeft: '30px',
              paddingRight: '30px',
            }}
            onClick={() => setModaCancel(false)}
            variant="outlined"
          >
            Không
          </Button>
        </BasicModal>
      </Grid>
    </>
  )
}

export default PromotionCreate
