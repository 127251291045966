import styled from 'styled-components'
import LogoutIcon from '@mui/icons-material/Logout'

export const IconLogout = styled(LogoutIcon)`
  width: 15px;
  height: 15px;
  margin-right: 0;
  color: red;
`

export const ListDetails = styled.div`
  display: block;
  position: absolute;
  width: 150px;
  top: 59px;
  right: 15%;
  font-weight: normal;
  background-color: #fff;
  color: #212529;
  border-radius: 4px;
  z-index: 99;
  border: 1px solid #e7e7e7;
  &::before {
    content: ' ';
    position: absolute;
    top: -16px;
    right: 8px;
    border: solid transparent;
    border-width: 8px;
    border-bottom-color: #fff;
  }
`

export const Profile = styled.div`
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background-color: #e7e7e7;
  }
`

export const Logout = styled.div`
  position: relative;
`

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: red;
  border-top: 1px solid #efefef;
  &:hover {
    background-color: #e7e7e7;
  }
`

export const HeaderProfile = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  /* padding: 10px; */
  margin-top: 12px;
  width: 100%;
  font-weight: 500;
`

export const ImageLogo = styled.img`
  width: 170px;
  height: 98px;
  object-fit: cover;
`

export const StyleLogo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const StyleTitleLogo = styled.h6`
  font-weight: bold;
`
