import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import styled from 'styled-components'
import TableCell from '@mui/material/TableCell'

export const StyleAvatar = styled.div`
  display: flex;
  align-items: center;
`
export const Campaigntitle = styled.div`
  display: flex;
  align-items: center;
`


export const StyleTotal = styled(Typography)`
  && {
    color: rgb(185, 185, 185);
    font-weight: 400;
    font-size: calc(0.75rem);
    text-transform: none;
    margin-left: 3px;
  }
`
export const StyleAction = styled.div`
  display: flex;
`
export const ActionButton = styled(Button)`
  font-weight: bold !important;

  && {
    color: #fff;
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
    rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }
`
export const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
`
export const StyleButton = styled(Button)`
  width: 30%;
`
export const StyleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 6px;
`
export const StyleTableHead = styled(TableCell)`
  && {
    font-weight: bold;
    color: var(--color-secondary);
  }
`
export const StyleFilter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;
  gap: 24px;
  flex-wrap: wrap;
  flex-flow: row wrap;
`

export const StyleFilterItem = styled.div`
  height: 100% !important;
`

export const StyleLinks = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin: 9px 0;
  transition: 0.1s ease-in-out;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);

  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`

export const StyleAddItem = styled.div`
  display: flex;
`

export const StyleLink = styled.span`
  margin-top: 2px;
  font-weight: bold;
`
