import React, { forwardRef } from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { ActionButton } from 'components/Icons/style'
/* eslint-disable react/display-name */
const Delete = forwardRef((props: any, ref: any) => {
  return (
    <div {...props}>
      <ActionButton>
        <DeleteOutlineOutlinedIcon
          sx={{ color: '#ff0000' }}
          fullWidth
          {...props}
          ref={ref}
        />
      </ActionButton>
    </div>
  )
})
export default Delete
