import {
  FormLogin,
  StyleLogin,
  TitleLogin,
  Heading,
  StyleError,
  SendButton,
  Description,
} from 'pages/auth/style'
import { TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { ResendButton, ResendDisable } from './style'
import countDownFunction from './functions'
import { OtpSchema_ } from 'validations/OtpSchema'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import useToken from 'hooks/useToken'
import authAPI from 'services/authAPI'

const FormOTP = () => {
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const {
    countDown,
    remainingMinutes,
    remainingSeconds,
    countdownTime,
    setCountdownTime,
  } = countDownFunction()

  const { setAlertClose, setAlertError, setAlertSuccess } = useAlert()

  const { email } = useParams()
  const navigate = useNavigate()
  const { token } = useToken()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPassword>({
    resolver: yupResolver(OtpSchema_),
  })
  const onSubmit = async (values: IForgotPassword) => {
    setLoadingTrue()
    setAlertClose()
    if (email && values.otp) {
      try {
        await authAPI.checkOTP({
          code: +values.otp,
          email: email,
        })
        setAlertSuccess('Gửi OTP thành công')
        localStorage.setItem('otp', values.otp)
        setLoadingFalse()
        setTimeout(() => {
          navigate(`/auth/change/${email}`)
        }, 300)
        // eslint-disable-next-line
      } catch (error: any) {
        setAlertError(error.response.data.message)
        setLoadingFalse()
      }
    }
  }

  const handleResend = async () => {
    setLoadingTrue()
    setAlertClose()
    if (email) {
      try {
        const data = await authAPI.sendOTP({ email: email })
        const expiredTime = new Date(data.data.resendTime).getTime()
        localStorage.setItem('countdownTime', expiredTime.toString())
        setAlertSuccess('Gửi lại OTP thành công')
        setLoadingFalse()
        // eslint-disable-next-line
      } catch (error: any) {
        setAlertError(error.response.data.message)
        setLoadingFalse()
      }
    }
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      countDown()
    }, 1000)
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [])
  useEffect(() => {
    const timeRemaining = Number(localStorage.getItem('countdownTime'))
    if (!timeRemaining) {
      setCountdownTime(0)
    }
  }, [])

  return (
    <FormLogin onSubmit={handleSubmit(onSubmit)}>
      <StyleLogin>
        <TitleLogin>Nhập OTP</TitleLogin>
        <Description>Bạn hãy nhập mã được gửi đến email: </Description>
        <Typography sx={{ fontWeight: 'bold' }}>{email}</Typography>
        <Heading>
          <TextField
            sx={{ width: '100%', marginTop: '20px' }}
            id="standard-textarea"
            placeholder="Mã OTP"
            variant="outlined"
            {...register('otp')}
            autoFocus
          />
          {errors.otp && (
            <StyleError>{errors.otp?.message as string}</StyleError>
          )}
        </Heading>

        <SendButton type="submit">Gửi</SendButton>
        <Description style={{ marginTop: ' 13px' }}>
          Không nhận được mã?
        </Description>
        {remainingSeconds ? (
          <>
            {countdownTime ? (
              <ResendDisable>
                Gửi lại mã({remainingMinutes}:
                {remainingSeconds.toString().padStart(2, '0')})
              </ResendDisable>
            ) : (
              <ResendButton
                onClick={() => {
                  handleResend()
                }}
              >
                Gửi lại mã
              </ResendButton>
            )}
          </>
        ) : (
          <>
            {countdownTime ? (
              <ResendDisable>Gửi lại mã(--:--)</ResendDisable>
            ) : (
              <ResendButton
                onClick={() => {
                  handleResend()
                }}
              >
                Gửi lại mã
              </ResendButton>
            )}
          </>
        )}
      </StyleLogin>
    </FormLogin>
  )
}

export default FormOTP
