import axiosClient from '../utils/axios'

const promotionCodeApi = {
  getPromotionCode: (params: any) => {
    const url = `/promotionCode?page=${params.page}&limit=${params.limit}&sortType='ASC'&typePromo=${params.typePromo}&datePromotion[startDate]=${params.datePromotionStart}&datePromotion[endDate]=${params.datePromotionEnd}`
    return axiosClient.get(url, params)
  },

  delete: (id: string) => {
    const url = `/promotionCode/delete/${id}`
    return axiosClient.delete(url)
  },
}

export default promotionCodeApi
