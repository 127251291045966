import * as yup from 'yup'
import { VALIDATE_STRING_PASSWORD } from 'utils/regex'


export const resetPasswordSchema_ = yup.object().shape({
  password: yup
    .string()
    .required('Mật khẩu không được để trống')
    .min(
      8,
      'Mật khẩu tối thiểu 8 ký tự, ít nhất có 1 ký tự viết hoa, 1 ký tự số và một ký tự đặc biệt'
    )
   
    .matches(
      VALIDATE_STRING_PASSWORD,
      'Mật khẩu tối thiểu 8 ký tự, ít nhất có 1 ký tự viết hoa, 1 ký tự số và một ký tự đặc biệt'
    ),
  confirmPassword: yup
    .string()
    .required('Xác nhận mật khẩu không được để trống')
    .oneOf([yup.ref('password'), null], 'Mật khẩu không khớp'),
})
