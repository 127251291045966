import React from 'react'
import { Link, Grid, Breadcrumbs } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Item from 'components/Item'
import { useNavigate } from 'react-router-dom'

interface IBreadcrumbProps {
  paths: { name: string; path?: string }[]
}
const Breadcrumb: React.FC<IBreadcrumbProps> = ({ paths }) => {
  const navigate = useNavigate()
  return (
    <Grid item xs={12} className="padding-bottom">
      <Item sx={{ padding: '12px 24px' }} className="bd-radius">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            sx={{ textTransform: 'capitalize', cursor: 'pointer' }}
            underline="hover"
            color="inherit"
            onClick={() => {
              navigate('/')
            }}
          >
            Trang chủ
          </Link>
          {paths.map((path, index) => (
            <Link
              key={index}
              sx={{
                textTransform: 'capitalize',
                fontWeight: index === paths.length - 1 ? 'bold' : 'normal',
                cursor: path.path ? 'pointer' : 'default',
                '&:hover': path.path ? { textDecoration: 'underline' } : {},
              }}
              underline="none"
              color="inherit"
              onClick={() => {
                if (path.path) {
                  navigate(path.path)
                }
              }}
            >
              {path.name}
            </Link>
          ))}
        </Breadcrumbs>
      </Item>
    </Grid>
  )
}

export default Breadcrumb
