import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { editProfileSchema_ } from 'validations/editProfileSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import ProfileApi from 'services/profileAPI'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {
  StyleAvatar,
  StyleContainer,
  StyleError,
  StyleItemLeft,
  StyleItemRight,
  StyleListLeft,
  StyleListRight,
  StyleTitleUser,
} from './style'
import { Avatar, Typography } from '@mui/material'
import InputField from 'components/FormControl/InputField'
import BasicModal from 'components/Modal'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import { useLoading } from 'contexts/LoadingContext'
import { useForm } from 'react-hook-form'
import Stack from '@mui/material/Stack'
import { useAlert } from 'contexts/AlertContext'
import dayjs from 'dayjs'
import { FileTypeEnum, getFileSize } from 'utils/FileUtils'
import { IMAGE_TYPE } from 'constants/common'
import { uploadImage } from 'utils/uploadImage'
import { Profiler } from 'inspector'

const EditProfile = () => {
  const navigate = useNavigate()
  const [isDisabled, setIsDisabled] = useState(true)
  const [modalSave, setModaSave] = useState<boolean>(false)
  const [modalReset, setModaReset] = useState<boolean>(false)
  const [data, setData] = useState<IProfile | null | undefined>()
  const [profile, setProfile] = useState<IProfile | null | undefined>()
  const [error, setError] = useState('')
  // const [imageURL, setImageURL] = useState('')
  const [image, setImage] = useState('')
  const { setLoadingTrue, setLoadingFalse } = useLoading()
  const { setAlertError, setAlertSuccess } = useAlert()

  const fetchData = async () => {
    const { data } = await ProfileApi.getUser()

    setProfile(data)
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editProfileSchema_),
    mode: 'onChange',
  })

  const handleClose = () => {
    setModaSave(false)
    setModaReset(false)
  }

  const handleChange = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    const MAX_FILE_SIZE = 15 // 15MB
    const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
    if (file) {
      if (fileSize > MAX_FILE_SIZE) {
        setError(`Dung lượng hình ảnh không quá ${MAX_FILE_SIZE} MB`)
        return
      }
      if (!file?.type.match(IMAGE_TYPE)) {
        setError('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
        return
      }
      const response: IUploadImg = await uploadImage(file)
      // setImageURL(response.medium)
      setImage(URL?.createObjectURL((target.files as FileList)[0]))
      setError('')
      const data: any = profile
      data.avatar = response.medium
      try {
        await ProfileApi.update(data as IProfile)
        setAlertSuccess('Sửa  thành công')
        setLoadingFalse()
        navigate('/profile')
      } catch (error: any) {
        setLoadingFalse()
        setAlertError('Sửa ảnh thất bại')
      }
    }
  }

  const onSubmit = async () => {
    setLoadingTrue()
    setModaSave(false)
    setLoadingFalse()
    try {
      await ProfileApi.update(data as IProfile)
      setAlertSuccess('Sửa thông tin thành công')
      setLoadingFalse()
      navigate('/profile')
    } catch (error: any) {
      setLoadingFalse()
      setAlertError('Sửa thông tin thất bại')
    }
  }

  const Reset = () => {
    setLoadingTrue()
    reset()
    setModaReset(false)
    setLoadingFalse()
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (!profile) return <></>
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit((data: IProfile) => {
        setData(data)
        setModaSave(true)
      })}
    >
      <StyleContainer>
        <StyleListLeft>
          <StyleAvatar>
            {image ? (
              <Avatar src={image} sx={{ width: 150, height: 150 }} />
            ) : (
              <Avatar src={profile?.avatar} sx={{ width: 150, height: 150 }} />
            )}
            <Button
              component="label"
              sx={{
                position: 'absolute',
                bottom: '-14px',
                right: '16px',
                backgroundColor: '#DCDCDC !important',
                color: '#696969',
                borderRadius: '50%',
                minWidth: '0px !important',
              }}
            >
              <CameraAltOutlinedIcon sx={{ fontSize: '2rem' }} />
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                {...register('avatar')}
                onChange={handleChange}
              />
            </Button>
          </StyleAvatar>
          <Typography sx={{ color: 'red', fontSize: '14px' }}>
            {error}
          </Typography>

          <StyleTitleUser variant="h6">{profile?.fullName}</StyleTitleUser>
        </StyleListLeft>
        <StyleListRight>
          <StyleItemLeft>
            <div>
              <InputField
                label="Họ và tên"
                defaultValue={profile?.fullName}
                {...register('fullName')}
                InputProps={{
                  readOnly: isDisabled,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <StyleError>
                {!isDisabled && (errors.fullName?.message as string)}
              </StyleError>

              <InputField
                label="Số điện thoại"
                defaultValue={profile?.phone}
                {...register('phone')}
                InputProps={{
                  readOnly: isDisabled,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <StyleError>
                {!isDisabled && (errors.phone?.message as string)}
              </StyleError>

              <InputField
                label="Email"
                defaultValue={profile?.email}
                {...register('email')}
                InputProps={{
                  readOnly: isDisabled,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <StyleError>
                {!isDisabled && (errors.email?.message as string)}
              </StyleError>

              <InputField
                label="Vị trí công việc"
                defaultValue={profile?.jobPosition}
                {...register('jobPosition')}
                InputProps={{
                  readOnly: isDisabled,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <StyleError>
                {!isDisabled && (errors.jobPosition?.message as string)}
              </StyleError>
              {!isDisabled && (
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    paddingTop: '14px',
                    justifyContent: 'left',
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    className="bgr-global"
                    sx={{ marginRight: '8px' }}
                  >
                    Lưu thay đổi
                  </Button>
                  <Button variant="outlined" onClick={() => setModaReset(true)}>
                    Đặt lại
                  </Button>
                </Stack>
              )}
            </div>
          </StyleItemLeft>
          <StyleItemRight>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '24px',
              }}
            >
              <div style={{ width: '100%' }}>
                <InputField
                  label="CMND/CCCD"
                  defaultValue={profile?.citizenId}
                  {...register('citizenId')}
                  InputProps={{
                    readOnly: isDisabled,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <StyleError>
                  {!isDisabled && (errors.citizenId?.message as string)}
                </StyleError>
              </div>
              <div style={{ width: '100%' }}>
                {isDisabled ? (
                  <InputField
                    label="Ngày cấp"
                    defaultValue={dayjs(profile?.registrationDate).format(
                      'DD/MM/YYYY'
                    )}
                    {...register('registrationDate')}
                    InputProps={{
                      readOnly: isDisabled,
                    }}
                  />
                ) : (
                  <InputField
                    type="date"
                    label="Ngày cấp"
                    defaultValue={dayjs(profile?.registrationDate).format(
                      'YYYY-MM-DD'
                    )}
                    {...register('registrationDate')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                <StyleError>
                  {!isDisabled && (errors.registrationDate?.message as string)}
                </StyleError>
              </div>
            </div>

            <InputField
              label="Nơi cấp"
              defaultValue={profile?.issuedBy}
              {...register('issuedBy')}
              InputProps={{
                readOnly: isDisabled,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <StyleError>
              {!isDisabled && (errors.issuedBy?.message as string)}
            </StyleError>

            <InputField
              label="Địa chỉ thường trú"
              defaultValue={profile?.permanentAddress}
              {...register('permanentAddress')}
              InputProps={{
                readOnly: isDisabled,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <StyleError>
              {!isDisabled && (errors.permanentAddress?.message as string)}
            </StyleError>

            <InputField
              label="Địa chỉ tạm trú"
              defaultValue={profile?.temporaryResidenceAddress}
              {...register('temporaryResidenceAddress')}
              InputProps={{
                readOnly: isDisabled,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <StyleError>
              {!isDisabled &&
                (errors.temporaryResidenceAddress?.message as string)}
            </StyleError>
          </StyleItemRight>

          {modalReset && (
            <BasicModal open={modalReset} handleClose={handleClose}>
              <Typography>Bạn có chắc chắn muốn đặt lại?</Typography>
              <Button
                sx={{
                  margin: '20px',
                  marginBottom: '0px',
                  paddingLeft: '31px',
                  paddingRight: '31px',
                }}
                variant="contained"
                onClick={() => Reset()}
                className="bgr-global"
              >
                Đặt lại
              </Button>
              <Button
                sx={{
                  margin: '20px',
                  marginBottom: '-0.5px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
                onClick={() => setModaReset(false)}
                variant="outlined"
              >
                Không
              </Button>
            </BasicModal>
          )}

          {modalSave && (
            <BasicModal open={modalSave} handleClose={handleClose}>
              <Typography>Bạn có chắc chắn muốn lưu thay đổi?</Typography>
              <Button
                sx={{
                  margin: '20px',
                  marginBottom: '0px',
                  paddingLeft: '43px',
                  paddingRight: '43px',
                }}
                variant="contained"
                onClick={() => onSubmit()}
                className="bgr-global"
              >
                Lưu
              </Button>
              <Button
                sx={{
                  margin: '20px',
                  marginBottom: '-0.5px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
                onClick={() => setModaSave(false)}
                variant="outlined"
              >
                Không
              </Button>
            </BasicModal>
          )}
        </StyleListRight>
      </StyleContainer>
    </Box>
  )
}

export default EditProfile
