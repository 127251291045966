import * as React from 'react'
import { styled, Paper } from '@mui/material'

interface IItemProps {
  children: React.ReactNode
  // eslint-disable-next-line
  [x: string]: any
}
const Style = styled(Paper)(() => ({
  backgroundColor: '#fff',
  textAlign: 'center',
  height: '100%',
  boxShadow:
    '0px 12px 33px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
}))

const Item = ({ children, ...others }: IItemProps) => (
  <Style {...others}>{children}</Style>
)
Item.displayName = 'Item'
export default React.memo(Item)
