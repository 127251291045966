import { Button, Stack, Switch, Typography } from '@mui/material'
import { API_URL, IMAGE_TYPE, MAX_FILE_SIZE } from 'constants/common'
import { EDITOR_TOOL_BAR } from 'constants/editorTool'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import Cookies from 'js-cookie'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { extraContentAPI, servicesAPI } from 'services/servicesAPI'
import SunEditor from 'suneditor-react'
import { FileTypeEnum, getFileSize } from 'utils/FileUtils'
import { uploadImage } from 'utils/uploadImage'
import { StyleItem } from './style'

interface FormData {
  id?: any
  title: string
  description: string
  icon: string
}

const TermsOfService = () => {
  const paramId = useParams()
  const navigate = useNavigate()
  const { setAlertError, setAlertSuccess } = useAlert()
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const [formsData, setFormsData] = useState<FormData[]>([])
  const [dataService, setDataService] = useState<any>([])
  const [errorTitle, setErrorTitle] = useState(false)
  const [errorDescription, setErrorDescription] = useState(false)
  const [errorIcon, setErrorIcon] = useState(false)

  const fetchDataExtraContent = async () => {
    const { data } = await extraContentAPI.getExtraContent(
      paramId?.serviceId as string
    )

    setFormsData(data?.rows)
  }

  const handleAddForm = () => {
    setFormsData((prevFormsData) => [
      ...prevFormsData,
      { title: '', description: '', icon: '' },
    ])
  }

  const fetchServiceDetail = async () => {
    const { data } = await servicesAPI.getServiceById(
      paramId?.serviceId as string
    )
    setDataService(data)
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target
    setFormsData((prevFormsData) =>
      prevFormsData.map((formData, i) =>
        i === index ? { ...formData, [name]: value } : formData
      )
    )
  }

  const handleUpload = async (e: ChangeEvent, index: number) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
    if (file) {
      if (fileSize > MAX_FILE_SIZE) {
        setAlertError(`Dung lượng hình ảnh không quá ${MAX_FILE_SIZE} MB`)
        return
      }
      if (!file?.type.match(IMAGE_TYPE)) {
        setAlertError('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
        return
      }
      if (fileSize === 0) {
        setAlertError(`Ảnh lỗi`)
        return
      }
      const response: IUploadImg = await uploadImage(file)
      setFormsData((prevFormValues) => {
        const updatedForms = [...prevFormValues]
        updatedForms[index] = {
          ...updatedForms[index],
          icon: response.origin as any,
        }

        return updatedForms
      })
    }
  }

  const handleEditorChange = (value: string, index: number) => {
    setFormsData((prevFormValues) => {
      const updatedForms = [...prevFormValues]
      updatedForms[index] = {
        ...updatedForms[index],
        description: value as any,
      }

      return updatedForms
    })
  }

  const handleRemoveForm = async (id: string, indexForm: number) => {
    if (id) {
      try {
        await extraContentAPI?.deleteExtraContent(
          paramId?.serviceId as string,
          id
        )
      } catch (error: any) {
        setAlertError(error?.response?.data?.message)
      }
    }

    setFormsData((prevFormsData) =>
      prevFormsData.filter((formData, i) => i !== indexForm)
    )
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoadingTrue()
    let hasError = false
    formsData?.map(async (item: any) => {
      if (!item?.title || item?.title == '') {
        setErrorTitle(true)
        hasError = true
        setLoadingFalse()
      }
      if (item?.description == '<p><br></p>' || item?.description == '') {
        setErrorDescription(true)
        hasError = true
        setLoadingFalse()
      }
      if (!item?.icon || item?.icon == '') {
        setErrorIcon(true)
        hasError = true
        setLoadingFalse()
      }
    })

    if (!hasError) {
      await Promise.all(
        formsData?.map(async (object: any, index: number) => {
          if (object?.id) {
            try {
              await extraContentAPI?.updateExtraContent(
                {
                  description: object?.description,
                  icon: object?.icon,
                  title: object?.title,
                },
                paramId?.serviceId as string,
                object?.id as string
              )
            } catch (error: any) {
              setAlertError(error?.response?.data?.message)
            }
          } else if (!object?.id) {
            try {
              await extraContentAPI?.createExtraContent(
                {
                  description: object?.description,
                  icon: object?.icon,
                  title: object?.title,
                },
                paramId?.serviceId as string
              )
            } catch (error: any) {
              setAlertError(error?.response?.data?.message)
            }
          }
        })
      )
      navigate(`/service/${paramId?.serviceId}`)
      setLoadingFalse()
      setAlertSuccess('Sửa điều khoản và lưu ý thành công!')
    }
  }

  const handleImageUploadBefore = async (
    files: File[],
    info: any,
    uploadHandler: any
  ) => {
    try {
      const uploaded = await uploadImage(files[0])
      const result = {
        result: [
          {
            url: uploaded.medium,
            size: files[0].size,
            name: files[0].name,
          },
        ],
      }
      uploadHandler(result)
    } catch (error: any) {
      setAlertError(error.response.data.message)
    }
  }

  function handleImageUploadError() {
    return
  }

  const handleOnOffChange = async (premium: boolean, id: string) => {
    // await rangeOfActiveApi.UpdateStatus(
    //   {
    //     isPremium: !premium,
    //   },
    //   id
    // )
    // fetchData()
  }

  useEffect(() => {
    fetchServiceDetail()
  }, [paramId?.serviceId])

  useEffect(() => {
    fetchDataExtraContent()
  }, [])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ display: 'flex' }} variant="body2" gutterBottom>
          *Phần này xuất hiện ở cuối màn hình xác nhận và thanh toán
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2">Ẩn</Typography>
          <Switch
            // checked={item.premium}
            // onChange={() => {
            //   handleOnOffChange(item.premium, item.areaId)
            // }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography variant="body2">Hiện</Typography>
        </Stack>
      </div>
      <form onSubmit={handleSubmit}>
        <StyleItem>
          <SunEditor
            // onChange={(value) => handleEditorChange(value, indexForm)}
            // setContents={formsData.description}
            height="auto"
            onImageUploadBefore={handleImageUploadBefore}
            setOptions={{
              showPathLabel: false,
              buttonList: EDITOR_TOOL_BAR,
              defaultStyle: 'text-align: left',
              imageUploadUrl: `${API_URL}/media/uploadImage`,
              imageUploadHeader: {
                Authorization: `Bearer ${Cookies.get('token')}`,
              },
            }}
            onImageUploadError={handleImageUploadError}
          />
        </StyleItem>

        <Stack
          spacing={2}
          direction="row"
          sx={{
            justifyContent: 'flex-start',
            paddingTop: '14px',
          }}
        >
          <Button variant="contained" type="submit" className="bgr-global">
            Lưu
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate(`/service/${paramId?.serviceId}`)}
          >
            Huỷ
          </Button>
        </Stack>
      </form>
    </>
  )
}

export default TermsOfService
