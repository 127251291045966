import { margin } from '@mui/system'
import styled from 'styled-components'
import { Typography } from '@mui/material'

export const StyleError = styled.p`
  display: flex;
  color: red;
  font-size: 14px;
  margin: auto !important;
  white-space: normal;
`

export const StyleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 24px;
`

export const StyleListRight = styled.div`
  width: 82%;
  display: flex;
  gap: 24px;
`
export const StyleListLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18%;
  max-height: 284px;
  background-color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 30px;
  margin: 10px 0px;
`

export const StyleTitle = styled.h2`
  font-size: 25px;
  color: #333333;
  font-weight: bold;
  margin: 12px 0 20px 0;
`

export const StyleAvatar = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  border: 3px dotted #536dfe;
  border-radius: 50%;
  padding: 4px;
`

export const StyleItemRight = styled.div`
  width: 100%;
  gap: 20px;
`
export const StyleItemLeft = styled.div`
  width: 100%;
`

export const StyleTitleUser = styled(Typography)`
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  line-height: 1.5rem;
  letter-spacing: 0.0125em !important;
  color: var(--color-primary);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`
