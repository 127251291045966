import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import Breadcrumb from 'components/Breadcrumbs'
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  StyleTableHeader,
  StyleTitle,
  StyleHeaderItem,
  StyleLink,
  StyleLinks,
  StyleTableCell,
  ActionButton,
  StyleAddImage,
  StyleTableCellItem,
  StylePaper,
  StyleTableCellLink,
} from './style'
import { useNavigate } from 'react-router'
import { Typography } from '@material-ui/core'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { newsAPI } from 'services/newsAPI'
import BasicModal from 'components/Modal'
import { useAlert } from 'contexts/AlertContext'
import { NEWS_LIST } from 'constants/breadcrumbs'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/common'
import Edit from 'components/Icons/components/Edit'
import Delete from 'components/Icons/components/Delete'

export default function News() {
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const [status, setStatus] = useState({
    id: 1,
  })
  const [value, setValue] = useState('all')
  const navigate = useNavigate()
  const { setAlertSuccess, setAlertError, setAlertClose } = useAlert()

  const fetchData = async (page: number) => {
    if (value === 'all') {
      const { data } = await newsAPI.getNews({
        page: page + 1,
        limit: rowsPerPage,
      })
      setData(data.rows)
      setCount(data.count)
      setPage(page)
    }
    if (value === 'priority') {
      const { data } = await newsAPI.getSortNews({
        page: page + 1,
        limit: rowsPerPage,
      })
      setData(data.rows)
      setCount(data.count)
      setPage(page)
    }
  }

  const handleChange = (event: any) => {
    setValue(event.target.value)
  }

  const handleDelete = async (id: number) => {
    setAlertClose()
    try {
      await newsAPI.deleteNews(id)
      setModalOpen(false)
      setAlertSuccess('Xóa blog thành công')
      fetchData(page)
    } catch (err) {
      setAlertError('Xoá blog không thành công')
    }
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const handleModalDelete = (id: number) => {
    setModalOpen(true)
    setStatus({
      id: id,
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    fetchData(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    fetchData(page)
  }, [rowsPerPage, value, page])

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={NEWS_LIST} />
      <Grid item xs={12}>
        <StylePaper>
          <StyleTableHeader>
            <StyleTitle>Blog</StyleTitle>
            <StyleHeaderItem>
              <StyleLinks onClick={() => navigate('/marketing/news/add')}>
                <AddCircleIcon sx={{ marginRight: '5px' }} />
                <StyleLink>Thêm Blog</StyleLink>
              </StyleLinks>
              <FormControl
                sx={{ width: 160 }}
                size="small"
                className="input-select"
              >
                <InputLabel id="demo-simple-select-label-status">
                  Sắp xếp Blog
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-status"
                  id="demo-simple-select-status"
                  value={value}
                  label="Lọc bài viết"
                  onChange={handleChange}
                >
                  <MenuItem value="all">Theo thời gian</MenuItem>
                  <MenuItem value="priority">Theo độ ưu tiên</MenuItem>
                </Select>
              </FormControl>
            </StyleHeaderItem>
          </StyleTableHeader>
          <TableContainer component={Paper} className="box-shadow">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyleTableCell align="left" width={50}>
                    STT
                  </StyleTableCell>

                  <StyleTableCell align="center" width={150}>
                    Ảnh bìa
                  </StyleTableCell>
                  <StyleTableCell align="left">Tiêu đề</StyleTableCell>
                  <StyleTableCell align="left">Nội dung ngắn</StyleTableCell>
                  <StyleTableCell align="left">Link</StyleTableCell>
                  <StyleTableCell align="center" width={150}>
                    Độ ưu tiên
                  </StyleTableCell>
                  <StyleTableCell align="right" sx={{ paddingRight: '60px' }}>
                    Chức năng
                  </StyleTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row: INews, index: number) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {data && index + 1 + page * rowsPerPage}
                    </TableCell>
                    <TableCell align="center">
                      {row?.thumbnail ? (
                        <StyleAddImage src={row.thumbnail} />
                      ) : (
                        <AddPhotoAlternateIcon
                          sx={{
                            width: '75px !important',
                            height: '50px !important',
                            color: '#1976d2',
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() =>
                        navigate(`/marketing/news/detail/${row.id}`)
                      }
                      sx={{
                        '&:hover': { opacity: 0.6 },
                        cursor: 'pointer',
                      }}
                    >
                      <StyleTableCellItem>{row.title}</StyleTableCellItem>
                    </TableCell>
                    <TableCell>
                      <StyleTableCellItem>{row.description}</StyleTableCellItem>
                    </TableCell>
                    <TableCell align="left" sx={{ maxWidth: '180px' }}>
                      <StyleTableCellLink
                        href={row.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {row.link}
                      </StyleTableCellLink>
                    </TableCell>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      className="fw-bold"
                    >
                      {row.priority == 0 ? '-' : row.priority}
                    </TableCell>
                    <TableCell align="right" width={180}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: '10px',
                        }}
                      >
                        <Edit
                          onClick={() =>
                            navigate(`/marketing/news/edit/${row.id}`)
                          }
                        />

                        <Delete
                          onClick={() => {
                            handleModalDelete(row?.id)
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {modalOpen && (
                  <BasicModal open={modalOpen} handleClose={handleClose}>
                    <Typography>
                      Bạn có chắc chắn muốn xoá blog này chứ?
                    </Typography>
                    <Button
                      sx={{ margin: '8px', marginBottom: '0px' }}
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(status.id)}
                    >
                      Xóa
                    </Button>
                    <Button
                      sx={{ margin: '8px', marginBottom: '0px' }}
                      onClick={handleClose}
                      variant="outlined"
                    >
                      Hủy
                    </Button>
                  </BasicModal>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Số dòng trên một trang"
            />
          </TableContainer>
        </StylePaper>
      </Grid>
    </Grid>
  )
}
