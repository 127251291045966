import { VALIDATE_STRING_PHONE } from 'utils/regex'
import * as yup from 'yup'

export const orderSchema_ = yup.object().shape({
  phone: yup
    .string()
    .matches(/^\d{10}$/, 'Số điện thoại phải có đúng 10 chữ số')
    .required('Số điện thoại không được để trống')
    .max(10, 'Số điện thoại không quá 10 số'),
    // .matches(VALIDATE_STRING_PHONE, 'Số điện thoại không hợp lệ'),
  name: yup
    .string()
    .trim()
    .required('Tên liên lạc không được để trống')
    .min(3, 'Tên liên lạc tối thiếu 3 ký tự, tối đa là 50 ký tự')
    .max(50, 'Tên liên lạc tối thiếu 3 ký tự, tối đa là 50 ký tự'),
  fullLocation: yup
    .string()
    .trim()
    .required('Địa chỉ làm việc không được để trống')
    .min(3, 'Địa chỉ làm việc tối thiếu 3 ký tự, tối đa là 500 ký tự')
    .max(500, 'Địa chỉ làm việc tối thiếu 3 ký tự, tối đa là 500 ký tự'),
    addLocation: yup
    .string()
    .trim(),
  serviceId: yup.string().required('Tên dịch vụ không được để trống'),
  phoneContact: yup
    .string()
    .required('Số điện thoại không được để trống')
    .max(10, 'Số điện thoại không quá 10 số')
    .matches(VALIDATE_STRING_PHONE, 'Số điện thoại không hợp lệ'),
  nameContact: yup
    .string()
    .trim()
    .required('Tên liên lạc không được để trống')
    .min(3, 'Tên liên lạc tối thiếu 3 ký tự, tối đa là 50 ký tự')
    .max(50, 'Tên liên lạc tối thiếu 3 ký tự, tối đa là 50 ký tự'),
  serviceItems: yup.string().required('Thời lượng không được để trống').typeError('Thời lượng không được để trống'),
  date: yup
  .string()
  .required('Thứ ngày không được để trống')
  .test(
    'date',
    'Ngày bắt đầu phải lớn hơn hoặc bằng ngày hiện tại và ít hơn 7 ngày',
    function (value: string | undefined) {
      const currentDate = new Date()
      const valueDate = new Date(value as any)
      const utcDate1 = Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      )
      const utcDate2 = Date.UTC(
        valueDate.getFullYear(),
        valueDate.getMonth(),
        valueDate.getDate()
      )
      if (!value) {
        return true
      }

      const diffInDays = Math.floor(
        (valueDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
      )

      return utcDate1 <= utcDate2 && diffInDays < 7
    }
  ),
  startTime: yup.string().required('Giờ bắt đầu không được để trống'),
  endTime: yup.string(),
  pet: yup.string().trim(),
  // .required('Vật nuôi không được để trống')
  // .min(3, 'Vật nuôi tối thiếu 3 ký tự, tối đa là 50 ký tự')
  // .max(50, 'Vật nuôi tối thiếu 3 ký tự, tối đa là 50 ký tự'),
  note: yup.string().trim(),
  paymentMethod: yup
    .string()
    .required('Phương thức thanh toán không được để trống'),
  promotionCode: yup.string().trim(),
})
