import { TableCell } from '@mui/material'
import styled from 'styled-components'

export const StyleHeaderItem = styled.div`
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const StyleTitle = styled.h2`
  font-size: 25px;
  color: var(--color-primary);
  font-weight: bold;
  text-align: left;
`

export const StyleLinksAdd = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  min-width: 47%;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin-bottom: 24px;
  transition: 0.1s ease-in-out;
  height: 37px;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`
export const StyleLinksDelete = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  min-width: 47%;
  border-radius: 6px;
  border: none;
  padding: 6px;
  margin-bottom: 24px;
  transition: 0.1s ease-in-out;
  height: 32px;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`

export const StyleLink = styled.span`
  margin-top: 2px;
  font-weight: bold;
`
export const StyleTableCell = styled(TableCell)`
  && {
    font-weight: bold;
    color: var(--color-secondary);
  }
`
export const StyleTableCellItem = styled.p`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyleAddImage = styled.img`
  width: 75px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
`

export const StyleAddImageShow = styled.img`
  width: 500px;
  height: 400px;
  border-radius: 8px;
  object-fit: cover;
`

export const StyleWrapInput = styled.div`
  width: 100%;
  input {
    -webkit-text-fill-color: #080808 !important;
  }
  label {
    color: #080808 !important;
  }
`
