import { TableCell } from '@mui/material'
import styled from 'styled-components'

export const StyleContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
`

export const StyleStatistics = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`

export const StyleItem = styled.div`
  color: var(--color-secondary);
  font-size: 16px;
  font-weight: 500;
`
export const StyleList = styled.div``
export const StyleTotalItem = styled.div`
  font-size: 1.5rem;
  color: var(--color-primary);
`

export const StyleTotalOrder = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  font-weight: bold;
  padding: 24px 12px;
  border-radius: 10px;
  width: 33%;
  border: 0.0625rem solid rgb(239, 239, 239);
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
`

export const StyleOrder = styled.div``
export const StyleTableCell = styled(TableCell)`
  font-weight: bold !important;
  color: var(--color-primary) !important;
`

export const StyleTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
`

export const StyleHeaderItem = styled.div`
  padding-bottom: 12px;
`

export const StyleTitle = styled.h2`
  font-size: 25px;
  color: var(--color-primary);
  font-weight: bold;
`

export const StyleFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 64px !important;
  gap: 15px;
`

export const StyleFilterItem = styled.div`
  height: 100% !important;
  margin: 0px !important;
`
