import { TreeView } from '@mui/lab'
import { TableCell, Typography } from '@mui/material'
import styled from 'styled-components'

export const StyleTitle = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 25px;
    color: var(--color-primary);
    text-align: left;
    margin-bottom: 14px;
  }
`

export const StyleTreeView = styled(TreeView)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  text-align: left;
`
export const StyleCountry = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
`
export const StyleCell = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const StyleCellDistrict = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
export const StyleElement = styled.div`
  background-color: #f2edf8;
  color: #8b6db7;
  border-radius: 30px;
  padding: 6px 10px;
  width: fit-content;
  font-size: 12px;
  margin: 5px;
`
export const StyleTableCellBorder = styled(TableCell)`
  &&& {
    border-left: 1px solid #e0e0e0;
  }
`
export const Wrapper = styled.div<{ isEditing: boolean }>`
  position: relative;
  ${({ isEditing }) =>
    isEditing &&
    `
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
  `}
`
