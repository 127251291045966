import AddCircleIcon from '@mui/icons-material/AddCircle'
import BasicModal from 'components/Modal'
import React, { useEffect, useState } from 'react'
import {
  StyleHeaderItem,
  StyleLink,
  StyleLinks,
  StyleTableCell,
  StyleTableCellItem,
  StyleTitle,
} from './style'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useAlert } from 'contexts/AlertContext'
import staffApi from 'services/staffAPI'
import TypeFileCreate from '../TypeFileCreate'
import TypeFileEdit from '../TypeFileEdit'
import Close from 'components/Icons/components/Close'

interface IProps {
  modalTypeFile: boolean
  setModalTypeFile: any
  resetData: any
}

const TypeFileList: React.FC<IProps> = (props) => {
  const { modalTypeFile, setModalTypeFile, resetData } = props
  const [modalOpenDelete, setModalOpenDelete] = useState(false)
  const [modalTypeFileCreate, setModalTypeFileCreate] = useState(false)
  const [modalTypeFileEdit, setModalTypeFileEdit] = useState(false)
  const [idTypeFile, setIdTypeFile] = useState({ id: '' })
  const [SelectIdentifierLabel, setSelectIdentifierLabel] = useState<any>([])
  const [status, setStatus] = useState({
    id: '',
  })
  const { setAlertSuccess, setAlertError } = useAlert()

  const fetchDataLabel = async () => {
    const { data } = await staffApi.getIdentifierLabel()
    setSelectIdentifierLabel(data)
  }

  const handleChangeChecked = async (id: string) => {
    await staffApi.updateStatusIdentifierLabel(id)
    fetchDataLabel()
  }

  const handleOnclickGetId = (id?: string) => {
    if (id) {
      setModalOpenDelete(true)
      setStatus({
        id,
      })
    }
  }

  const handleOnclickGetIdEdit = (id?: string) => {
    if (id) {
      setModalTypeFileEdit(true)
      setIdTypeFile({
        id,
      })
    }
  }

  const handleDeleteTypeFile = async (id: string) => {
    try {
      await staffApi.deleteIdentifierLabel(id)
      setModalOpenDelete(false)
      setAlertSuccess('Xóa loại hồ sơ thành công')
      fetchDataLabel()
    } catch (err) {
      setAlertError('Xoá loại hồ sơ không thành công')
    }
  }

  const handleCloseDelete = () => {
    setModalOpenDelete(false)
  }

  useEffect(() => {
    fetchDataLabel()
  }, [modalTypeFileCreate, modalTypeFileEdit])

  return (
    <>
      <BasicModal
        open={modalTypeFile}
        handleClose={() => {
          setModalTypeFile(false)
          resetData()
        }}
      >
        <StyleHeaderItem>
          <StyleTitle>Thiết lập giá trị</StyleTitle>
          <Close
            onClick={() => {
              setModalTypeFile(false)
              resetData()
            }}
          />
        </StyleHeaderItem>
        <div
          style={{ width: '130px', marginTop: '24px' }}
          onClick={() => setModalTypeFileCreate(true)}
        >
          <StyleLinks>
            <AddCircleIcon sx={{ marginRight: '10px', color: 'white' }} />
            <StyleLink>Thêm giá trị</StyleLink>
          </StyleLinks>
        </div>
        <TableContainer
          component={Paper}
          className="box-shadow"
          sx={{ maxHeight: '400px', overflow: 'auto' }}
        >
          <Table
            //   sx={{ minWidth: 650 }}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow
                sx={{
                  position: 'sticky',
                  top: '0px',
                  background: 'white',
                }}
              >
                <StyleTableCell align="center" sx={{ padding: '0px' }}>
                  STT
                </StyleTableCell>
                <StyleTableCell align="center">Hiện</StyleTableCell>
                <StyleTableCell align="center">Giá trị</StyleTableCell>
                <StyleTableCell align="center">Chức năng</StyleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {SelectIdentifierLabel?.map((row: any, index: number) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{ padding: '0px' }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell align="center" sx={{ padding: '0px' }}>
                    <StyleTableCellItem>
                      <Checkbox
                        checked={row?.status}
                        onChange={() => handleChangeChecked(row?.id)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </StyleTableCellItem>
                  </TableCell>
                  <TableCell align="center" sx={{ padding: '0px' }}>
                    <StyleTableCellItem>{row?.name}</StyleTableCellItem>
                  </TableCell>
                  <TableCell align="center">
                    <StyleTableCellItem
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        justifyContent: 'center',
                      }}
                    >
                      <DriveFileRenameOutlineOutlinedIcon
                        color="primary"
                        sx={{
                          fontSize: '25px',
                          '&:hover': {
                            cursor: 'pointer',
                            transform: 'scale(1.3)',
                          },
                        }}
                        onClick={() => handleOnclickGetIdEdit(row?.id)}
                      />

                      <DeleteOutlineOutlinedIcon
                        color="error"
                        sx={{
                          fontSize: '25px',
                          '&:hover': {
                            cursor: 'pointer',
                            transform: 'scale(1.3)',
                          },
                        }}
                        onClick={() => handleOnclickGetId(row?.id)}
                      />
                    </StyleTableCellItem>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <BasicModal open={modalOpenDelete} handleClose={handleCloseDelete}>
            <Typography>Bạn có chắc chắn muốn xoá loại hồ sơ này?</Typography>
            <Button
              sx={{ margin: '8px', marginBottom: '0px' }}
              variant="contained"
              color="error"
              onClick={() => handleDeleteTypeFile(status.id)}
            >
              Xóa
            </Button>
            <Button
              sx={{ margin: '8px', marginBottom: '0px' }}
              onClick={handleCloseDelete}
              variant="outlined"
            >
              Hủy
            </Button>
          </BasicModal>
        </TableContainer>
      </BasicModal>
      <TypeFileCreate
        modalTypeFileCreate={modalTypeFileCreate}
        setModalTypeFileCreate={setModalTypeFileCreate}
      />
      <TypeFileEdit
        id={idTypeFile}
        modalTypeFileEdit={modalTypeFileEdit}
        setModalTypeFileEdit={setModalTypeFileEdit}
      />
    </>
  )
}

export default TypeFileList
