import React, { useEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { Grid, Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Breadcrumb from 'components/Breadcrumbs'
import Item from 'components/Item'
import { useForm, Controller } from 'react-hook-form'
import InputField from 'components/FormControl/InputField'
import { useNavigate, useParams } from 'react-router'
import { useAlert } from 'contexts/AlertContext'
import { yupResolver } from '@hookform/resolvers/yup'
import campaignApi from 'services/campaignAPI'
import { campaignSchema_ } from 'validations/campaignSchema'
import {
  StyleDate,
  StyleError,
  StyleErrorSelect,
  StyleTimePicker,
  StyleTitle,
} from 'pages/campaign/style'
import { useLoading } from 'contexts/LoadingContext'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Button } from '@mui/material'
import { CAMPAIGN_EDIT } from 'constants/breadcrumbs'
import LoadingVersion2 from 'components/LoadingV2'

const CampaignEdit = () => {
  const { id } = useParams()
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const [data, setData] = useState<ICampaign>()
  const [reviewer, setReviewer] = useState<any>([])
  const { setAlertClose, setAlertError, setAlertSuccess } = useAlert()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<ICampaign>({
    resolver: yupResolver(campaignSchema_),
    mode: 'onChange',
  })

  const onSubmit = async (data: ICampaign) => {
    setLoadingTrue()
    setAlertClose()
    try {
      await campaignApi.editCampaign(data, id as string)
      setAlertSuccess('Sửa chiến dịch thành công')
      setLoadingFalse()
      navigate('/campaign')
    } catch (error) {
      setLoadingFalse()
      setAlertError('Sửa chiến dịch thất bại')
    }
  }

  const fetchCampaign = async () => {
    const response: any = await campaignApi.getCampaignById(id as string)
    setData(response)
  }
  const fetchReviewer = async () => {
    const data: any = await campaignApi.getReviewer()
    setReviewer(data)
  }
  useEffect(() => {
    fetchReviewer()
    setValue('flatform', data?.flatform)
  }, [])
  useEffect(() => {
    setValue('start', data?.start)
    setValue('end', data?.end)
    setValue('reviewer', data?.reviewer)
    setValue('flatform', data?.flatform)
  }, [data])
  useEffect(() => {
    fetchCampaign()
  }, [id])

  return (
    <>
      <Grid container spacing={2}>
        <Breadcrumb paths={CAMPAIGN_EDIT} />
        <Grid item xs={12}>
          <Item sx={{ padding: '24px' }}>
            <StyleTitle>Sửa chiến dịch</StyleTitle>
            {data ? (
              <form
                style={{ padding: '0 200px' }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <InputField
                  label="Tên chiến dịch"
                  defaultValue={data.campaign}
                  {...register('campaign')}
                />
                {errors.campaign && (
                  <StyleError>{errors.campaign.message as string}</StyleError>
                )}
                <InputField
                  label="Mục đích"
                  multiline={true}
                  defaultValue={data.purpose}
                  {...register('purpose')}
                />
                {errors.purpose && (
                  <StyleErrorSelect>
                    {errors.purpose.message as string}
                  </StyleErrorSelect>
                )}
                <StyleDate>
                  <StyleTimePicker>
                    <Controller
                      control={control}
                      name="start"
                      render={({
                        field: { ref, onBlur, onChange, name, ...field },
                      }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            {...field}
                            minDate={new Date()}
                            inputRef={ref}
                            label="Ngày bắt đầu"
                            renderInput={(inputProps) => (
                              <InputField
                                sx={{ marginBottom: '20px' }}
                                {...inputProps}
                                onBlur={onBlur}
                                fullWidth
                                name={name}
                              />
                            )}
                            onChange={(e: Date | null) => {
                              onChange(e)
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    {errors.start && (
                      <StyleErrorSelect>
                        {errors.start.message as string}
                      </StyleErrorSelect>
                    )}
                  </StyleTimePicker>
                  <StyleTimePicker>
                    <Controller
                      control={control}
                      name="end"
                      render={({
                        field: { ref, onBlur, onChange, name, ...field },
                      }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            {...field}
                            minDate={new Date()}
                            inputRef={ref}
                            label="Ngày kết thúc"
                            renderInput={(inputProps) => (
                              <InputField
                                sx={{ marginBottom: '20px' }}
                                {...inputProps}
                                onBlur={onBlur}
                                fullWidth
                                name={name}
                              />
                            )}
                            onChange={(e: Date | null) => {
                              onChange(e)
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    {errors.end && (
                      <StyleErrorSelect>
                        {errors.end.message as string}
                      </StyleErrorSelect>
                    )}
                  </StyleTimePicker>
                </StyleDate>
                <Controller
                  control={control}
                  name={`reviewer`}
                  render={({ field: { onChange }, fieldState: { error } }) => (
                    <Autocomplete
                      autoHighlight
                      defaultValue={
                        data.reviewer
                          ? ({
                              name: data.reviewer,
                            } as IReviewer)
                          : null
                      }
                      getOptionLabel={(option: IReviewer) => option.name ?? ''}
                      sx={{ marginBottom: '24px' }}
                      onChange={(_, val: IReviewer | null) => {
                        if (val !== null) {
                          onChange(val.name)
                        }
                      }}
                      options={reviewer}
                      fullWidth
                      renderInput={(params) => (
                        <InputField {...params} label="Người duyệt" />
                      )}
                    />
                  )}
                />
                {errors.reviewer && (
                  <StyleErrorSelect>
                    {errors.reviewer.message as string}
                  </StyleErrorSelect>
                )}
                <Controller
                  control={control}
                  name={`flatform`}
                  render={({ field: { onChange } }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: '24px' }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Nền tảng
                      </InputLabel>
                      <Select
                        sx={{ textAlign: 'left' }}
                        defaultValue={data.flatform}
                        fullWidth
                        label="Nền tảng"
                        onChange={(e: SelectChangeEvent<string>) => {
                          onChange(e)
                        }}
                      >
                        <MenuItem value="Google">Google</MenuItem>
                        <MenuItem value="Facebook">Facebook</MenuItem>
                        <MenuItem value="Youtube">Youtube</MenuItem>
                        <MenuItem value="Tiktok">Tiktok</MenuItem>
                        <MenuItem value="Khác">Khác</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.flatform && (
                  <StyleError>{errors.flatform.message as string}</StyleError>
                )}
                <InputField
                  label="Nội dung chiến dịch"
                  multiline={true}
                  defaultValue={data?.content}
                  {...register('content')}
                />
                {errors.content && (
                  <StyleError>{errors.content.message as string}</StyleError>
                )}
                <InputField
                  label="Chi phí dự kiến"
                  defaultValue={data?.expense}
                  {...register('expense')}
                />
                {errors.expense && (
                  <StyleError>{errors.expense.message as string}</StyleError>
                )}
                <InputField
                  label="Doanh thu dự kiến"
                  defaultValue={data?.revenue}
                  {...register('revenue')}
                />
                {errors.revenue && (
                  <StyleError>{errors.revenue.message as string}</StyleError>
                )}
                <InputField
                  label="Lượng phản hồi dự kiến"
                  defaultValue={data?.feedbackNumbers}
                  {...register('feedbackNumbers')}
                />
                {errors.feedbackNumbers && (
                  <StyleError>
                    {errors.feedbackNumbers.message as string}
                  </StyleError>
                )}
                <InputField
                  label="Số Lead sự kiến"
                  defaultValue={data?.leadNumbers}
                  {...register('leadNumbers')}
                />
                {errors.leadNumbers && (
                  <StyleError>
                    {errors.leadNumbers.message as string}
                  </StyleError>
                )}
                <InputField
                  label="Số khách hàng dự kiến"
                  defaultValue={data?.customerNumbers}
                  {...register('customerNumbers')}
                />
                {errors.customerNumbers && (
                  <StyleError>
                    {errors.customerNumbers.message as string}
                  </StyleError>
                )}
                <InputField
                  label="ROI mong đợi"
                  defaultValue={data?.profit}
                  {...register('profit')}
                />
                {errors.profit && (
                  <StyleError>{errors.profit.message as string}</StyleError>
                )}
                <InputField
                  label="Ghi chú"
                  multiline={true}
                  defaultValue={data?.note}
                  {...register('note')}
                />
                {errors.note && (
                  <StyleError>{errors.note.message as string}</StyleError>
                )}
                <Stack spacing={2} direction="row" sx={{ paddingTop: '10px' }}>
                  <Button variant="contained" type="submit">
                    Lưu thay đổi
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => navigate('/campaign')}
                  >
                    Hủy
                  </Button>
                </Stack>
              </form>
            ) : (
              <LoadingVersion2 />
            )}
          </Item>
        </Grid>
      </Grid>
    </>
  )
}

export default CampaignEdit
