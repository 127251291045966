import AttachFileIcon from '@mui/icons-material/AttachFile'
import {
  Avatar,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import Divider from '@mui/material/Divider'
import InputField from 'components/FormControl/InputField'
import { useFile } from 'contexts/FileContext'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import staffApi from 'services/staffAPI'
import {
  StyleAvatar,
  StyleEconomicInformation,
  StyleImage,
  StyleInfo,
  StyleItem,
  StyleItemInformation,
  StyleLink,
  StyleLinks,
  StylePersonal,
  StyleTableCell,
  StyleTableCellItem,
  StyleWrapRow,
} from './style'

const PersonalInformationDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [staff, setStaff] = useState<IStaff>()
  const { addFileData } = useFile()

  useEffect(() => {
    const getStaffDetail = async () => {
      const { data } = await staffApi.getStaffById(id as string)
      setStaff(data)
      addFileData(
        data?.staffIdentification?.map((staffIdent: any) => {
          return {
            ...staffIdent,
            // title: staffIdent?.identifierType,
          }
        })
      )
    }
    getStaffDetail().catch(console.error)
  }, [id])

  if (!staff) return <></>

  return (
    <>
      <ListItem>
        <Typography variant="h6" gutterBottom>
          Thông tin cá nhân
        </Typography>
      </ListItem>
      <Divider />
      <StyleItem>
        <StyleImage>
          <StyleAvatar>
            <Avatar src={staff?.avatar} sx={{ width: 150, height: 150 }} />
          </StyleAvatar>
        </StyleImage>
        <StyleInfo>
          <StyleWrapRow>
            <InputField
              label="Mã cộng tác viên"
              value={staff.displayId}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Họ và tên"
              value={staff?.fullName}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Số điện thoại chính"
              value={staff?.phone}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Số điện thoại phụ"
              value={staff?.subPhone}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Email"
              value={staff?.email}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />

            <InputField
              label="Kỹ năng"
              value={staff?.skill == null ? null : JSON.parse(staff?.skill)}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Ngày sinh"
              value={dayjs(staff?.birth).format('DD/MM/YYYY')}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Ngày sinh nhật"
              value={dayjs(staff?.born).format('DD/MM/YYYY')}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Chuyên viên tuyển dụng"
              value={staff?.staffManagers?.recruitment}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Chuyên viên nhân sự"
              value={staff?.staffManagers?.hrExecutive}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Tổ trưởng"
              value={staff?.staffManagers?.leader}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Tổ/đội/nhóm"
              value={staff?.staffManagers?.groups}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Zalo"
              value={staff?.zalo}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Facebook"
              value={staff?.facebook}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <div
            style={{
              display: 'flex',
              width: '49%',
              gap: '24px',
            }}
          >
            <InputField
              label="Gán nhãn"
              value={staff?.staffLabel?.map((item: any) => item?.name)}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </div>
        </StyleInfo>
      </StyleItem>
      <ListItem>
        <Typography variant="h6" gutterBottom>
          Thông tin hợp đồng
        </Typography>
      </ListItem>
      <Divider />
      <div style={{ width: '130px', marginTop: '24px' }}>
        <StyleLinks
          onClick={() =>
            navigate(`/staff-manager/part-time-staff/detail/${id}/file-detail`)
          }
        >
          <AttachFileIcon sx={{ marginRight: '10px', color: 'white' }} />
          <StyleLink>Xem hồ sơ</StyleLink>
        </StyleLinks>
      </div>
      <StyleItemInformation>
        <StylePersonal>
          <StyleWrapRow>
            <InputField
              label="Dịch vụ"
              multiline
              value={staff?.services?.map(
                (service: any) => service?.staffService?.name
              )}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Số hợp đồng"
              value={staff?.contractNumber}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Ngày ký hợp đồng"
              value={dayjs(staff?.signingDate).format('DD/MM/YYYY')}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Ngày bắt đầu làm việc"
              value={dayjs(staff?.startDate).format('DD/MM/YYYY')}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          {/* {errors.name && (
          <StyleError>{errors.name.message as string}</StyleError>
        )} */}
          <StyleWrapRow>
            <InputField
              label="Giới tính"
              value={staff?.gender == 'male' ? 'Nam' : 'Nữ'}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Nguyên quán"
              value={staff?.homeTown}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          {/* {errors.description && (
          <StyleError>{errors.description.message as string}</StyleError>
        )} */}
          <StyleWrapRow>
            <InputField
              label="Loại giấy tờ tuỳ thân"
              value={staff?.citizenIdType}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Số giầy tờ tuỳ thân"
              value={staff?.citizenId}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Ngày cấp"
              value={dayjs(staff?.citizenIdDate).format('DD/MM/YYYY')}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Nơi cấp CCCD/CMND"
              value={staff?.placeCitizenId}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <InputField
            label="Địa chỉ thường trú"
            multiline
            value={staff?.permanentAddress}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            size="small"
          />
          <InputField
            label="Địa chỉ tạm trú"
            multiline
            value={staff?.shelterAddress}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            size="small"
          />
          <StyleWrapRow>
            <InputField
              label="Tôn giáo"
              value={staff?.religion}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Phương tiện"
              value={staff?.vehicle}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Tình trạng hôn nhân"
              value={staff?.maritalStatus}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Nguồn (biết đến 3Clean qua)"
              value={staff?.socialSource}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Cọc đăng ký công việc"
              value={staff?.staffDeposit?.deposit}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Ngày cọc"
              value={dayjs(staff?.staffDeposit?.dateDeposit).format(
                'DD/MM/YYYY'
              )}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Tình trạng cọc"
              value={staff?.staffDeposit?.depositStatus}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Ngày hoàn cọc"
              value={dayjs(staff?.staffDeposit?.dateDepositBack).format(
                'DD/MM/YYYY'
              )}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <InputField
            label="Mong muốn của cộng tác viên"
            value={staff?.note}
            multiline={true}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
            size="small"
          />
          <div
            style={{
              display: 'flex',
              width: '49%',
              gap: '24px',
            }}
          >
            <InputField
              label="Mã số thuế cá nhân"
              value={staff?.personalTaxCode}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </div>
        </StylePersonal>
      </StyleItemInformation>
      <ListItem>
        <Typography variant="h6" gutterBottom>
          Thông tin tài chính
        </Typography>
      </ListItem>
      <Divider />

      <StyleEconomicInformation>
        <StylePersonal>
          <StyleWrapRow>
            <InputField
              label="Số tài khoản"
              value={staff?.staffFinance?.[0]?.accountNumber}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Tên tài khoản"
              value={staff?.staffFinance?.[0]?.accountName}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
          <StyleWrapRow>
            <InputField
              label="Ngân hàng"
              value={staff?.staffFinance?.[0]?.bankName}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
            <InputField
              label="Chi nhánh"
              value={staff?.staffFinance?.[0]?.agency}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </StyleWrapRow>
        </StylePersonal>
      </StyleEconomicInformation>

      <ListItem>
        <Typography variant="h6" gutterBottom>
          Người liên hệ khẩn cấp
        </Typography>
      </ListItem>
      <Divider />

      <TableContainer component={Paper} className="box-shadow">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <StyleTableCell align="left">STT</StyleTableCell>
              <StyleTableCell align="left">Quan hệ</StyleTableCell>
              <StyleTableCell align="left">Họ tên</StyleTableCell>
              <StyleTableCell align="left">Số điện thoại</StyleTableCell>
              <StyleTableCell align="left">Địa chỉ</StyleTableCell>
              <StyleTableCell align="left">Ghi chú</StyleTableCell>
              {/* <StyleTableCell align="left">Chức năng</StyleTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {staff?.relatives?.map((row: any, index: number) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell align="left" component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="left">
                  <StyleTableCellItem>{row?.relationship}</StyleTableCellItem>
                </TableCell>
                <TableCell align="left">
                  <StyleTableCellItem>{row?.fullName}</StyleTableCellItem>
                </TableCell>
                <TableCell align="left">
                  <StyleTableCellItem>{row?.phone}</StyleTableCellItem>
                </TableCell>
                <TableCell align="left">{row?.address}</TableCell>
                <TableCell align="left">
                  <StyleTableCellItem>{row?.note}</StyleTableCellItem>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ListItem>
        <Typography variant="h6" gutterBottom>
          Vị trí công việc
        </Typography>
      </ListItem>
      <Divider />

      <TableContainer
        component={Paper}
        className="box-shadow"
        sx={{ marginBottom: '24px' }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <StyleTableCell align="center" sx={{ padding: '0px' }}>
                STT
              </StyleTableCell>
              <StyleTableCell align="left">Quốc gia</StyleTableCell>
              <StyleTableCell align="left">Tỉnh/Thành phố</StyleTableCell>
              <StyleTableCell align="left">Quận/Huyện</StyleTableCell>
              <StyleTableCell align="left">Địa chỉ</StyleTableCell>
              {/* <StyleTableCell align="left">Chức năng</StyleTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {staff?.districts?.map((row: any, index: number) => (
              <TableRow
                key={row.index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ padding: '0px' }}
                >
                  {index + 1}
                </TableCell>
                <TableCell align="left">
                  <StyleTableCellItem>
                    {/* {row?.country?.native} */}
                    Việt Nam
                  </StyleTableCellItem>
                </TableCell>
                <TableCell align="left">
                  <StyleTableCellItem>
                    {row?.staffProvince?.fullName}
                  </StyleTableCellItem>
                </TableCell>
                <TableCell align="left">
                  <StyleTableCellItem>
                    {row?.staffDistrict?.fullName}
                  </StyleTableCellItem>
                </TableCell>
                <TableCell align="left">
                  <StyleTableCellItem>{row?.fullLocation}</StyleTableCellItem>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default PersonalInformationDetail
