import React from 'react'
import BannerList from './components/BannerList'

const Banner = () => {
  return (
    <>
      <BannerList />
    </>
  )
}

export default Banner
