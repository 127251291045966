import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone'
import ConfirmationNumberTwoToneIcon from '@mui/icons-material/ConfirmationNumberTwoTone'
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone'
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone'

export interface IObjectRank {
  '1': string
  '2': string
  '3': string
  '4': string
  '5': string
}

export const rank: IObjectRank = {
  '1': 'member',
  '2': 'silver',
  '3': 'gold',
  '4': 'platinum',
  '5': 'premium',
}

export const colorRank: IObjectRank = {
  '1': '#f0ad4e',
  '2': '#5bc0de',
  '3': '#5bc0de',
  '4': '#5cb85c',
  '5': '#d9534f',
}

export const iconsRank: Record<string, any> = {
  '1': <HourglassTopTwoToneIcon />,
  '2': <ConfirmationNumberTwoToneIcon />,
  '3': <ConfirmationNumberTwoToneIcon />,
  '4': <CheckBoxTwoToneIcon />,
  '5': <CancelPresentationTwoToneIcon />,
}
