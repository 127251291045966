import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Stack } from '@mui/material'
import InputField from 'components/FormControl/InputField'
import BasicModal from 'components/Modal'
import { useAlert } from 'contexts/AlertContext'
import React from 'react'
import { useForm } from 'react-hook-form'
import staffApi from 'services/staffAPI'
import { TypeFileSchema_ } from 'validations/partTimeStaffSchema'
import { StyleError, StyleHeaderItem, StyleTitle } from './style'
import Close from 'components/Icons/components/Close'

interface IProps {
  modalTypeFileCreate: boolean
  setModalTypeFileCreate: any
}

const TypeFileCreate: React.FC<IProps> = (props) => {
  const { modalTypeFileCreate, setModalTypeFileCreate } = props
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(TypeFileSchema_),
    mode: 'onChange',
  })
  const { setAlertError, setAlertSuccess } = useAlert()

  const onSubmit = async (data: any) => {
    try {
      await staffApi.createIdentifierLabel(data)
      setModalTypeFileCreate(false)
      setAlertSuccess('Thêm loại hồ sơ thành công')
      reset()
    } catch (error: any) {
      setAlertError(error?.response?.data?.message)
    }
  }

  return (
    <BasicModal
      open={modalTypeFileCreate}
      handleClose={() => {
        setModalTypeFileCreate(false)
        reset()
      }}
    >
      <StyleHeaderItem>
        <StyleTitle>Thêm giá trị</StyleTitle>
        <Close
          onClick={() => {
            setModalTypeFileCreate(false)
            reset()
          }}
        />
      </StyleHeaderItem>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          {...register('name')}
          label="Giá trị"
          defaultValue=""
          size="small"
        />
        <StyleError>{errors.name?.message as string}</StyleError>

        <Stack
          spacing={2}
          direction="row"
          sx={{
            justifyContent: 'flex-start',
            paddingTop: '14px',
          }}
        >
          <Button variant="contained" type="submit" className="bgr-global">
            Lưu
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setModalTypeFileCreate(false)
              reset()
            }}
          >
            Huỷ
          </Button>
        </Stack>
      </form>
    </BasicModal>
  )
}

export default TypeFileCreate
