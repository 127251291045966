import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import InputField from 'components/FormControl/InputField'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import {
  StyleAddImage,
  StyleAddItem,
  StyleError,
  StyleImage,
  StyleInput,
  StyleLabel,
  StyleLink,
  StyleLinks,
} from './style'
import {
  Paper,
  MenuItem,
  Typography,
  TextField,
  Autocomplete,
} from '@mui/material'
import { Grid } from '@mui/material'
import Breadcrumb from 'components/Breadcrumbs'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import bannerApi from 'services/bannerAPI'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import { bannerEditSchema_ } from 'validations/bannerSchema'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import { FileTypeEnum, getFileSize } from 'utils/FileUtils'
import { IMAGE_TYPE, MAX_FILE_SIZE } from 'constants/common'
import { uploadImage } from 'utils/uploadImage'
import dayjs from 'dayjs'
import { bannerTypeAction, bannerStatus } from 'constants/banner'
import { BANNER_EDIT } from 'constants/breadcrumbs'
import BasicModal from 'components/Modal'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { checkChangeData } from 'utils/confirmBox'
import Editor from 'components/Editor'

const BannerEdit = () => {
  const { id } = useParams()
  const [data, setData] = useState<IBanner>()
  const [dataSave, setDataSave] = useState<IBanner | null | undefined>()
  const [listService, setListService] = useState([])
  const [valueBannerType, setValueTypeAction] = useState()
  const [valueService, setValueService] = useState('')
  const [valueStatus, setValueStatus] = useState()
  const [image, setImage] = useState('')
  const [error, setError] = useState('')
  const [imageData, setDataImg] = useState('')
  const [checkFile, setCheckFile] = useState<File | null>()
  const [modalSave, setModalSave] = useState<boolean>(false)
  const [modalCancel, setModalCancel] = useState<boolean>(false)
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertError, setAlertSuccess } = useAlert()
  const navigate = useNavigate()
  const [content, setContent] = useState('')
  const [errorContent, setErrorContent] = useState<string>()
  const [listPromoCode, setListPromoCode] = useState([])
  const [promoCode, setPromoCode] = useState<any>()

  const {
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IBanner>({
    resolver: yupResolver(bannerEditSchema_),
    mode: 'onChange',
  })

  const dataDefaultFriendVoucher = {
    thumbnail: data?.thumbnail,
    title: data?.title,
    content: content,
    typeAction: data?.typeAction,
    status: data?.status,
    priority: data?.priority,
    buttonName: data?.buttonName,
    link: '',
    code: '',
    endDate: '',
    serviceId: '',
    startDate: '',
  }

  const dataDefaultLinks = {
    thumbnail: data?.thumbnail,
    title: data?.title,
    content: content,
    typeAction: data?.typeAction,
    link: data?.link,
    status: data?.status,
    priority: data?.priority,
    buttonName: data?.buttonName,
    code: '',
    endDate: '',
    serviceId: '',
    startDate: '',
  }

  const dataDefaultServices = {
    code: data?.code,
    endDate: data?.endDate,
    serviceId: data?.serviceId,
    startDate: data?.startDate,
    thumbnail: data?.thumbnail,
    title: data?.title,
    content: content,
    typeAction: data?.typeAction,
    status: data?.status,
    priority: data?.priority,
    buttonName: data?.buttonName,
    link: '',
  }

  const handleChangeService = (event: React.SyntheticEvent, newValue: any) => {
    setValueService(newValue?.props?.value)
  }

  const handleChangeBannerType = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setValueTypeAction(newValue?.props?.value)
  }

  const handleChangeStatus = (event: React.SyntheticEvent, newValue: any) => {
    setValueStatus(newValue?.props?.value)
  }

  const fetchData = async () => {
    const { data } = await bannerApi.getService()
    setListService(data)
  }

  const fetchDataPromoCode = async () => {
    const { data } = await bannerApi.getPromoCode()

    const code = await bannerApi.getPromoCode({ limit: data?.count })

    const listCode = code?.data?.rows.map((item: any, index: number) => {
      return {
        value: index,
        title: item.promotionCode,
      }
    })

    setListPromoCode(listCode)
  }

  const handlePromoCode = (event: any, value: any) => {
    setPromoCode(value)
  }

  const handleEditorChange = (value: string) => {
    setContent(value.trim())
    if (value.trim() === '<p><br></p>' || value.trim() === '') {
      setErrorContent('Nội dung không được để trống')
      return
    } else {
      setErrorContent(undefined)
    }
  }

  const handleUpload = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
    if (file) {
      const name = file?.name.split('.')
      if (fileSize > MAX_FILE_SIZE) {
        setError(`Dung lượng ảnh tối đa cho phép là ${MAX_FILE_SIZE} MB`)
        return
      }
      if (!name[name.length - 1].match(IMAGE_TYPE)) {
        setError('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
        return
      }

      if (fileSize === 0) {
        setError(`Ảnh lỗi`)
        return
      }

      setCheckFile(file)
      setImage(URL?.createObjectURL((target.files as FileList)[0]))
      setError('')
    }
  }

  const handleClose = () => {
    setModalSave(false)
  }

  const handleCheckSubmit = (data: IBanner) => {
    if (content.trim() == '<p><br></p>' || content.trim() == '') {
      setErrorContent('Nội dung không được để trống')
      return
    } else {
      setErrorContent(undefined)
      setDataSave(data)
      handleCheckDataSave()
    }
  }

  const onSubmit = async () => {
    setLoadingTrue()
    let isUploadSuccess = false

    const dataEdit = { ...dataSave, content: content }
    if (checkFile) {
      try {
        const response: IUploadImg = await uploadImage(checkFile as File)
        dataEdit.thumbnail = response.origin

        isUploadSuccess = true
      } catch (error: any) {
        setError('Vui lòng chọn ảnh!')
      }

      if (!isUploadSuccess) {
        setLoadingFalse()
        return
      }
    }
    if (dataEdit.priority === null) {
      dataEdit.priority = 0
    }
    try {
      const dataAll = {
        thumbnail: dataEdit.thumbnail,
        title: dataEdit.title,
        content: dataEdit.content,
        typeAction: dataEdit.typeAction,
        status: dataEdit.status,
        priority: dataEdit.priority,
        buttonName: dataEdit.buttonName,
      }
      if (valueBannerType == 'link') {
        const dataLink = { ...dataAll, link: dataEdit.link }
        await bannerApi.update(dataLink, id as string)
        setLoadingFalse()
        setAlertSuccess('Lưu thay đổi thành công!')
        navigate('/marketing/banners')
      } else if (valueBannerType == 'service') {
        const dataService = {
          ...dataAll,
          serviceId: dataEdit.serviceId,
          code: promoCode.title,
          startDate: dataEdit.startDate,
          endDate: dataEdit.endDate,
        }
        await bannerApi.update(dataService, id as string)
        setLoadingFalse()
        setAlertSuccess('Lưu thay đổi thành công!')
        navigate('/marketing/banners')
      } else if (
        valueBannerType == 'inviteFriend' ||
        valueBannerType == 'voucher'
      ) {
        await bannerApi.update(dataAll, id as string)
        setLoadingFalse()
        setAlertSuccess('Lưu thay đổi thành công!')
        navigate('/marketing/banners')
      } else {
        setAlertError('Sửa thất bại!')
      }
    } catch (error: any) {
      setLoadingFalse()
      setAlertError(error.response?.data?.message)
    }
  }

  const handleCheckDataSave = () => {
    const getValuesItem = getValues()
    const isChanged = checkChangeData({
      dataChange: getValuesItem,
      dataDefault:
        valueBannerType == 'service'
          ? dataDefaultServices
          : valueBannerType == 'link'
          ? dataDefaultLinks
          : dataDefaultFriendVoucher,
    })

    if (isChanged || image) {
      setModalSave(true)
    } else {
      navigate('/marketing/banners')
    }
  }

  const handleCheckDataCancel = () => {
    const getValuesItem = getValues()
    const isChanged = checkChangeData({
      dataChange: getValuesItem,
      dataDefault:
        valueBannerType == 'service'
          ? dataDefaultServices
          : valueBannerType == 'link'
          ? dataDefaultLinks
          : dataDefaultFriendVoucher,
    })

    if (isChanged || image) {
      setModalCancel(true)
    } else {
      navigate('/marketing/banners')
    }
  }

  useEffect(() => {
    fetchDataPromoCode()
  }, [])

  useEffect(() => {
    const getCharacters = async () => {
      fetchData()
      const { data } = await bannerApi.getBannerById(id as string)
      setData(data)
      setValueTypeAction(data?.typeAction)
      setValueStatus(data?.status)

      setDataImg(data?.thumbnail)

      setContent(data?.content)

      setValue('title', data?.title)
      setValue('content', data?.content)
      setValue('typeAction', data?.typeAction)
      setValue('status', data?.status)
      setValue('thumbnail', data?.thumbnail)
      setValue('priority', data?.priority)
      setValue('buttonName', data?.buttonName)
      if (data?.typeAction === 'service') {
        setPromoCode({ title: data?.code })
        setValueService(data?.serviceId)
        setValue('code', data?.code)
        setValue('serviceId', data?.serviceId)
        setValue('startDate', data?.startDate)
        setValue('endDate', data?.endDate)
        setValue('link', '')
      } else if (data?.typeAction == 'link') {
        setValue('link', data?.link)
        setValue('code', '')
        setValue('serviceId', '')
        setValue('startDate', '')
        setValue('endDate', '')
      } else if (
        data?.typeAction == 'inviteFriend' ||
        data?.typeAction == 'voucher'
      ) {
        setValue('link', '')
        setValue('code', '')
        setValue('serviceId', '')
        setValue('startDate', '')
        setValue('endDate', '')
      }
    }

    getCharacters().catch(console.error)
  }, [id])

  if (!data) return <></>

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={BANNER_EDIT} />
      <Grid item xs={12}>
        <Paper
          sx={{
            boxShadow:
              '0px 12px 33px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a',
            textAlign: 'center',
            height: '100%',
            padding: '24px',
          }}
        >
          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '100ch', margin: 'auto' },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleCheckSubmit)}
          >
            <div>
              <StyleImage>
                {image ? (
                  <StyleAddImage>
                    <img src={image} alt="img news" />
                    <StyleAddItem>
                      <StyleLinks>
                        <CameraAltOutlinedIcon />
                        <StyleLink>Chỉnh sửa ảnh bìa</StyleLink>
                      </StyleLinks>
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={handleUpload}
                        name="image"
                      />
                    </StyleAddItem>
                  </StyleAddImage>
                ) : (
                  <StyleAddImage>
                    {imageData ? (
                      <>
                        <img src={imageData} alt="img news" />
                        <StyleAddItem>
                          <StyleLinks>
                            <CameraAltOutlinedIcon />
                            <StyleLink>Chỉnh sửa ảnh bìa</StyleLink>
                          </StyleLinks>
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={handleUpload}
                          />
                        </StyleAddItem>
                      </>
                    ) : (
                      <>
                        <AddPhotoAlternateIcon
                          sx={{
                            width: '890px !important',
                            height: '300px !important',
                            color: '#1976d2',
                          }}
                        />
                        <StyleAddItem>
                          <StyleLinks>
                            <CameraAltOutlinedIcon />
                            <StyleLink>Thêm ảnh bìa</StyleLink>
                          </StyleLinks>
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={handleUpload}
                          />
                        </StyleAddItem>
                      </>
                    )}
                  </StyleAddImage>
                )}
              </StyleImage>
              <StyleError>{error}</StyleError>
            </div>

            <div>
              <InputField
                label="Tiêu đề"
                defaultValue={data?.title}
                {...register('title')}
                multiline={true}
              />
              <StyleError>{errors.title?.message as string}</StyleError>
            </div>

            <div style={{ marginBottom: '10px' }}>
              <StyleLabel>Nội dung</StyleLabel>
              <Editor onChange={handleEditorChange} value={content.trim()} />
              {!!errorContent && (
                <StyleError style={{ marginTop: '10px' }}>
                  {errorContent}
                </StyleError>
              )}
            </div>

            <StyleInput>
              <div style={{ width: '100%' }}>
                <InputField
                  id="typeAction"
                  label="Kiểu banner"
                  select
                  value={valueBannerType}
                  {...register('typeAction')}
                  onChange={handleChangeBannerType}
                >
                  {bannerTypeAction.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
              </div>

              <div style={{ width: '100%' }}>
                <InputField
                  id="status"
                  label="Trạng thái"
                  select
                  value={valueStatus}
                  {...register('status')}
                  onChange={handleChangeStatus}
                >
                  {bannerStatus.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
              </div>

              {valueBannerType === 'link' ||
              valueBannerType === 'inviteFriend' ||
              valueBannerType === 'voucher' ? (
                <div style={{ width: '100%' }}>
                  <InputField
                    label="Độ ưu tiên"
                    defaultValue=""
                    {...register('priority')}
                    multiline={true}
                  />
                  <StyleError>{errors.priority?.message as string}</StyleError>
                </div>
              ) : (
                <div style={{ width: '100%' }}>
                  <InputField
                    id="serviceId"
                    label="Tên dịch vụ"
                    select
                    value={valueService}
                    {...register('serviceId')}
                    onChange={handleChangeService}
                  >
                    {listService.map((option: any) => (
                      <MenuItem
                        key={option?.id}
                        value={option?.id}
                        sx={{ justifyContent: 'center' }}
                      >
                        {option?.name}
                      </MenuItem>
                    ))}
                  </InputField>
                  <StyleError>
                    {!valueService && (errors.serviceId?.message as string)}
                  </StyleError>
                </div>
              )}
            </StyleInput>

            <StyleInput>
              <div style={{ width: '32%' }}>
                <InputField
                  label="Nội dung nút bấm"
                  defaultValue={data?.buttonName}
                  {...register('buttonName')}
                />
                <StyleError>{errors.buttonName?.message as string}</StyleError>
              </div>
              {valueBannerType === 'service' ? (
                <>
                  <div style={{ width: '32%' }}>
                    {/* <InputField
                      label="Mã khuyến mãi"
                      defaultValue={data?.code}
                      {...register('code')}
                      multiline={true}
                    />
                    <StyleError>{errors.code?.message as string}</StyleError> */}
                    <Autocomplete
                      size="small"
                      sx={{ padding: '10px 0' }}
                      disablePortal
                      options={listPromoCode}
                      getOptionLabel={(option) => option?.title || ''}
                      isOptionEqualToValue={(option, value) =>
                        option?.title === value?.title
                      }
                      defaultValue={promoCode}
                      noOptionsText={'Danh sách rỗng'}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Mã khuyến mãi"
                          placeholder="Mã khuyến mãi"
                          {...register('code')}
                        />
                      )}
                      onChange={handlePromoCode}
                    />
                    <StyleError>
                      {!promoCode && (errors.code?.message as string)}
                    </StyleError>
                  </div>

                  <div style={{ width: '32%' }}>
                    <InputField
                      label="Độ ưu tiên"
                      defaultValue={data?.priority}
                      {...register('priority')}
                    />
                    <StyleError>
                      {errors.priority?.message as string}
                    </StyleError>
                  </div>
                </>
              ) : (
                valueBannerType === 'link' && (
                  <div style={{ width: '67%' }}>
                    <InputField
                      label="Link"
                      defaultValue={data?.link}
                      {...register('link')}
                      sx={{
                        width: '100%',
                      }}
                      multiline={true}
                    />
                    <StyleError>{errors.link?.message as string}</StyleError>
                  </div>
                )
              )}
            </StyleInput>

            {valueBannerType === 'service' && (
              <div style={{ display: 'flex', gap: '24px' }}>
                <div style={{ width: '31.5%' }}>
                  <Controller
                    control={control}
                    name="startDate"
                    defaultValue={dayjs(data?.startDate).format('YYYY-MM-DD')}
                    render={({
                      field: { onChange, ref, onBlur, name, ...field },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          minDate={new Date()}
                          {...field}
                          inputRef={ref}
                          inputFormat="DD/MM/YYYY"
                          label="Ngày bắt đầu"
                          renderInput={(inputProps) => (
                            <InputField
                              {...inputProps}
                              onBlur={onBlur}
                              name={name}
                              error={false}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onChange={(e: Date | null) => {
                            onChange(e)
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <StyleError>{errors.startDate?.message as string}</StyleError>
                </div>
                <div style={{ width: '31.5%' }}>
                  <Controller
                    control={control}
                    name="endDate"
                    defaultValue={dayjs(data?.endDate).format('YYYY-MM-DD')}
                    render={({
                      field: { onChange, ref, onBlur, name, ...field },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          minDate={new Date()}
                          {...field}
                          inputRef={ref}
                          inputFormat="DD/MM/YYYY"
                          label="Ngày kết thúc"
                          renderInput={(inputProps) => (
                            <InputField
                              {...inputProps}
                              onBlur={onBlur}
                              name={name}
                              error={false}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onChange={(e: Date | null) => {
                            onChange(e)
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <StyleError>{errors.endDate?.message as string}</StyleError>
                </div>
              </div>
            )}

            <Stack
              spacing={2}
              direction="row"
              sx={{
                justifyContent: 'flex-start',
                paddingTop: '14px',
              }}
            >
              <Button variant="contained" type="submit" className="bgr-global">
                Lưu
              </Button>
              <Button variant="outlined" onClick={handleCheckDataCancel}>
                Huỷ
              </Button>
            </Stack>
            {modalSave && (
              <BasicModal open={modalSave} handleClose={handleClose}>
                <Typography>Bạn có chắc chắn muốn lưu thay đổi?</Typography>
                <Button
                  sx={{
                    margin: '20px',
                    marginBottom: '0px',
                    paddingLeft: '43px',
                    paddingRight: '43px',
                  }}
                  variant="contained"
                  onClick={() => onSubmit()}
                  className="bgr-global"
                >
                  Lưu
                </Button>
                <Button
                  sx={{
                    margin: '20px',
                    marginBottom: '-0.5px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                  }}
                  onClick={() => setModalSave(false)}
                  variant="outlined"
                >
                  Không
                </Button>
              </BasicModal>
            )}
            <BasicModal open={modalCancel} handleClose={handleClose}>
              <Typography>Bạn có chắc chắn muốn hủy thay đổi?</Typography>
              <Button
                sx={{
                  margin: '20px',
                  marginBottom: '0px',
                  paddingLeft: '43px',
                  paddingRight: '43px',
                }}
                variant="contained"
                onClick={() => navigate('/marketing/banners')}
                className="bgr-global"
              >
                Hủy
              </Button>
              <Button
                sx={{
                  margin: '20px',
                  marginBottom: '-0.5px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
                onClick={() => setModalCancel(false)}
                variant="outlined"
              >
                Không
              </Button>
            </BasicModal>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default BannerEdit
