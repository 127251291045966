import React, { useState, useEffect } from 'react'
import Breadcrumb from 'components/Breadcrumbs'
import { Grid, Button, Stack } from '@mui/material'
import Item from 'components/Item'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  StyleAddImage,
  StyleHeaderItem,
  StyleImage,
  StyleTitle,
  StyleForm,
  StyleContentRight,
} from './style'
import { yupResolver } from '@hookform/resolvers/yup'
import { newsSchema_ } from 'validations/newsSchema'
import { newsAPI } from 'services/newsAPI'
import InputField from 'components/FormControl/InputField'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { NEWS_DETAIL } from 'constants/breadcrumbs'

const DetailNews = () => {
  const [data, setData] = useState<INews>({
    id: 1,
    title: '',
    description: '',
    thumbnail: '',
    link: '',
  })
  const navigate = useNavigate()
  const { id } = useParams()

  const { register, setValue } = useForm({
    resolver: yupResolver(newsSchema_),
    mode: 'onChange',
  })

  useEffect(() => {
    const getCharacters = async () => {
      const { data } = await newsAPI.getNewsById(id as string)
      if (data) {
        setData(data)
      }
    }
    getCharacters().catch(console.error)
  }, [id])

  useEffect(() => {
    setValue('title', data?.title)
    setValue('description', data?.description)
    setValue('link', data?.link ?? '')
    setValue('priority', data?.priority)
  }, [data])

  return (
    data && (
      <Grid container spacing={2}>
        <Breadcrumb paths={NEWS_DETAIL} />
        <Grid item xs={12}>
          <Item sx={{ padding: '24px' }}>
            <StyleHeaderItem>
              <StyleTitle>Chi tiết Blog</StyleTitle>
            </StyleHeaderItem>
            <StyleForm>
              <StyleImage>
                {data.thumbnail ? (
                  <StyleAddImage>
                    <img src={data?.thumbnail} alt="" />
                  </StyleAddImage>
                ) : (
                  <StyleAddImage>
                    <AddPhotoAlternateIcon
                      sx={{
                        width: '500px !important',
                        height: '300px !important',
                        color: '#1976d2',
                      }}
                    />
                  </StyleAddImage>
                )}
              </StyleImage>
              <StyleContentRight className="d-flex input-views">
                <InputField
                  label="Tiêu đề "
                  multiline={true}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('title')}
                  variant="outlined"
                />
                <InputField
                  label="Nội dung ngắn"
                  multiline={true}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('description')}
                />
                <InputField
                  label="Link"
                  multiline={true}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register('link')}
                />
                <InputField
                  label="Độ ưu tiên của bài viết"
                  autoCompleted="famili-name"
                  multiline={true}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: '150px !important',
                    float: 'left',
                  }}
                  {...register('priority')}
                />
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ paddingTop: '10px', gap: '8px' }}
                >
                  <Button
                    variant="contained"
                    onClick={() => navigate(`/marketing/news/edit/${data.id}`)}
                    className="bgr-global"
                  >
                    Chỉnh sửa
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => navigate('/marketing/news')}
                  >
                    Trở lại
                  </Button>
                </Stack>
              </StyleContentRight>
            </StyleForm>
          </Item>
        </Grid>
      </Grid>
    )
  )
}

export default DetailNews
