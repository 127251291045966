import React from 'react'
import PartTimeStaffList from './components/PartTimeStaffList'

const PartTimeStaff = () => {
  return (
    <>
      <PartTimeStaffList />
    </>
  )
}

export default PartTimeStaff
