import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, MenuItem, Stack, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Controller, useForm } from 'react-hook-form'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import Breadcrumb from 'components/Breadcrumbs'
import InputField from 'components/FormControl/InputField'
import Item from 'components/Item'
import LoadingVersion2 from 'components/LoadingV2'
import BasicModal from 'components/Modal'
import { ORDER_EDIT } from 'constants/breadcrumbs'
import { CMS } from 'constants/common'
import { paymentMethod, status, statusPayment } from 'constants/order'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import orderAPI from 'services/orderAPI'
import { checkChangeData } from 'utils/confirmBox'
import { orderEditSchema_ } from 'validations/orderEditSchema'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {
  StyleActionButton,
  StyleContentItem,
  StyleContentLeft,
  StyleContentRight,
  StyleError,
  StyleForm,
  StyleHeaderItem,
  StyleInput,
  StyleInputField,
  StylePrice,
  StyleTitle,
  StyleTitleDescribe,
} from './style'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

const weeklyRepeat = [
  { value: 2, title: 'Thứ 2' },
  { value: 3, title: 'Thứ 3' },
  { value: 4, title: 'Thứ 4' },
  { value: 5, title: 'Thứ 5' },
  { value: 6, title: 'Thứ 6' },
  { value: 7, title: 'Thứ 7' },
  { value: 8, title: 'Chủ Nhật' },
]

const OrderEdit = () => {
  const [checked, setChecked] = useState<string[]>([])
  const [isPet, setIsPet] = useState<boolean>()
  const [isPetError, setIsPetError] = useState('')
  const [isReason, setIsReason] = useState<boolean>()
  const [isReasonError, setIsReasonError] = useState('')
  const [checkedDefault, setCheckedDefault] = useState<string[]>([])
  const [premiumChecked, setPremiumChecked] = useState<string[]>([])
  const [premiumCheckedDefault, setPremiumCheckedDefault] = useState<string[]>(
    []
  )
  const [data, setData] = useState<IOrder>()
  const [serviceItem, setServiceItem] = useState<any>()
  const [hourPeriods, setHourPeriods] = useState<any>()
  const [serviceId, setServiceId] = useState()
  const [startTime, setStartTime] = useState<any>()
  const [endTime, setEndTime] = useState('')
  const [isWeeklyRepeat, setIsWeeklyRepeat] = useState<boolean>()
  const [selectedWeedkly, setSelectedWeedkly] = useState<any>()
  const [selectedWeedklyDefault, setSelectedWeedklyDefault] = useState<any>()
  const [locationId, setLocationId] = useState()
  const [valuePayment, setValuePayment] = useState('')
  const [longitude, setLongitude] = useState()
  const [latitude, setLatitudet] = useState()
  const [valueStatus, setValueStatus] = useState('')
  const [valueStatusPay, setValueStatusPay] = useState('')
  const [valueService, setValueService] = useState('')
  const [dataServicesItem, setDataServicesItem] = useState([])
  const [grandTotalPrice, setGrandTotalPrice] = useState<any>()
  const [discountPrice, setDiscountPrice] = useState<any>()
  const [orderServiceItemId, setOrderServiceItemId] = useState()
  const [valueServiceItem, setValueServiceItem] = useState('')
  const [promotion, setPromotion] = useState('')
  const navigate = useNavigate()
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertSuccess, setAlertError } = useAlert()
  const { id } = useParams()
  const [dataDefault, setDataDefault] = useState<any>()
  const [statusFormat, setStatusFormat] = useState([])
  const [isCalc, setIsCalc] = useState(false)
  const [modalCancel, setModaCancel] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    resolver: yupResolver(orderEditSchema_),
    mode: 'onChange',
  })
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(localizedFormat)
  dayjs.tz.setDefault('Asia/Ho_Chi_Minh')
  const fetchDataServicesItem = async () => {
    const { data } = await orderAPI.getServiceById(valueService as string)
    setDataServicesItem(data?.serviceItems)
  }
  const isSelectCalc = getValues()

  const fetchDataCalc = async () => {
    const getValueCalc = getValues()
    getValueCalc.serviceId = valueService
    getValueCalc.serviceItems = [
      { id: valueServiceItem, count: 1, itemOptions: premiumChecked },
    ]
    getValueCalc.serviceOptions = checked
    const weeklyIds: string[] = []
    if (selectedWeedkly) {
      selectedWeedkly?.map((weekly: any) => {
        return weeklyIds.push(`${weekly.value}`)
      })
    }
    const date = dayjs
      .utc(getValueCalc.date)
      .tz('Asia/Ho_Chi_Minh')
      .format('YYYY-MM-DD')

    const dataTotalOrder: any = await orderAPI.createCalcOrder({
      serviceId: serviceId,
      promotionCode: promotion?.toUpperCase(),
      serviceItems: [
        {
          id: valueServiceItem,
          count: 1,
          itemOptions: premiumChecked,
        },
      ],
      locationId: locationId,
      serviceOptions: checked,
      note: getValueCalc.note,
      fullLocation: getValueCalc.location,
      locationPoint: {
        longitude: longitude,
        latitude: latitude,
      },
      orderProfile: {
        name: getValueCalc.contactName,
        phone: getValueCalc.contactPhone,
      },
      serviceType: 'simple',
      startTime: new Date(`${date} ${startTime}`).toISOString(),
      endTime: new Date(`${date} ${endTime}`).toISOString(),
      weeklyRepeat: weeklyIds,
      workDateTimes: [],
      ignoreTimes: [],
      isRemember: false,
      paymentMethod: valuePayment,
    })
    setGrandTotalPrice(dataTotalOrder?.data?.grandTotalPrice)
    setDiscountPrice(dataTotalOrder?.data?.discountPrice)
  }

  const checkOtherCondition = () => {
    const isServices =
      selectedWeedkly.length === selectedWeedklyDefault.length &&
      selectedWeedkly.every(
        (value: any, index: number) => value === selectedWeedklyDefault[index]
      )
    const isChecked = checked.every(
      (element, index) => element === checkedDefault[index]
    )
    const isPremiumChecked = premiumChecked.every(
      (element, index) => element === premiumCheckedDefault[index]
    )
    if (!isChecked || !isPremiumChecked || !isServices) {
      return true
    }
    return false
  }
  const handlePromotionChange = (event: any) => {
    setTimeout(() => {
      setPromotion(event.target.value)
    }, 500)
  }
  const handleClose = () => setModaCancel(false)
  const handleCheckDataCancel = () => {
    const getValuesItem = getValues()
    delete getValuesItem.contactName
    delete getValuesItem.contactPhone
    delete getValuesItem.fullLocation
    const isChanged = checkChangeData({
      dataChange: getValuesItem,
      dataDefault: dataDefault,
    })
    const checkOrther = checkOtherCondition()
    if (isChanged || checkOrther) {
      setModaCancel(true)
    } else {
      navigate('/order')
    }
  }
  const handleToggle = (value: any, name: any) => {
    if (name == 'pet' && checked.indexOf(value)) {
      setIsPet(true)
    } else {
      setIsPet(false)
    }
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }
  const handleChangePayment = (event: React.SyntheticEvent, newValue: any) => {
    setValuePayment(newValue?.props?.value)
  }
  const handleChangeService = (event: React.SyntheticEvent, newValue: any) => {
    setValueService(newValue?.props?.value)
  }
  const handleChangeStatus = (event: React.SyntheticEvent, newValue: any) => {
    setValueStatus(newValue?.props?.value)
  }
  const handleChangeServiceItem = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setIsCalc(true)
    setValueServiceItem(newValue?.props?.value)
  }

  const handleHourPlus = (value: any) => {
    const timeArray = value?.split(':')
    const hour = parseInt(timeArray?.[0])
    const minute = parseInt(timeArray?.[1])
    const date = new Date()
    date.setHours(hour + hourPeriods)
    date.setMinutes(minute)
    const result = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })
    if (result.includes('PM') || result.includes('AM')) {
      const endTime = dayjs(result, 'hh:mm A').locale('vi').format('HH:mm')
      setEndTime(endTime)
    } else {
      setEndTime(result)
    }
  }

  const handlePremium = (value: any) => {
    const currentIndex = premiumChecked?.indexOf(value)
    const newChecked = [...premiumChecked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setPremiumChecked(newChecked)
  }
  const handlePetChange = (e: any) => {
    if (e.target.value) {
      // if (!/^[\p{L}\s']+$/u.test(e.target.value)) {
      //   setIsPetError('Vật nuôi không bao gồm số và ký tự đặc biệt')
      // } else {
      setIsPetError('')
      // }
    } else {
      setIsPetError('Vật nuôi không được để trống')
    }
  }
  const handleReasonChange = (e: any) => {
    if (e.target.value) {
      setIsReasonError('')
    } else {
      setIsReasonError('Lý do huỷ không được để trống')
    }
  }
  const onSubmit = async (value: any) => {
    if (isPet) {
      if (value.pet) {
        // if (!/^[\p{L}\s']+$/u.test(value.pet)) {
        //   setIsPetError('Vật nuôi không bao gồm số và ký tự đặc biệt')
        //   return
        // } else {
        setIsPetError('')
        // }
      } else {
        setIsPetError('Vật nuôi không được để trống')
        return
      }
    }
    if (isReason) {
      if (!value.reason) {
        setIsReasonError('Lý do huỷ không được để trống')
        return
      }
    }
    setIsPetError('')
    setIsReasonError('')
    setLoadingTrue()
    const weeklyIds: number[] = []
    if (selectedWeedkly) {
      selectedWeedkly.map((weekly: any) => {
        return weeklyIds.push(weekly.value)
      })
    }

    const date = dayjs
      .utc(value.date)
      .tz('Asia/Ho_Chi_Minh')
      .format('YYYY-MM-DD')

    try {
      await orderAPI.updateOrder(
        {
          serviceId: serviceId,
          staffId: 'aa9c461d-7687-48ec-8bde-e6f15c0205a3',
          promotionCode: promotion ? promotion.toUpperCase() : '',
          serviceItems: [
            {
              orderServiceItemId: orderServiceItemId,
              id: valueServiceItem,
              count: 1,
              itemOptions: premiumChecked,
            },
          ],
          serviceOptions: checked,
          note: value.note,
          fullLocation: value.location,
          locationPoint: {
            longitude: longitude,
            latitude: latitude,
          },
          orderProfile: {
            name: value.contactName,
            phone: value.contactPhone,
          },
          serviceType: 'simple',
          startTime: new Date(`${date} ${startTime}`).toISOString(),
          endTime: new Date(`${date} ${endTime}`).toISOString(),
          weeklyRepeat: weeklyIds,
          workDateTimes: [],
          ignoreTimes: [],
          isRemember: false,
          paymentMethod: valuePayment,
          status: valueStatus,
        },
        id as string
      )
      setLoadingFalse()
      setAlertSuccess('Sửa đơn hàng thành công!')
      navigate('/order')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoadingFalse()
      setAlertError(error.response?.data?.message)
    }
  }
  useEffect(() => {
    const statusRespone: any = status.filter(
      (item: any) => item?.value == 'rejected' || item?.value == valueStatus
    )
    setStatusFormat(statusRespone)
  }, [data])

  useEffect(() => {
    fetchDataServicesItem()
  }, [valueService])

  useEffect(() => {
    const fetchOrder = async () => {
      const respone = await orderAPI.getOrderId(id as string)
      setData(respone.data.order)
      setValueServiceItem(
        respone?.data?.order?.serviceItems?.[0]?.serviceItemId
      )
      setLocationId(respone?.data?.locationId)
      setValuePayment(respone?.data?.order?.paymentMethod)
      setValueStatus(respone?.data?.order?.status)
      setValueStatusPay(respone?.data?.order?.paymentStatus)
      setLongitude(respone?.data?.order?.locationPoint?.coordinates?.[0])
      setLatitudet(respone?.data?.order?.locationPoint?.coordinates?.[1])
      setGrandTotalPrice(respone?.data?.order?.grandTotalPrice)
      setServiceId(respone?.data?.order?.serviceId)
      setOrderServiceItemId(respone?.data?.order?.serviceItems?.[0]?.id)
      setPromotion(respone?.data?.order?.promotionCode)
      setHourPeriods(respone?.data?.order?.serviceItems?.[0]?.hourPeriods)
      setStartTime(dayjs(respone?.data?.order?.startTime).format('HH:mm'))
      setValueService(respone.data?.order.serviceId)
      setEndTime(
        dayjs(respone?.data?.order?.endTime).locale('vi').format('HH:mm')
      )
      setSelectedWeedkly(
        weeklyRepeat?.filter((item: any) =>
          respone?.data?.order?.weeklyRepeat?.includes(item.value)
        )
      )
      // setSelectedWeedkly(respone?.data?.order?.weeklyRepeat)
      setValue('date', respone?.data?.order?.startTime)
      setValue('fullLocation', respone?.data?.order?.fullLocation)
      setValue('contactPhone', respone?.data?.order?.orderProfile?.phone)
      setValue('contactName', respone?.data?.order?.orderProfile?.name)
      if (
        respone?.data?.order?.serviceItems?.[0]?.itemOptions?.[0]
          ?.serviceExtraOptionId
      ) {
        setPremiumChecked([
          respone?.data?.order?.serviceItems?.[0]?.itemOptions?.[0]
            ?.serviceExtraOptionId,
        ])
      }
      setDiscountPrice(respone?.data?.order?.discountPrice)
      setChecked(
        respone.data.order?.serviceOptions?.map(
          (item: any) => item.serviceExtraOptionId
        )
      )
      if (respone.data.order?.createdBy?.platform === CMS) {
        setIsWeeklyRepeat(true)
      }
    }
    fetchOrder()
  }, [id])
  useEffect(() => {
    if (data) {
      const serviceId = data.serviceId
      const fetchServiceDetail = async () => {
        const { data } = await orderAPI.getServiceById(serviceId as string)
        if (data) {
          setServiceItem(data)
        }
      }
      fetchServiceDetail()
    }
  }, [data])
  useEffect(() => {
    if (data) {
      setDataDefault({
        date: dayjs(data.startTime).format('YYYY-MM-DD'),
        note: data.note ? data.note : '',
        promotion: data.promotionCode,
        serviceItems: valueServiceItem,
        startTime: dayjs(data.startTime).format('HH:mm'),
        status: data.status,
        statusPayment: data.paymentStatus,
      })
      setPremiumCheckedDefault([
        data?.serviceItems?.[0]?.itemOptions?.[0]?.serviceExtraOptionId,
      ])
      setCheckedDefault(
        data?.serviceOptions?.map((item: any) => item.serviceExtraOptionId)
      )
      setSelectedWeedklyDefault(
        weeklyRepeat?.filter((item: any) =>
          data?.weeklyRepeat?.includes(item.value)
        )
      )
    }
  }, [data])
  useEffect(() => {
    fetchDataCalc()
  }, [isSelectCalc, checked, premiumChecked, valueServiceItem, promotion])

  useEffect(() => {
    if (valueServiceItem) {
      const hour: any = dataServicesItem?.filter((item: any) =>
        valueServiceItem?.includes(item.id)
      )
      if (hour?.[0]) {
        setHourPeriods(hour?.[0]?.hourPeriods)
      }
    }
  }, [valueServiceItem])
  useEffect(() => {
    if (hourPeriods) {
      handleHourPlus(startTime)
    }
  }, [hourPeriods])
  useEffect(() => {
    if (valueStatus === 'rejected') {
      setIsReason(true)
    } else {
      setIsReason(false)
      setIsReasonError('')
    }
  }, [valueStatus])
  useEffect(() => {
    if (valueStatus === 'rejected' || valueStatus === 'done') {
      navigate('/order')
    }
  }, [data])
  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={ORDER_EDIT} />
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <StyleHeaderItem>
            <StyleTitle>Sửa đơn hàng</StyleTitle>
          </StyleHeaderItem>
          {data ? (
            <>
              <StyleHeaderItem>
                <StyleTitle
                  style={{ fontSize: '20px' }}
                >{`Đơn hàng : ${data.displayId}`}</StyleTitle>
              </StyleHeaderItem>
              <StyleForm autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <StyleContentRight>
                  <StyleContentItem>
                    <StyleTitleDescribe style={{ paddingBottom: '10px' }}>
                      Thông tin khách hàng
                    </StyleTitleDescribe>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ width: '48%' }}>
                        <TextField
                          size="small"
                          fullWidth
                          inputProps={{
                            readOnly: true,
                          }}
                          className="input-views"
                          label="Số điện thoại KH"
                          defaultValue={data.user?.phone}
                        />
                      </div>
                      <div style={{ width: '48%' }}>
                        <TextField
                          size="small"
                          fullWidth
                          inputProps={{
                            readOnly: true,
                          }}
                          label="Khách hàng"
                          className="input-views"
                          defaultValue={data.user?.fullName}
                        />
                      </div>
                    </div>
                  </StyleContentItem>
                  <StyleContentItem>
                    <StyleTitleDescribe>Thông tin dịch vụ</StyleTitleDescribe>
                    <InputField
                      size="small"
                      label="Địa chỉ làm việc"
                      className="input-views"
                      {...register('fullLocation')}
                      inputProps={{ readOnly: true }}
                      defaultValue={data.fullLocation}
                    />
                    <div style={{ width: '100%' }}>
                      {valueService && (
                        <InputField
                          size="small"
                          id="serviceId"
                          className="input-views"
                          label="Tên dịch vụ"
                          value={data.rootServiceDetail?.name}
                          inputProps={{ readOnly: true }}
                          sx={{
                            width: '100%',
                          }}
                          onChange={handleChangeService}
                        ></InputField>
                      )}
                    </div>
                    <StyleInput>
                      <StyleInputField>
                        <InputField
                          size="small"
                          className="input-views"
                          label="Số điện thoại"
                          {...register('contactPhone')}
                          inputProps={{ readOnly: true }}
                          defaultValue={data.orderProfile?.phone}
                        />
                      </StyleInputField>
                      <StyleInputField>
                        <InputField
                          size="small"
                          className="input-views"
                          label="Tên liên lạc"
                          {...register('contactName')}
                          inputProps={{ readOnly: true }}
                          defaultValue={data.orderProfile?.name}
                        />
                      </StyleInputField>
                    </StyleInput>
                  </StyleContentItem>
                  <StyleContentItem style={{ paddingBottom: '0' }}>
                    <StyleTitleDescribe>
                      Thời lượng và thời gian
                    </StyleTitleDescribe>
                    <div style={{ width: '100%' }}>
                      <InputField
                        size="small"
                        id="timeDes"
                        label="Thời lượng"
                        select
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={valueServiceItem}
                        {...register('serviceItems')}
                        sx={{
                          width: '100%',
                        }}
                        onChange={handleChangeServiceItem}
                      >
                        {dataServicesItem?.map((option: any) => (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.name} {option?.shortDescription}
                          </MenuItem>
                        ))}
                      </InputField>
                      {errors.timeDes && (
                        <StyleError>
                          {errors.timeDes.message as string}
                        </StyleError>
                      )}
                    </div>
                    <StyleInput style={{ paddingBottom: '10px' }}>
                      <StyleInputField
                        style={{
                          paddingTop: '10px',
                        }}
                      >
                        <Controller
                          control={control}
                          name="date"
                          defaultValue={dayjs(data.startTime).format(
                            'YYYY-MM-DD'
                          )}
                          render={({
                            field: { onChange, ref, onBlur, name, ...field },
                          }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                minDate={new Date().toISOString()}
                                maxDate={dayjs()
                                  .add(6, 'day')
                                  .format('YYYY-MM-DD')}
                                {...field}
                                inputRef={ref}
                                inputFormat="DD/MM/YYYY"
                                label="Ngày bắt đầu"
                                renderInput={(inputProps) => (
                                  <TextField
                                    {...inputProps}
                                    size="small"
                                    onBlur={onBlur}
                                    name={name}
                                    error={false}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{ readOnly: true }}
                                    value={dayjs(field.value)
                                      .format('dddd, DD/MM/YYYY')
                                      .replace(/^\w/, (c) => c.toUpperCase())}
                                  />
                                )}
                                onChange={(e: any) => {
                                  onChange(e)
                                }}
                              />
                            </LocalizationProvider>
                          )}
                        />
                        {errors.date && (
                          <StyleError>
                            {errors.date.message as string}
                          </StyleError>
                        )}
                      </StyleInputField>
                      <StyleInputField>
                        <InputField
                          type="time"
                          size="small"
                          {...register('startTime')}
                          label="Giờ bắt đầu"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={dayjs(data.startTime)
                            .locale('vi')
                            .format('HH:mm')}
                          onChange={(e: any) => {
                            handleHourPlus(e.target.value)
                            setStartTime(e.target.value)
                          }}
                        />
                        {errors.startTime && (
                          <StyleError>
                            {errors.startTime.message as string}
                          </StyleError>
                        )}
                      </StyleInputField>
                      {endTime && (
                        <StyleInputField>
                          <InputField
                            size="small"
                            type="time"
                            label="Giờ kết thúc"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{ readOnly: true }}
                            value={endTime}
                          />
                        </StyleInputField>
                      )}
                    </StyleInput>
                    <Autocomplete
                      sx={{ padding: '10px 0' }}
                      size="small"
                      multiple
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value?.value
                      }
                      disableCloseOnSelect
                      className="input-views"
                      options={weeklyRepeat}
                      getOptionLabel={(option: any) => option.title}
                      noOptionsText="Danh sách rỗng"
                      defaultValue={selectedWeedkly}
                      readOnly={isWeeklyRepeat}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox checked={selected} />
                          {option.title}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Lặp lại hằng tuần" />
                      )}
                      onChange={(event, value: any) => {
                        setSelectedWeedkly([...value])
                      }}
                    />
                  </StyleContentItem>
                  <StyleContentItem
                    style={{ paddingBottom: '0', marginTop: '24px' }}
                  >
                    <StyleTitleDescribe>Lựa chọn thêm</StyleTitleDescribe>
                    <List
                      sx={{
                        width: '100%',
                        paddingBottom: '0',
                      }}
                    >
                      {serviceItem?.serviceItems?.[0]?.itemOptions?.map(
                        (value: any) => {
                          const labelId = `${value?.id}`
                          return (
                            <ListItem key={value?.id} disablePadding>
                              <ListItemButton
                                sx={{ padding: '0', fontSize: '16px' }}
                                role={undefined}
                                onClick={() => handlePremium(value?.id)}
                                dense
                              >
                                <ListItemIcon sx={{ minWidth: '0' }}>
                                  <Checkbox
                                    edge="start"
                                    checked={
                                      premiumChecked?.indexOf(value?.id) !== -1
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={` ${value.name}`}
                                />
                              </ListItemButton>
                            </ListItem>
                          )
                        }
                      )}
                    </List>
                    <List
                      sx={{
                        width: '100%',
                        paddingTop: '0',
                      }}
                    >
                      {serviceItem?.serviceExtraOptions?.map((value: any) => {
                        const labelId = `checkbox-list-label-${value.id}`
                        return (
                          <>
                            <ListItem key={value.id} disablePadding>
                              <ListItemButton
                                sx={{ padding: '0', fontSize: '16px' }}
                                role={undefined}
                                onClick={() =>
                                  handleToggle(value.id, value.key)
                                }
                                dense
                                disabled={
                                  data.createdBy?.platform === CMS &&
                                  (value.key == 'prioritize' ||
                                    value.key == 'manualSelect')
                                    ? true
                                    : false
                                }
                              >
                                <ListItemIcon sx={{ minWidth: '0' }}>
                                  <Checkbox
                                    edge="start"
                                    checked={checked?.indexOf(value.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={` ${value.name}`}
                                />
                              </ListItemButton>
                            </ListItem>
                            {value.key == 'pet' &&
                            checked.indexOf(value.id) !== -1 ? (
                              <>
                                <InputField
                                  size="small"
                                  {...register('pet')}
                                  label="Vật nuôi"
                                  multiline={true}
                                  onChange={(e: any) => handlePetChange(e)}
                                />
                                {isPetError && (
                                  <StyleError>{isPetError}</StyleError>
                                )}
                              </>
                            ) : null}
                          </>
                        )
                      })}
                    </List>
                  </StyleContentItem>
                  <StyleContentItem>
                    <InputField
                      size="small"
                      {...register('note')}
                      label="Ghi chú"
                      multiline={true}
                      defaultValue={data.note}
                    />
                    {errors.note && (
                      <StyleError>{errors.note.message as string}</StyleError>
                    )}
                  </StyleContentItem>
                  <StyleContentItem
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <StyleTitleDescribe>
                      Thông tin thanh toán
                    </StyleTitleDescribe>

                    <div style={{ width: '35%' }}>
                      <InputField
                        size="small"
                        id="serviceId"
                        label="Phương thức thanh toán"
                        select
                        className="input-views"
                        inputProps={{
                          readOnly: true,
                        }}
                        value={valuePayment}
                        sx={{
                          width: '100%',
                        }}
                        onChange={handleChangePayment}
                      >
                        {paymentMethod?.map((option: any) => (
                          <MenuItem key={option?.value} value={option?.value}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </InputField>
                    </div>
                    <StyleInputField style={{ width: '35%' }}>
                      <InputField
                        className="upperCase"
                        size="small"
                        {...register('promotion')}
                        label="Mã khuyến mãi"
                        multiline={true}
                        defaultValue={data.promotionCode}
                        onChange={handlePromotionChange}
                      />
                      {errors.promotion && (
                        <StyleError>
                          {errors.promotion.message as string}
                        </StyleError>
                      )}
                    </StyleInputField>
                    <StyleInput
                      style={{ justifyContent: 'space-between', width: '35%' }}
                    >
                      Số tiền được giảm:{' '}
                      <StylePrice>
                        {(discountPrice as number).toLocaleString('vi-VN', {
                          style: 'currency',
                          currency: 'VND',
                        })}
                      </StylePrice>
                    </StyleInput>
                    <StyleInput
                      style={{ justifyContent: 'space-between', width: '35%' }}
                    >
                      Thu thêm tiền mặt: <StylePrice></StylePrice>
                    </StyleInput>
                    <StyleInput
                      style={{ justifyContent: 'space-between', width: '35%' }}
                    >
                      Tổng tiền:{' '}
                      <StylePrice>
                        {' '}
                        {(grandTotalPrice as number).toLocaleString('vi-VN', {
                          style: 'currency',
                          currency: 'VND',
                        })}
                      </StylePrice>
                    </StyleInput>
                  </StyleContentItem>
                  <StyleActionButton>
                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{
                        paddingTop: '20px',
                        gap: '8px',
                        position: 'relative',
                      }}
                    >
                      <Button variant="contained" type="submit">
                        Lưu
                      </Button>
                      <Button
                        variant="outlined"
                        // onClick={() => setOpenModalCancel(true)}
                        onClick={handleCheckDataCancel}
                      >
                        Hủy
                      </Button>
                    </Stack>
                  </StyleActionButton>
                </StyleContentRight>
                <StyleContentLeft>
                  <StyleContentItem>
                    <StyleTitleDescribe>Thông tin đơn hàng</StyleTitleDescribe>
                    <div style={{ width: '100%' }}>
                      <InputField
                        size="small"
                        id="serviceId"
                        label="Trạng thái đơn hàng"
                        select
                        value={valueStatus}
                        {...register('status')}
                        sx={{
                          width: '100%',
                        }}
                        onChange={handleChangeStatus}
                      >
                        {statusFormat?.map((item: any) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </InputField>
                      {errors.status && (
                        <StyleError>
                          {errors.status.message as string}
                        </StyleError>
                      )}
                    </div>
                    <div style={{ width: '100%' }}>
                      <InputField
                        size="small"
                        id="serviceId"
                        label="Trạng thái thanh toán"
                        className="input-views"
                        select
                        value={valueStatusPay}
                        {...register('statusPayment')}
                        sx={{
                          width: '100%',
                        }}
                        inputProps={{ readOnly: true }}
                        SelectProps={{ IconComponent: () => null }}
                      >
                        {statusPayment?.map((item: any) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </InputField>
                    </div>
                    <StyleInput>
                      <StyleInputField>
                        {data.staffs
                          .filter(
                            (item: any) =>
                              item?.staffOrderStatus?.status === 'assigned'
                          )
                          .map((staff: any, index: number) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '10px',
                              }}
                            >
                              <div
                                style={{ width: '48%', paddingBottom: '10px' }}
                              >
                                <TextField
                                  size="small"
                                  fullWidth
                                  className="input-views"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  label="Số điện thoại NV"
                                  defaultValue={staff?.phone}
                                />
                              </div>
                              <div style={{ width: '48%' }}>
                                <TextField
                                  size="small"
                                  fullWidth
                                  className="input-views"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  label="Nhân viên"
                                  defaultValue={staff?.fullName}
                                />
                              </div>
                            </div>
                          ))}
                      </StyleInputField>
                    </StyleInput>
                    <StyleInput style={{ justifyContent: 'space-between' }}>
                      Thời gian bắt đầu: --:--
                    </StyleInput>
                    <StyleInput style={{ justifyContent: 'space-between' }}>
                      Thời gian kết thúc: --:--
                    </StyleInput>

                    {valueStatus === 'rejected' && (
                      <div style={{ width: '100%', marginTop: '10px' }}>
                        <InputField
                          size="small"
                          id="serviceId"
                          label="Lý do hủy"
                          {...register('reason')}
                          className="input-views"
                          SelectProps={{ IconComponent: () => null }}
                          sx={{
                            width: '100%',
                          }}
                          onChange={(e: any) => handleReasonChange(e)}
                        />

                        {isReasonError && (
                          <StyleError>{isReasonError}</StyleError>
                        )}
                      </div>
                    )}
                  </StyleContentItem>
                </StyleContentLeft>
              </StyleForm>
            </>
          ) : (
            <LoadingVersion2 />
          )}
        </Item>
      </Grid>
      <BasicModal open={modalCancel} handleClose={handleClose}>
        <Typography>Bạn có chắc chắn muốn hủy thay đổi?</Typography>
        <Button
          sx={{
            margin: '20px',
            marginBottom: '0px',
            paddingLeft: '43px',
            paddingRight: '43px',
          }}
          variant="contained"
          onClick={() => navigate('/order')}
          className="bgr-global"
        >
          Hủy
        </Button>
        <Button
          sx={{
            margin: '20px',
            marginBottom: '-0.5px',
            paddingLeft: '30px',
            paddingRight: '30px',
          }}
          onClick={() => setModaCancel(false)}
          variant="outlined"
        >
          Không
        </Button>
      </BasicModal>
    </Grid>
  )
}

export default OrderEdit
