import AddCircleIcon from '@mui/icons-material/AddCircle'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import TreeItem from '@mui/lab/TreeItem'
import TreeView from '@mui/lab/TreeView'
import {
  Avatar,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { SelectChangeEvent } from '@mui/material/Select'
import Breadcrumb from 'components/Breadcrumbs'
import { intersection, not, union } from 'components/FilterArea'
import InputSearch from 'components/InputSearch'
import Item from 'components/Item'
import { PART_TIME_STAFF } from 'constants/breadcrumbs'
import { ROWS_PER_PAGE_OPTIONS } from 'constants/common'
import {
  FilterStaffType,
  aspectStatus,
  filterDateTime,
  rankType,
  workStatus,
} from 'constants/partTimeStaff'
import useDebounce from 'hooks/useDebounce'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import rangeOfActiveApi from 'services/houseCleaningAPI'
import staffApi from 'services/staffAPI'
import { ExportToExcel } from 'utils/exportToExcel'
import {
  StyleAvatarList,
  StyleFilter,
  StyleFilterAll,
  StyleFilterItem,
  StyleFilterItemSearch,
  StyleHeaderButton,
  StyleHeaderItem,
  StyleHeaderItemFilter,
  StyleLabel,
  StyleLink,
  StyleLinks,
  StyleTableCell,
  StyleTableCellItem,
  StyleTableHeader,
  StyleTitle,
} from './style'

import { DatePicker } from 'antd'
import dayjs from 'dayjs'
const { RangePicker } = DatePicker

import MenuList from '@mui/material/MenuList'
import Edit from 'components/Icons/components/Edit'
import { IObjectRank, rank } from 'utils/rankPartTimeStaff'
import { useAlert } from 'contexts/AlertContext'

const PartTimeStaffList = () => {
  const [dates, setDates] = useState<any>([])
  const [labelId, setLabelId] = useState('')
  //List staff
  const [staff, setStaff] = useState([])
  const [hours, setHours] = useState<any>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [count, setCount] = useState(0)
  const navigate = useNavigate()
  const [valuePromoType, setValuePromoType] = useState(1)
  const { setAlertError, setAlertSuccess } = useAlert()

  //Filter services
  const [listServices, setListServices] = useState([])
  const [valueServices, setValueServices] = useState('')

  //Filter rank
  const [valueRanks, setValueRanks] = useState('')

  //Filter Work Status
  const [valueWorkStatus, setValueWorkStatus] = useState('')

  // Filter aspect Status
  const [valueAspectStatus, setValueAspectStatus] = useState('')

  //Filter Date Time
  const [valueDateTime, setValueDateTime] = useState('')

  //Search
  const [keySearch, setKeySearch] = useState('')

  // List data fake
  const [data, setData] = useState([])
  const [dataFilter, setDataFilter] = useState([])
  const [expanded, setExpanded] = React.useState<string[]>([])

  // Filter button
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [subMenuAnchorEl, setSubMenuAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const handleSubMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setSubMenuAnchorEl(event.currentTarget)
  }

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null)
  }

  //Filter Date Time
  const [value, setValue] = useState('')

  const handleChange = (event: any) => {
    const inputValue = event.target.value
    setValue(inputValue)
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const [number1, number2] = value.split(' ')
    setHours({ from: number1, to: number2 })
  }

  //listServices
  const fetchDataListService = async () => {
    const { data } = await staffApi.getService()
    setListServices(data)
  }
  const handleChangeServices = (event: SelectChangeEvent, newValue: any) => {
    setValueServices(newValue?.props?.value)
  }

  //listRank
  const handleChangeRanks = (event: SelectChangeEvent, newValue: any) => {
    setValueRanks(newValue?.props?.value)
  }

  //listWorkStatus
  const handleChangeWorkStatus = (event: SelectChangeEvent, newValue: any) => {
    setValueWorkStatus(newValue?.props?.value)
  }

  //list Aspect Status
  const handleChangeAspectStatus = (
    event: SelectChangeEvent,
    newValue: any
  ) => {
    setValueAspectStatus(newValue?.props?.value)
  }

  //listDateTime
  const handleChangeDateTime = (event: SelectChangeEvent, newValue: any) => {
    setValueDateTime(newValue?.props?.value)
  }

  //handle Search
  const handleSearch = (event: SelectChangeEvent) => {
    setKeySearch(event.target.value as string)
  }
  const debouncedValue = useDebounce<string>(keySearch, 500)

  //Logic filter area checkbox
  const [checked, setChecked] = React.useState<readonly number[]>([])
  const duplicates = dataFilter.filter((item) => checked.includes(item))

  //Logic filter area list data fake
  const fetchDataTest = async () => {
    const { data } = await rangeOfActiveApi.getRangeOfActive({
      // serviceId: paramIds.serviceId,
      serviceId: 'd7ee6fa9-2cc6-4568-94ab-f7a0953bb9d6',
    })
    setData(data)

    const districtsId = data[0]?.province?.map((item: any) =>
      item?.districts?.map((dist: any) => dist?.district?.id)
    )
    const mergedArr = [].concat(...districtsId)

    setDataFilter(mergedArr)

    const nodeIds = data?.map((itemCountries: any) => {
      const ProvinceIds = itemCountries?.province?.map(
        (itemProvinces: any) => itemProvinces?.provinceId
      )

      return ProvinceIds.concat(itemCountries.countryId)
    })
    setExpanded(nodeIds[0])
  }

  const handleToggleTree = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const handleExpandClick = () => {
    const nodeIds = data?.map((itemCountries: any) => {
      const ProvinceIds = itemCountries?.province?.map(
        (itemProvinces: any) => itemProvinces?.provinceId
      )

      return ProvinceIds.concat(itemCountries.countryId)
    })
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? nodeIds[0] : []))
  }

  // Logic checkbox
  const handleToggleCheckbox = (value: number) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = (items: readonly number[]) =>
    intersection(checked, items).length

  const handleToggleAll = (items: readonly number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const fetchDataPartTimeStaff = async (page: number) => {
    const { data } = await staffApi.getStaff({
      page: page + 1,
      limit: rowsPerPage,
      rank: valueRanks,
      search: keySearch,
      service: valueServices,
      district: duplicates,
      status: valueWorkStatus,
      activeStatus: valueAspectStatus,
      birthStartDate:
        valueDateTime == FilterStaffType.BD ? dates?.[0]?.start : '',
      birthEndDate: valueDateTime == FilterStaffType.BD ? dates?.[0]?.end : '',
      startStartDate:
        valueDateTime == FilterStaffType.FDOW ? dates?.[0]?.start : '',
      startEndDate:
        valueDateTime == FilterStaffType.FDOW ? dates?.[0]?.end : '',
      signingStartDate:
        valueDateTime == FilterStaffType.CSD ? dates?.[0]?.start : '',
      signingEndDate:
        valueDateTime == FilterStaffType.CSD ? dates?.[0]?.end : '',

      monthlyWorkingHoursStartOfMonth:
        valueDateTime == FilterStaffType.MWH && value.trim()
          ? dayjs().startOf('month').format('YYYY-MM-DD')
          : '',
      monthlyWorkingHoursEndOfMonth:
        valueDateTime == FilterStaffType.MWH && value.trim()
          ? dayjs().endOf('month').format('YYYY-MM-DD')
          : '',
      monthlyWorkingHoursFrom:
        valueDateTime == FilterStaffType.MWH ? hours?.from : '',
      monthlyWorkingHoursTo:
        valueDateTime == FilterStaffType.MWH ? hours?.to : '',

      workingHoursLastMonthStartOfPreviousMonth:
        valueDateTime == FilterStaffType.WHLM && value.trim()
          ? dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
          : '',
      workingHoursLastMonthEndOfPreviousMonth:
        valueDateTime == FilterStaffType.WHLM && value.trim()
          ? dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
          : '',
      workingHoursLastMonthFrom:
        valueDateTime == FilterStaffType.WHLM ? hours?.from : '',
      workingHoursLastMonthTo:
        valueDateTime == FilterStaffType.WHLM ? hours?.to : '',

      accumulatedWorkingHoursOfTheYearStartOfYear:
        valueDateTime == FilterStaffType.AWHOTY && value.trim()
          ? dayjs().startOf('year').format('YYYY-MM-DD')
          : '',
      accumulatedWorkingHoursOfTheYearEndOfYear:
        valueDateTime == FilterStaffType.AWHOTY && value.trim()
          ? dayjs().endOf('year').format('YYYY-MM-DD')
          : '',
      accumulatedWorkingHoursOfTheYearFrom:
        valueDateTime == FilterStaffType.AWHOTY ? hours?.from : '',
      accumulatedWorkingHoursOfTheYearTo:
        valueDateTime == FilterStaffType.AWHOTY ? hours?.to : '',

      starFrom: valueDateTime == FilterStaffType.STAR ? hours?.from : '',
      starTo: valueDateTime == FilterStaffType.STAR ? hours?.to : '',

      label: labelId,
    })
    setStaff(data.rows)
    setCount(data.count)
    setPage(page)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    fetchDataPartTimeStaff(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const hexToRGBA = (hex: string, alpha: number): string => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  useEffect(() => {
    fetchDataListService()
  }, [])

  useEffect(() => {
    fetchDataPartTimeStaff(page)
  }, [
    page,
    rowsPerPage,
    valueRanks,
    debouncedValue,
    valueWorkStatus,
    valueAspectStatus,
    valueServices,
    valueDateTime,
    dates,
    hours,
    labelId,
  ])

  useEffect(() => {
    fetchDataTest()
  }, [])

  if (!staff) return <></>

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={PART_TIME_STAFF} />
      <Grid item xs={12}>
        <Item
          sx={{
            padding: '24px',
            width: '100%',
          }}
        >
          <StyleTableHeader>
            <StyleHeaderItem style={{ gap: '0px 24px' }}>
              <StyleHeaderItemFilter>
                <StyleHeaderButton>
                  <StyleLinks
                    onClick={() =>
                      navigate('/staff-manager/part-time-staff/create')
                    }
                  >
                    <AddCircleIcon
                      sx={{ marginRight: '10px', color: 'white' }}
                    />
                    <StyleLink>Thêm cộng tác viên</StyleLink>
                  </StyleLinks>
                  <StyleLinks
                    onClick={() => setAlertError('Tính năng sẽ sớm ra mắt!')}
                  >
                    <UploadFileOutlinedIcon
                      sx={{ marginRight: '10px', color: 'white' }}
                    />
                    <StyleLink>Thêm File Excel</StyleLink>
                  </StyleLinks>
                  <StyleLinks
                    onClick={async () => {
                      const { data } = await staffApi.getStaff({
                        limit: count,
                      })
                      ExportToExcel(
                        data?.rows?.map((item: any, index: number) => {
                          return {
                            'Số thứ tự': index + 1,
                            'Mã nhân viên': item?.displayId,
                            'Họ và tên': item?.fullName,
                            'Số điện thoại': item?.phone,
                            'CMND/CCCD/Passport': item?.citizenId,
                            'Dịch vụ': item?.services
                              ?.map(
                                (service: any) => service?.staffService?.name
                              )
                              .join(', '),
                            'Gắn nhãn': item?.staffLabel
                              ?.map((label: any) => label?.name)
                              .join(', '),
                            'Tình trạng': item?.status,
                            'Tình trạng hoạt động': item?.activeStatus,
                            'Kỹ nẵng':
                              item?.skill == null
                                ? null
                                : JSON.parse(item?.skill)
                                    ?.map((skill: any) => skill)
                                    .join(', '),
                            'Thành phố':
                              item?.districts?.[0]?.staffProvince?.fullName,
                            Quận: item?.districts?.[0]?.staffDistrict?.name,
                            'Vị trí làm việc':
                              item?.districts?.[0]?.fullLocation,
                            Hạng: rank[item?.rank as keyof IObjectRank],
                            'Số sao': item?.avgRate,
                            'Giờ công tháng': '',
                            'Giờ công tháng trước': '',
                            'Giờ công luỹ kế năm': '',
                            'Ca trống lịch': '',
                            'Ca đang làm/ ca đăng ký': '',
                            'Ngày ký hợp đồng': dayjs(item?.signingDate).format(
                              'DD/MM/YYYY'
                            ),
                            'Ngày làm việc đầu tiên': dayjs(
                              item?.startDate
                            ).format('DD/MM/YYYY'),
                            'Nhân sự phụ trách/tuyển dụng':
                              item?.staffManagers?.recruitment,
                            'Ngày sinh nhật': dayjs(item?.birth).format(
                              'DD/MM/YYYY'
                            ),
                          }
                        })
                      )
                    }}
                  >
                    <FileDownloadOutlinedIcon
                      sx={{ marginRight: '10px', color: 'white' }}
                    />
                    <StyleLink>Xuất File Excel</StyleLink>
                  </StyleLinks>
                </StyleHeaderButton>
                <div>
                  <Tooltip title="Filter">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <FilterAltIcon sx={{ width: 30, height: 30 }} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <MenuItem onClick={handleSubMenuOpen}>Kỹ năng</MenuItem>
                    <Menu
                      sx={{ top: -10, left: -10 }}
                      anchorEl={subMenuAnchorEl}
                      id="account-menu"
                      open={Boolean(subMenuAnchorEl)}
                      onClose={handleSubMenuClose}
                      // onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 15,
                            right: -5,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                    >
                      <MenuList id="1">
                        <MenuItem>Dọn dẹp</MenuItem>
                        <MenuItem>Làm kính</MenuItem>
                        <MenuItem>Nấu ăn</MenuItem>
                      </MenuList>
                    </Menu>
                    <MenuItem>Nhân sự phụ trách</MenuItem>
                    <MenuItem>Nhân sự tuyển dụng</MenuItem>
                  </Menu>
                </div>
              </StyleHeaderItemFilter>

              <StyleFilterAll>
                <StyleFilter>
                  <StyleFilterItem className="d-flex justify-center">
                    <FormControl
                      sx={{ width: 220 }}
                      size="small"
                      className="input-select"
                    >
                      <InputLabel id="demo-simple-select-label-status">
                        Dịch vụ
                      </InputLabel>
                      <Select
                        value={valueServices}
                        label="Dịch vụ"
                        onChange={handleChangeServices}
                      >
                        <MenuItem value={''}>Tất cả</MenuItem>
                        {listServices?.map((item: any) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyleFilterItem>
                  <StyleFilterItem className="d-flex justify-center">
                    <FormControl
                      sx={{ width: 220 }}
                      size="small"
                      className="input-select"
                    >
                      <InputLabel id="demo-simple-select-label-status">
                        Hạng
                      </InputLabel>
                      <Select
                        value={valueRanks}
                        label="Hạng"
                        onChange={handleChangeRanks}
                      >
                        <MenuItem value={''}>Tất cả</MenuItem>
                        {rankType?.map((item: any) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyleFilterItem>
                  {/* <StyleFilterItem className="d-flex justify-center">
                    <FormControl
                      sx={{ width: 220 }}
                      size="small"
                      className="input-select"
                    >
                      <InputLabel id="demo-simple-select-label-status">
                        Khu vực
                      </InputLabel>
                      <Select
                        value={valuePromoType}
                        label="Dịch vụ"
                        // onChange={handleChangeBannerType}
                      >
                        <div
                          // value=""
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <div style={{ display: 'flex' }}>
                            <Button onClick={handleExpandClick}>
                              {expanded.length === 0
                                ? 'Mở rộng tất cả'
                                : 'Thu gọn tất cả'}
                            </Button>
                          </div>

                          <TreeView
                            aria-label="file system navigator"
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            expanded={expanded}
                            onNodeToggle={handleToggleTree}
                            sx={{
                              flexGrow: 1,
                              overflowY: 'auto',
                              textAlign: 'left',
                            }}
                            multiSelect
                          >
                            {[]?.map(
                              (itemCountries: any, indexCountries: number) => {
                                const districtsId =
                                  itemCountries?.province?.map((item: any) =>
                                    item?.districts?.map(
                                      (dist: any) => dist?.district?.id
                                    )
                                  )
                                const mergedArr = [].concat(...districtsId)

                                const arrAll = [itemCountries.countryId]
                                  .concat(
                                    itemCountries?.province?.map(
                                      (value: any) => value.provinceId
                                    )
                                  )
                                  .concat(mergedArr)
                                return (
                                  <React.Fragment key={indexCountries}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                      }}
                                    >
                                      <Checkbox
                                        onClick={handleToggleAll(arrAll)}
                                        checked={
                                          numberOfChecked(arrAll) ===
                                            arrAll.length &&
                                          [itemCountries.countryId].concat(
                                            itemCountries?.province?.map(
                                              (value: any) => value.provinceId
                                            )
                                          ).length !== 0
                                        }
                                        indeterminate={
                                          numberOfChecked(arrAll) !==
                                            arrAll.length &&
                                          numberOfChecked(arrAll) !== 0
                                        }
                                        disabled={
                                          [itemCountries.countryId].concat(
                                            itemCountries?.province?.map(
                                              (value: any) => value.provinceId
                                            )
                                          ).length === 0
                                        }
                                        inputProps={{
                                          'aria-label': 'all items selected',
                                        }}
                                        sx={{
                                          marginTop: '-10px',
                                          marginRight: '-10px',
                                        }}
                                      />
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          gap: '10px',
                                        }}
                                        key={indexCountries}
                                      >
                                        <TreeItem
                                          key={indexCountries}
                                          nodeId={itemCountries?.countryId}
                                          label={itemCountries?.country?.native}
                                        >
                                          {itemCountries?.province?.map(
                                            (
                                              itemProvinces: any,
                                              indexProvinces: number
                                            ) => {
                                              const province = [
                                                itemProvinces?.provinceId,
                                              ].concat(
                                                itemProvinces?.districts.map(
                                                  (itemDistricts: any) =>
                                                    itemDistricts?.district?.id
                                                )
                                              )
                                              return (
                                                <>
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      alignItems: 'flex-start',
                                                    }}
                                                  >
                                                    <Checkbox
                                                      onClick={handleToggleAll(
                                                        province
                                                      )}
                                                      checked={
                                                        numberOfChecked([
                                                          itemProvinces?.provinceId,
                                                        ]) ===
                                                          [
                                                            itemProvinces?.provinceId,
                                                          ].length &&
                                                        [
                                                          itemProvinces?.provinceId,
                                                        ].length !== 0
                                                      }
                                                      indeterminate={
                                                        numberOfChecked([
                                                          itemProvinces?.provinceId,
                                                        ]) !==
                                                          [
                                                            itemProvinces?.provinceId,
                                                          ].length &&
                                                        numberOfChecked([
                                                          itemProvinces?.provinceId,
                                                        ]) !== 0
                                                      }
                                                      disabled={
                                                        [
                                                          itemProvinces?.provinceId,
                                                        ].length === 0
                                                      }
                                                      inputProps={{
                                                        'aria-label':
                                                          'all items selected',
                                                      }}
                                                      sx={{
                                                        marginTop: '-10px',
                                                        marginRight: '-15px',
                                                      }}
                                                    />
                                                    <TreeItem
                                                      key={indexProvinces}
                                                      nodeId={
                                                        itemProvinces?.provinceId
                                                      }
                                                      label={
                                                        itemProvinces?.province
                                                          ?.name
                                                      }
                                                    >
                                                      {itemProvinces?.districts.map(
                                                        (
                                                          itemDistricts: any,
                                                          indexDistricts: number
                                                        ) => {
                                                          const labelId = `transfer-list-all-item-${itemDistricts?.district?.id}-label`

                                                          return (
                                                            <div
                                                              style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                  'row',
                                                              }}
                                                              key={
                                                                indexDistricts
                                                              }
                                                              onClick={handleToggleCheckbox(
                                                                itemDistricts
                                                                  ?.district?.id
                                                              )}
                                                            >
                                                              <Checkbox
                                                                checked={
                                                                  checked.indexOf(
                                                                    itemDistricts
                                                                      ?.district
                                                                      ?.id
                                                                  ) !== -1
                                                                }
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{
                                                                  'aria-labelledby':
                                                                    labelId,
                                                                }}
                                                                sx={{
                                                                  marginTop:
                                                                    '-10px',
                                                                  marginRight:
                                                                    '-35px',
                                                                }}
                                                              />
                                                              <TreeItem
                                                                key={
                                                                  indexDistricts
                                                                }
                                                                nodeId={
                                                                  itemDistricts
                                                                    ?.district
                                                                    ?.id
                                                                }
                                                                label={
                                                                  itemDistricts
                                                                    ?.district
                                                                    ?.fullName
                                                                }
                                                              />
                                                            </div>
                                                          )
                                                        }
                                                      )}
                                                    </TreeItem>
                                                  </div>
                                                </>
                                              )
                                            }
                                          )}
                                        </TreeItem>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )
                              }
                            )}
                          </TreeView>
                        </div>
                        {[]?.map((item: any) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyleFilterItem> */}
                  <StyleFilterItem className="d-flex justify-center">
                    <FormControl
                      sx={{ width: 220 }}
                      size="small"
                      className="input-select"
                    >
                      <InputLabel id="demo-simple-select-label-status">
                        Tình trạng
                      </InputLabel>
                      <Select
                        value={valueWorkStatus}
                        label="Tình trạng"
                        onChange={handleChangeWorkStatus}
                      >
                        <MenuItem value={''}>Tất cả</MenuItem>
                        {workStatus?.map((item: any) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyleFilterItem>
                  <StyleFilterItem className="d-flex justify-center">
                    <FormControl
                      sx={{ width: 220 }}
                      size="small"
                      className="input-select"
                    >
                      <InputLabel id="demo-simple-select-label-status">
                        Tình trạng hoạt động
                      </InputLabel>
                      <Select
                        value={valueAspectStatus}
                        label="Tình trạng hoạt động"
                        onChange={handleChangeAspectStatus}
                      >
                        <MenuItem value={''}>Tất cả</MenuItem>
                        {aspectStatus?.map((item: any) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyleFilterItem>
                  <StyleFilterItem
                    className="d-flex justify-center"
                    style={{
                      display: 'flex',
                      gap: valueDateTime ? '10px' : '',
                    }}
                  >
                    <FormControl
                      sx={{
                        width: 220,
                        marginRight: valueDateTime ? '' : '-5px',
                      }}
                      size="small"
                      className="input-select"
                    >
                      <InputLabel id="demo-simple-select-label-status">
                        Ngày giờ
                      </InputLabel>
                      <Select
                        value={valueDateTime}
                        label="Ngày giờ"
                        onChange={handleChangeDateTime}
                      >
                        <MenuItem value={''}>Tất cả</MenuItem>
                        {filterDateTime?.map((item: any) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    -
                    {/* </StyleFilterItem>
                  <StyleFilterItem className="d-flex justify-center"> */}
                    {valueDateTime == '' ? (
                      <></>
                    ) : valueDateTime == 'contractSigningDate' ||
                      valueDateTime == 'firstDayOfWork' ||
                      valueDateTime == 'birthDay' ? (
                      <RangePicker
                        style={{ height: '40px', width: '220px' }}
                        onChange={(values: any) => {
                          setDates(
                            values?.map((item: any) => {
                              return {
                                start: dayjs(item).format('YYYY-MM-DD'),
                                end: dayjs(item).format('YYYY-MM-DD'),
                              }
                            })
                          )
                        }}
                      />
                    ) : (
                      <div>
                        <form onSubmit={handleSubmit}>
                          <TextField
                            label="Nhập hai số, cách nhau bởi khoảng trắng"
                            placeholder="1 10"
                            variant="outlined"
                            type="text"
                            size="small"
                            sx={{
                              '.MuiOutlinedInput-input': {
                                padding: '8.5px 14px',
                              },
                              width: '146px',
                            }}
                            value={value}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          -
                          <Button
                            type="submit"
                            variant="outlined"
                            sx={{ height: '40px' }}
                          >
                            OK
                          </Button>
                        </form>
                      </div>
                    )}
                  </StyleFilterItem>

                  <StyleFilterItemSearch>
                    <InputSearch
                      onChange={handleSearch}
                      placeholder="Tìm kiếm"
                      value={keySearch}
                      variant="outlined"
                      size="small"
                    />
                  </StyleFilterItemSearch>
                </StyleFilter>
              </StyleFilterAll>
            </StyleHeaderItem>
          </StyleTableHeader>

          <Paper
            sx={{
              boxSizing: 'border-box',
              left: 0,
              width: '100%',
            }}
          >
            {staff.length == 0 ? (
              <></>
            ) : (
              <TableContainer
                className="box-shadow"
                sx={{
                  overflow: 'auto',
                  '&::-webkit-scrollbar': {
                    height: 12,
                  },
                  '@media only screen and (max-height: 715px)': {
                    maxHeight: 545,
                  },
                  '@media only screen and (min-height: 925px)': {
                    maxHeight: 750,
                  },
                }}
              >
                <Table
                  sx={
                    {
                      // minWidth: 650,
                    }
                  }
                  aria-label="simple table"
                  size="small"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <StyleTableCell
                        align="center"
                        sx={{
                          position: 'sticky',
                          zIndex: 3,
                          left: 0,
                          minWidth: '55px!important',
                          maxWidth: '55px!important',
                        }}
                      >
                        STT
                      </StyleTableCell>

                      <StyleTableCell
                        align="left"
                        sx={{
                          position: 'sticky',
                          zIndex: 3,
                          left: 55,
                        }}
                      >
                        Mã cộng tác viên
                      </StyleTableCell>
                      <StyleTableCell
                        align="left"
                        sx={{
                          position: 'sticky',
                          zIndex: 3,
                          left: '255px',
                        }}
                      >
                        Họ và tên
                      </StyleTableCell>
                      <StyleTableCell
                        align="left"
                        sx={{
                          position: 'sticky',
                          zIndex: 3,
                          left: 504,
                          borderRight: '3px solid #00000040',
                        }}
                      >
                        Số điện thoại
                      </StyleTableCell>

                      <StyleTableCell align="left">
                        CCCD/CMND/ Passport
                      </StyleTableCell>

                      <StyleTableCell align="left">Dịch vụ</StyleTableCell>
                      <StyleTableCell align="left">Gắn nhãn</StyleTableCell>
                      <StyleTableCell align="left">Tình trạng</StyleTableCell>
                      <StyleTableCell align="left">
                        Tình trạng hoạt động
                      </StyleTableCell>
                      <StyleTableCell align="left">Kỹ năng</StyleTableCell>
                      <StyleTableCell align="left">Thành phố</StyleTableCell>
                      <StyleTableCell align="left">Quận</StyleTableCell>
                      <StyleTableCell align="left">
                        Vị trí làm việc
                      </StyleTableCell>
                      <StyleTableCell align="center">Hạng</StyleTableCell>
                      <StyleTableCell align="center">Số sao</StyleTableCell>

                      <StyleTableCell align="center">
                        Giờ công tháng
                      </StyleTableCell>
                      <StyleTableCell align="center">
                        Giờ công tháng trước
                      </StyleTableCell>
                      <StyleTableCell align="center">
                        Giờ công luỹ kế năm
                      </StyleTableCell>
                      <StyleTableCell align="center">
                        Ca trống lịch
                      </StyleTableCell>
                      <StyleTableCell align="left">
                        Ca đang làm/ ca đăng ký
                      </StyleTableCell>
                      <StyleTableCell align="left">Ngày ký HĐ</StyleTableCell>
                      <StyleTableCell align="left">
                        Ngày làm việc đầu tiên
                      </StyleTableCell>
                      <StyleTableCell
                        align="left"
                        sx={{ minWidth: '250px!important' }}
                      >
                        Nhân sự phụ trách/ tuyển dụng
                      </StyleTableCell>
                      <StyleTableCell align="left">
                        Ngày sinh nhật
                      </StyleTableCell>
                      <StyleTableCell
                        align="center"
                        sx={{
                          position: 'sticky',
                          right: -1,
                          minWidth: '110px!important',
                          maxWidth: '110px!important',
                        }}
                      >
                        Hành động
                      </StyleTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {staff?.map((row: IStaff, index: number) => (
                      <TableRow
                        // hover
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{
                            position: 'sticky',
                            left: 0,
                            backgroundColor: 'white',
                          }}
                        >
                          {staff && index + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            cursor: 'pointer',
                            position: 'sticky',
                            left: 55,
                            backgroundColor: 'white',
                            '&:hover': { color: '#9e9e9e' },
                          }}
                          onClick={() =>
                            navigate(
                              `/staff-manager/part-time-staff/detail/${row.id}`
                            )
                          }
                        >
                          <StyleTableCellItem>
                            {row?.displayId}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            position: 'sticky',
                            left: 255,
                            backgroundColor: 'white',
                            minWidth: '250px',
                            maxWidth: '250px',
                          }}
                        >
                          <StyleTableCellItem>
                            <StyleAvatarList>
                              <Avatar alt={row.fullName} src={row.avatar} />
                              <Typography
                                sx={{
                                  marginLeft: '5px',
                                }}
                              >
                                {row.fullName}
                              </Typography>
                            </StyleAvatarList>
                          </StyleTableCellItem>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            position: 'sticky',
                            left: 504,
                            backgroundColor: 'white',
                            borderRight: '3px solid #00000040 !important',
                          }}
                        >
                          <StyleTableCellItem>
                            {(row?.phone as string).slice(0, 7) + '***'}
                          </StyleTableCellItem>
                        </TableCell>

                        <TableCell align="left">
                          <StyleTableCellItem>
                            {row?.citizenId}
                          </StyleTableCellItem>
                        </TableCell>

                        <TableCell align="left">
                          {row?.services?.map((service: any) => {
                            return (
                              <>
                                <li>{service?.staffService?.name}</li>
                              </>
                            )
                          })}
                        </TableCell>
                        <TableCell align="left">
                          <StyleTableCellItem
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: '5px',
                            }}
                          >
                            {row?.staffLabel?.map(
                              (label: any, index: number) => {
                                return (
                                  <StyleLabel
                                    key={index}
                                    style={{
                                      color: `${hexToRGBA(label?.color, 1)}`,
                                    }}
                                    onClick={() => {
                                      setLabelId(label?.id)
                                      setKeySearch(label?.name as string)
                                    }}
                                  >
                                    <div
                                      style={{
                                        padding: '6px 12px',
                                        backgroundColor: `${hexToRGBA(
                                          label?.color,
                                          0.2
                                        )}`,
                                        borderRadius: '50px',
                                      }}
                                    >
                                      {label?.name}
                                    </div>
                                  </StyleLabel>
                                )
                              }
                            )}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="left">
                          <StyleTableCellItem>{row?.status}</StyleTableCellItem>
                        </TableCell>
                        <TableCell align="left">
                          <StyleTableCellItem>
                            {row?.activeStatus == 'active'
                              ? 'Đang hoạt động'
                              : 'Khoá tài khoản'}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="left">
                          <StyleTableCellItem
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            {row?.skill == null
                              ? null
                              : JSON.parse(row?.skill)?.map((skill: any) => (
                                  <li key={skill?.id}>{skill}</li>
                                ))}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="left">
                          <StyleTableCellItem style={{ display: 'flex' }}>
                            {row?.districts?.[0]?.staffProvince?.fullName}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="left">
                          {row?.districts?.[0]?.staffDistrict?.name}
                        </TableCell>
                        <TableCell align="left">
                          <StyleTableCellItem style={{ display: 'flex' }}>
                            {row?.districts?.[0]?.fullLocation}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="center">
                          <StyleTableCellItem>
                            {rank[row?.rank as keyof IObjectRank]}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="center">
                          <StyleTableCellItem>
                            {row?.avgRate}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="center">
                          <StyleTableCellItem>
                            {row?.workingHours?.currentMonthWorkingHours}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="center">
                          <StyleTableCellItem>
                            {row?.workingHours?.previousMonthWorkingHours}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="center">
                          <StyleTableCellItem>
                            {row?.workingHours?.yearWorkingHours}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="center">
                          <StyleTableCellItem>
                            {row?.schedule?.countFree}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="center">
                          <StyleTableCellItem>
                            {row?.schedule?.countWork}/
                            {row?.schedule?.countRegister}
                          </StyleTableCellItem>
                        </TableCell>

                        <TableCell align="left">
                          <StyleTableCellItem>
                            {dayjs(row?.signingDate).format('DD/MM/YYYY')}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="left">
                          <StyleTableCellItem>
                            {dayjs(row?.startDate).format('DD/MM/YYYY') ?? ''}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="left">
                          <StyleTableCellItem>
                            {row?.staffManagers?.recruitment}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell align="left">
                          <StyleTableCellItem>
                            {dayjs(row?.birth).format('DD/MM/YYYY')}
                          </StyleTableCellItem>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            position: 'sticky',
                            right: -1,
                            backgroundColor: 'white',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: '10px',
                            }}
                          >
                            <Edit
                              onClick={() =>
                                navigate(
                                  `/staff-manager/part-time-staff/edit/${row.id}`
                                )
                              }
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <TablePagination
              rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Số dòng trên một trang"
            />
          </Paper>
        </Item>
      </Grid>
    </Grid>
  )
}

export default PartTimeStaffList
