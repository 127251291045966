import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Breadcrumb from 'components/Breadcrumbs'
import { StyleContainer, StylePaper, StyleTitle } from './style'
import { Grid } from '@mui/material'
import UserInfo from './components/UserInfo'
import OrderHistory from './components/OrderHistory'
import WalletCards from './components/WalletCards'
import { USER_DETAIL } from 'constants/breadcrumbs'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function UserDetails() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={USER_DETAIL} />
      <Grid item xs={12}>
        <StylePaper className="bd-radius">
          <StyleTitle>Thông tin chi tiết khách hàng</StyleTitle>
          <StyleContainer>
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  fontWeight: 'bold !important',
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="tranform-none"
                >
                  <Tab
                    className="fw-bold"
                    label="Thông tin cá nhân"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="fw-bold"
                    label="Lịch sử đơn hàng"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="fw-bold"
                    label="Ví ảo và thẻ"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <UserInfo />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <OrderHistory />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <WalletCards />
              </TabPanel>
            </Box>
          </StyleContainer>
        </StylePaper>
      </Grid>
    </Grid>
  )
}
