import React from 'react'
import { Grid, Typography } from '@mui/material'
import Item from 'components/Item'
import comingsoon from 'assets/images/comingsoon.webp'
const ComingSoon = () => {
  return (
    <>
      <Grid item xs={12} sx={{ marginTop: '18px' }}>
        <Item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '75vh',
            padding: '24px',
          }}
        >
          <div>
            <Typography
              gutterBottom
              sx={{
                fontWeight: '500',
                fontSize: '34px',
                color: '#4c4a54',
                marginBottom: '4px',
              }}
            >
              Chúng tôi sẽ sớm ra mắt 🚀
            </Typography>
            <Typography
              gutterBottom
              sx={{ fontSize: '16px', color: '#87858d' }}
            >
              Chức năng này đang được phát triển. Vui lòng đăng ký để nhận thông
              báo khi nó sẵn sàng!
            </Typography>
          </div>
          <img
            src={comingsoon}
            height="400px"
            alt="My Image"
            style={{ marginTop: '40px' }}
          />
        </Item>
      </Grid>
    </>
  )
}

export default ComingSoon
