import React, { useEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { ReactNode } from 'react'
import { Grid, Stack } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Breadcrumb from 'components/Breadcrumbs'
import Item from 'components/Item'
import { useForm, Controller } from 'react-hook-form'
import InputField from 'components/FormControl/InputField'
import { useNavigate } from 'react-router'
import { useAlert } from 'contexts/AlertContext'
import { yupResolver } from '@hookform/resolvers/yup'
import campaignApi from 'services/campaignAPI'
import { campaignSchema_ } from 'validations/campaignSchema'
import { Button } from '@mui/material'
import {
  StyleDate,
  StyleError,
  StyleErrorSelect,
  StyleTimePicker,
  StyleTitle,
} from 'pages/campaign/style'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useLoading } from 'contexts/LoadingContext'
import { CAMPAIGN_ADD } from 'constants/breadcrumbs'

const CampaignCreate = () => {
  const [reviewer, setReviewer] = useState<any>([])
  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertClose, setAlertError, setAlertSuccess } = useAlert()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<ICampaign>({
    resolver: yupResolver(campaignSchema_),
    mode: 'onChange',
  })
  const tmpExpense = watch('expense') // Retrieve the value of expense
  const tmpRevenue = watch('revenue') // Retrieve the value of revenue
  const endProfit =
    (100 * (Number(tmpRevenue) - Number(tmpExpense))) / Number(tmpExpense) || 0

  const onSubmit = async (data: ICampaign) => {
    setLoadingTrue()
    setAlertClose()
    try {
      await campaignApi.createCampaign(data)
      setAlertSuccess('Thêm mới chiến dịch thành công')
      setLoadingFalse()
      navigate('/campaign')
    } catch (error) {
      setLoadingFalse()
      setAlertError('Thêm mới chiến dịch thất bại')
    }
  }

  const fetchReviewer = async () => {
    const data: any = await campaignApi.getReviewer()
    setReviewer(data)
  }
  useEffect(() => {
    fetchReviewer()
  }, [])
  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={CAMPAIGN_ADD} />
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <StyleTitle>Thêm chiến dịch</StyleTitle>
          <form
            style={{ padding: '0 200px' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <InputField label="Tên chiến dịch" {...register('campaign')} />
            {errors.campaign && (
              <StyleError>{errors.campaign.message as string}</StyleError>
            )}
            <InputField
              label="Mục đích"
              multiline={true}
              {...register('purpose')}
            />
            {errors.purpose && (
              <StyleError>{errors.purpose.message as string}</StyleError>
            )}
            <StyleDate>
              <StyleTimePicker>
                <Controller
                  control={control}
                  name="start"
                  render={({
                    field: { onChange, ref, onBlur, name, ...field },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        minDate={new Date()}
                        {...field}
                        inputRef={ref}
                        label="Ngày bắt đầu"
                        renderInput={(inputProps) => (
                          <InputField
                            sx={{ marginBottom: '20px' }}
                            {...inputProps}
                            onBlur={onBlur}
                            name={name}
                            error={false}
                            fullWidth
                          />
                        )}
                        onChange={(e: Date | null) => {
                          onChange(e)
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
                {errors.start && (
                  <StyleErrorSelect>
                    {errors.start.message as string}
                  </StyleErrorSelect>
                )}
              </StyleTimePicker>
              <StyleTimePicker>
                <Controller
                  control={control}
                  name="end"
                  render={({
                    field: { ref, onBlur, onChange, name, ...field },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        minDate={new Date()}
                        {...field}
                        inputRef={ref}
                        label="Ngày kết thúc"
                        renderInput={(inputProps) => (
                          <InputField
                            sx={{ marginBottom: '20px' }}
                            {...inputProps}
                            onBlur={onBlur}
                            error={false}
                            fullWidth
                            name={name}
                          />
                        )}
                        onChange={(e: Date | null) => {
                          onChange(e)
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />

                {errors.end && (
                  <StyleErrorSelect>
                    {errors.end.message as string}
                  </StyleErrorSelect>
                )}
              </StyleTimePicker>
            </StyleDate>
            <Controller
              control={control}
              name={`reviewer`}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Autocomplete
                  autoHighlight
                  getOptionLabel={(option: IReviewer) => option.name}
                  isOptionEqualToValue={(option: IReviewer, item: IReviewer) =>
                    option.id === item?.id
                  }
                  sx={{ marginBottom: '24px' }}
                  onChange={(_, val) => {
                    onChange(val?.id)
                  }}
                  options={reviewer}
                  fullWidth
                  renderInput={(params) => (
                    <InputField {...params} label="Người duyệt" />
                  )}
                />
              )}
            />
            {errors.reviewer && (
              <StyleErrorSelect>
                {errors.reviewer.message as string}
              </StyleErrorSelect>
            )}
            <Controller
              control={control}
              name={`flatform`}
              render={({ field: { onChange } }) => (
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ marginBottom: '24px' }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Nền tảng
                  </InputLabel>
                  <Select
                    sx={{ textAlign: 'left' }}
                    fullWidth
                    label="Nền tảng"
                    defaultValue=""
                    onChange={(
                      e: SelectChangeEvent<string>,
                      child: ReactNode
                    ) => {
                      onChange(e)
                    }}
                  >
                    <MenuItem value="Google">Google</MenuItem>
                    <MenuItem value="Facebook">Facebook</MenuItem>
                    <MenuItem value="Youtube">Youtube</MenuItem>
                    <MenuItem value="Tiktok">Tiktok</MenuItem>
                    <MenuItem value="Khác">Khác</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            {errors.flatform && (
              <StyleErrorSelect>
                {errors.flatform.message as string}
              </StyleErrorSelect>
            )}
            <InputField
              sx={{ marginTop: '-10px' }}
              label="Nội dung chiến dịch"
              multiline={true}
              {...register('content')}
            />
            {errors.content && (
              <StyleError>{errors.content.message as string}</StyleError>
            )}
            <InputField label="Chi phí dự kiến" {...register('expense')} />
            {errors.expense && (
              <StyleError>{errors.expense.message as string}</StyleError>
            )}
            <InputField label="Doanh thu dự kiến" {...register('revenue')} />
            {errors.revenue && (
              <StyleError>{errors.revenue.message as string}</StyleError>
            )}
            <InputField
              label="Lượng phản hồi dự kiến"
              {...register('feedbackNumbers')}
            />
            {errors.feedbackNumbers && (
              <StyleError>
                {errors.feedbackNumbers.message as string}
              </StyleError>
            )}
            <InputField label="Số Lead sự kiến" {...register('leadNumbers')} />
            {errors.leadNumbers && (
              <StyleError>{errors.leadNumbers.message as string}</StyleError>
            )}
            <InputField
              label="Số khách hàng dự kiến"
              {...register('customerNumbers')}
            />
            {errors.customerNumbers && (
              <StyleError>
                {errors.customerNumbers.message as string}
              </StyleError>
            )}
            <InputField
              label="ROI mong đợi"
              {...register('profit')}
              value={endProfit + '%'}
            />
            {errors.profit && (
              <StyleError>{errors.profit.message as string}</StyleError>
            )}
            <InputField label="Link đính kèm" {...register('refLink')} />
            {errors.refLink && (
              <StyleError>{errors.refLink.message as string}</StyleError>
            )}
            <InputField
              label="Ghi chú"
              multiline={true}
              {...register('note')}
            />
            {errors.note && (
              <StyleError>{errors.note.message as string}</StyleError>
            )}

            <Stack spacing={2} direction="row" sx={{ paddingTop: '10px' }}>
              <Button variant="contained" type="submit">
                Lưu
              </Button>
              <Button variant="outlined" onClick={() => navigate('/campaign')}>
                Hủy
              </Button>
            </Stack>
          </form>
        </Item>
      </Grid>
    </Grid>
  )
}

export default CampaignCreate
