import { Button, Grid, MenuItem, Stack, Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import Breadcrumb from 'components/Breadcrumbs'
import InputField from 'components/FormControl/InputField'
import Item from 'components/Item'
import LoadingVersion2 from 'components/LoadingV2'
import { ORDER_DETAIL } from 'constants/breadcrumbs'
import {
  cancelReason,
  likeOption,
  paymentMethod,
  status,
  statusPayment,
} from 'constants/order'

import dayjs from 'dayjs'
import 'dayjs/locale/vi'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import orderAPI from 'services/orderAPI'
import {
  StyleActionButton,
  StyleContentItem,
  StyleContentLeft,
  StyleContentRight,
  StyleFeedback,
  StyleFeedbackContent,
  StyleFeedbackTitle,
  StyleForm,
  StyleHeaderItem,
  StyleInput,
  StyleInputField,
  StylePrice,
  StyleTitle,
  StyleTitleDescribe,
} from './style'

const weeklyRepeat = [
  { value: 2, title: 'Thứ 2' },
  { value: 3, title: 'Thứ 3' },
  { value: 4, title: 'Thứ 4' },
  { value: 5, title: 'Thứ 5' },
  { value: 6, title: 'Thứ 6' },
  { value: 7, title: 'Thứ 7' },
  { value: 8, title: 'Chủ Nhật' },
]
dayjs.locale('vi')

const OrderDetail = () => {
  const [data, setData] = useState<IOrder>()
  const [serviceItem, setServiceItem] = useState<any>()
  const [premiumChecked, setPremiumChecked] = useState<string[]>([])
  const [selectedWeedkly, setSelectedWeedkly] = useState<any>()
  const [isIncognito, setIsIncognito] = useState<boolean>()
  const [isFavorite, setIsFavorite] = useState<boolean>(false)
  const [valueStatusPay, setValueStatusPay] = useState('')
  const [dataServices, setDataServices] = useState([])
  const [valueService, setValueService] = useState('')
  const [valuePayment, setValuePayment] = useState('')
  const [valueStaffCancel, setValueStaffCancel] = useState('')
  const [valueStatus, setValueStatus] = useState('')
  const [dataServicesItem, setDataServicesItem] = useState([])
  const navigate = useNavigate()
  const { id } = useParams()
  const [checked, setChecked] = useState<any[]>([])
  const [likeOptions, setLikeOptions] = useState<any[]>([])

  const fetchDataListServices = async () => {
    const { data } = await orderAPI.getServices()
    setDataServices(data)
  }

  const fetchDataServicesItem = async () => {
    const { data } = await orderAPI.getServiceById(valueService as string)
    setDataServicesItem(data?.serviceItems)
  }

  const handleChangeService = (event: React.SyntheticEvent, newValue: any) => {
    setValueService(newValue?.props?.value)
  }
  const handleChangePayment = (event: React.SyntheticEvent, newValue: any) => {
    setValuePayment(newValue?.props?.value)
  }

  const handleStaffCancel = (event: React.SyntheticEvent, newValue: any) => {
    setValueStaffCancel(newValue?.props?.value)
  }
  const handleChangeStatus = (event: React.SyntheticEvent, newValue: any) => {
    setValueStatus(newValue?.props?.value)
  }

  useEffect(() => {
    fetchDataListServices()
  }, [valueService])

  useEffect(() => {
    fetchDataServicesItem()
  }, [valueService])
  useEffect(() => {
    if (data) {
      const serviceId = data.serviceId
      const fetchServiceDetail = async () => {
        const { data } = await orderAPI.getServiceById(serviceId as string)
        if (data) {
          setServiceItem(data)
        }
      }
      fetchServiceDetail()
    }
  }, [data])
  useEffect(() => {
    const fetchOrder = async () => {
      const { data } = await orderAPI.getOrderId(id as string)
      setData(data.order)
      setValuePayment(data?.order?.paymentMethod)
      setValueStatus(data?.order?.status)
      setValueStatusPay(data?.order?.paymentStatus)
      setValueService(data?.order?.rootServiceDetail?.name)
      if (
        data?.order?.serviceItems?.[0]?.itemOptions?.[0]?.serviceExtraOptionId
      ) {
        setPremiumChecked([
          data?.order?.serviceItems?.[0]?.itemOptions?.[0]
            ?.serviceExtraOptionId,
        ])
      }
      setChecked(
        data.order?.serviceOptions?.map(
          (item: any) => item.serviceExtraOptionId
        )
      )
      setIsIncognito(data.order?.review?.isIncognito)
      setLikeOptions(data.order?.review?.options)
      setSelectedWeedkly(
        weeklyRepeat.filter((item: any) =>
          data?.order?.weeklyRepeat.includes(item.value)
        )
      )
    }
    fetchOrder()
  }, [id])
  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={ORDER_DETAIL} />
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <StyleHeaderItem>
            <StyleTitle>Chi tiết đơn hàng</StyleTitle>
          </StyleHeaderItem>
          {data ? (
            <>
              <StyleHeaderItem>
                <StyleTitle
                  style={{ fontSize: '20px' }}
                >{`Đơn hàng : ${data.displayId}`}</StyleTitle>
              </StyleHeaderItem>
              <StyleForm autoComplete="off">
                <StyleContentRight>
                  <StyleContentItem>
                    <StyleTitleDescribe style={{ paddingBottom: '10px' }}>
                      Thông tin khách hàng
                    </StyleTitleDescribe>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ width: '48%' }}>
                        <TextField
                          size="small"
                          fullWidth
                          inputProps={{
                            readOnly: true,
                          }}
                          className="input-views"
                          sx={{
                            '&.Mui-disabled': {
                              '& .MuiInputBase-input': {
                                borderColor: 'red',
                                color: 'blue',
                              },
                            },
                          }}
                          label="Số điện thoại KH"
                          defaultValue={data.user?.phone}
                        />
                      </div>
                      <div style={{ width: '48%' }}>
                        <TextField
                          size="small"
                          fullWidth
                          inputProps={{
                            readOnly: true,
                          }}
                          className="input-views"
                          label="Khách hàng"
                          defaultValue={data.user?.fullName}
                        />
                      </div>
                    </div>
                  </StyleContentItem>
                  <StyleContentItem>
                    <StyleTitleDescribe>Thông tin dịch vụ</StyleTitleDescribe>
                    <InputField
                      size="small"
                      label="Địa chỉ làm việc"
                      inputProps={{ readOnly: true }}
                      className="input-views"
                      defaultValue={data.fullLocation}
                    />
                    <div style={{ width: '100%' }}>
                      {valueService && (
                        <InputField
                          size="small"
                          id="serviceId"
                          label="Tên dịch vụ"
                          value={valueService}
                          className="input-views"
                          inputProps={{ readOnly: true }}
                          sx={{
                            width: '100%',
                          }}
                          onChange={handleChangeService}
                        >
                          {dataServices?.map((option: any) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.name}
                            </MenuItem>
                          ))}
                        </InputField>
                      )}
                    </div>
                    <StyleInput>
                      <StyleInputField>
                        <InputField
                          size="small"
                          label="Số điện thoại"
                          className="input-views"
                          inputProps={{ readOnly: true }}
                          defaultValue={data.orderProfile?.phone}
                        />
                      </StyleInputField>
                      <StyleInputField>
                        <InputField
                          size="small"
                          label="Tên liên lạc"
                          className="input-views"
                          inputProps={{ readOnly: true }}
                          defaultValue={data.orderProfile?.name}
                        />
                      </StyleInputField>
                    </StyleInput>
                  </StyleContentItem>
                  <StyleContentItem>
                    <StyleTitleDescribe>
                      Thời lượng và thời gian
                    </StyleTitleDescribe>
                    <div style={{ width: '100%' }}>
                      <InputField
                        size="small"
                        id="timeDes"
                        className="input-views"
                        label="Thời lượng"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ readOnly: true }}
                        value={`${data.serviceItems?.[0]?.name} (${data.serviceItems?.[0]?.shortDescription})`}
                        sx={{
                          width: '100%',
                        }}
                      >
                        {dataServicesItem?.map((option: any) => (
                          <MenuItem key={option?.id} value={option?.id}>
                            {option?.name} {option?.shortDescription}
                          </MenuItem>
                        ))}
                      </InputField>
                    </div>

                    <StyleInput style={{ paddingBottom: '10px' }}>
                      <StyleInputField>
                        <InputField
                          size="small"
                          label="Thứ ngày"
                          className="input-views"
                          inputProps={{ readOnly: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={dayjs(data.startTime)
                            .format('dddd, D/M/YYYY ')
                            .replace(/^\w/, (c) => c.toUpperCase())}
                        />
                      </StyleInputField>
                      <StyleInputField>
                        <InputField
                          size="small"
                          label="Giờ bắt đầu"
                          className="input-views"
                          inputProps={{ readOnly: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={dayjs(data.startTime).format('h:mm A')}
                        />
                      </StyleInputField>
                      <StyleInputField>
                        <InputField
                          size="small"
                          label="Giờ kết thúc"
                          className="input-views"
                          inputProps={{ readOnly: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={dayjs(data.endTime).format('h:mm A')}
                        />
                      </StyleInputField>
                    </StyleInput>

                    {selectedWeedkly && (
                      <Autocomplete
                        sx={{ padding: '10px 0' }}
                        size="small"
                        className="input-views"
                        multiple
                        disableCloseOnSelect
                        options={weeklyRepeat}
                        getOptionLabel={(option: any) => option.title}
                        noOptionsText="Danh sách rỗng"
                        defaultValue={selectedWeedkly}
                        readOnly
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox checked={selected} />
                            {option.title}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Lặp lại hằng tuần" />
                        )}
                        onChange={(event, value: any) => {
                          setSelectedWeedkly([...value])
                        }}
                      />
                    )}
                  </StyleContentItem>
                  <StyleContentItem style={{ paddingBottom: '0' }}>
                    <StyleTitleDescribe>Lựa chọn thêm</StyleTitleDescribe>
                    <List
                      sx={{
                        width: '100%',
                        paddingBottom: '0',
                      }}
                    >
                      {serviceItem?.serviceItems[0]?.itemOptions?.map(
                        (value: any) => {
                          const labelId = `${value.id}`
                          return (
                            <ListItem key={value.id} disablePadding>
                              <ListItemButton
                                sx={{ padding: '0', fontSize: '16px' }}
                                role={undefined}
                                dense
                              >
                                <ListItemIcon sx={{ minWidth: '0' }}>
                                  <Checkbox
                                    edge="start"
                                    checked={
                                      premiumChecked.indexOf(value.id) !== -1
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={` ${value.name}`}
                                />
                              </ListItemButton>
                            </ListItem>
                          )
                        }
                      )}
                    </List>
                    <List
                      sx={{
                        width: '100%',
                        paddingTop: '0',
                      }}
                    >
                      {serviceItem?.serviceExtraOptions?.map((value: any) => {
                        const labelId = `checkbox-list-label-${value.id}`
                        return (
                          <>
                            <ListItem key={value.id} disablePadding>
                              <ListItemButton
                                sx={{ padding: '0', fontSize: '16px' }}
                                role={undefined}
                                dense
                              >
                                <ListItemIcon sx={{ minWidth: '0' }}>
                                  <Checkbox
                                    edge="start"
                                    checked={checked?.indexOf(value.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={` ${value.name}`}
                                />
                              </ListItemButton>
                            </ListItem>
                            {value.key == 'pet' &&
                            checked.indexOf(value.id) !== -1 ? (
                              <>
                                <InputField
                                  size="small"
                                  label="Vật nuôi"
                                  className="input-views"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  multiline={true}
                                />
                              </>
                            ) : null}
                          </>
                        )
                      })}
                    </List>
                  </StyleContentItem>
                  <StyleContentItem>
                    <InputField
                      size="small"
                      className="input-views"
                      label="Ghi chú"
                      multiline={true}
                      inputProps={{
                        readOnly: true,
                      }}
                      defaultValue={data.note}
                    />
                  </StyleContentItem>
                  <StyleContentItem
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <StyleTitleDescribe>
                      Thông tin thanh toán
                    </StyleTitleDescribe>

                    <div style={{ width: '35%' }}>
                      <InputField
                        size="small"
                        id="serviceId"
                        label="Phương thức thanh toán"
                        select
                        className="input-views"
                        value={valuePayment}
                        sx={{
                          width: '100%',
                        }}
                        onChange={handleChangePayment}
                        inputProps={{ readOnly: true }}
                        SelectProps={{ IconComponent: () => null }}
                      >
                        {paymentMethod?.map((option: any) => (
                          <MenuItem key={option?.value} value={option?.value}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </InputField>
                    </div>
                    <StyleInputField style={{ width: '35%' }}>
                      <InputField
                        size="small"
                        className="input-views upperCase"
                        inputProps={{ readOnly: true }}
                        label="Mã khuyến mãi"
                        defaultValue={data.promotionCode}
                        multiline={true}
                      />
                    </StyleInputField>

                    <StyleInput
                      style={{ justifyContent: 'space-between', width: '35%' }}
                    >
                      Số tiền được giảm:{' '}
                      <StylePrice>
                        {' '}
                        {(data.discountPrice as number).toLocaleString(
                          'vi-VN',
                          {
                            style: 'currency',
                            currency: 'VND',
                          }
                        )}
                      </StylePrice>
                    </StyleInput>
                    <StyleInput
                      style={{ justifyContent: 'space-between', width: '35%' }}
                    >
                      Tổng tiền:{' '}
                      <StylePrice>
                        {(data.grandTotalPrice as number).toLocaleString(
                          'vi-VN',
                          {
                            style: 'currency',
                            currency: 'VND',
                          }
                        )}
                      </StylePrice>
                    </StyleInput>
                  </StyleContentItem>
                  <StyleActionButton>
                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{
                        paddingTop: '20px',
                        gap: '8px',
                        position: 'relative',
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => navigate('/order')}
                      >
                        Quay lại
                      </Button>
                    </Stack>
                  </StyleActionButton>
                </StyleContentRight>
                <StyleContentLeft>
                  <StyleContentItem>
                    <StyleTitleDescribe>Thông tin đơn hàng</StyleTitleDescribe>
                    <div style={{ width: '100%' }}>
                      <InputField
                        size="small"
                        id="serviceId"
                        label="Trạng thái đơn hàng"
                        value={valueStatus}
                        className="input-views"
                        sx={{
                          width: '100%',
                        }}
                        select
                        onChange={handleChangeStatus}
                        inputProps={{ readOnly: true }}
                        SelectProps={{ IconComponent: () => null }}
                      >
                        {status.map((item: any) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </InputField>
                    </div>
                    <div style={{ width: '100%' }}>
                      <InputField
                        size="small"
                        id="serviceId"
                        className="input-views"
                        label="Trạng thái thanh toán"
                        select
                        value={valueStatusPay}
                        sx={{
                          width: '100%',
                        }}
                        inputProps={{ readOnly: true }}
                        SelectProps={{ IconComponent: () => null }}
                      >
                        {statusPayment?.map((item: any) => (
                          <MenuItem key={item?.value} value={item?.value}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </InputField>
                    </div>
                    <StyleInput>
                      <StyleInputField>
                        {data.staffs
                          .filter(
                            (item: any) =>
                              item?.staffOrderStatus?.status === 'assigned'
                          )
                          .map((staff: any, index: number) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '10px',
                              }}
                            >
                              <div
                                style={{ width: '48%', paddingBottom: '10px' }}
                              >
                                <TextField
                                  size="small"
                                  fullWidth
                                  className="input-views"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  label="Số điện thoại NV"
                                  defaultValue={staff?.phone}
                                />
                              </div>
                              <div style={{ width: '48%' }}>
                                <TextField
                                  size="small"
                                  fullWidth
                                  className="input-views"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  label="Nhân viên"
                                  defaultValue={staff?.fullName}
                                />
                              </div>
                            </div>
                          ))}
                      </StyleInputField>
                    </StyleInput>

                    {valueStatus && (
                      <>
                        <StyleInput style={{ justifyContent: 'space-between' }}>
                          Thời gian bắt đầu:{' '}
                          <StylePrice>
                            {dayjs(data.startTime).format('h:mm A')}
                          </StylePrice>
                        </StyleInput>
                        <StyleInput style={{ justifyContent: 'space-between' }}>
                          Thời gian kết thúc:{' '}
                          <StylePrice>
                            {dayjs(data.endTime).format('h:mm A')}
                          </StylePrice>
                        </StyleInput>
                      </>
                    )}
                    {valueStatus === 'rejected' && (
                      <div style={{ width: '100%' }}>
                        <InputField
                          size="small"
                          id="serviceId"
                          className="input-views"
                          label="Lý do hủy"
                          select
                          value={valueStaffCancel}
                          sx={{
                            width: '100%',
                          }}
                          inputProps={{ readOnly: true }}
                          SelectProps={{ IconComponent: () => null }}
                          onChange={handleStaffCancel}
                        >
                          {cancelReason.map((option: any) => (
                            <MenuItem key={option?.value} value={option?.value}>
                              {option?.name}
                            </MenuItem>
                          ))}
                        </InputField>
                      </div>
                    )}
                  </StyleContentItem>
                  {valueStatus === 'done' && (
                    <>
                      {data.review && (
                        <StyleContentItem>
                          <StyleTitleDescribe>
                            Đánh giá của khách hàng
                          </StyleTitleDescribe>
                          <StyleFeedback>
                            <StyleFeedbackTitle>Số sao:</StyleFeedbackTitle>
                            <StyleFeedbackContent>
                              {`${data.review?.star} sao`}
                            </StyleFeedbackContent>
                          </StyleFeedback>
                          <StyleFeedback>
                            <StyleFeedbackTitle>
                              Điều bạn thích:
                            </StyleFeedbackTitle>
                            <StyleFeedbackContent>
                              {likeOption.map((value: any) => {
                                const labelId = `checkbox-list-label-${value.id}`
                                return (
                                  <>
                                    <ListItem key={value.id} disablePadding>
                                      <ListItemButton
                                        sx={{ padding: '0', fontSize: '16px' }}
                                        role={undefined}
                                        dense
                                      >
                                        <ListItemIcon sx={{ minWidth: '0' }}>
                                          <Checkbox
                                            edge="start"
                                            checked={
                                              likeOptions?.indexOf(value.id) !==
                                              -1
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                              'aria-labelledby': labelId,
                                            }}
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          id={labelId}
                                          primary={` ${value.name}`}
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  </>
                                )
                              })}
                            </StyleFeedbackContent>
                          </StyleFeedback>
                          <StyleFeedback>
                            <StyleFeedbackTitle>Lựa chọn:</StyleFeedbackTitle>
                            <StyleFeedbackContent>
                              <div
                                style={{ display: 'flex', marginLeft: '-3px' }}
                              >
                                {' '}
                                <Checkbox
                                  sx={{ paddingLeft: '0', paddingTop: '0' }}
                                  checked={isFavorite}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <Typography>
                                  Thêm vào nhân viên yêu thích
                                </Typography>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: '-3px',
                                  marginTop: '10px',
                                }}
                              >
                                {' '}
                                <Checkbox
                                  sx={{ paddingLeft: '0', paddingTop: '0' }}
                                  checked={isIncognito}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <Typography>
                                  Đánh giá ở chế độ ẩn danh
                                </Typography>
                              </div>
                            </StyleFeedbackContent>
                          </StyleFeedback>
                          <div style={{ width: '100%' }}>
                            <InputField
                              size="small"
                              id="serviceId"
                              className="input-views"
                              label="Nhận xét"
                              defaultValue={data.review?.content}
                              sx={{
                                width: '100%',
                              }}
                              inputProps={{ readOnly: true }}
                              SelectProps={{ IconComponent: () => null }}
                              onChange={handleStaffCancel}
                            >
                              {cancelReason.map((option: any) => (
                                <MenuItem
                                  key={option?.value}
                                  value={option?.value}
                                >
                                  {option?.name}
                                </MenuItem>
                              ))}
                            </InputField>
                          </div>
                        </StyleContentItem>
                      )}

                      <StyleContentItem>
                        <StyleTitleDescribe>
                          Đánh giá của nhân viên
                        </StyleTitleDescribe>
                        <div style={{ width: '100%' }}>
                          <InputField
                            size="small"
                            id="serviceId"
                            className="input-views"
                            label="Pending"
                            value={valueStaffCancel}
                            sx={{
                              width: '100%',
                            }}
                            inputProps={{ readOnly: true }}
                            SelectProps={{ IconComponent: () => null }}
                            onChange={handleStaffCancel}
                          >
                            {cancelReason.map((option: any) => (
                              <MenuItem
                                key={option?.value}
                                value={option?.value}
                              >
                                {option?.name}
                              </MenuItem>
                            ))}
                          </InputField>
                        </div>
                      </StyleContentItem>
                    </>
                  )}
                </StyleContentLeft>
              </StyleForm>
            </>
          ) : (
            <LoadingVersion2 />
          )}
        </Item>
      </Grid>
    </Grid>
  )
}

export default OrderDetail
