import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Stack } from '@mui/material'
import InputField from 'components/FormControl/InputField'
import BasicModal from 'components/Modal'
import { useAlert } from 'contexts/AlertContext'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import staffApi from 'services/staffAPI'
import { TypeFileSchema_ } from 'validations/partTimeStaffSchema'
import { StyleError, StyleHeaderItem, StyleTitle } from './style'
import Close from 'components/Icons/components/Close'

interface IProps {
  id: any
  modalTypeFileEdit: boolean
  setModalTypeFileEdit: any
}

const TypeFileEdit: React.FC<IProps> = (props) => {
  const { modalTypeFileEdit, setModalTypeFileEdit, id } = props
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(TypeFileSchema_),
    mode: 'onChange',
  })
  const { setAlertError, setAlertSuccess } = useAlert()
  const [typeFileId, setTypeFileId] = useState<any>([])

  const fetchDataLabelId = async () => {
    const { data } = await staffApi.getIdentifierLabelById(id?.id)
    setTypeFileId(data)
    setValue('name', data?.name)
  }

  const onSubmit = async (data: any) => {
    try {
      await staffApi.updateNameIdentifierLabel(data, id?.id)
      setModalTypeFileEdit(false)
      setAlertSuccess('Sửa loại hồ sơ thành công')
      reset()
    } catch (error: any) {
      setAlertError(error?.response?.data?.message)
    }
  }

  useEffect(() => {
    fetchDataLabelId()
  }, [id])

  return (
    <BasicModal
      open={modalTypeFileEdit}
      handleClose={() => {
        setModalTypeFileEdit(false)
        reset()
      }}
    >
      <StyleHeaderItem>
        <StyleTitle>Sửa giá trị</StyleTitle>
        <Close
          onClick={() => {
            setModalTypeFileEdit(false)
            reset()
          }}
        />
      </StyleHeaderItem>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          {...register('name')}
          label="Giá trị"
          defaultValue={typeFileId?.name}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
        />
        <StyleError>{errors.name?.message as string}</StyleError>

        <Stack
          spacing={2}
          direction="row"
          sx={{
            justifyContent: 'flex-start',
            paddingTop: '14px',
          }}
        >
          <Button variant="contained" type="submit" className="bgr-global">
            Lưu
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setModalTypeFileEdit(false)
              reset()
            }}
          >
            Huỷ
          </Button>
        </Stack>
      </form>
    </BasicModal>
  )
}

export default TypeFileEdit
