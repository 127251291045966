import { Grid, Switch, Typography } from '@mui/material'
import Breadcrumb from 'components/Breadcrumbs'
import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
} from '@mui/material'
import {
  StyleTableHeader,
  StyleTitle,
  StyleHeaderItem,
  StyleTableCell,
  StyleFilter,
  StylePaper,
  StyleFilterItem,
  StyleFilterItemSearch,
} from './style'
import { useNavigate } from 'react-router'
import userApi from 'services/userAPI'
import SearchInput from 'components/InputSearch'
import useDebounce from 'hooks/useDebounce'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import BasicModal from '../../components/Modal/index'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { USER_LIST } from 'constants/breadcrumbs'
import { getSocialImg, SocialImgEnum } from '../../utils/UserUtils'

interface IDataList {
  id: number
  name: string
  phone: number
  email: string
  status: string
  social: SocialImgEnum
}

export default function UserList() {
  const [modalOpen, setModalOpen] = useState(false)
  const [dataUser, setDataUser] = useState([])
  const [dataSearch, setDataSearch] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [checked, setChecked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [status, setStatus] = useState({
    id: '',
  })
  const navigate = useNavigate()

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(event.target.checked)
  // }

  const handleOnclick = (id: any) => {
    setChecked(true)
    setModalOpen(true)
    setStatus({
      id: id,
    })
  }
  const handleClose = () => {
    setModalOpen(false)
  }

  const fetchData = async () => {
    const data: any = await userApi.getUser()

    setDataUser(data)
    setDataSearch(data)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value as string)
  }

  const handleBlock = async (id: string) => {
    await userApi.delete(id)
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (searchTerm.length >= 2) {
      setDataUser(
        dataUser.filter((item: IDataList) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase().trim())
        )
      )
    } else {
      setDataUser(dataSearch)
    }
    if (searchTerm.length === 0) {
      setSearchTerm('')
    }
  }, [debouncedSearchTerm, searchTerm])

  // useEffect(() => {
  //   if (status) {
  //     setDataUser(
  //       dataUser.filter((item: IDataList) =>
  //         item.status.toLowerCase().includes(status.toLowerCase().trim())
  //       )
  //     )
  //   } else {
  //     setDataUser(dataSearch)
  //   }
  // }, [status])

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={USER_LIST} />
      <Grid item xs={12}>
        <StylePaper className="bd-radius">
          <StyleTableHeader>
            <StyleHeaderItem>
              <StyleTitle>Danh sách khách hàng</StyleTitle>
            </StyleHeaderItem>
            <StyleHeaderItem>
              <StyleFilter>
                <StyleFilterItemSearch>
                  <SearchInput
                    onChange={handleSearch}
                    placeholder="Tìm kiếm khách hàng"
                    value={searchTerm}
                    variant="outlined"
                  />
                </StyleFilterItemSearch>
                <StyleFilterItem className="d-flex justify-center">
                  <FormControl
                    sx={{ width: 140 }}
                    size="small"
                    className="input-select"
                  >
                    <InputLabel id="demo-simple-select-label-status">
                      Tình trạng
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-status"
                      id="demo-simple-select-status"
                      // value={status}
                      label="Tình trạng"
                      // onChange={handleStatus}
                    >
                      <MenuItem value="Bình thường">Bình thường</MenuItem>
                      <MenuItem value="Cần lưu ý">Cần lưu ý</MenuItem>
                      <MenuItem value="Tất cả"> Tất cả</MenuItem>
                    </Select>
                  </FormControl>
                </StyleFilterItem>
                <StyleFilterItem>
                  <FormControl
                    sx={{ width: 120 }}
                    size="small"
                    className="input-select"
                  >
                    <InputLabel id="demo-simple-select-label-source">
                      Nguồn
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-source"
                      id="demo-simple-select-source"
                      // value={status}
                      label="Tình trạng"
                      // onChange={handleStatus}
                    >
                      <MenuItem value="Google">Google</MenuItem>
                      <MenuItem value="Facebook">Facebook</MenuItem>
                      <MenuItem value="Tiktok">Tiktok</MenuItem>
                      <MenuItem value="Bạn bè">Bạn bè</MenuItem>
                      <MenuItem value="Khác">Khác</MenuItem>
                    </Select>
                  </FormControl>
                </StyleFilterItem>
              </StyleFilter>
            </StyleHeaderItem>
          </StyleTableHeader>
          <TableContainer component={Paper} className="box-shadow">
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <StyleTableCell width={50}>STT</StyleTableCell>
                  <StyleTableCell align="left" width={250}>
                    Tên
                  </StyleTableCell>
                  <StyleTableCell align="left">Số điện thoại</StyleTableCell>
                  <StyleTableCell align="left" width={150}>
                    Email
                  </StyleTableCell>
                  <StyleTableCell align="center">Tình trạng</StyleTableCell>
                  <StyleTableCell align="center">Nguồn</StyleTableCell>
                  <StyleTableCell align="center">Khóa</StyleTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataUser
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: IDataList, index: number) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {index + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell
                        align="left"
                        onClick={() => navigate('/user/detail')}
                        sx={{
                          '&:hover': { opacity: 0.6 },
                          cursor: 'pointer',
                        }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="center">
                        <div className="d-flex align-center justify-center">
                          {row.status === 'Bình thường' ? (
                            <DoneAllIcon sx={{ color: '#6ccc99' }} />
                          ) : (
                            <WarningAmberIcon sx={{ color: '#f39a13' }} />
                          )}
                          <Typography
                            fontSize="14px"
                            color={
                              row.status === 'Bình thường'
                                ? '#6ccc99'
                                : '#f39a13'
                            }
                            marginLeft="5px"
                          >
                            {row.status}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex align-center justify-center">
                          <img
                            src={getSocialImg(row.social)}
                            alt={row.social}
                            width={24}
                            height={24}
                          />
                          <Typography
                            fontSize="14px"
                            color={'#64748B'}
                            marginLeft="5px"
                          >
                            {row.social}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        onClick={() => handleOnclick(row.id)}
                      >
                        <Switch
                          checked={checked}
                          // onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                {modalOpen && (
                  <BasicModal open={modalOpen} handleClose={handleClose}>
                    <Typography style={{ fontWeight: 'bold' }}>
                      Bạn có chắc chắn muốn khóa Khách Hàng này không?
                    </Typography>
                    <Button
                      sx={{ margin: '8px', marginBottom: '0px' }}
                      variant="outlined"
                      color="error"
                      onClick={() => handleBlock(status?.id)}
                    >
                      Khóa
                    </Button>
                    <Button
                      sx={{ margin: '8px', marginBottom: '0px' }}
                      onClick={handleClose}
                      variant="outlined"
                    >
                      Hủy
                    </Button>
                  </BasicModal>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 15, 25]}
              component="div"
              count={dataUser?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Số dòng trên một trang"
            />
          </TableContainer>
        </StylePaper>
      </Grid>
    </Grid>
  )
}
