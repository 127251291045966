import React, { useState, useRef, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { useNavigate } from 'react-router-dom'
import MoreIcon from '@mui/icons-material/MoreVert'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import {
  ListDetails,
  Profile,
  Logout,
  Icons,
  HeaderProfile,
  IconLogout,
  StyleLogo,
  StyleTitleLogo,
} from './style'
import useToken from 'hooks/useToken'
import useOnClickOutside from 'hooks/useOnClickOutside'
import ChangePassWord from 'pages/profile/components/ChangePassWord'
import BasicModal from 'components/Modal'
import ProfileApi from 'services/profileAPI'
import logo from 'assets/images/logo2.png'
import { useLoading } from 'contexts/LoadingContext'

interface IProps {
  handleDrawerOpen: () => void
  open: boolean
}
const Header: React.FC<IProps> = (props) => {
  const navigate = useNavigate()
  const ref = useRef(null)
  useOnClickOutside(ref, () => setIsOpen(false))
  const { remove } = useToken()
  const [isOpen, setIsOpen] = useState(false)
  const { handleDrawerOpen, open } = props
  const menuId = 'primary-search-account-menu'
  const mobileMenuId = 'primary-search-account-menu-mobile'
  const { setLoadingTrue, setLoadingFalse } = useLoading()
  const [profile, setProfile] = useState<IProfile>()
  const { token } = useToken()
  const [modalOpen, setModalOpen] = useState(false)
  const handleClose = () => setModalOpen(false)
  const handleOff = () => setModalOpen(false)

  const logout = async () => {
    setLoadingTrue()
    const timer = setTimeout(() => {
      setLoadingFalse()
      remove()
      location.reload()
    }, 500)
    return () => clearTimeout(timer)
  }

  const fetchData = async () => {
    try {
      const { data } = await ProfileApi.getUser()
      await setProfile(data)
    } catch (error) {
      navigate('/auth/login')
    }
  }

  useEffect(() => {
    if (token) {
      fetchData()
    }
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          width: '100%',
          zIndex: 999,
        }}
        className="bgr-global box-none"
      >
        <Toolbar>
          <IconButton
            onClick={handleDrawerOpen}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            {!open ? <MenuIcon /> : <ArrowBackIosIcon />}
          </IconButton>
          <StyleLogo>
            <img src={logo} alt="logo 3 clean" width={24} height={24} />
            <StyleTitleLogo>3CLEAN</StyleTitleLogo>
          </StyleLogo>

          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            Home
          </Typography>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {/* <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton> */}
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <HeaderProfile>{profile?.fullName}</HeaderProfile>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={() => setIsOpen(!isOpen)}
              color="inherit"
            >
              <AccountCircle />
              {isOpen && (
                <ListDetails ref={ref}>
                  <Profile onClick={() => navigate('/profile')}>
                    Thông tin cá nhân
                  </Profile>
                  <Profile onClick={() => setModalOpen(true)}>
                    Đổi mật khẩu
                  </Profile>
                  <Icons onClick={logout}>
                    <Logout> Đăng xuất</Logout>
                    <IconLogout fontSize="small" />
                  </Icons>
                </ListDetails>
              )}
            </IconButton>
          </Box>
          {modalOpen && (
            <BasicModal open={modalOpen}>
              <ChangePassWord handleClose={handleClose} handleOff={handleOff} />
            </BasicModal>
          )}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header
