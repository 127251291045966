import React from 'react'
import Breadcrumb from 'components/Breadcrumbs'
import { Grid } from '@mui/material'
import Item from 'components/Item'
import EditProfile from './components/Edit'
import { PROFILE_EDIT } from 'constants/breadcrumbs'

const Profile = () => {
  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={PROFILE_EDIT} />
      <Grid item xs={12}>
        <Item sx={{ padding: '14px 24px 24px' }}>
          <EditProfile />
        </Item>
      </Grid>
    </Grid>
  )
}

export default Profile
