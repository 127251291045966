import styled from 'styled-components'

export const ActionButton = styled.div`
  :hover {
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    width: 40px;
    padding: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transform: scale(1.1);
  }
`
