import { uploadImgApi } from 'services/uploadImage'

export const uploadImage = async (file: File) => {
  if (file) {
    const uploaded = await uploadImgApi.uploadImg(file)
    return uploaded?.data
  }
}

export const uploadMultipleImage = async (data: File[]) => {
  if (data) {
    const uploaded = await uploadImgApi.uploadMultipleImg(data)
    return uploaded?.data
  }
}
