import styled from 'styled-components'

export const StyleForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const StyleHeaderItem = styled.div`
  color: #333333;
  display: flex;
  justify-content: space-between;
`

export const StyleTitle = styled.h2`
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 24px;
  color: var(--color-primary);
`

export const StyleContentRight = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 24px;
  border-right: 0.0625rem solid rgb(239, 239, 239);
`
export const StyleContentLeft = styled.div`
  width: 40%;
  padding-left: 24px;
`

export const StyleActionButton = styled.div`
  display: flex;
  /* justify-content: center; */
  /* padding-top: 30px; */
  border-top: 0.0625rem solid rgb(239, 239, 239);
`

export const StyleContentItem = styled.div`
  width: 100%;
  padding-bottom: 24px;
  text-align: left;
`
export const StyleTitleDescribe = styled.h3`
  font-weight: 600;
  color: var(--color-primary);
  text-align: left;
`
export const StyleSubTitle = styled.div`
  width: 300px;
  text-align: left;
  font-size: 12px;
  color: var(--color-primary);
`
export const StyleInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  color: var(--color-primary);
`

export const StyleCheckbox = styled.div`
  width: 100%;
`
export const StylePrice = styled.span`
  font-weight: 600;
`
export const StyleError = styled.p`
  margin-bottom: 12px;
  display: block;
  color: red;
  font-size: 14px;
  text-align: left;
  white-space: normal;
`

export const StyleInputField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StyleFeedback = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0px;
  justify-content: space-between;
`

export const StyleFeedbackTitle = styled.div`
  width: 30%;
`

export const StyleFeedbackContent = styled.div`
  width: 60%;
`
