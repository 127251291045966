import styled from 'styled-components'

export const StyleHeaderItem = styled.div`
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 24px;
`
export const StyleTitle = styled.h2`
  font-size: 25px;
  color: var(--color-primary);
  font-weight: bold;
  text-align: left;
`
export const StyleLink = styled.span`
  font-weight: bold;
`
export const StyleLinks = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  width: 150px;
  border-radius: 6px;
  border: none;
  padding: 8px 6px;
  margin: 12px 0;
  transition: 0.1s ease-in-out;
  transition: all 600ms ease-in-out 0s;
  background: var(--bgr-primary);
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
`

export const StyleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const StyleWrapLeft = styled.div``

export const StyleWrapRight = styled.div``

export const StyleWrapTop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`

export const StyleWrapBottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 24px;
`
