import styled from 'styled-components'

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  color: #5f5f60;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`
export const Back = styled.span`
  color: #6c7cec;
  && {
    font-size: 16px;
  }
`
