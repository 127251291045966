import React, { useState, useEffect, ChangeEvent } from 'react'
import Breadcrumb from 'components/Breadcrumbs'
import { Grid, Button, Stack, Typography } from '@mui/material'
import Item from 'components/Item'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  StyleAddImage,
  StyleError,
  StyleHeaderItem,
  StyleImage,
  StyleTitle,
  StyleForm,
  StyleContentRight,
  StyleErrorImage,
} from './style'
import { useAlert } from 'contexts/AlertContext'
import { yupResolver } from '@hookform/resolvers/yup'
import { newsSchema_ } from 'validations/newsSchema'
import { newsAPI } from 'services/newsAPI'
import { useLoading } from 'contexts/LoadingContext'
import InputField from 'components/FormControl/InputField'
import { StyleAddItem, StyleLink, StyleLinks } from '../AddNews/style'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import { IMAGE_TYPE, MAX_FILE_SIZE } from 'constants/common'
import { getFileSize, FileTypeEnum } from 'utils/FileUtils'
import { NEWS_EDIT } from 'constants/breadcrumbs'
import { uploadImage } from 'utils/uploadImage'
import BasicModal from 'components/Modal'
import { checkChangeData } from 'utils/confirmBox'

const EditNews = () => {
  const [data, setData] = useState<INews>({
    id: 1,
    title: '',
    description: '',
    link: '',
  })
  const [dataSave, setDataSave] = useState<INews | null | undefined>()
  const [error, setError] = useState('')
  const [image, setImage] = useState('')
  const [imageURL, setImageURL] = useState('')
  const [imageData, setDataImg] = useState('')
  const [modalSave, setModaSave] = useState<boolean>(false)
  const [modalCancel, setModaCancel] = useState<boolean>(false)
  const navigate = useNavigate()
  const { id } = useParams()

  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertError, setAlertSuccess } = useAlert()

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm<INews>({
    resolver: yupResolver(newsSchema_),
    mode: 'onChange',
  })

  useEffect(() => {
    setValue('title', data?.title)
    setValue('description', data?.description)
    setValue('link', data?.link)
    setValue('priority', data?.priority)
  }, [data])

  useEffect(() => {
    const getCharacters = async () => {
      const { data } = await newsAPI.getNewsById(id as string)
      setData(data)
      setDataImg(data.thumbnail)
    }
    getCharacters().catch(console.error)
  }, [id])

  const onSubmit = async () => {
    const dataNews = { ...dataSave, thumbnail: imageURL || imageData }
    setLoadingTrue()
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await newsAPI.updateNews(dataNews as any, id as string)
      setLoadingFalse()
      setAlertSuccess('Lưu thay đổi thành công')
      navigate('/marketing/news')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoadingFalse()
      setAlertError(error.response?.data?.message)
    }
  }

  useEffect(() => {
    setValue('title', data?.title)
    setValue('description', data?.description)
    setValue('link', data?.link)
    setValue('priority', data?.priority)
  }, [data])

  const handleUpload = async (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    const fileSize: number = getFileSize(file.size, FileTypeEnum.MB)
    if (file) {
      if (fileSize > MAX_FILE_SIZE) {
        setError(`Dung lượng ảnh tối đa cho phép là ${MAX_FILE_SIZE} MB`)
        return
      }
      if (!file?.type.match(IMAGE_TYPE)) {
        setError('Ảnh chỉ bao gồm định dạng JPEG, JPG, PNG, SVG')
        return
      }
      if (fileSize === 0) {
        setError(`Ảnh lỗi`)
        return
      }
      const response: IUploadImg = await uploadImage(file)
      setImageURL(response.origin)
      setImage(URL?.createObjectURL((target.files as FileList)[0]))
      setError('')
    }
  }

  const handleClose = () => {
    setModaSave(false)
  }

  const handleCheckDataSave = () => {
    const getValuesItem = getValues()
    const isChanged = checkChangeData({
      dataChange: getValuesItem,
      dataDefault: data,
    })
    if (isChanged || imageURL) {
      setModaSave(true)
    } else {
      navigate('/marketing/news')
    }
  }

  const handleCheckDataCancel = () => {
    const getValuesItem = getValues()
    const isChanged = checkChangeData({
      dataChange: getValuesItem,
      dataDefault: data,
    })
    if (isChanged || imageURL) {
      setModaCancel(true)
    } else {
      navigate('/marketing/news')
    }
  }

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={NEWS_EDIT} />
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <StyleHeaderItem>
            <StyleTitle>Chỉnh sửa Blog</StyleTitle>
          </StyleHeaderItem>
          <StyleForm
            autoComplete="off"
            onSubmit={handleSubmit((data: INews) => {
              setDataSave(data)
              handleCheckDataSave()
            })}
            // handleSubmit(handleCheckData, data: INews) =>  }
          >
            <StyleImage>
              {image ? (
                <StyleAddImage>
                  <img src={image} alt="img news" />
                  <StyleAddItem>
                    <StyleLinks>
                      <CameraAltOutlinedIcon />
                      <StyleLink>Chỉnh sửa ảnh bìa</StyleLink>
                    </StyleLinks>
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={handleUpload}
                      name="image"
                    />
                  </StyleAddItem>
                </StyleAddImage>
              ) : (
                <StyleAddImage>
                  <img src={data?.thumbnail} alt="img news" />
                  <StyleAddItem>
                    <StyleLinks>
                      <CameraAltOutlinedIcon />
                      <StyleLink>Chỉnh sửa ảnh bìa</StyleLink>
                    </StyleLinks>
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={handleUpload}
                    />
                  </StyleAddItem>
                </StyleAddImage>
              )}
              {error && <StyleErrorImage>{error}</StyleErrorImage>}
            </StyleImage>
            <StyleContentRight className="d-flex">
              <InputField
                autoFocus
                {...register('title')}
                label="Tiêu đề "
                multiline={true}
                name="title"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors.title && (
                <StyleError>{errors.title.message as string}</StyleError>
              )}
              <InputField
                {...register('description')}
                id="outlined-required"
                label="Nội dung ngắn"
                multiline={true}
                name="description"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors.description && (
                <StyleError>{errors.description.message as string}</StyleError>
              )}
              <InputField
                {...register('link')}
                label="Link"
                multiline={true}
                id="outlined-required"
                name="link"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {errors.link && (
                <StyleError>{errors.link.message as string}</StyleError>
              )}
              <InputField
                {...register('priority')}
                label="Độ ưu tiên của bài viết"
                autoCompleted="famili-name"
                multiline={true}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: '150px !important',
                  float: 'left !important',
                }}
              />
              {errors.priority && (
                <StyleError>{errors.priority.message as string}</StyleError>
              )}
              <Stack
                spacing={2}
                direction="row"
                sx={{ paddingTop: '10px', gap: '8px' }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  className="bgr-global"
                >
                  Lưu
                </Button>
                <Button variant="outlined" onClick={handleCheckDataCancel}>
                  Hủy
                </Button>
              </Stack>
              <BasicModal open={modalSave} handleClose={handleClose}>
                <Typography>Bạn có chắc chắn muốn lưu thay đổi?</Typography>
                <Button
                  sx={{
                    margin: '20px',
                    marginBottom: '0px',
                    paddingLeft: '43px',
                    paddingRight: '43px',
                  }}
                  variant="contained"
                  onClick={() => onSubmit()}
                  className="bgr-global"
                >
                  Lưu
                </Button>
                <Button
                  sx={{
                    margin: '20px',
                    marginBottom: '-0.5px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                  }}
                  onClick={() => setModaSave(false)}
                  variant="outlined"
                >
                  Không
                </Button>
              </BasicModal>
              <BasicModal open={modalCancel}>
                <Typography>Bạn có chắc chắn muốn hủy thay đổi?</Typography>
                <Button
                  sx={{
                    margin: '20px',
                    marginBottom: '0px',
                    paddingLeft: '43px',
                    paddingRight: '43px',
                  }}
                  variant="contained"
                  onClick={() => navigate('/marketing/news')}
                  className="bgr-global"
                >
                  Hủy
                </Button>
                <Button
                  sx={{
                    margin: '20px',
                    marginBottom: '-0.5px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                  }}
                  onClick={() => setModaCancel(false)}
                  variant="outlined"
                >
                  Không
                </Button>
              </BasicModal>
            </StyleContentRight>
          </StyleForm>
        </Item>
      </Grid>
    </Grid>
  )
}

export default EditNews
