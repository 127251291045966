const bannerTypeAction = [
  {
    value: 'service',
    label: 'Dịch vụ',
  },
  {
    value: 'link',
    label: 'Link',
  },
  {
    value: 'inviteFriend',
    label: 'Mời bạn bè',
  },
  {
    value: 'voucher',
    label: 'Ưu đãi',
  },
]

const bannerStatus = [
  {
    value: 'true',
    label: 'Hiển thị',
  },
  {
    value: 'false',
    label: 'Ẩn',
  },
]

const bannerPriority = [
  {
    value: 1,
    label: 'Theo thời gian',
  },
  {
    value: 2,
    label: 'Theo độ ưu tiên',
  },
]

enum FilterBannerType {
  ALL = 1,
  PRIORITY = 2,
}

export { bannerTypeAction, bannerStatus, bannerPriority, FilterBannerType }
