import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Stack, Typography } from '@mui/material'
import InputField from 'components/FormControl/InputField'
import BasicModal from 'components/Modal'
import { labelColor } from 'constants/partTimeStaff'
import { useAlert } from 'contexts/AlertContext'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import staffApi from 'services/staffAPI'
import { labelSchema_ } from 'validations/partTimeStaffSchema'

import {
  StyleChoiceColor,
  StyleError,
  StyleHeaderItem,
  StyleTitle,
} from './style'
import Close from 'components/Icons/components/Close'

interface IProps {
  id: any
  modalLabelEdit: boolean
  setModalLabelEdit: any
}

const LabelEdit: React.FC<IProps> = (props) => {
  const { id, modalLabelEdit, setModalLabelEdit } = props
  const { setAlertError, setAlertSuccess } = useAlert()
  const [LabelId, setLabelId] = useState<any>([])

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(labelSchema_),
    mode: 'onChange',
  })

  const [backgroundColor, setBackgroundColor] = useState('')
  const [backgroundColorError, setBackgroundColorError] = useState('')

  const fetchDataLabelId = async () => {
    const { data } = await staffApi.getLabelById(id?.id)
    setLabelId(data)
    setBackgroundColor(data?.color)
    setValue('name', data?.name)
  }

  const handleColorChange = (color: any) => {
    setBackgroundColor(color)
  }

  const onSubmit = async (data: any) => {
    if (backgroundColor == '') {
      setBackgroundColorError('Vui lòng chọn màu')
    } else if (backgroundColor) {
      try {
        const dataFormat = { ...data, color: backgroundColor }
        await staffApi.updateLabel(dataFormat, id?.id)
        setBackgroundColorError('')
        setAlertSuccess('Sửa nhãn thành công')
        setModalLabelEdit(false)
        setBackgroundColor('')
      } catch (error: any) {
        setAlertError(error?.response?.data?.message)
      }
    }
  }

  useEffect(() => {
    fetchDataLabelId()
  }, [id])

  return (
    <BasicModal
      open={modalLabelEdit}
      handleClose={() => {
        setLabelId([])
        setModalLabelEdit(false)
        setBackgroundColor('')
      }}
    >
      <StyleHeaderItem>
        <StyleTitle>Sửa giá trị</StyleTitle>
        <Close
          onClick={() => {
            setLabelId([])
            setModalLabelEdit(false)
            setBackgroundColor('')
          }}
        />
      </StyleHeaderItem>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'flex', gap: '24px' }}>
          <Typography
            sx={{ display: 'flex', minWidth: '50px', alignItems: 'center' }}
            variant="body2"
            gutterBottom
          >
            Giá trị*:
          </Typography>

          <div style={{ width: '100%' }}>
            <InputField
              {...register('name')}
              defaultValue={LabelId?.name}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
            <StyleError style={{ marginBottom: '10px' }}>
              {errors.name?.message as string}
            </StyleError>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            gap: '24px',
          }}
        >
          <Typography
            sx={{ display: 'flex', minWidth: '50px', alignItems: 'center' }}
            variant="body2"
            gutterBottom
          >
            Màu:
          </Typography>
          <div>
            <div
              style={{
                display: 'flex',
                gap: '3.5px',
                border: '1px outset',
              }}
            >
              {labelColor?.map((color: any, index: number) => (
                <StyleChoiceColor
                  key={index}
                  style={{
                    backgroundColor: color.value,
                    border: backgroundColor == color.value ? '2px solid ' : '',
                    transform:
                      backgroundColor == color.value ? 'scale(1.3)' : '',
                  }}
                  onClick={() => handleColorChange(color.value)}
                ></StyleChoiceColor>
              ))}
            </div>
            <StyleError style={{ marginTop: '10px' }}>
              {backgroundColorError}
            </StyleError>
          </div>
        </div>

        <Stack
          spacing={2}
          direction="row"
          sx={{
            justifyContent: 'flex-start',
            paddingTop: '14px',
          }}
        >
          <Button variant="contained" type="submit" className="bgr-global">
            Lưu
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setBackgroundColor('')
              setModalLabelEdit(false)
              setLabelId([])
            }}
          >
            Huỷ
          </Button>
        </Stack>
      </form>
    </BasicModal>
  )
}

export default LabelEdit
