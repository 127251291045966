import axiosClient from '../utils/axios'

export const servicesAPI = {
  getServices: () => {
    const url = `/service?sortType=ASC&sortBy=kind`
    return axiosClient.get(url)
  },
  getServiceById: (id: string) => {
    const url = `/service/${id}`
    return axiosClient.get(url)
  },
  updateService: (data: any, id: string) => {
    const url = `/service/${id}`
    return axiosClient.put(url, data)
  },
}

export const ConfigurePriceAPI = {
  getPriceArea: (params: any) => {
    const url = `/price-list?serviceId=${params.serviceId}`
    return axiosClient.get(url)
  },
  getPriceTable: (params: any) => {
    const url = `/price-area-service-item?serviceId=${params.serviceId}&provinceId=${params?.province}`
    return axiosClient.get(url)
  },
  updatePriceTable: (data: any, id: string) => {
    const url = `/price-area-service-item/${id}`
    return axiosClient.put(url, data)
  },
  getPriceListCountry: (params: any) => {
    const url = `/price-list/detail-list?serviceId=${params.serviceId}&provinceId=${params?.province}`
    return axiosClient.get(url, params)
  },
  updatePriceListCountryById: (data: any, id: string) => {
    const url = `/price-list/${id}`
    return axiosClient.put(url, data)
  },
  deletePriceListCountry: (id: number) => {
    const url = `/price-list/${id}`
    return axiosClient.delete(url)
  },
  getPriceListDistrict: (params: any) => {
    const url = `/price-list?serviceId=${params.serviceId}&provinceId=${params?.provinceId}&districtId=${params?.districtId}`
    return axiosClient.get(url, params)
  },
  createPriceListDistrict: (data: any) => {
    const url = `/price-list`
    return axiosClient.post(url, data)
  },
  createPriceListCountry: (data: any) => {
    const url = `/price-list`
    return axiosClient.post(url, data)
  },
  deletePriceListDistrict: (id: number) => {
    const url = `/price-list/${id}`
    return axiosClient.delete(url)
  },
}

export const extraContentAPI = {
  //Điều khoản và nội dung
  getExtraContent: (serviceId: string) => {
    const url = `/service/${serviceId}/extraContent`
    return axiosClient.get(url)
  },

  createExtraContent: (data: any, serviceId: string) => {
    const url = `/service/${serviceId}/extraContent`
    return axiosClient.post(url, data)
  },

  updateExtraContent: (
    data: any,
    serviceId: string,
    extraContentId: string
  ) => {
    const url = `/service/${serviceId}/extraContent/${extraContentId}`
    return axiosClient.patch(url, data)
  },

  deleteExtraContent: (serviceId: string, extraContentId: string) => {
    const url = `/service/${serviceId}/extraContent/${extraContentId}`
    return axiosClient.delete(url)
  },
}
