import React, { useState, useEffect } from 'react'
import Breadcrumb from 'components/Breadcrumbs'
import { Grid, Button, Stack, MenuItem } from '@mui/material'
import Item from 'components/Item'
import { Controller, useForm } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import InputField from 'components/FormControl/InputField'
import { useNavigate } from 'react-router-dom'
import {
  StyleForm,
  StyleHeaderItem,
  StyleTitle,
  StyleContentRight,
  StyleTitleDescribe,
  StyleContentItem,
  StyleInput,
  StylePrice,
  StyleActionButton,
  StyleError,
  StyleInputField,
  StyleContentLeft,
  StyleTitleDescribePopup,
} from './style'
import { useAlert } from 'contexts/AlertContext'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLoading } from 'contexts/LoadingContext'
import { ORDER_ADD } from 'constants/breadcrumbs'
import Checkbox from '@mui/material/Checkbox'
import { orderSchema_ } from 'validations/orderSchema'
import orderAPI from 'services/orderAPI'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import AddLocationTwoToneIcon from '@mui/icons-material/AddLocationTwoTone'
import BasicModal from 'components/Modal'
import dayjs from 'dayjs'
import useDebounce from 'hooks/useDebounce'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'

const paymentMethod = [
  {
    title: 'Tiền mặt',
    name: 'cash',
  },
]

const options = [{ fullLocation: 'Chưa có lựa chọn', value: 1 }]

const AddOrder = () => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(localizedFormat)
  dayjs.tz.setDefault('Asia/Ho_Chi_Minh')
  const [checkedItems, setCheckedItems] = useState<any>({})
  const [dataUser, setDataUser] = useState<any[]>([])
  const [dataStaff, setDataStaff] = useState<any[]>([])
  const [keySearch, setKeySearch] = useState('')
  const [idUser, setIdUser] = useState<any>()
  const [dataServices, setDataServices] = useState([])
  const [valueService, setValueService] = useState('')
  const [dataServicesItem, setDataServicesItem] = useState<any>()
  const [valueServiceItem, setValueServiceItem] = useState<any>([])
  const [valuePaymentMethod, setValuePaymentMethod] = useState('')
  const [calcPrice, setCalcPrice] = useState<any>()
  const [selectLocation, setSelectLocation] = useState<any>([])
  const [locationUser, setLocationUser] = useState<any>([])
  const [selectedMode, setSelectedMode] = useState<any>()
  const [itemOptions, setItemOptions] = useState('')
  const [checked, setChecked] = useState<string[]>([])
  const [valueLocation, setValueLocation] = useState<any>('')
  const [addLocation, setAddLocation] = useState<any>('')
  const [isChanged, setIsChanged] = useState(false)
  const [openModalLocation, setOpenModaLocation] = useState(false)
  const [openModalUser, setOpenModaUser] = useState(false)
  const [isCalc, setIsCalc] = useState(false)
  const [isAddLocation, setIsAddLocation] = useState(false)
  const [startTime, setStartTime] = useState<any>()
  const [endTime, setEndTime] = useState<any>()
  const [hourPeriods, setHourPeriods] = useState<any>()
  const [phoneUser, setPhoneUser] = useState('')
  const [phoneStaff, setPhoneStaff] = useState('')
  const [displayIdStaff, setDisplayIdStaff] = useState('')
  const [nameUser, setNameUser] = useState('')
  const [idStaff, setIdStaff] = useState<any>()
  const [dataCount, setDataCount] = useState(0)
  const [dataCountStaff, setDataCountStaff] = useState(0)
  const [isPetError, setIsPetError] = useState('')
  const [isPet, setIsPet] = useState<boolean>()
  const [valuePet, setValuePet] = useState('')
  const [promotion, setPromotion] = useState('')
  const [errorPromotion, setErrorPromotion] = useState('')
  const [openPopUp, setOpenPopUp] = useState(false)

  const navigate = useNavigate()
  const debouncedValue = useDebounce<string>(phoneUser, 500)
  const debouncedValueMap = useDebounce<string>(keySearch, 500)

  const handleChangePromotion = (event: any) => {
    setPromotion(event.target.value)
  }

  const { setLoadingFalse, setLoadingTrue } = useLoading()
  const { setAlertSuccess, setAlertError } = useAlert()

  const handleHourPlus = (value: any) => {
    const timeArray = value?.split(':')
    const hour = parseInt(timeArray?.[0])
    const minute = parseInt(timeArray?.[1])
    const date = new Date()
    date.setHours(hour + hourPeriods)
    date.setMinutes(minute)
    const result = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })
    if (result.includes('PM') || result.includes('AM')) {
      const endTime = dayjs(result, 'hh:mm A').locale('vi').format('HH:mm')
      setEndTime(endTime)
    } else {
      setEndTime(result)
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(orderSchema_),
    mode: 'onChange',
  })

  const isSelectCalc = getValues()

  const fetchDataCalc = async () => {
    const getValueCalc = getValues()
    if (isSelectCalc) {
      getValueCalc.serviceId = valueService
      getValueCalc.serviceItems = [
        {
          id: valueServiceItem,
          count: 1,
          itemOptions: itemOptions.length ? [itemOptions] : [],
        },
      ]
      getValueCalc.locationId = valueLocation?.id
      getValueCalc.serviceOptions = checked
      getValueCalc.promotionCode = promotion
      if (isCalc) {
        try {
          const dataTotalOrder: any = await orderAPI.createCalcOrder(
            getValueCalc
          )
          setCalcPrice(dataTotalOrder?.data)
          setErrorPromotion('')
        } catch (error: any) {
          setErrorPromotion(
            error?.response?.data?.message === 'Bad request'
              ? 'Mã khuyến mãi không hợp lệ'
              : ''
          )
          setAlertError(
            error?.response?.data?.message === 'SERVICE_LOCATION_UNSUPPORTED'
              ? 'Vị trí dịch vụ không được hỗ trợ'
              : error?.response?.data?.message
          )
          setCalcPrice('')
        }
      }
    }
  }

  useEffect(() => {
    fetchDataCalc()
  }, [valueServiceItem, promotion, checkedItems])

  useEffect(() => {
    setValue('name', idUser?.fullName ?? '')
    setValue('phone', idUser?.phone ?? '')
    setValue('phoneContact', idUser?.phone ?? '')
    setValue('nameContact', idUser?.fullName ?? '')
    setValue('fullLocation', valueLocation ?? '')
    setValue('paymentMethod', valuePaymentMethod ?? '')
    setValue('serviceItems', valueServiceItem ?? '')
  }, [idUser, isChanged])

  useEffect(() => {
    setValue('nameStaff', idStaff?.fullName ?? '')
    setValue('displayId', idStaff?.displayId ?? '')
    setValue('phoneStaff', idStaff?.phone ?? '')
  }, [idStaff])

  const fetchDataListUser = async () => {
    const { data } = await orderAPI.getUser({
      search: phoneUser,
    })
    setDataUser(data.rows)
    setDataCount(data.count)
  }

  const fetchDataListStaff = async () => {
    const { data } = await orderAPI.getStaff({
      search: phoneStaff,
    })
    setDataStaff(data.rows)
    setDataCountStaff(data.count)
  }

  const fetchDataListStaffId = async () => {
    const { data } = await orderAPI.getStaff({
      displayId: displayIdStaff,
    })
    setDataStaff(data.rows)
    setDataCountStaff(data.count)
  }

  const fetchDataListServices = async () => {
    const { data } = await orderAPI.getServices()
    setDataServices(data)
  }

  const fetchDataServicesItem = async () => {
    const { data } = await orderAPI.getServiceById(valueService as string)
    setDataServicesItem(data)
  }

  const handleCheckboxChange = (event: any) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    })
    if (event.target.checked == true) {
      setItemOptions(event?.target?.id)
    } else {
      setItemOptions('')
    }
  }

  const handleServiceOptions = (value: any, name: any) => {
    if (name == 'pet' && checked.indexOf(value)) {
      setIsPet(true)
    } else {
      setIsPet(false)
    }
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const handleChangeService = (event: React.SyntheticEvent, newValue: any) => {
    setValueService(newValue?.props?.value)
  }

  const handleChangeServiceItem = (
    event: React.SyntheticEvent,
    newValue: any
  ) => {
    setValueServiceItem(newValue?.props?.value)
    setIsCalc(true)
  }

  const handlePetChange = (e: any) => {
    setValuePet(e.target.value)
    if (e.target.value) {
      setIsPetError('')
    } else {
      setIsPetError('Vật nuôi không được để trống')
    }
  }

  const handleChangePaymentMethod = (event: any) => {
    setValuePaymentMethod(event.target.value)
  }
  const onSubmit = async (dataCreateOrder: any) => {
    if (isPet) {
      if (dataCreateOrder.pet) {
        setIsPetError('')
      } else {
        setIsPetError('Vật nuôi không được để trống')
        return
      }
    }
    const date = dayjs
      ?.utc(dataCreateOrder?.date)
      ?.tz('Asia/Ho_Chi_Minh')
      ?.format('YYYY-MM-DD')
    dataCreateOrder.userId = idUser?.id
    dataCreateOrder.startTime = new Date(`${date} ${startTime}`)?.toISOString()
    dataCreateOrder.endTime = new Date(`${date} ${endTime}`)?.toISOString()
    dataCreateOrder.isRemember = false
    ;(dataCreateOrder.orderProfile = {
      name: dataCreateOrder.nameContact,
      phone: dataCreateOrder.phoneContact,
    }),
      (dataCreateOrder.locationId = valueLocation?.id)
    dataCreateOrder.serviceItems = [
      {
        id: valueServiceItem,
        count: 1,
        itemOptions: itemOptions.length ? [itemOptions] : [],
      },
    ]
    dataCreateOrder.serviceOptions = checked
    dataCreateOrder.fullLocation = valueLocation?.address
    dataCreateOrder.promotionCode = promotion
    dataCreateOrder.staffId = idStaff?.id

    setLoadingTrue()
    try {
      await orderAPI.createOrder(dataCreateOrder)
      setLoadingFalse()
      setAlertSuccess('Thêm đơn hàng thành công!')
      navigate('/order')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoadingFalse()
      setAlertError(
        error?.response?.data?.message === 'SERVICE_LOCATION_UNSUPPORTED'
          ? 'Vị trí dịch vụ không được hỗ trợ'
          : error?.response?.data?.message
      )
    }
  }

  useEffect(() => {
    if (phoneUser || isAddLocation) {
      fetchDataListUser()
    }
  }, [debouncedValue, isAddLocation])

  useEffect(() => {
    if (phoneStaff) {
      fetchDataListStaff()
    }
  }, [phoneStaff])

  useEffect(() => {
    if (displayIdStaff) {
      fetchDataListStaffId()
    }
  }, [displayIdStaff])

  useEffect(() => {
    if (idUser) {
      fetchDataListServices()
    }
  }, [idUser])

  useEffect(() => {
    fetchDataServicesItem()
  }, [valueService])

  const handleInputChange = async (event: any, value: any) => {
    setKeySearch(event.target.value)
  }

  const handleSearchUser = async (event: any, value: any) => {
    setPhoneUser(event.target.value)
  }

  const handleSearchStaff = async (event: any, value: any) => {
    setPhoneStaff(event.target.value)
    setDisplayIdStaff('')
  }

  const handleSearchStaffId = async (event: any, value: any) => {
    setDisplayIdStaff(event.target.value)
    setPhoneStaff('')
  }

  const dataLocation = {
    userId: idUser?.id,
    name: addLocation?.name,
    fullLocation: addLocation?.address,
    longitude: addLocation?.location?.lng,
    latitude: addLocation?.location?.lat,
  }

  const fetchAddLocation = async () => {
    if (valueLocation && idUser) {
      await orderAPI.addLocation(dataLocation)
    }
  }

  useEffect(() => {
    if (keySearch) {
      setTimeout(() => {
        orderAPI.getLocation(keySearch).then((respone: any) => {
          if (respone.data.result) {
            setSelectLocation(respone.data.result)
          }
        })
      }, 500)
    }
  }, [debouncedValueMap])

  useEffect(() => {
    if (idUser || isAddLocation) {
      orderAPI.getAddLocation(idUser?.id).then((respone: any) => {
        const defaultSelectedMode = respone?.data?.rows?.find(
          (item: any) => item.defaultLocation === true
        )
        setLocationUser(respone?.data?.rows)
        setSelectedMode(defaultSelectedMode)
      })
    }
  }, [idUser, isAddLocation])

  const handleAddLocation = async () => {
    setLoadingTrue()
    await fetchAddLocation()
    await orderAPI.getAddLocation(idUser?.id).then((respone: any) => {
      const defaultSelectedMode = respone?.data?.rows?.find(
        (item: any) => item.defaultLocation === true
      )
      setLocationUser(respone?.data?.rows)
      setSelectedMode(defaultSelectedMode)
      setLoadingFalse()
      setOpenModaLocation(false)
      setAlertSuccess('Thêm địa chỉ thành công!')
    })
  }

  const dataSignUpUser = {
    phone: phoneUser,
    fullName: nameUser,
  }

  const handleAddUser = async () => {
    setLoadingTrue()
    try {
      await orderAPI.addUser(dataSignUpUser)
      await fetchDataListUser()
      setOpenModaUser(false)
      setAlertSuccess('Tạo mới khách hàng thành công!')
      setLoadingFalse()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoadingFalse()
      setOpenModaUser(false)
    }
  }

  useEffect(() => {
    const hour: any = dataServicesItem?.serviceItems?.filter((item: any) =>
      valueServiceItem?.includes(item?.id)
    )
    setHourPeriods(hour?.[0]?.hourPeriods)
  }, [valueServiceItem])

  useEffect(() => {
    handleHourPlus(startTime)
  }, [hourPeriods])

  const handleOpenModalUser = () => {
    setOpenModaUser(true)
    setValue('name', '')
    setValue('phoneContact', '')
    setValue('nameContact', '')
  }

  const validatePhone = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g.test(phoneUser)
  const validatePhoneStaff = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g.test(phoneStaff)

  const handleKeyPress = (event: any) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  }

  return (
    <Grid container spacing={2}>
      <Breadcrumb paths={ORDER_ADD} />
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <StyleHeaderItem>
            <StyleTitle>Thêm đơn hàng</StyleTitle>
          </StyleHeaderItem>
          <StyleForm autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <StyleContentRight>
              <StyleContentItem>
                <StyleTitleDescribe>Thông tin khách hàng</StyleTitleDescribe>
                <StyleInput>
                  <StyleInputField>
                    <Autocomplete
                      sx={{ padding: '10px 0' }}
                      {...register('phone')}
                      disablePortal
                      size="small"
                      options={dataUser}
                      getOptionLabel={(option) => option.phone}
                      noOptionsText={
                        dataCount === 0 ? (
                          <>
                            {phoneUser.length === 10 &&
                            validatePhone === true ? (
                              <div
                                onClick={handleOpenModalUser}
                                style={{
                                  color: 'blue',
                                  cursor: 'pointer',
                                }}
                              >
                                Tạo mới {phoneUser}
                              </div>
                            ) : validatePhone === false && phoneUser ? (
                              <div
                                style={{
                                  color: 'red',
                                }}
                              >
                                Số điện thoại không hợp lệ!
                              </div>
                            ) : (
                              <div
                                style={{
                                  color: 'blue',
                                }}
                              >
                                Vui lòng nhập số điện thoại...
                              </div>
                            )}
                          </>
                        ) : null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Số điện thoại"
                          placeholder="Số điện thoại"
                        />
                      )}
                      onInputChange={handleSearchUser}
                      onChange={(event, value: any) => {
                        if (value === null) {
                          setLocationUser([])
                          setIdUser('')
                        }
                        setValueLocation('')
                        setIdUser(value)
                        setIsChanged(true)
                      }}
                      onKeyPress={handleKeyPress}
                    />
                    {errors.phone && (
                      <StyleError>
                        {!isChanged ? (errors.phone.message as string) : null}
                      </StyleError>
                    )}
                  </StyleInputField>
                  <StyleInputField className="input-views">
                    <InputField
                      {...register('name')}
                      size="small"
                      label="Tên khách hàng"
                      multiline={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ readOnly: true }}
                    />
                    {errors.name && (
                      <StyleError>
                        {!isChanged ? (errors.name.message as string) : null}
                      </StyleError>
                    )}
                  </StyleInputField>
                </StyleInput>
              </StyleContentItem>
              <StyleContentItem>
                <StyleTitleDescribe>Thông tin dịch vụ</StyleTitleDescribe>
                {!openModalLocation ? (
                  <StyleInputField>
                    <Autocomplete
                      sx={{ padding: '10px 0' }}
                      size="small"
                      options={[...locationUser, ...options]}
                      getOptionLabel={() =>
                        valueLocation.fullLocation
                          ? valueLocation.fullLocation
                          : ''
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.value === 1 && idUser ? (
                            <>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  color: 'blue',
                                  width: '100%',
                                  height: '100%',
                                }}
                                onClick={() => setOpenModaLocation(true)}
                              >
                                <AddLocationTwoToneIcon /> Thêm địa chỉ mới
                              </div>
                            </>
                          ) : (
                            option.fullLocation
                          )}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option?.id === value?.id
                      }
                      disableClearable
                      noOptionsText="Chưa có lựa chọn"
                      renderInput={(params) => (
                        <TextField
                          {...register('fullLocation')}
                          {...params}
                          label="Địa chỉ làm việc"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                      onChange={(event: any, newValue: any) => {
                        setValueLocation(newValue)
                      }}
                    />
                    {errors.fullLocation && (
                      <StyleError>
                        {!valueLocation
                          ? (errors.fullLocation.message as string)
                          : null}
                      </StyleError>
                    )}
                  </StyleInputField>
                ) : null}
                <div style={{ width: '100%' }}>
                  <InputField
                    {...register('serviceId')}
                    size="small"
                    id="serviceId"
                    label="Tên dịch vụ"
                    select
                    value={valueService}
                    sx={{
                      width: '100%',
                    }}
                    onChange={handleChangeService}
                  >
                    {dataServices?.map((option: any) => (
                      <MenuItem key={option?.id} value={option?.id}>
                        {option?.name}
                      </MenuItem>
                    ))}
                  </InputField>
                  {errors.serviceId && (
                    <StyleError>
                      {!valueService
                        ? (errors.serviceId.message as string)
                        : null}
                    </StyleError>
                  )}
                </div>
                <StyleInput>
                  <StyleInputField>
                    <InputField
                      size="small"
                      {...register('phoneContact')}
                      label="Số điện thoại"
                      multiline={true}
                      value={idUser?.phone}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.phoneContact && (
                      <StyleError>
                        {!idUser?.phone
                          ? (errors.phoneContact.message as string)
                          : null}
                      </StyleError>
                    )}
                  </StyleInputField>
                  <StyleInputField>
                    <InputField
                      {...register('nameContact')}
                      size="small"
                      label="Tên liên lạc"
                      multiline={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {errors.nameContact && (
                      <StyleError>
                        {!idUser?.fullName
                          ? (errors.nameContact.message as string)
                          : null}
                      </StyleError>
                    )}
                  </StyleInputField>
                </StyleInput>
              </StyleContentItem>
              <StyleContentItem>
                <StyleTitleDescribe>Thời lượng và thời gian</StyleTitleDescribe>
                <div style={{ width: '100%' }}>
                  <InputField
                    {...register('serviceItems')}
                    size="small"
                    id="timeDes"
                    label="Thời lượng"
                    select
                    value={valueServiceItem}
                    sx={{
                      width: '100%',
                    }}
                    onChange={handleChangeServiceItem}
                  >
                    {dataServicesItem?.serviceItems?.map((option: any) => (
                      <MenuItem key={option?.id} value={option?.id}>
                        {option?.name} {option?.shortDescription}
                      </MenuItem>
                    ))}
                  </InputField>
                  {errors.serviceItems && (
                    <StyleError>
                      {!isCalc ? (errors.serviceItems.message as string) : null}
                    </StyleError>
                  )}
                </div>

                <StyleInput style={{ paddingBottom: '10px' }}>
                  <StyleInputField
                    style={{
                      paddingTop: '10px',
                    }}
                  >
                    <Controller
                      control={control}
                      name="date"
                      render={({
                        field: { onChange, ref, onBlur, name, ...field },
                      }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            minDate={new Date().toISOString()}
                            maxDate={dayjs().add(6, 'day').format('YYYY-MM-DD')}
                            {...field}
                            inputRef={ref}
                            inputFormat="DD/MM/YYYY"
                            label="Thứ ngày"
                            renderInput={(inputProps) => (
                              <TextField
                                {...inputProps}
                                size="small"
                                onBlur={onBlur}
                                name={name}
                                error={false}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{ readOnly: true }}
                                value={dayjs(field.value)
                                  .format('dddd, DD/MM/YYYY')
                                  .replace(/^\w/, (c) => c.toUpperCase())}
                              />
                            )}
                            onChange={(e: any) => {
                              onChange(e)
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    {errors.date && (
                      <StyleError>{errors.date.message as string}</StyleError>
                    )}
                  </StyleInputField>
                  <StyleInputField>
                    <InputField
                      type="time"
                      size="small"
                      {...register('startTime')}
                      label="Giờ bắt đầu"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e: any) => {
                        handleHourPlus(e.target.value)
                        setStartTime(e.target.value)
                      }}
                    />
                    {errors.startTime && (
                      <StyleError>
                        {!startTime
                          ? (errors.startTime.message as string)
                          : null}
                      </StyleError>
                    )}
                  </StyleInputField>
                  <StyleInputField>
                    <InputField
                      size="small"
                      type="time"
                      {...register('endTime')}
                      label="Giờ kết thúc"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ readOnly: true }}
                      value={endTime}
                    />
                  </StyleInputField>
                </StyleInput>
              </StyleContentItem>
              {dataServicesItem?.itemExtraOptions ? (
                <StyleContentItem>
                  <StyleTitleDescribe>Lựa chọn thêm</StyleTitleDescribe>
                  <List sx={{ width: '100%' }}>
                    {dataServicesItem?.itemExtraOptions?.map(
                      (option: any, index: number) => (
                        <FormGroup
                          key={index}
                          sx={{
                            marginLeft: '15px !important',
                            fontSize: '14px !important',
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                id={option.id}
                                name={option.name}
                                checked={checkedItems[option.name] || false}
                                onChange={handleCheckboxChange}
                                inputProps={{
                                  'aria-label': 'Checkbox example',
                                }}
                              />
                            }
                            label={option?.name}
                          />
                        </FormGroup>
                      )
                    )}
                    {dataServicesItem?.serviceExtraOptions?.map(
                      (value: any) => {
                        const labelId = `checkbox-list-label-${value.id}`
                        return (
                          <>
                            <ListItem disablePadding>
                              <ListItemButton
                                role={undefined}
                                onClick={() =>
                                  handleServiceOptions(value?.id, value?.key)
                                }
                                dense
                                disabled={
                                  value.key == 'prioritize' ||
                                  value.key == 'manualSelect'
                                    ? true
                                    : false
                                }
                              >
                                <ListItemIcon sx={{ minWidth: '0' }}>
                                  <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(value.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={` ${value.name}`}
                                />
                              </ListItemButton>
                            </ListItem>
                            {value.key == 'pet' &&
                            checked.indexOf(value.id) !== -1 ? (
                              <>
                                <InputField
                                  size="small"
                                  {...register('pet')}
                                  value={valuePet}
                                  label="Vật nuôi"
                                  multiline={true}
                                  onChange={(e: any) => handlePetChange(e)}
                                />
                                {isPetError && (
                                  <StyleError>{isPetError}</StyleError>
                                )}
                              </>
                            ) : null}
                          </>
                        )
                      }
                    )}
                  </List>
                </StyleContentItem>
              ) : null}
              <StyleContentItem>
                <InputField
                  size="small"
                  {...register('note')}
                  label="Ghi chú"
                  multiline={true}
                />
                {errors.note && (
                  <StyleError>{errors.note.message as string}</StyleError>
                )}
              </StyleContentItem>
              <StyleContentItem
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexDirection: 'column',
                }}
              >
                <StyleTitleDescribe>Thông tin thanh toán</StyleTitleDescribe>
                <div style={{ width: '35%' }}>
                  <InputField
                    size="small"
                    label="Phương thức thanh toán"
                    select
                    value={valuePaymentMethod}
                    {...register('paymentMethod')}
                    sx={{
                      width: '100%',
                    }}
                    onChange={handleChangePaymentMethod}
                  >
                    {paymentMethod?.map((option: any) => (
                      <MenuItem key={option?.id} value={option?.name}>
                        {option?.title}
                      </MenuItem>
                    ))}
                  </InputField>
                  {errors.paymentMethod && (
                    <StyleError>
                      {!valuePaymentMethod
                        ? (errors.paymentMethod.message as string)
                        : null}
                    </StyleError>
                  )}
                </div>
                <StyleInputField style={{ width: '35%' }}>
                  <InputField
                    className="upperCase"
                    size="small"
                    {...register('promotionCode')}
                    label="Mã khuyến mãi"
                    multiline={true}
                    onChange={handleChangePromotion}
                  />
                  <StyleError>{errorPromotion}</StyleError>
                </StyleInputField>
                <StyleInput
                  style={{ justifyContent: 'space-between', width: '35%' }}
                >
                  Tổng tiền
                  <StylePrice>
                    {calcPrice
                      ? (calcPrice?.subTotalPrice as number).toLocaleString(
                          'vi-VN',
                          {
                            style: 'currency',
                            currency: 'VND',
                          }
                        )
                      : (0).toLocaleString('vi-VN', {
                          style: 'currency',
                          currency: 'VND',
                        })}
                  </StylePrice>
                </StyleInput>
                <StyleInput
                  style={{ justifyContent: 'space-between', width: '35%' }}
                >
                  Số tiền được giảm:{' '}
                  <StylePrice>
                    {calcPrice
                      ? (-calcPrice?.discountPrice as number).toLocaleString(
                          'vi-VN',
                          {
                            style: 'currency',
                            currency: 'VND',
                          }
                        )
                      : (0).toLocaleString('vi-VN', {
                          style: 'currency',
                          currency: 'VND',
                        })}
                  </StylePrice>
                </StyleInput>
                <StyleInput
                  style={{ justifyContent: 'space-between', width: '35%' }}
                >
                  Thu thêm tiền mặt: <StylePrice></StylePrice>
                </StyleInput>
                <StyleInput
                  style={{ justifyContent: 'space-between', width: '35%' }}
                >
                  Thành tiền
                  <StylePrice
                    style={{
                      fontSize: '17',
                      color: 'var(--color-primary)',
                      fontWeight: '600',
                    }}
                  >
                    {calcPrice
                      ? (calcPrice?.grandTotalPrice as number).toLocaleString(
                          'vi-VN',
                          {
                            style: 'currency',
                            currency: 'VND',
                          }
                        )
                      : (0).toLocaleString('vi-VN', {
                          style: 'currency',
                          currency: 'VND',
                        })}
                  </StylePrice>
                </StyleInput>
              </StyleContentItem>
              <StyleActionButton>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{
                    paddingTop: '50px',
                    gap: '8px',
                    position: 'relative',
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => {
                      if (isPet) {
                        if (valuePet) {
                          setIsPetError('')
                        } else {
                          setIsPetError('Vật nuôi không được để trống')
                          return
                        }
                      }
                    }}
                  >
                    Lưu
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setOpenPopUp(true)
                    }}
                  >
                    Hủy
                  </Button>
                </Stack>
              </StyleActionButton>
            </StyleContentRight>
            <StyleContentLeft>
              <StyleContentItem className="input-views">
                <StyleTitleDescribe>Thông tin đơn hàng</StyleTitleDescribe>
                <div style={{ width: '100%' }}>
                  <InputField
                    size="small"
                    id="serviceId"
                    label="Trạng thái thanh toán"
                    select
                    sx={{
                      width: '100%',
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ readOnly: true }}
                  />
                  <InputField
                    size="small"
                    id="serviceId"
                    label="Trạng thái đơn hàng"
                    select
                    sx={{
                      width: '100%',
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ readOnly: true }}
                  />
                </div>
                <StyleInput style={{ paddingBottom: '10px' }}>
                  {displayIdStaff?.length === 0 && idStaff ? (
                    <StyleInputField>
                      <InputField
                        {...register('displayId')}
                        size="small"
                        label="Mã nhân viên"
                        multiline={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ readOnly: true }}
                      />
                    </StyleInputField>
                  ) : (
                    <StyleInputField>
                      <Autocomplete
                        sx={{ padding: '10px 0' }}
                        {...register('displayId')}
                        disablePortal
                        size="small"
                        options={dataStaff || []}
                        getOptionLabel={(option) => option.displayId}
                        noOptionsText={
                          dataCountStaff === 0 && !idStaff ? (
                            <div style={{ color: 'blue' }}>
                              Vui lòng nhập mã id
                            </div>
                          ) : null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Mã nhân viên"
                            placeholder="Mã nhân viên"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        onInputChange={handleSearchStaffId}
                        onChange={(event, value: any) => {
                          setIdStaff(value)
                        }}
                        onKeyPress={handleKeyPress}
                      />
                      {errors.phone && (
                        <StyleError>
                          {!isChanged ? (errors.phone.message as string) : null}
                        </StyleError>
                      )}
                    </StyleInputField>
                  )}

                  <StyleInputField>
                    {phoneStaff?.length === 0 && idStaff ? (
                      <>
                        <InputField
                          {...register('phoneStaff')}
                          size="small"
                          label="Số điện thoại NV"
                          multiline={true}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ readOnly: true }}
                        />
                      </>
                    ) : (
                      <>
                        <Autocomplete
                          sx={{ padding: '10px 0' }}
                          {...register('phoneStaff')}
                          disablePortal
                          size="small"
                          options={dataStaff}
                          getOptionLabel={(option) => option.phone}
                          noOptionsText={
                            dataCountStaff === 0 ? (
                              <>
                                {!validatePhoneStaff && phoneStaff ? (
                                  <div
                                    style={{
                                      color: 'red',
                                    }}
                                  >
                                    Số điện thoại không hợp lệ!
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      color: 'blue',
                                    }}
                                  >
                                    Vui lòng nhập số điện thoại...
                                  </div>
                                )}
                              </>
                            ) : null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Số điện thoại NV"
                              placeholder="Số điện thoại"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          onInputChange={handleSearchStaff}
                          onChange={(event, value: any) => {
                            setIdStaff(value)
                          }}
                          onKeyPress={handleKeyPress}
                        />
                        {errors.phone && (
                          <StyleError>
                            {!isChanged
                              ? (errors.phone.message as string)
                              : null}
                          </StyleError>
                        )}
                      </>
                    )}
                  </StyleInputField>
                </StyleInput>

                <StyleInputField>
                  <InputField
                    sx={{ width: '47.5%' }}
                    {...register('nameStaff')}
                    size="small"
                    label="Nhân viên"
                    multiline={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ readOnly: true }}
                  />
                </StyleInputField>
                <StyleInput style={{ justifyContent: 'space-between' }}>
                  Thời gian bắt đầu: <StylePrice>--</StylePrice>
                </StyleInput>
                <StyleInput
                  style={{
                    justifyContent: 'space-between',
                    paddingBottom: '10px',
                  }}
                >
                  Thời gian kết thúc: <StylePrice>--</StylePrice>
                </StyleInput>
              </StyleContentItem>
            </StyleContentLeft>
            <BasicModal open={openModalLocation}>
              <StyleTitleDescribe>Thêm địa chỉ mới</StyleTitleDescribe>
              <StyleInputField style={{ padding: '10px 0' }}>
                <Autocomplete
                  sx={{ padding: '10px 0' }}
                  size="small"
                  options={
                    selectLocation
                      ? selectLocation
                      : [{ fulLocation: 'Option 1' }]
                  }
                  getOptionLabel={(option: any) => option.address}
                  noOptionsText="Chưa có lựa chọn"
                  renderInput={(params) => (
                    <TextField
                      {...register('addLocation')}
                      {...params}
                      label="Địa chỉ làm việc"
                    />
                  )}
                  onInputChange={handleInputChange}
                  onChange={(event: any, newValue: any) => {
                    setAddLocation(newValue)
                  }}
                />
              </StyleInputField>
              <Button
                sx={{ margin: '8px', marginBottom: '0px' }}
                variant="contained"
                onClick={handleAddLocation}
              >
                Lưu
              </Button>
              <Button
                sx={{ margin: '8px', marginBottom: '0px' }}
                onClick={() => setOpenModaLocation(false)}
                variant="outlined"
              >
                Hủy
              </Button>
            </BasicModal>
            <BasicModal open={openModalUser}>
              <StyleTitleDescribe>Thêm khách hàng mới</StyleTitleDescribe>
              <StyleInputField style={{ padding: '10px 0' }}>
                <InputField
                  size="small"
                  value={phoneUser}
                  label="Số điện thoại"
                  onChange={(event: any) => setPhoneUser(event.target.value)}
                />
                <InputField
                  {...register('addName')}
                  size="small"
                  label="Tên khách hàng"
                  value={nameUser}
                  onChange={(event: any) => {
                    setNameUser(event.target.value)
                  }}
                />
              </StyleInputField>
              <Button
                sx={{ margin: '8px', marginBottom: '0px' }}
                variant="contained"
                onClick={handleAddUser}
              >
                Lưu
              </Button>
              <Button
                sx={{ margin: '8px', marginBottom: '0px' }}
                onClick={() => {
                  setOpenModaUser(false)
                  setPhoneUser('')
                }}
                variant="outlined"
              >
                Hủy
              </Button>
            </BasicModal>

            <BasicModal open={openPopUp}>
              <StyleTitleDescribePopup>
                Bạn có chắc chắn muốn huỷ không ?
              </StyleTitleDescribePopup>
              <Button
                sx={{ margin: '8px', marginBottom: '0px' }}
                variant="contained"
                onClick={() => {
                  navigate('/order')
                  setOpenPopUp(false)
                }}
              >
                Xác nhận
              </Button>
              <Button
                sx={{ margin: '8px', marginBottom: '0px' }}
                onClick={() => {
                  setOpenPopUp(false)
                }}
                variant="outlined"
              >
                Hủy
              </Button>
            </BasicModal>
          </StyleForm>
        </Item>
      </Grid>
    </Grid>
  )
}

export default AddOrder
