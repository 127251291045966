import { VALIDATE_NUMBER } from 'utils/regex'
import * as yup from 'yup'

export const campaignSchema_ = yup.object().shape({
  campaign: yup
    .string()
    .required('Tên chiến dịch không được để trống')
    .min(3, 'Tên chiến dịch tối thiểu 3 ,tối đa 50 ký tự')
    .max(50, 'Tên chiến dịch tối thiểu 3 ,tối đa 50 ký tự'),
  purpose: yup
    .string()
    .required('Mục đích không được để trống')
    .min(3, 'Mục đích tối thiểu 3 ký tự'),
  reviewer: yup.string(),
  flatform: yup.string().required('Nền tảng không được để trống'),
  content: yup
    .string()
    .required('Nội dung không được để trống')
    .min(3, 'Nội dung tối thiểu 3 ký tự'),

  expense: yup
    .string()
    .required('Chi phí dự kiến không được để trống')
    .matches(VALIDATE_NUMBER, 'Chi phí dự kiến là số'),
  start: yup
    .string()
    .required('Ngày bắt đầu chiến dịch không được để trống')
    .test(
      'start',
      'Ngày bắt đầu phải trước ngày kết thúc',
      function (value: string | undefined) {
        const { end } = this.parent
        if (!value) {
          return true
        }
        return !end || new Date(value) <= new Date(end)
      }
    ),
  end: yup
    .string()
    .required('Ngày kết thúc chiến dịch không được để trống')
    .test(
      'end',
      'Ngày kết thúc phải sau ngày bắt đầu',
      function (value: string | undefined) {
        const { start } = this.parent
        if (!value) {
          return true
        }
        return !start || new Date(value) >= new Date(start)
      }
    ),
  revenue: yup
    .string()
    .required('Doanh thu dự kiến không được để trống')
    .matches(VALIDATE_NUMBER, 'Doanh thu dự kiến là số'),
  feedbackNumbers: yup
    .string()
    .required('Lượng phản hồi dự kiến không được để trống')
    .matches(VALIDATE_NUMBER, 'Lượng phản hồi dự kiến là số'),
  leadNumbers: yup
    .string()
    .required('Số lead không được để trống')
    .matches(VALIDATE_NUMBER, 'Số lead là số'),
  customerNumbers: yup
    .string()
    .required('Số khách hàng dự kiến không được để trống')
    .matches(VALIDATE_NUMBER, 'Số khách hàng dự kiến là số'),
  profit: yup.string().required('Lợi nhuận dự kiến không được để trống'),
  note: yup.string().min(3, 'Ghi chú tối thiểu 3 ký tự'),
  refLink: yup.string().required('Link không được để trống'),
})
