export const promotionStatus = [
  {
    // value: 'Hiển thị',
    value: 'show',
    label: 'Hiển thị',
  },
  {
    // value: 'Không hiển thị',
    value: 'hide',
    label: 'Không hiển thị',
  },
  {
    value: 'disable',
    // value: 'Vô hiệu hoá',
    label: 'Vô hiệu hoá',
  },
]

export const promotionCode = [
  {
    value: 'code',
    label: 'Mã khuyến mãi',
  },
  {
    value: 'point',
    label: 'Đổi điểm',
  },
  {
    value: 'point',
    label: 'Đổi quà',
  },

]

export const promotionPrice = [
  {
    value: 'Giảm tiền',
    label: 'Giảm tiền',
  },
  {
    value: 'Giảm phần trăm',
    label: 'Giảm phần trăm',
  },
]
export const promotionNation = [
  {
    value: 'Việt Nam',
    label: 'Việt Nam',
  },
]
export const promotionObject = [
  {
    value: 'Tất cả',
    label: 'Tất cả',
  },
  {
    value: 'Hạng khách hàng',
    label: 'Hạng khách hàng',
  },
  {
    value: 'Nhóm khách hàng',
    label: 'Nhóm khách hàng',
  },
  {
    value: 'Khách hàng cụ thể',
    label: 'Khách hàng cụ thể',
  },
]
