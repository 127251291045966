import React, { useState } from 'react'

export default function countDownFunction() {
  const [remainingMinutes, setRemainingMinutes] = useState(0)
  const [remainingSeconds, setRemainingSeconds] = useState(0)
  const timeRemaining = Number(localStorage.getItem('countdownTime'))
  const [countdownTime, setCountdownTime] = useState(timeRemaining || 0)
  const countDown = () => {
    const countdownTimeInMili = timeRemaining || 0
    const utcDate = new Date().toISOString()
    const now = new Date(utcDate).getTime()
    const remainingTimeInMillis = countdownTimeInMili - now + 1000
    const remainingSeconds = Math.floor((remainingTimeInMillis / 1000) % 60)
    const remainingMinutes = Math.floor(remainingTimeInMillis / 1000 / 60)
    if (remainingSeconds >= 0) {
      setRemainingMinutes(remainingMinutes)
      setRemainingSeconds(remainingSeconds)
      setCountdownTime(remainingSeconds)
    } else {
      localStorage.removeItem('countdownTime')
    }
  }
  return {
    countDown,
    remainingMinutes,
    remainingSeconds,
    countdownTime,
    setCountdownTime,
  }
}
