import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import Delete from 'components/Icons/components/Delete'
import Edit from 'components/Icons/components/Edit'
import Item from 'components/Item'
import BasicModal from 'components/Modal'
import { DELETE_CODE } from 'constants/common'
import { useAlert } from 'contexts/AlertContext'
import { useLoading } from 'contexts/LoadingContext'
import { StyleTableCell } from 'pages/order/style'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import rangeOfActiveApi from 'services/houseCleaningAPI'
import { StyleLink, StyleLinks, StyleTitle } from './style'

const RangeOfActive = () => {
  const paramIds = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<any>()
  const [modalOpen, setModalOpen] = useState(false)
  const { serviceId } = useParams()
  const [deleteId, setDeleteId] = useState({
    id: 1,
  })
  const [isDelete, setIsDelete] = useState('')

  const { setLoadingTrue, setLoadingFalse } = useLoading()
  const { setAlertSuccess, setAlertClose, setAlertError } = useAlert()
  const fetchData = async () => {
    const { data } = await rangeOfActiveApi.getRangeOfActive({
      serviceId: paramIds.serviceId,
    })
    if (data) {
      const result = data.map((country: any) => ({
        countryName: country?.country?.native,
        countryId: country?.countryId,
        status: true,
        premium: false,
        province: country.province.map((provinces: any) => ({
          name: provinces.province.name,
          premium: provinces.isPremium,
          provinceId: provinces?.provinceId,
          areaId: provinces.areaId,
          status: provinces.status,
        })),
      }))
      setData(result)
    } else {
      setData(undefined)
    }
  }

  const handlePremiumChange = async (premium: boolean, id: string) => {
    await rangeOfActiveApi.UpdateStatus(
      {
        isPremium: !premium,
      },
      id
    )
    fetchData()
  }
  const handleStatusChange = async (status: boolean, id: string) => {
    await rangeOfActiveApi.UpdateStatus(
      {
        status: !status,
      },
      id
    )
    fetchData()
  }
  const handleDelete = async (id: number) => {
    setAlertClose()
    setLoadingTrue()
    try {
      await rangeOfActiveApi.deleteCountry({
        id: id,
        serviceId: serviceId,
      })
      setModalOpen(false)
      setIsDelete('')
      fetchData()
      setLoadingFalse()
      setAlertSuccess('Xóa phạm vi hoạt động thành công')
    } catch (err) {
      setLoadingFalse()
      setAlertError('Xoá phạm vi hoạt động không thành công')
    }
  }
  const handleModalDelete = (id: number) => {
    setModalOpen(true)
    setDeleteId({
      id: id,
    })
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="padding-bottom">
        <Item sx={{ padding: '12px 24px' }} className="bd-radius">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              sx={{
                textTransform: 'capitalize',
                cursor: 'default',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
            >
              Quản lý dịch vụ
            </Link>
            <Link
              sx={{
                textTransform: 'capitalize',
                cursor: 'pointer',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
              onClick={() => navigate(`/service/${serviceId}`)}
            >
              Dọn dẹp nhà gói lẻ
            </Link>

            <Link
              sx={{
                textTransform: 'capitalize',
                fontWeight: 'bold',
                cursor: 'default',
                '&:hover': {},
              }}
              underline="none"
              color="inherit"
            >
              Phạm vi hoạt động
            </Link>
          </Breadcrumbs>
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item sx={{ padding: '24px' }}>
          <div>
            <StyleTitle>Phạm vi hoạt động</StyleTitle>
            <StyleLinks
              onClick={() =>
                navigate(
                  `/service/housecleaning/${paramIds.serviceId}/range-of-active-create`
                )
              }
            >
              <AddCircleIcon sx={{ marginRight: '10px', color: 'white' }} />
              <StyleLink>Thêm mới</StyleLink>
            </StyleLinks>
          </div>
          <TableContainer component={Paper} className="box-shadow">
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <StyleTableCell align="center">Quốc gia</StyleTableCell>
                  <StyleTableCell align="center">Tỉnh/TP</StyleTableCell>
                  <StyleTableCell align="center">Trạng thái</StyleTableCell>
                  <StyleTableCell align="center">Premium</StyleTableCell>
                  <StyleTableCell align="center">Hành động</StyleTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row: any, index: number) => (
                  <>
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row" align="center">
                        {row?.countryName}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                      ></TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                      ></TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                      ></TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '10px',
                            alignItems: 'center',
                            minHeight: '35px',
                          }}
                        >
                          <Edit
                            onClick={() =>
                              navigate(
                                `/housecleaning/${serviceId}/range-of-active-edit/${row.countryId}`
                              )
                            }
                          />
                          <Delete
                            onClick={() => {
                              handleModalDelete(row?.countryId)
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    {row?.province?.map((item: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                        ></TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {item?.name}
                        </TableCell>

                        <TableCell component="th" scope="row" align="center">
                          <Switch
                            checked={item.status}
                            onChange={() => {
                              handleStatusChange(item.status, item.areaId)
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Switch
                            checked={item.premium}
                            onChange={() => {
                              handlePremiumChange(item.premium, item.areaId)
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                        ></TableCell>
                      </TableRow>
                    ))}
                  </>
                ))}
              </TableBody>
              <BasicModal
                handleClose={() => {
                  setIsDelete(''), setModalOpen(false)
                }}
                open={modalOpen}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  <h4 style={{ fontSize: '1rem', fontWeight: '600' }}>
                    Bạn có chắc chắn xoá quốc gia này không?
                  </h4>
                  <div>
                    <CloseIcon
                      sx={{
                        cursor: 'pointer',

                        '&:hover': { backgroundColor: '#ececef' },
                      }}
                      onClick={() => {
                        setModalOpen(false)
                        setIsDelete('')
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginBottom: '1rem',
                    backgroundColor: '#fcf1ef',
                    fontSize: '0.875rem',
                    position: 'relative',
                    paddingLeft: '3rem',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                  }}
                >
                  <ErrorOutlineIcon
                    sx={{
                      width: '20px',
                      color: 'red',
                      position: 'absolute',
                      top: '1rem',
                      left: '1rem',
                    }}
                  />
                  <div style={{ fontSize: '0.875rem', textAlign: 'left' }}>
                    <h4
                      style={{
                        fontSize: '0.875rem',
                        fontWeight: '600',
                        marginTop: '0px',
                        marginBottom: '0.5rem',
                      }}
                    >
                      Toàn bộ dữ liệu về quốc gia bao gồm:
                    </h4>
                    <ul>
                      <li>* Các tỉnh/ thành phố đang hoạt động</li>
                      <li>* Giá trong phần cấu hình giá</li>
                    </ul>
                  </div>
                  <h4
                    style={{
                      fontSize: '0.875rem',
                      fontWeight: '600',
                      marginTop: '10px',
                      marginBottom: '0.5rem',
                      textAlign: 'left',
                    }}
                  >
                    Tất cả các dữ liệu liên quan sẽ không thể khôi phục.
                  </h4>
                </div>
                <Typography sx={{ textAlign: 'left' }}>
                  Nhập đoạn chữ sau để xác nhận:
                </Typography>
                <div
                  style={{
                    width: 'fit-content',
                    padding: '4px 5px',
                    color: '#1f1e24',
                    backgroundColor: '#ececef',
                    borderRadius: '4px',
                    marginBottom: '20px',
                  }}
                >
                  {DELETE_CODE}
                </div>
                <TextField
                  size="small"
                  fullWidth
                  onChange={(e: any) => setIsDelete(e.target.value)}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '15px',
                  }}
                >
                  {isDelete === DELETE_CODE ? (
                    <Button
                      sx={{ margin: '8px', marginBottom: '0px' }}
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(deleteId.id)}
                    >
                      Xóa
                    </Button>
                  ) : (
                    <Button
                      sx={{
                        margin: '8px',
                        marginBottom: '0px',
                        cursor: 'default',
                        '&:disabled': {
                          opacity: '0.7',
                          color: '#fff',
                          backgroundColor: '#f15f5f',
                        },
                      }}
                      disabled
                      variant="contained"
                    >
                      Xóa
                    </Button>
                  )}

                  <Button
                    sx={{
                      margin: '8px',
                      marginBottom: '0px',
                      marginRight: '0px',
                    }}
                    onClick={() => {
                      setModalOpen(false), setIsDelete('')
                    }}
                    variant="outlined"
                  >
                    Hủy
                  </Button>
                </div>
              </BasicModal>
            </Table>
          </TableContainer>
        </Item>
      </Grid>
    </Grid>
  )
}

export default RangeOfActive
